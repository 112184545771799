<template>
    <section class="endodontic-list panel">
        <h2 class="heading">{{ t('title', 'endodonticTest') }} </h2>

        <ul class="tests">
            <li
                v-for="testName in testNames"
                :key="testName"
                class="test"
                :data-name="testName"
                :class="{ active: isTestInProgress(testName) }"
            >

                <button type="button" v-on:click="toggleTest(testName)">
                    <span class="name">
                        {{ testLabel(testName) }}
                    </span>

                    <span class="value">
                        {{ currentValueLabel(testName) }}
                    </span>
                </button>

            </li>
        </ul>
    </section>
</template>

<script>

export default {
    components: {  },
    props: {
        tooth: { required: true }
    },
    data() {
        return {

        };
    },
    computed:
    {
        toothNumber() {
            return this.tooth.number;
        },
        testNames() {
            return this.$store.getters['patient/teeth/state/endodontic/testNames'];
        }
    },
    methods:
    {
        test(testName) {
            return this.$store.getters['patient/teeth/state/endodontic/test']({
                toothNumber: this.toothNumber,
                testName
            });
        },

        testLabel(testName) {
            return this.$store.getters['patient/teeth/state/endodontic/testLabel'](testName);
        },

        currentValueLabel(testName) {
            const test = this.test(testName);
            const validValues = [test.result, test.subresult].filter(value => value != 0);

            if (validValues.length < 1) {
                return this.t('test', 'endodonticTest');
            } else {
                return validValues.map(value => this.$store.getters['patient/teeth/state/endodontic/valueLabel']({ testName, value })).join(', ');
            }
        },

        isTestInProgress(testName) {
            return this.$store.getters['patient/teeth/state/endodontic/isTestInProgress'](this.toothNumber, testName);
        },

        toggleTest(testName) {
            this.ensureTeethStateModifiability().then(() => {
                const toothNumber = this.toothNumber;
                if (!this.$store.getters['patient/teeth/state/endodontic/isTestInProgress'](toothNumber, testName)) {
                    return this.$store.dispatch('patient/teeth/state/endodontic/startEditingTest', { toothNumber, testName });
                } else {
                    return this.$store.dispatch('patient/teeth/state/endodontic/endEditingTest');
                }
            }, () => {});
        }
    }
};
</script>
