import clone from '@/utils/clone.js';
import freeze from '@/utils/freeze.js';

import {
    ERUPTION_STATES,
    TOOTH_SETS,
    TOOTH_SLOTS_BY_TOOTH_NUMBER,
    TOOTH_NUMBERS_BY_SLOT,
    TOOTH_SLOTS_WITH_MULTIPLE_TOOTH_NUMBERS,
    TOOTH_SETS_BY_TOOTH_NUMBER
} from '@/config/teeth.js';

const OPERATIONS = freeze({
    NOT_YET_DEVELOPED: "not_yet_developed",
    DECIDUOUS_TOOTH: "deciduous_tooth",
    DECIDUOUS_TOOTH_MISSING: "deciduous_tooth_missing",
    PERMANENT_TOOTH: "permanent_tooth",
    PERMANENT_TOOTH_MISSING: "permanent_tooth_missing"
});

const PARAMS = freeze({
    operation: {
        labelKey: "quickselect.slotState.selectState",

        options: [
            { value: OPERATIONS.NOT_YET_DEVELOPED,         labelKey: "quickselect.slotState.operations.not_yet_developed" },
            { value: OPERATIONS.DECIDUOUS_TOOTH,           labelKey: "quickselect.slotState.operations.deciduous_tooth" },
            { value: OPERATIONS.DECIDUOUS_TOOTH_MISSING,   labelKey: "quickselect.slotState.operations.deciduous_tooth_missing" },
            { value: OPERATIONS.PERMANENT_TOOTH,           labelKey: "quickselect.slotState.operations.permanent_tooth" },
            { value: OPERATIONS.PERMANENT_TOOTH_MISSING,   labelKey: "quickselect.slotState.operations.permanent_tooth_missing" },
        ]
    }
});

const TASKS = freeze({
    SET_ERUPTION_STATE: "set_eruption",
    SET_PRESENCE: "set_presence"
});

const TASKS_BY_OPERATION = freeze({
    [OPERATIONS.NOT_YET_DEVELOPED]: {
        [TOOTH_SETS.DECIDUOUS]: {
            [TASKS.SET_ERUPTION_STATE]: ERUPTION_STATES.PENDING
        },
        [TOOTH_SETS.PERMANENT]: {
            [TASKS.SET_ERUPTION_STATE]: ERUPTION_STATES.PENDING
        },
    },
    [OPERATIONS.DECIDUOUS_TOOTH]: {
        [TOOTH_SETS.DECIDUOUS]: {
            [TASKS.SET_ERUPTION_STATE]: ERUPTION_STATES.ERUPTED,
            [TASKS.SET_PRESENCE]: true
        },
        [TOOTH_SETS.PERMANENT]: {
            [TASKS.SET_ERUPTION_STATE]: ERUPTION_STATES.PENDING
        },
    },
    [OPERATIONS.DECIDUOUS_TOOTH_MISSING]: {
        [TOOTH_SETS.DECIDUOUS]: {
            [TASKS.SET_ERUPTION_STATE]: ERUPTION_STATES.ERUPTED,
            [TASKS.SET_PRESENCE]: false
        },
        [TOOTH_SETS.PERMANENT]: {
            [TASKS.SET_ERUPTION_STATE]: ERUPTION_STATES.PENDING
        },
    },
    [OPERATIONS.PERMANENT_TOOTH]: {
        [TOOTH_SETS.PERMANENT]: {
            [TASKS.SET_ERUPTION_STATE]: ERUPTION_STATES.ERUPTED,
            [TASKS.SET_PRESENCE]: true
        },
    },
    [OPERATIONS.PERMANENT_TOOTH_MISSING]: {
        [TOOTH_SETS.PERMANENT]: {
            [TASKS.SET_ERUPTION_STATE]: ERUPTION_STATES.ERUPTED,
            [TASKS.SET_PRESENCE]: false
        },
    }
});


export default {

    namespaced: true,

    getters: {
        params: () => (selectedParams, selectedToothNumbers) => {
            const params = clone(PARAMS);

            // if any slot has beet selected that cannot have a deciduous tooth,
            // the remove deciduous teeth operations from available params
            const anySingleToothSlotsSelected = selectedToothNumbers.some(toothNumber => {
                const slotNumber = TOOTH_SLOTS_BY_TOOTH_NUMBER[toothNumber];
                return !TOOTH_SLOTS_WITH_MULTIPLE_TOOTH_NUMBERS.includes(slotNumber);
            });

            if (anySingleToothSlotsSelected) {
                const deciduousToothOperations = [ OPERATIONS.DECIDUOUS_TOOTH, OPERATIONS.DECIDUOUS_TOOTH_MISSING ];
                params.operation.options = params.operation.options.filter(option => {
                    return !deciduousToothOperations.includes(option.value);
                });
            }

            return params;
        }
    },

    actions: {
        perform(context, { numbers, params }) {
            const operation = params.operation;

            const slotNumbers = numbers.map(toothNumber => TOOTH_SLOTS_BY_TOOTH_NUMBER[toothNumber]);

            return Promise.all(slotNumbers.map(slotNumber => {
                return context.dispatch('performOperation', { slotNumber, operation });
            }));
        },
        performOperation(context, { slotNumber, operation }) {
            const toothNumbers = TOOTH_NUMBERS_BY_SLOT[slotNumber];
            const tasksByToothSet = TASKS_BY_OPERATION[operation];
            return Promise.all(Object.keys(tasksByToothSet).map(toothSet => {
                const toothNumber = toothNumbers.find(number => TOOTH_SETS_BY_TOOTH_NUMBER[number] == toothSet);
                if (!toothNumber && toothSet == TOOTH_SETS.DECIDUOUS && operation == OPERATIONS.NOT_YET_DEVELOPED) {
                    // "not_yet_developed" is the only operation that contains a deciduous tooth task,
                    // but is allowed to be performed on a slot that cannot have a deciduous tooth
                    return Promise.resolve();
                }
                const tasksWithValues = tasksByToothSet[toothSet];
                return context.dispatch('performTasks', { toothNumber, tasksWithValues });
            }));
        },
        performTasks(context, { toothNumber, tasksWithValues }) {
            return Promise.all(Object.keys(tasksWithValues).map(task => {
                const value = tasksWithValues[task];
                return context.dispatch('performTask', { toothNumber, task, value });
            }));
        },
        performTask(context, { toothNumber, task, value }) {
            if (task == TASKS.SET_ERUPTION_STATE) {
                const eruptionState = value;
                return context.dispatch('patient/teeth/state/updateToothEruptionState', { toothNumber, eruptionState }, { root: true });
            } else if (task == TASKS.SET_PRESENCE) {
                const storeAction = (value) ? 'restoreTooth' : 'markToothAsMissing';
                return context.dispatch('patient/teeth/state/' + storeAction, toothNumber, { root: true });
            }
            return Promise.resolve();
        }

    }

};
