import freeze from '@/utils/freeze.js';
import clone from '@/utils/clone.js';
import { TOOTH_TYPES, ZONES, TOOTH_SETS, TOOTH_TYPES_BY_TOOTH_NUMBER, TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE } from '@/config/teeth.js';

import { RESTORATION_DEFINITION_DEFAULT_DETAILS } from '@/config/restorations/general.js';

// "restoration": 4

const definition = {
    toothTypes: [TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};

const permanentPremolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.PREMOLAR];
const deciduousPremolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.DECIDUOUS][TOOTH_TYPES.PREMOLAR];
const permanentMolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.MOLAR];
const deciduousMolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.DECIDUOUS][TOOTH_TYPES.MOLAR];

const partialCrownToothNumbers = permanentPremolarNumbers.concat(deciduousPremolarNumbers, permanentMolarNumbers, deciduousMolarNumbers);

const partialCrownSurfaces = { };

const partialCrownDefaults = {
    [TOOTH_TYPES.PREMOLAR]: [ ZONES.BUCCAL ],
    [TOOTH_TYPES.MOLAR]: [ ZONES.MESIAL, ZONES.OCCLUSAL, ZONES.DISTAL ],
};
const partialCrownExclusives = {
    [TOOTH_TYPES.PREMOLAR]: [ ZONES.BUCCAL, ZONES.PALATAL, ZONES.LINGUAL ],
    [TOOTH_TYPES.MOLAR]: [ ],
};


// for permanent teeth, partial crown surfaces are the same for all teeth of the same tooth type

permanentPremolarNumbers.forEach(number => {
    partialCrownSurfaces[number] = {
        [ZONES.BUCCAL]: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 17, 18, 20],
        [ZONES.PALATAL]: [2, 4, 5, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19, 20],
        [ZONES.LINGUAL]: [2, 4, 5, 7, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19, 20],
        [ZONES.BUCCAL_CUSP]: [6, 11, 12],
        [ZONES.PALATAL_CUSP]: [13, 14, 16],
        [ZONES.LINGUAL_CUSP]: [13, 14, 16],
    };
});

permanentMolarNumbers.forEach(number => {
    partialCrownSurfaces[number] = {
        [ZONES.MESIAL]: [2, 7, 11, 18, 22],
        [ZONES.OCCLUSAL]: [12],
        [ZONES.DISTAL]: [6, 10, 13, 21, 26],
        [ZONES.MESIO_BUCCAL_CUSP]: [2, 3, 7, 8, 14],
        [ZONES.DISTO_BUCCAL_CUSP]: [5, 6, 9, 10, 15],
        [ZONES.MESIO_PALATAL_CUSP]: [16, 18, 19, 22, 23],
        [ZONES.MESIO_LINGUAL_CUSP]: [16, 18, 19, 22, 23],
        [ZONES.DISTO_PALATAL_CUSP]: [17, 20, 21, 25, 26],
        [ZONES.DISTO_LINGUAL_CUSP]: [17, 20, 21, 25, 26]
    };
});

// for deciduous teeth, partial crown surfaces are different for each individual tooth

partialCrownSurfaces["54"] = {
    [ZONES.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8].concat([9, 10, 11, 12, 13, 14, 15], [20, 22, 23, 26]),
    [ZONES.PALATAL]:  [2, 5, 6, 8].concat([13, 14, 15, 16, 17, 18, 19], [20, 21, 22, 23, 24, 25, 26, 27]),
    [ZONES.BUCCAL_CUSP]: [7].concat([10, 12], []),
    [ZONES.PALATAL_CUSP]: [].concat([17, 19], [21]),
};
partialCrownSurfaces["64"] = clone(partialCrownSurfaces["54"]);

partialCrownSurfaces["55"] = {
    [ZONES.MESIAL]: [2, 7].concat([13], [18, 22]),
    [ZONES.OCCLUSAL]: [].concat([14], []),
    [ZONES.DISTAL]: [6, 10].concat([15], [21, 27]),
    [ZONES.MESIO_BUCCAL_CUSP]: [2, 3, 4, 7, 8].concat([11], []),
    [ZONES.DISTO_BUCCAL_CUSP]: [5, 6, 9, 10].concat([12], []),
    [ZONES.MESIO_PALATAL_CUSP]: [].concat([16], [18, 19, 22, 23, 24]),
    [ZONES.DISTO_PALATAL_CUSP]: [].concat([17], [20, 21, 25, 26, 27])
};
partialCrownSurfaces["65"] = clone(partialCrownSurfaces["55"]);


partialCrownSurfaces["84"] = {
    [ZONES.MESIAL]: [1, 5].concat([14], [22, 23]),
    [ZONES.OCCLUSAL]: [].concat([15], []),
    [ZONES.DISTAL]: [4, 10].concat([16], [19, 30]),
    [ZONES.MESIO_BUCCAL_CUSP]: [1, 2, 5, 6, 7].concat([12], []),
    [ZONES.DISTO_BUCCAL_CUSP]: [3, 4, 8, 9, 10].concat([13], []),
    [ZONES.MESIO_LINGUAL_CUSP]: [].concat([17], [21, 22, 23, 24, 25, 26]),
    [ZONES.DISTO_LINGUAL_CUSP]: [].concat([18], [19, 20, 27, 28, 29, 30])
};
partialCrownSurfaces["74"] = clone(partialCrownSurfaces["84"]);


partialCrownSurfaces["85"] = {
    [ZONES.MESIAL]: [7, 11].concat([14], [19, 23]),
    [ZONES.OCCLUSAL]: [].concat([15], []),
    [ZONES.DISTAL]: [2, 8].concat([16], [22, 28]),
    [ZONES.MESIO_BUCCAL_CUSP]: [5, 6, 7, 10, 11].concat([12], []),
    [ZONES.DISTO_BUCCAL_CUSP]: [2, 3, 4, 8, 9 ].concat([13], []),
    [ZONES.MESIO_LINGUAL_CUSP]: [].concat([18], [19, 20, 23, 24, 25]),
    [ZONES.DISTO_LINGUAL_CUSP]: [].concat([17], [21, 22, 26, 27, 28]),
};
partialCrownSurfaces["75"] = clone(partialCrownSurfaces["85"]);



partialCrownToothNumbers.forEach(number => {
    const toothType = TOOTH_TYPES_BY_TOOTH_NUMBER[number];
    definition.teeth[number] = {
        surfaces: partialCrownSurfaces[number],
        default: partialCrownDefaults[toothType],
        exclusive: partialCrownExclusives[toothType],
        details: RESTORATION_DEFINITION_DEFAULT_DETAILS
    };
});

const PARTIAL_CROWN_DEFINITION = freeze(definition);

export {
    PARTIAL_CROWN_DEFINITION
};
