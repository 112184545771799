import "core-js/modules/es.parse-int.js";
export default {
  __name: 'score-selector',
  props: {
    sextant: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  emits: ['input'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var level = computed({
      get: function get() {
        return props.value.level;
      },
      set: function set(value) {
        emit('input', {
          level: value,
          furcation: props.value.furcation
        });
      }
    });
    var setLevel = function setLevel(event) {
      level.value = parseInt(event.target.value);
    };
    var setLevelIfNotChanged = function setLevelIfNotChanged(event) {
      // if clicking again on the same input, the value should be reset to null
      if (level.value === parseInt(event.target.value)) {
        level.value = null;
      }
    };
    var furcation = computed({
      get: function get() {
        return props.value.furcation;
      },
      set: function set(value) {
        emit('input', {
          level: props.value.level,
          furcation: value
        });
      }
    });
    return {
      __sfc: true,
      props: props,
      emit: emit,
      level: level,
      setLevel: setLevel,
      setLevelIfNotChanged: setLevelIfNotChanged,
      furcation: furcation
    };
  }
};