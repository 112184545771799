<template>
    <div data-view="user">
        <user-header />

        <main>
            <route-transition :level="1">
                <router-view  v-if="viewportStatus == 'ok'" />
            </route-transition>
            <viewport-warning v-if="viewportStatus != 'ok'" :viewport-status="viewportStatus" />
        </main>
    </div>
</template>

<script>
import UserHeader from '@/components/user/header.vue';
import RouteTransition from '@/components/route-transition.vue';
import ViewportWarning from '@/views/viewport-warning.vue';

export default {
    components: { UserHeader, RouteTransition, ViewportWarning },
    data() {
        return {
        };
    },

    computed:
    {
        viewportStatus() {
            if (this.$route.meta.restrictViewport !== false) {
                return this.$store.getters['general/viewportStatus'];
            }

            return 'ok';
        },
    },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/user";
</style>
