import freeze from '@/utils/freeze.js';
import { TOOTH_TYPES, TOOTH_NUMBERS } from '@/config/teeth.js';


const DEVELOPMENT_DISORDER_DETAIL_TYPES = freeze({
    PRESENCE: "presence"
});
const DEVELOPMENT_DISORDER_PRESENCES = freeze({
    YES: "yes",
    NO: "no"
});
const DEVELOPMENT_DISORDER_DETAILS = freeze({
    [DEVELOPMENT_DISORDER_DETAIL_TYPES.PRESENCE]: {
        options: [
            { value: DEVELOPMENT_DISORDER_PRESENCES.YES, labelKey: "common.yes" },
            { value: DEVELOPMENT_DISORDER_PRESENCES.NO, labelKey: "common.no" },
        ]
    }
});

const definition = {
    toothTypes: [TOOTH_TYPES.INCISOR, TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};

TOOTH_NUMBERS.forEach(number => {
    definition.teeth[number] = {
        surfaces: {},
        details: DEVELOPMENT_DISORDER_DETAILS
    };
});


const DEVELOPMENT_DISORDER_DEFINITION = freeze(definition);

export {
    DEVELOPMENT_DISORDER_DEFINITION,

    DEVELOPMENT_DISORDER_DETAIL_TYPES,
    DEVELOPMENT_DISORDER_PRESENCES,
    DEVELOPMENT_DISORDER_DETAILS,
};
