import store from '@/store/store.js';

import PatientDashboard from '@/views/patient/dashboard.vue';

import DashboardTreatmentPlan from '@/views/patient/dashboard/treatment-plan.vue';
import DashboardHistory from '@/views/patient/dashboard/history.vue';
import DashboardSoftTissue from '@/views/patient/dashboard/soft-tissue.vue';


const exitPatient = () => {
    if (store.getters['authentication/isIntegratorModeActive']) {
        return store.getters['authentication/integratorReturnUrl'];
    } else {
        return store.getters['navigation/patientsRoute'];
    }
};

export default [{
    path: 'dashboard',
    name: 'dashboard',
    redirect: '/patients/:patient_id/dashboard',
    component: PatientDashboard,
    meta: { back: exitPatient, title: false },
    children: [
        {
            path: 'history',
            name: 'history',
            component: DashboardHistory,
            meta: { back: exitPatient, title: false }
        },
        {
            path: '',
            name: 'treatment-plan',
            component: DashboardTreatmentPlan,
            meta: { back: exitPatient, title: false }
        },
        {
            path: 'soft-tissue',
            name: 'soft-tissue',
            component: DashboardSoftTissue,
            meta: { back: exitPatient, title: false }
        },
    ]
}];
