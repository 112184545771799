export default {
    documentListeners: {
        click: 'closeOnClickOutside'
    },
    methods: {
        closeOnClickOutside(event) {
            if (event.target && !this.$el.contains(event.target)) {
                this.close();
            }
        }

    }
};
