<template>
    <div class="endodontic-issues" v-if="displayableIssue">
        <div class="issue" :data-name="displayableIssue" :title="t(displayableIssue, 'dental.endodontic')">
            <span class="text">{{ t(displayableIssue, 'dental.endodontic') }}</span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        toothNumber: { required: true },
        ticker: { required: true }
    },

    data: () => {
        return {
            issueTimer: undefined,
            displayableIssue: undefined
        };
    },

    computed: {
        tooth() {
            return this.$store.getters['patient/teeth/tooth'](this.toothNumber);
        },

        activeIssues() {
            return this.$store.getters['patient/teeth/state/endodontic/issues/active'](this.toothNumber);
        },

        activeIssuesExist() {
            return this.activeIssues.length > 0;
        }

    },

    watch: {
        ticker() {
            this.updateDisplayableIssue();
        }
    },
    created() {
        this.updateDisplayableIssue();
    },

    methods: {

        updateDisplayableIssue() {
            if (this.activeIssuesExist) {
                const currentIssueIndex = this.activeIssues.findIndex((value) => value === this.displayableIssue);
                let nextIssueIndex;
                if (currentIssueIndex > -1 && currentIssueIndex < this.activeIssues.length - 1) {
                    nextIssueIndex = currentIssueIndex + 1;
                } else {
                    nextIssueIndex = 0;
                }
                this.displayableIssue = this.activeIssues[nextIssueIndex];
            } else {
                this.displayableIssue = undefined;
            }
        }

    }
};
</script>
