import freeze from '@/utils/freeze.js';
import clone from '@/utils/clone.js';

import { TOOTH_TYPES, ZONES, TOOTH_SETS, TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE } from '@/config/teeth.js';

import { RESTORATION_DEFINITION_DEFAULT_DETAILS } from '@/config/restorations/general.js';

// "restoration": 2

const definition = {
    toothTypes: [TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};

const permanentPremolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.PREMOLAR];
const deciduousPremolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.DECIDUOUS][TOOTH_TYPES.PREMOLAR];
const permanentMolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.MOLAR];
const deciduousMolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.DECIDUOUS][TOOTH_TYPES.MOLAR];

const onlayToothNumbers = permanentPremolarNumbers.concat(deciduousPremolarNumbers, permanentMolarNumbers, deciduousMolarNumbers);

const onlaySurfaces = { };

// for permanent teeth, onlay surfaces are the same for all teeth of the same tooth type

permanentPremolarNumbers.forEach(number => {
    onlaySurfaces[number] = {
        [ZONES.MESIAL]: [2, 18],
        [ZONES.DISTAL]: [4, 20],
        [ZONES.BUCCAL_CUSP]: [5, 6, 7, 8, 9, 10, 11, 12],
        [ZONES.PALATAL_CUSP]: [13, 14, 15, 16, 17],
        [ZONES.LINGUAL_CUSP]: [13, 14, 15, 16, 17],
        [ZONES.BUCCAL_SURFACE]: [2, 3, 4],
        [ZONES.PALATAL_SURFACE]: [18, 19, 20],
        [ZONES.LINGUAL_SURFACE]: [18, 19, 20]
    };
});

permanentMolarNumbers.forEach(number => {
    onlaySurfaces[number] = {
        [ZONES.MESIAL]: [2, 22],
        [ZONES.DISTAL]: [6, 26],
        [ZONES.BUCCAL_CUSP]: [7, 8, 9, 10, 11, 12, 13, 14, 15],
        [ZONES.PALATAL_CUSP]: [16, 17, 18, 19, 20, 21],
        [ZONES.LINGUAL_CUSP]: [16, 17, 18, 19, 20, 21]
    };
});


// for deciduous teeth, onlay surfaces are different for each individual tooth

onlaySurfaces["54"] = {
    [ZONES.MESIAL]: [2].concat([], [23]),
    [ZONES.DISTAL]: [5].concat([], [26]),
    [ZONES.BUCCAL_CUSP]: [6, 7, 8].concat([9, 10, 11, 12, 13, 14, 15, 16, 18], []),
    [ZONES.PALATAL_CUSP]: [].concat([17, 19], [20, 21, 22]),
    [ZONES.BUCCAL_SURFACE]: [1, 2, 3, 4, 5].concat([], []),
    [ZONES.PALATAL_SURFACE]: [].concat([], [23, 24, 25, 26, 27]),
};
onlaySurfaces["64"] = clone(onlaySurfaces["54"]);

onlaySurfaces["55"] = {
    [ZONES.MESIAL]: [2].concat([], [22]),
    [ZONES.DISTAL]: [6].concat([], [27]),
    [ZONES.BUCCAL_CUSP]: [7, 8, 9, 10].concat([11, 12, 13, 14, 15], []),
    [ZONES.PALATAL_CUSP]: [].concat([16, 17], [18, 19, 20, 21])
};
onlaySurfaces["65"] = clone(onlaySurfaces["55"]);


onlaySurfaces["84"] = {
    [ZONES.MESIAL]: [5].concat([], [23]),
    [ZONES.DISTAL]: [10].concat([], [30]),
    [ZONES.BUCCAL_CUSP]: [1, 2, 3, 4].concat([12, 13, 14, 15, 16], []),
    [ZONES.LINGUAL_CUSP]: [].concat([17, 18], [19, 20, 21, 22, 25, 28])
};
onlaySurfaces["74"] = clone(onlaySurfaces["84"]);

onlaySurfaces["85"] = {
    [ZONES.MESIAL]: [7].concat([], [23]),
    [ZONES.DISTAL]: [2].concat([], [28]),
    [ZONES.BUCCAL_CUSP]: [8, 9, 10, 11].concat([12, 13, 14, 15, 16], []),
    [ZONES.LINGUAL_CUSP]: [].concat([17, 18], [19, 20, 21, 22])
};
onlaySurfaces["75"] = clone(onlaySurfaces["85"]);


onlayToothNumbers.forEach(number => {
    definition.teeth[number] = {
        surfaces: onlaySurfaces[number],
        forced: [ ZONES.BUCCAL_CUSP, ZONES.PALATAL_CUSP, ZONES.LINGUAL_CUSP ],
        details: RESTORATION_DEFINITION_DEFAULT_DETAILS
    };
});


const ONLAY_DEFINITION = freeze(definition);

export {
    ONLAY_DEFINITION
};
