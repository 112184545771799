<template>
    <div data-view="viewport-warning">

        <span class="decoration">
            <svg width="286px" height="162px" viewBox="0 0 286 162" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-557.000000, -158.000000)" fill-rule="nonzero">
                        <g transform="translate(557.000000, 158.000000)">
                            <path d="M83,162 L46.8404284,162 C44.7032881,162 43.3334613,159.743411 44.3333647,157.869492 L49.8500735,147.526407 L49.8500735,118 L83,118 L83,162 Z" fill="#99BCE4" />
                            <path d="M83.2694995,125 L13.5446638,125 C6.06381228,125 0,118.937475 0,111.458793 L0,13.5417877 C0,6.06310556 6.06381228,0 13.5446638,0 L166.455336,0 C173.935607,0 180,6.06310556 180,13.5417877 L180,44.9081066 L180,125 L83.2694995,125 Z" fill="#E5EEF8" />
                            <path d="M72,51.4858915 L72,125 L83.5823284,125 L180,125 L180,44.6541996 L180,33 L90.3718893,33 C80.2419849,33 72,41.2925457 72,51.4858915 L72,51.4858915 Z" fill="#D5E2F3" />
                            <path d="M84.2602574,90 L17.350974,90 C16.6045056,90 16,89.400452 16,88.6613937 L16,16.3386063 C16,15.5989715 16.6050874,15 17.350974,15 L164.649026,15 C165.395494,15 166,15.599548 166,16.3386063 L166,44.5802011 L166,88.6608172 C166,89.400452 165.394913,90 164.649026,90 L84.2602574,90 L84.2602574,90 Z" fill="#65DAF9" />
                            <path d="M90.3831617,33 C80.2464624,33 72,41.2112727 72,51.3052932 L72,90 L83.5894349,90 L163.654467,90 C164.397929,90 165,89.399921 165,88.6602083 L165,44.540557 L165,33.000577 L90.3831617,33.000577 L90.3831617,33 Z" fill="#008FD6" />
                            <path d="M107.702157,70.6062015 L95.561428,70.6062015 C64.567572,70.6062015 39.4419,45.7103546 39.4419,15 L17.350974,15 C16.6045056,15 16,15.5995526 16,16.3386166 L16,88.6613834 C16,89.4010239 16.6050874,90 17.350974,90 L84.2602574,90 L164.649026,90 C165.395494,90 166,89.4004474 166,88.6613834 L166,70.6062015 L107.702157,70.6062015 Z" fill="#008FD6" />
                            <path d="M0,106 L0,111.840162 C0,119.107906 6.06383185,125 13.5447075,125 L83.2697683,125 L180,125 L180,106 L0,106 Z"  fill="#C5E1FF" />
                            <polygon fill="#B4D9FD" points="72 125 83.5823284 125 180 125 180 106 72 106" />
                            <path d="M180.132031,150 L83,150 L83,50.8663304 C83,47.0741529 86.0623039,44 89.8398648,44 L221.160135,44 C224.937696,44 228,47.0741529 228,50.8663304 L228,74.2466421 L228,150 L180.132031,150 Z" fill="#E5EEF8" />
                            <path d="M180.301472,149 L228,149 L228,74.2469005 L228,65 L187.72579,65 C178.5028,65 171,72.459359 171,81.6276609 L171,149 L180.301472,149 L180.301472,149 Z" fill="#D5E2F3" />
                            <path d="M180.623002,143.000595 L163.030643,143.000595 L68.5485831,143.000595 C67.1411479,143.000595 66,144.170548 66,145.613511 L66,152.563048 C66,157.7752 70.1213599,162 75.2046025,162 L163.030643,162 L188.032631,162 L204,162 L204,143 L180.623002,143 L180.623002,143.000595 Z" fill="#C5E1FF" />
                            <path d="M213,73.9969489 L213,61.0719123 C213,59.9274775 212.072,59 210.926921,59 L100.073079,59 C98.9279998,59 98,59.9274775 98,61.0719123 L98,125.928088 C98,127.072522 98.9279998,128 100.073079,128 L180.204044,128 L210.926336,128 C212.071415,128 213,127.072522 213,125.928088 L213,73.9969489 L213,73.9969489 Z" fill="#65DAF9" />
                            <path d="M170.8425,81.4658915 L170.8425,112.763872 L170.769945,112.763872 C141.059759,112.763872 116.975527,88.6927308 116.975527,59 L100.073089,59 C98.9280045,59 98,59.9274775 98,61.0719123 L98,125.928088 C98,127.072522 98.9280045,128 100.073089,128 L170.8425,128 L180.204462,128 L210.926911,128 C212.071996,128 213,127.072522 213,125.928088 L213,112.763872 L213,73.9969489 L213,64.6403031 L187.677063,64.6403031 C178.379465,64.6408879 170.8425,72.1735726 170.8425,81.4658915 Z" fill="#0299D5" />
                            <path d="M274.821099,162 L191.178901,162 C185.004773,162 180,158.647669 180,154.512796 L180,81.4860317 C180,77.3511584 185.004773,74 191.178901,74 L274.821099,74 C280.995227,74 286,77.3517445 286,81.4860317 L286,93.5489122 L286,162 L274.821099,162 L274.821099,162 Z" fill="#E5EEF8" />
                            <path d="M254.579235,90.5682569 L248.79414,90.5682569 C247.31587,90.5682569 246.11738,91.2686673 246.11738,92.1325841 L246.11738,94.363113 C246.11738,98.4351183 240.468207,101.736556 233.500496,101.736556 C226.532785,101.736556 220.883612,98.4351183 220.883612,94.363113 L220.883612,91.5643704 C220.883612,90.6958151 219.673216,89.9936652 218.18701,90 L197.656917,90.0893338 C196.186584,90.0957118 195,90.7943827 195,91.6536611 L195,144.416947 C195,145.843859 196.979294,147 199.419928,147 L272,147 L272,92.0386549 C272,91.1683602 270.783652,90.4650507 269.294469,90.4743277 L254.579235,90.5682569 Z" fill="#65DAF9" />
                            <path d="M269.394111,91 L263.524107,91.0621269 C263.191388,91.7560226 263,92.5274646 263,93.3442855 L263,147 L271,147 L271,92.5498669 C271,91.687667 270.278027,90.9908992 269.394111,91 Z" fill="#008FD6" />
                            <path d="M268,133.701682 C237.202317,133.701682 212.23534,118.3054 212.23534,99.3135208 L212.23534,90 L197.518896,90.0678634 C196.124944,90.0742437 195,90.7731783 195,91.6327808 L195,144.415972 C195,145.843423 196.876474,147 199.190321,147 L268,147 L268,133.701682 Z" fill="#008FD6" />
                        </g>
                    </g>
                </g>
            </svg>
        </span>

        <p> {{ message }} </p>
    </div>
</template>
<script>
export default {
    props: {
        viewportStatus: { required: true }
    },
    computed: {
        message() {
            if (this.viewportStatus == 'bad-orientation') {
                return this.t('Please use derec® on a screen in a landscape orientation', 'system');
            } else if (this.viewportStatus == 'bad-size') {
                return this.t('Please use derec® on a bigger tablet or computer screen', 'system');
            }
            return '';
        }
    }

};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/viewport-warning";
</style>
