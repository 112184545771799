import freeze from '@/utils/freeze.js';
import clone from '@/utils/clone.js';
import { TOOTH_TYPES, ZONES, TOOTH_SETS, TOOTH_NUMBERS, TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE } from '@/config/teeth.js';

const DECAY_DETAIL_TYPES = freeze({
    STAGE: "stage",
    CAVITATION: "cavitation",
    PULP: "pulp",
    LEVEL: "level",
});

const DECAY_STAGES = freeze({
    DENTIN: "dentin",
    ENAMEL: "enamel",
});

const DECAY_CAVITATIONS = freeze({
    CAVITATION: "cavitation",
    NO_CAVITATION: "noCavitation",
});

const DECAY_PULPS = freeze({
    INVOLVED: "involved",
    NOT_INVOLVED: "notInvolved",
});

const DECAY_LEVELS = freeze({
    C1: "c1",
    C2: "c2",
    C3: "c3",
    C4: "c4",
});

const DECAY_DETAILS = freeze({
    [DECAY_DETAIL_TYPES.STAGE]: {
        options: [
            { value: DECAY_STAGES.DENTIN, labelKey: "pathology.decay.type.dentin" },
            { value: DECAY_STAGES.ENAMEL, labelKey: "pathology.decay.type.enamel" },
        ]
    },
    [DECAY_DETAIL_TYPES.CAVITATION]: {
        options: [
            { value: DECAY_CAVITATIONS.CAVITATION, labelKey: "pathology.decay.cavitation.yes" },
            { value: DECAY_CAVITATIONS.NO_CAVITATION, labelKey: "pathology.decay.cavitation.no" },
        ]
    },
    [DECAY_DETAIL_TYPES.PULP]: Object.values(DECAY_PULPS),
    [DECAY_DETAIL_TYPES.LEVEL]: {
        options: [
            { value: DECAY_LEVELS.C1, labelKey: "pathology.decay.stage.c1" },
            { value: DECAY_LEVELS.C2, labelKey: "pathology.decay.stage.c2" },
            { value: DECAY_LEVELS.C3, labelKey: "pathology.decay.stage.c3" },
            { value: DECAY_LEVELS.C4, labelKey: "pathology.decay.stage.c4" },
        ]
    }

});


const definition = {
    toothTypes: [TOOTH_TYPES.INCISOR, TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};

const decaySurfaces = {};

// for permanent teeth, decay surfaces are the same for all teeth of the same tooth type
const permanentIncisorNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.INCISOR];
permanentIncisorNumbers.forEach(number => {
    decaySurfaces[number] = {
        [ZONES.CERVICAL_BUCCAL]: [1],
        [ZONES.BUCCAL]: [28, 29],
        [ZONES.MESIAL]: [2, 21],
        [ZONES.INCISAL]: [7, 8, 9, 11, 12, 13, 14, 15, 16, 18, 19, 20],
        [ZONES.DISTAL]: [6, 25],
        [ZONES.PALATAL]: [23],
        [ZONES.LINGUAL]: [23],
        [ZONES.CERVICAL_PALATAL]: [27],
        [ZONES.CERVICAL_LINGUAL]: [27],
        [ZONES.CLASS_4_MESIAL]: [2, 3, 7, 11, 14, 18, 21, 22],
        [ZONES.CLASS_4_DISTAL]: [5, 6, 9, 13, 16, 20, 25, 26],
        [ZONES.BUCCAL_SURFACE]: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        [ZONES.PALATAL_SURFACE]: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
        [ZONES.LINGUAL_SURFACE]: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]
    };
});


const permanentPremolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.PREMOLAR];
permanentPremolarNumbers.forEach(number => {
    decaySurfaces[number] = {
        [ZONES.CERVICAL_BUCCAL]: [1],
        [ZONES.BUCCAL]: [3],
        [ZONES.MESIAL]: [2, 5, 8, 15, 18],
        [ZONES.OCCLUSAL]: [9],
        [ZONES.DISTAL]: [4, 7, 10, 17, 20],
        [ZONES.PALATAL]: [19],
        [ZONES.LINGUAL]: [19],
        [ZONES.CERVICAL_PALATAL]: [21],
        [ZONES.CERVICAL_LINGUAL]: [21],
        [ZONES.BUCCAL_CUSP]: [5, 6, 7, 11, 12],
        [ZONES.PALATAL_CUSP]: [13, 14, 15, 16, 17],
        [ZONES.LINGUAL_CUSP]: [13, 14, 15, 16, 17],
        [ZONES.BUCCAL_SURFACE]: [2, 3, 4, 5, 6, 7, 11],
        [ZONES.PALATAL_SURFACE]: [14, 15, 16, 17, 18, 19, 20],
        [ZONES.LINGUAL_SURFACE]: [14, 15, 16, 17, 18, 19, 20]
    };
});


const permanentMolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.MOLAR];
permanentMolarNumbers.forEach(number => {
    decaySurfaces[number] = {
        [ZONES.CERVICAL_BUCCAL]: [1],
        [ZONES.BUCCAL]: [4],
        [ZONES.MESIAL]: [2, 7, 11, 18, 22],
        [ZONES.OCCLUSAL]: [12],
        [ZONES.DISTAL]: [6, 10, 13, 21, 26],
        [ZONES.PALATAL]: [24],
        [ZONES.LINGUAL]: [24],
        [ZONES.CERVICAL_PALATAL]: [27],
        [ZONES.CERVICAL_LINGUAL]: [27],
        [ZONES.MESIO_BUCCAL_CUSP]: [2, 3, 7, 8, 14],
        [ZONES.DISTO_BUCCAL_CUSP]: [5, 6, 9, 10, 15],
        [ZONES.MESIO_PALATAL_CUSP]: [16, 18, 19, 22, 23],
        [ZONES.MESIO_LINGUAL_CUSP]: [16, 18, 19, 22, 23],
        [ZONES.DISTO_PALATAL_CUSP]: [17, 20, 21, 25, 26],
        [ZONES.DISTO_LINGUAL_CUSP]: [17, 20, 21, 25, 26]
    };
});



// for deciduous teeth, decay surfaces are different for each individual tooth
// (for incisors - partially the same or similar, but with some individual differences)

decaySurfaces["51"] = {
    [ZONES.CERVICAL_BUCCAL]: [1],
    [ZONES.BUCCAL]: [3].concat([13], []),
    [ZONES.MESIAL]: [4].concat([], [28]),
    [ZONES.INCISAL]: [8, 9, 10].concat([15, 16, 17, 18, 19, 20], [25, 26, 27]),
    [ZONES.DISTAL]: [7].concat([], [33]),
    [ZONES.PALATAL]: [30],
    [ZONES.CERVICAL_PALATAL]: [34],
    [ZONES.CLASS_4_MESIAL]: [4, 5, 8].concat([11, 15, 18, 22], [25, 28, 29]),
    [ZONES.CLASS_4_DISTAL]: [6, 7, 10].concat([14, 17, 20, 21], [27, 32, 33]),
    [ZONES.BUCCAL_SURFACE]: [2, 3, 4, 5, 6, 7, 8, 9, 10].concat([11, 12, 13, 14, 15, 16, 17], []),
    [ZONES.PALATAL_SURFACE]: [].concat([18, 19, 20, 21, 22, 23, 24], [25, 26, 27, 28, 29, 30, 31, 32, 33])
};
decaySurfaces["61"] = clone(decaySurfaces["51"]);


decaySurfaces["52"] = {
    [ZONES.CERVICAL_BUCCAL]: [1],
    [ZONES.BUCCAL]: [3].concat([13], []),
    [ZONES.MESIAL]: [4].concat([], [28]),
    [ZONES.INCISAL]: [8, 9, 10].concat([15, 16, 17, 18, 19, 20], [25, 26, 27]),
    [ZONES.DISTAL]: [7].concat([], [33]),
    [ZONES.PALATAL]: [30],
    [ZONES.CERVICAL_PALATAL]: [34],
    [ZONES.CLASS_4_MESIAL]: [4, 5, 8].concat([11, 15, 18, 21], [25, 28, 29]),
    [ZONES.CLASS_4_DISTAL]: [6, 7, 10].concat([14, 17, 20, 24], [27, 32, 33]),
    [ZONES.BUCCAL_SURFACE]: [2, 3, 4, 5, 6, 7, 8, 9, 10].concat([11, 12, 13, 14, 15, 16, 17], []),
    [ZONES.PALATAL_SURFACE]: [].concat([18, 19, 20, 21, 22, 23, 24], [25, 26, 27, 28, 29, 30, 31, 32, 33])
};
decaySurfaces["62"] = clone(decaySurfaces["52"]);


decaySurfaces["53"] = {
    [ZONES.CERVICAL_BUCCAL]: [1],
    [ZONES.BUCCAL]: [5].concat([13], []),
    [ZONES.MESIAL]: [2].concat([], [28]),
    [ZONES.INCISAL]: [8, 9, 10].concat([15, 16, 17, 18, 19, 20], [25, 26, 27]),
    [ZONES.DISTAL]: [7].concat([], [33]),
    [ZONES.PALATAL]: [31],
    [ZONES.CERVICAL_PALATAL]: [34],
    [ZONES.CLASS_4_MESIAL]: [2, 3, 8].concat([11, 15, 18, 21], [25, 28, 29]),
    [ZONES.CLASS_4_DISTAL]: [6, 7, 10].concat([14, 17, 20, 24], [27, 32, 33]),
    [ZONES.BUCCAL_SURFACE]: [2, 3, 4, 5, 6, 7, 8, 9, 10].concat([11, 12, 13, 14, 15, 16, 17], []),
    [ZONES.PALATAL_SURFACE]: [].concat([18, 19, 20, 21, 22, 23, 24], [25, 26, 27, 28, 29, 30, 31, 32, 33])
};

decaySurfaces["63"] = clone(decaySurfaces["53"]);


decaySurfaces["54"] = {
    [ZONES.CERVICAL_BUCCAL]: [1],
    [ZONES.BUCCAL]: [3, 4],
    [ZONES.MESIAL]: [2, 6].concat([9, 13, 16], [20, 23]),
    [ZONES.OCCLUSAL]: [14],
    [ZONES.DISTAL]: [5, 8].concat([11, 15, 18], [22, 26]),
    [ZONES.PALATAL]: [24, 25],
    [ZONES.CERVICAL_PALATAL]: [27],
    [ZONES.BUCCAL_CUSP]: [6, 7, 8].concat([9, 10, 12], []),
    [ZONES.PALATAL_CUSP]: [].concat([17, 19], [20, 21, 22]),
    [ZONES.BUCCAL_SURFACE]: [2, 3, 4, 5, 6, 7, 8].concat([9, 10, 11], []),
    [ZONES.PALATAL_SURFACE]: [].concat([16, 18, 19], [20, 21, 22, 23, 24, 25, 25, 26])
};
decaySurfaces["64"] = clone(decaySurfaces["54"]);


decaySurfaces["55"] = {
    [ZONES.CERVICAL_BUCCAL]: [1],
    [ZONES.BUCCAL]: [4],
    [ZONES.MESIAL]: [2, 7].concat([13], [18, 22]),
    [ZONES.OCCLUSAL]: [14],
    [ZONES.DISTAL]: [6, 10].concat([15], [21, 27]),
    [ZONES.PALATAL]: [].concat([], [24, 25]),
    [ZONES.CERVICAL_PALATAL]: [28],
    [ZONES.MESIO_BUCCAL_CUSP]: [2, 3, 4, 7, 8].concat([11], []),
    [ZONES.DISTO_BUCCAL_CUSP]: [5, 6, 9, 10].concat([12], []),
    [ZONES.MESIO_PALATAL_CUSP]: [].concat([16], [18, 19, 22, 23, 24]),
    [ZONES.DISTO_PALATAL_CUSP]: [].concat([17], [20, 21, 25, 26, 27])
};
decaySurfaces["65"] = clone(decaySurfaces["55"]);


const deciduousLowerIncisorDecaySurfaces = {
    [ZONES.CERVICAL_BUCCAL]: [1],
    [ZONES.BUCCAL]: [5].concat([23], []),
    [ZONES.MESIAL]: [2].concat([], [28]),
    [ZONES.INCISAL]: [8, 9, 10].concat([15, 16, 17, 18, 19, 20], [25, 26, 27]),
    [ZONES.DISTAL]: [7].concat([], [33]),
    [ZONES.LINGUAL]: [31],
    [ZONES.CERVICAL_LINGUAL]: [34],
    [ZONES.CLASS_4_MESIAL]: [2, 3, 8].concat([11, 15, 20, 21], [25, 28, 29]),
    [ZONES.CLASS_4_DISTAL]: [6, 7, 10].concat([14, 17, 18, 24], [27, 32, 33]),
    [ZONES.BUCCAL_SURFACE]: [2, 3, 4, 5, 6, 7, 8, 9, 10].concat([18, 19, 20, 21, 22, 23, 24], []),
    [ZONES.LINGUAL_SURFACE]: [].concat([11, 12, 13, 14, 15, 16, 17], [25, 26, 27, 28, 29, 30, 31, 32, 33])
};

decaySurfaces["81"] = clone(deciduousLowerIncisorDecaySurfaces);
decaySurfaces["71"] = clone(decaySurfaces["81"]);


decaySurfaces["82"] = clone(deciduousLowerIncisorDecaySurfaces);
decaySurfaces["72"] = clone(decaySurfaces["82"]);


decaySurfaces["83"] = clone(deciduousLowerIncisorDecaySurfaces);
decaySurfaces["73"] = clone(decaySurfaces["83"]);


decaySurfaces["84"] = {
    [ZONES.CERVICAL_BUCCAL]: [11],
    [ZONES.BUCCAL]: [7, 8].concat([], []),
    [ZONES.MESIAL]: [1, 5].concat([14], [22, 23]),
    [ZONES.OCCLUSAL]: [15],
    [ZONES.DISTAL]: [4, 10].concat([16], [19, 30]),
    [ZONES.LINGUAL]: [].concat([], [25, 26, 27, 28]),
    [ZONES.CERVICAL_LINGUAL]: [31],
    [ZONES.MESIO_BUCCAL_CUSP]: [1, 2, 5, 6, 7].concat([12], []),
    [ZONES.DISTO_BUCCAL_CUSP]: [3, 4, 8, 9, 10].concat([13], []),
    [ZONES.MESIO_LINGUAL_CUSP]: [].concat([17], [21, 22, 23, 24, 25, 26]),
    [ZONES.DISTO_LINGUAL_CUSP]: [].concat([18], [19, 20, 27, 28, 29, 30])
};
decaySurfaces["74"] = clone(decaySurfaces["84"]);


decaySurfaces["85"] = {
    [ZONES.CERVICAL_BUCCAL]: [1],
    [ZONES.BUCCAL]: [4, 5].concat([], []),
    [ZONES.MESIAL]: [7, 11].concat([14], [19, 23]),
    [ZONES.OCCLUSAL]: [15],
    [ZONES.DISTAL]: [2, 8].concat([16], [22, 28]),
    [ZONES.LINGUAL]: [].concat([], [25, 26]),
    [ZONES.CERVICAL_LINGUAL]: [29],
    [ZONES.MESIO_BUCCAL_CUSP]: [5, 6, 7, 10, 11].concat([12], []),
    [ZONES.DISTO_BUCCAL_CUSP]: [2, 3, 4, 8, 9 ].concat([13], []),
    [ZONES.MESIO_LINGUAL_CUSP]: [].concat([18], [19, 20, 23, 24, 25]),
    [ZONES.DISTO_LINGUAL_CUSP]: [].concat([17], [21, 22, 26, 27, 28]),
};
decaySurfaces["75"] = clone(decaySurfaces["85"]);


TOOTH_NUMBERS.forEach(number => {
    definition.teeth[number] = {
        surfaces: decaySurfaces[number],
        details: DECAY_DETAILS
    };
});


const DECAY_DEFINITION = freeze(definition);


export {
    DECAY_DEFINITION,

    DECAY_DETAIL_TYPES,
    DECAY_STAGES,
    DECAY_CAVITATIONS,
    DECAY_PULPS,
    DECAY_LEVELS,
    DECAY_DETAILS,

};
