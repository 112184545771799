<template>
    <div data-view="history">

        <p class="actions">
            <button class="button link" data-icon="add" type="button" v-on:click="addRecord">
                {{ t('addRecord', 'patientVC') }}
            </button>
        </p>

        <ul v-if="recordsExist" class="records">
            <li v-for="record in records" :key="record.history_record_id" class="record">
                <p class="text">{{ recordMessage(record) }}</p>
                <p class="date">{{ formatDate(dateFromUtcTimestamp(record.created_at)) }}</p>
                <p class="actions">
                    <button
                        class="delete"
                        type="button"
                        :title="t('delete', 'common')"
                        v-on:click="askForRecordDeletion(record)"
                    >
                        {{ t('delete', 'common') }}
                    </button>
                </p>
            </li>
        </ul>
        <p v-else>
            {{ t('noHistory', 'patientVC') }}
        </p>

        <new-record-dialog v-if="addingRecord" v-on:close="closeNewRecordDialog" />

        <confirmation-dialog :for="deletionWaiterName" v-if="deletableRecord" v-on:confirm="deleteRecord" v-on:close="closeRecordDeletionDialog">
            <h1 class="title"> {{ t('delete', 'common') }} </h1>
            <p>
                {{ recordMessage(deletableRecord) }}
            </p>
        </confirmation-dialog>

    </div>
</template>

<script>
import NewRecordDialog from '@/components/patient/dashboard/history/new-record-dialog.vue';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { NewRecordDialog, ConfirmationDialog },
    data() {
        return {
            addingRecord: false,
            deletableRecord: undefined
        };
    },
    computed:
    {
        records() {
            return this.$store.getters['patient/history/orderedActiveItems'];
        },
        recordsExist() {
            return this.records.length > 0;
        }
    },
    created() {
        this.deletionWaiterName = WAITERS.DELETING_HISTORY_RECORD;
    },
    methods:
    {
        addRecord() {
            this.ensureTeethStateModifiability().then(() => {
                this.addingRecord = true;
            }, () => {});
        },

        closeNewRecordDialog() {
            this.addingRecord = false;
        },

        askForRecordDeletion(record) {
            this.ensureTeethStateModifiability().then(() => {
                this.deletableRecord = record;
            }, () => {});
        },

        closeRecordDeletionDialog() {
            this.deletableRecord = undefined;
        },

        deleteRecord() {
            return this.$store.dispatch('patient/history/deleteRecord', this.deletableRecord.history_record_id).then(
                () => { this.closeRecordDeletionDialog(); }
            );
        },

        recordMessage(record) {
            return this.$store.getters['patient/history/recordMessage'](record);
        }

    }

};
</script>
