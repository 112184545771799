export default {
  __name: 'zone',
  props: {
    zone: {
      type: String,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var maxLength = 16 * 1024; // max length of a utf8mb4 text field in mysql

    var store = useStore();
    var value = computed(function () {
      return store.getters['patient/softTissue/description'](props.zone);
    });
    var placeholder = computed(function () {
      return store.getters['i18n/t']('Enter description', 'softTissue');
    });
    var editingIsAvailable = computed(function () {
      return store.getters['patient/softTissue/editingIsAvailable'];
    });
    var _useNavigation = useNavigation(),
      ensureTeethStateModifiability = _useNavigation.ensureTeethStateModifiability;
    var handleInput = function handleInput(description) {
      ensureTeethStateModifiability();
      if (description == "") {
        description = null;
      }
      store.dispatch('patient/softTissue/setDescriptionForZone', {
        zone: props.zone,
        description: description
      });
    };
    var textarea = ref(null);
    var cancelOnEscape = function cancelOnEscape(event) {
      if (event.keyCode == 27) {
        cancel();
        if (textarea.value) {
          textarea.value.blur();
        }
      }
    };
    var cancel = function cancel() {
      store.dispatch('patient/softTissue/clearChangesForZone', {
        zone: props.zone
      });
    };
    return {
      __sfc: true,
      props: props,
      maxLength: maxLength,
      store: store,
      value: value,
      placeholder: placeholder,
      editingIsAvailable: editingIsAvailable,
      ensureTeethStateModifiability: ensureTeethStateModifiability,
      handleInput: handleInput,
      textarea: textarea,
      cancelOnEscape: cancelOnEscape,
      cancel: cancel
    };
  }
};