import merge from 'lodash/merge';
import ItemStoreModule from '../lib/itemStoreModule.js';
import { WAITERS } from '@/config/waiters.js';

export default merge({}, ItemStoreModule, {
    state: {
        idAttribute: "patient_id",

        // there currently is no way to get the actual total number of patients from the API
        // so they can only be estimated from the number of returned results when loading the session
        // (when there is no search query)
        // and then manually increased/decreased when adding / deleting patients
        totalNumberOfPatients: 0,


        attemptedQuery: undefined,

        // these values represent meta data for the patient list that is currently actually loaded
        // (not what the user has just selected or what should be shown or what is currently being loaded)
        totalNumberOfItems: undefined,
        offset: undefined,
        limit: undefined,
        moreUrl: undefined,
        query: undefined

    },

    getters: {

    },

    mutations: {
        setMeta (state, meta) {
            state.totalNumberOfItems = meta.total_items;
            state.offset = meta.offset;
            state.limit = meta.limit;
            state.moreUrl = meta.more_url;
        },
        setQuery (state, query) {
            state.query = query;
        },
        setAttemptedQuery (state, query) {
            state.attemptedQuery = query;
        },
        setTotalNumberOfPatients (state, numberOPatients) {
            state.totalNumberOfPatients = numberOPatients;
        },
        increaseTotalNumberOfPatients(state) {
            state.totalNumberOfPatients = state.totalNumberOfPatients + 1;
        },
        decreaseTotalNumberOfPatients(state) {
            state.totalNumberOfPatients = state.totalNumberOfPatients - 1;
        }
    },

    actions: {
        loadWithMeta(context, { list, loadedWithSession }) {
            context.commit('setMeta', list);

            // if this list has been loaded along with the session, then it has no query string
            // and the total_items contain the actual total number of patients
            if (loadedWithSession) {
                context.commit('setTotalNumberOfPatients', list.total_items);
            }

            context.dispatch('replace', list.items);
        },

        fetch(context, { query, offset, limit }) {
            const waiterName = (context.state.query != query) ? WAITERS.SEARCHING_PATIENTS : WAITERS.LOADING_PATIENTS;

            context.dispatch('wait/start', waiterName, { root: true });
            return this.$api.getPatientList(query, offset, limit).then((response) => {
                context.dispatch('loadWithMeta', { list: response.data });
                context.commit('setQuery', query);
            }).catch((error) => {
                if (!error.requestCancelled) {
                    throw error;
                }
            }).catch(this.apiErrorHandler).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        },

        refresh(context) {
            return context.dispatch('fetch', {
                query: context.state.query,
                offset: context.state.offset,
                limit: context.state.limit
            });
        },

        search(context, query) {
            return context.dispatch('fetch', {
                query,
                offset: context.state.offset,
                limit: context.state.limit
            });
        },

        setAttemptedQuery(context, query) {
            return context.commit('setAttemptedQuery', query);
        },

        increaseTotalNumberOfPatients(context) {
            return context.commit('increaseTotalNumberOfPatients');
        },

        decreaseTotalNumberOfPatients(context) {
            return context.commit('decreaseTotalNumberOfPatients');
        }

    }
});
