import User from '@/views/user.vue';
import UserSchedule from '@/views/user/schedule.vue';
import UserPatients from '@/views/user/patients.vue';

export default [{
    path: '/',
    name: 'root',
    redirect: '/welcome',
    component: User,
    meta: { transition: { sequenceNumber: 2, from: 'right' }, title: false },
    children: [
        {
            path: '/schedule',
            name: 'schedule',
            component: UserSchedule,
            meta: { transition: { sequenceNumber: 1 }, title: 'Schedule', allowInIntegratorMode: false }
        },

        {
            path: '/patients',
            name: 'patients',
            component: UserPatients,
            meta: { transition: { sequenceNumber: 2, from: 'right' }, title: 'Patients', allowInIntegratorMode: false }
        },
    ]
}];
