import { ZONES_BY_JAW, NOTATION_SYSTEMS} from '@/config/teeth.js';
import { PATHOLOGIES_AND_RESTORATIONS } from '@/config/pathologies-and-restorations.js';

export default {

    namespaced: true,

    state: {
        name: undefined,
        parentName: undefined
    },

    getters: {
        // these are for accessing state and getters in the pathology/restoration modules
        // for which the types are included as submodules
        parentState: (state, getters, rootState) => {
            return rootState.patient.teeth.state[state.parentName];
        },

        parent: (state, getters, rootState, rootGetters) => (parentGetterName, ...args) => {
            return rootGetters['patient/teeth/state/' + state.parentName + '/' + parentGetterName](...args);
        },


        toothNumber: (state, getters) => {
            return getters.parentState.editableTooth.number;
        },

        baseState: (state, getters, rootState, rootGetters) => {
            const baseState = JSON.parse(JSON.stringify(getters.blankState)); // clone to not change the original instance
            baseState.created_at = rootGetters['time/currentUtcTimestamp'];

            return baseState;
        },

        originalDefinition: (state /*, _getters, _rootState */) => (toothNumber) => {
            let source = PATHOLOGIES_AND_RESTORATIONS;

            // :DEBUG: uncomment this to enable development overrides for PATHOLOGIES_AND_RESTORATIONS
            // if (
            //     rootState.patient.teeth.state.developmentOverrides
            //     &&
            //     rootState.patient.teeth.state.developmentOverrides.pathologiesAndRestorations
            // ) {
            //     source = rootState.patient.teeth.state.developmentOverrides.pathologiesAndRestorations;
            // }

            return source[state.name].teeth[toothNumber];
        },

        definition: (state, getters, rootState, rootGetters) => (toothNumber) => {

            // this filters out stuff that is irrelevant to the given tooth
            // (e.g., palatal zones for lower jaw teeth etc)
            // and expands shorthand syntax for details into full structure
            const tooth = getters.parent('tooth', toothNumber);
            const relevantZoneNames = ZONES_BY_JAW[tooth.jaw];

            const originalDefinition = getters.originalDefinition(toothNumber);

            const definition = {
                surfaces: Object.keys(originalDefinition.surfaces)
                    .filter(zoneName => relevantZoneNames.includes(zoneName))
                    .reduce((object, zoneName) => {
                        object[zoneName] = originalDefinition.surfaces[zoneName];
                        return object;
                    }, {})
            };

            ["default", "forced", "exclusive"].forEach(key => {
                const zoneNames = Object.keys(originalDefinition).includes(key) ? originalDefinition[key] : [];
                definition[key] = zoneNames.filter(zoneName => relevantZoneNames.includes(zoneName));
            });

            if (Object.keys(originalDefinition).includes('details')) {
                definition.details = Object.keys(originalDefinition.details).reduce((details, detailName) => {
                    const originalDetail = originalDefinition.details[detailName];
                    const options = (originalDetail instanceof Array) ? originalDetail : originalDetail.options;
                    const baseDetail = (originalDetail instanceof Array) ? {} : JSON.parse(JSON.stringify(originalDetail));
                    const labelScope = state.parentName + '.' + rootGetters['filters/camelize'](state.name) + '.' + detailName;
                    const detail = Object.assign(baseDetail, {
                        options: options.map(option => {
                            option = (typeof option == "string") ? { value: option } : JSON.parse(JSON.stringify(option));
                            if (option.labelKey) {
                                option.label = rootGetters['i18n/t'](option.labelKey);
                            } else {
                                option.label = rootGetters['i18n/t'](option.value, labelScope);
                            }

                            return option;
                        })
                    });
                    details[detailName] = detail;
                    return details;
                }, {});
            }

            return definition;
        },



        // ZONE / SURFACE CALCULATIONS

        definedZoneNames: (state, getters) => (toothNumber) => {
            const type = getters.parent('type', state.name, toothNumber);
            return Object.keys(type.surfaces);
        },

        selectedZoneNames: (state, getters) => () => {
            if (!getters.toothNumber) {
                return [];
            }
            // calculates zone names from surface list in state
            const selectedSurfaces = getters.toothState.surfaces || [];
            const toothNumber = getters.toothNumber;

            return getters.calculateSelectedZoneNames({ toothNumber, selectedSurfaces });
        },

        calculateSelectedZoneNames: (state, getters) => ({ toothNumber, selectedSurfaces }) => {
            const surfacesByZoneName = getters.definition(toothNumber).surfaces;

            const selectedZoneNames = Object.keys(surfacesByZoneName).reduce((selectedZoneNames, zoneName) => {
                if (surfacesByZoneName[zoneName].every(surface => selectedSurfaces.includes(surface))) {
                    selectedZoneNames.push(zoneName);
                }
                return selectedZoneNames;
            }, []);
            return selectedZoneNames;
        },

        zoneSurfaces: (state, getters) => ({ toothNumber, zoneName }) => {
            const definition = getters.definition(toothNumber);
            return definition.surfaces[zoneName];
        },

        supersetZoneNames: (state, getters) => ({ toothNumber, zoneNames, subsetZoneName }) => {
            // returns all names from given zoneNames which completely contain all subsetZoneName surfaces
            // according to type definition
            const subsetSurfaces = getters.zoneSurfaces({ toothNumber, zoneName: subsetZoneName });
            if (subsetSurfaces.length < 1) {
                return [];
            }
            return zoneNames.filter(zoneName => {
                const potentialSupersetSurfaces = getters.zoneSurfaces({ toothNumber, zoneName});
                return subsetSurfaces.every(surface => potentialSupersetSurfaces.includes(surface));
            });
        },

        calculateSurfaces: (state, getters) => ({ toothNumber, selectedZoneNames, previouslySelectedZoneNames, previousSurfaces }) => {
            // automatically add all forced zones to selection
            (getters.definition(toothNumber).forced || []).forEach(forcedZoneName => {
                selectedZoneNames.push(forcedZoneName);
            });

            const removedZoneNames = previouslySelectedZoneNames.filter(zoneName => !selectedZoneNames.includes(zoneName));
            let addedZoneNames = selectedZoneNames.filter(zoneName => !previouslySelectedZoneNames.includes(zoneName));

            const exclusiveZoneNames = getters.definition(toothNumber).exclusive;
            if (exclusiveZoneNames) {
                const addedExclusiveZoneIndex = addedZoneNames.findIndex(zoneName => exclusiveZoneNames.includes(zoneName));
                if (addedExclusiveZoneIndex > -1) {
                    const addedExclusiveZoneName = addedZoneNames[addedExclusiveZoneIndex];
                    const excludedZoneNames = exclusiveZoneNames.filter(zoneName => zoneName != addedExclusiveZoneName);
                    // if an exclusive zone has been added, then all other exlusive zones should be
                    // 1) ignored in addedZoneNames if not previously selected
                    addedZoneNames = addedZoneNames.filter(zoneName => !excludedZoneNames.includes(zoneName));

                    // 2) removed if previously selected
                    previouslySelectedZoneNames.forEach(zoneName => {
                        if (
                            (excludedZoneNames.includes(zoneName))
                            &&
                            (!removedZoneNames.includes(zoneName))
                        ) {
                            removedZoneNames.push(zoneName);
                        }
                    });
                }
            }

            // it is important to remove zones one by one because some of them overlap in different ways.
            // simply removing all surfaces that belong to given removable zones is incorrect.

            // overlapping scenarios:

            // identical zones: e.g., decay -> incisor -> buccal [28, 29] and buccal-surface [28, 29]
            // both zones always switch on/off together

            // superset - subset: e.g., decay -> incisor -> class-4-distal [5, 6, 9, 13, 16, 20, 25, 26] and distal [6, 25]
            // when deselecting the subset zone, all superset zones should be deselected as well,
            // because they can no longer be full

            // when deselecting the superset zone, all subsets should be left selected (only deselect the differing surfaces)

            // intersection: e.g., decay -> incisor -> incisal [7, 8, 9, 11, 12, 13, 14, 15, 16, 18, 19, 20]
            // and class-4-distal [5, 6, 9, 13, 16, 20, 25, 26], intersecting at [9, 13, 16, 20].
            //
            // when deselecting a zone that partially intersects with another, but is neither a subset nor a superset,
            // the intersecting surfaces should be left intact, so that the intersecting zone is left in a full state

            // therefore, deselecting a superset and deselecting an intersecting zone is the same


            // first, combine all initial zones with all of their superset zones that could be deselected as well
            const removableZoneNames = getters.calculateRemovableZoneNames({ toothNumber, previouslySelectedZoneNames, removedZoneNames });

            // then, for each removable zone,
            // collect all removable surfaces which do not intersect with any other zone that should not be removed
            const removableSurfaces = getters.calculateRemovableSurfaces({ toothNumber, previouslySelectedZoneNames, removableZoneNames });

            // combine the new set of surfaces from previous state by removing removables and adding the new ones
            const addableSurfaces = getters.calculateAddableSurfaces({ toothNumber, addedZoneNames });
            const surfaces = getters.combineSurfaces({ previousSurfaces, removableSurfaces, addableSurfaces });
            return surfaces;
        },

        calculateRemovableZoneNames: (state, getters) => ({ toothNumber, previouslySelectedZoneNames, removedZoneNames }) => {
            const removableZoneNames = removedZoneNames.reduce((removableZoneNames, zoneName) => {
                if (!removableZoneNames.includes(zoneName)) {
                    removableZoneNames.push(zoneName);
                }
                getters.supersetZoneNames({
                    toothNumber,
                    zoneNames: previouslySelectedZoneNames,
                    subsetZoneName: zoneName
                }).forEach(supersetZoneName => {
                    if (!removableZoneNames.includes(supersetZoneName)) {
                        removableZoneNames.push(supersetZoneName);
                    }
                });

                return removableZoneNames;
            }, []);
            return removableZoneNames;
        },

        calculateRemovableSurfaces: (state, getters) => ({ toothNumber, previouslySelectedZoneNames, removableZoneNames }) => {
            const typeName = state.name;

            const nonRemovableZoneNames = previouslySelectedZoneNames.filter(zoneName => !removableZoneNames.includes(zoneName));
            const nonRemovableSurfaces = nonRemovableZoneNames.reduce((surfaces, zoneName) => {
                getters.zoneSurfaces({ toothNumber, zoneName }).forEach(surface => surfaces.push(surface));
                return surfaces;
            }, []);
            const removableSurfaces = removableZoneNames.reduce((surfaces, zoneName) => {
                getters.zoneSurfaces({ toothNumber, typeName, zoneName })
                    .filter(surface => !nonRemovableSurfaces.includes(surface))
                    .forEach(surface => {
                        if (!surfaces.includes(surface)) {
                            surfaces.push(surface);
                        }
                    })
                ;
                return surfaces;
            }, []);
            return removableSurfaces;
        },

        calculateAddableSurfaces: (state, getters) => ({ toothNumber, addedZoneNames }) => {
            const typeName = state.name;

            const addableSurfaces = addedZoneNames.reduce((surfaces, zoneName) => {
                getters.zoneSurfaces({ toothNumber, typeName, zoneName }).forEach(surface => surfaces.push(surface));
                return surfaces;
            }, []);
            return addableSurfaces;
        },

        combineSurfaces: () => ({ previousSurfaces, removableSurfaces, addableSurfaces }) => {
            const surfaces = previousSurfaces.filter(surface => !removableSurfaces.includes(surface));

            // adding new zones is easier and does not require any special handling of intersections,
            // so surfaces can be simply added
            addableSurfaces.forEach(surface => {
                if (!surfaces.includes(surface)) {
                    surfaces.push(surface);
                }
            });

            surfaces.sort((a, b) => a - b);

            return surfaces;
        },


        // DETAIL CALCULATIONS

        isDetailAvailable: () => (/*{ toothNumber, toothState, detailName, detailDefinition, details }*/) => {
            // provides a hook to conditionally disable a detail
            // (e.g., based on the tooth's number)
            return true;
        },

        filterOutTrailingDetails: (state, getters) => (details) => {
            // removes all detail values after the first that has a blank value.
            // also removes details which are not available (depending on values of earlier details)
            const toothNumber = getters.toothNumber;
            const toothState = getters.toothState;
            const definition = getters.parent('type', state.name, toothNumber).details || {};

            let blankValueEncountered = false;
            return Object.keys(details).reduce((result, detailName) => {
                if (!blankValueEncountered) {
                    const detailDefinition = definition[detailName];
                    if (getters.isDetailAvailable({ toothNumber, toothState, detailName, detailDefinition, details: result })) {
                        result[detailName] = details[detailName];
                        if (result[detailName].values.length < 1) {
                            blankValueEncountered = true;
                        }
                    }
                }
                return result;
            }, {});
        },


        // history / treatment plan record message construction

        creatableRecordMessage: (state, getters, rootState, rootGetters) => ({ toothNumber, selectedSurfaces, details }) => {
            // tooth labels always use ISO notation internally, and only get converted to USA notation on output, if needed
            const toothLabel    = rootGetters["patient/teeth/toothLabelInGivenNotation"](toothNumber, NOTATION_SYSTEMS.ISO);

            const zoneLabels    = getters.creatableRecordMessageZoneLabels({ toothNumber, selectedSurfaces });
            const typeLabel     = getters.creatableRecordMessageTypeLabel( toothNumber );
            const detailLabels  = getters.creatableRecordMessageDetailLabels( details );

            // 15, Palatal, Cervical Palatal, Buccal Cusp, Filling, Composit, Uncertain, Shortfall
            return [ toothLabel ]
                .concat(zoneLabels)
                .concat([typeLabel])
                .concat(detailLabels)
                .join(', ')
                .replace(/[\n ]+/g, ' ')
            ;
        },

        creatableRecordMessageZoneLabels: (state, getters, rootState, rootGetters) => ({ toothNumber, selectedSurfaces }) => {
            return getters.creatableRecordMessageZoneNames({ toothNumber, selectedSurfaces }).map(zoneName => {
                return rootGetters['patient/teeth/zoneLabel'](zoneName);
            });
        },

        creatableRecordMessageZoneNames: (state, getters) => ({ toothNumber, selectedSurfaces }) => {
            return getters.calculateSelectedZoneNames({ toothNumber, selectedSurfaces });
        },

        creatableRecordMessageTypeLabel: (state, getters) => (toothNumber) => {
            return getters.parent('type', state.name, toothNumber).label;
        },


        creatableRecordMessageDetailLabels: () => (details) => {
            return Object.keys(details).reduce((array, detailName) => {
                const selectedLabels = details[detailName].values.map(value => {
                    const option = details[detailName].options.find(option => option.value == value);
                    return option ? option.label : undefined;
                }).filter(label => !!label);
                return array.concat(selectedLabels);
            }, []);
        },


    },

    actions: {
        // for calling actions on pathology/restoration modules
        // which include types as submodules
        dispatchOnParent(context, { actionName, payload }) {
            const parentActionPath = 'patient/teeth/state/' + context.state.parentName + '/' + actionName;
            return context.dispatch(parentActionPath, payload, { root: true });
        },

        setState(context, { selectedZoneNames, details }) {
            const typeState = context.getters.calculateState({ selectedZoneNames, details });
            const payload = {
                typeName: context.state.name,
                typeState
            };
            return context.dispatch('dispatchOnParent', { actionName: 'setTypeState', payload } );
        },

        setSelectedZoneNames(context, { toothNumber, selectedZoneNames }) {
            if (context.getters.toothNumber != toothNumber) {
                return;
            }

            return context.dispatch('setState', { selectedZoneNames });
        },

        setDetail(context, { toothNumber, detailName, values }) {
            if (context.getters.toothNumber != toothNumber) {
                return;
            }

            const details = JSON.parse(JSON.stringify(context.getters.details(toothNumber)));
            details[detailName].values = values;

            return context.dispatch('setState', { details: context.getters.filterOutTrailingDetails(details) });
        }
    }
};
