<template>

    <div class="periodontal-issue-legend" :data-row="row">
        <p v-for="issueName in issueNames" :key="issueName" :data-type="issueName">
            {{ t(issueName, 'dental') }}
        </p>
    </div>

</template>
<script>
export default {
    props: {
        row: { required: true }
    },
    computed: {
        issueNames() {
            return ["plaque", "bleeding", "pus"];
        }
    }
};
</script>
