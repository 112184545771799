import { SEXTANTS_IN_ORDER } from '@/config/basic-periodontal-examination.js';
import clone from '@/utils/clone.js';
import SextantPads from '@/components/patient/dashboard/basic-periodontal-examination/sextant-pads.vue';
import BasicPeriodontalExaminationDialog from '@/components/patient/dashboard/basic-periodontal-examination/dialog.vue';
export default {
  __name: 'basic-periodontal-examination',
  props: {
    patientId: {
      type: String,
      required: true
    }
  },
  setup: function setup(__props) {
    var store = useStore();
    var editingIsAvailable = computed(function () {
      return store.getters['patient/basicPeriodontalExamination/editingIsAvailable'];
    });
    var defaultSextant = computed(function () {
      return clone(SEXTANTS_IN_ORDER)[0];
    });
    var isDialogOpen = ref(false);
    var defaultDialogSextant = ref(undefined);
    var openDialog = function openDialog(sextant) {
      defaultDialogSextant.value = sextant;
      isDialogOpen.value = true;
    };
    var closeDialog = function closeDialog() {
      isDialogOpen.value = false;
      defaultDialogSextant.value = null;
    };
    var scoresBySextant = computed(function () {
      return store.getters['patient/basicPeriodontalExamination/currentScoresBySextant'];
    });
    return {
      __sfc: true,
      store: store,
      editingIsAvailable: editingIsAvailable,
      defaultSextant: defaultSextant,
      isDialogOpen: isDialogOpen,
      defaultDialogSextant: defaultDialogSextant,
      openDialog: openDialog,
      closeDialog: closeDialog,
      scoresBySextant: scoresBySextant,
      SextantPads: SextantPads,
      BasicPeriodontalExaminationDialog: BasicPeriodontalExaminationDialog
    };
  }
};