const IMAGES = require('@/assets/images/images.json');

// this object is for actual image instances of all requested images. image paths as keys.
// image instances are stored outside the store,
// to avoid vuex slowing things down by attempting to make each native property of the image object reactive
const imageInstances = {

};

export default {
    namespaced: true,

    state: {
        loaded: false
    },

    getters: {
        image: () => imagePath => {
            return imageInstances[imagePath + '.png'];
        }
    },


    mutations: {
        setLoadedState(state, loaded) {
            state.loaded = loaded;
        },
    },

    actions: {
        load(context) {
            let imagesToLoad = IMAGES.length;
            IMAGES.forEach((imagePath) => {
                const image = new Image();
                image.addEventListener("load", () => {
                    --imagesToLoad;
                    if (imagesToLoad == 0) {
                        context.commit('setLoadedState', true);
                    }
                }, false);

                imageInstances[imagePath] = image;
                imageInstances[imagePath].src = require('@/assets/images/' + imagePath);
            });
        },
    }

};
