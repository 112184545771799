import freeze from '@/utils/freeze.js';
import { TOOTH_TYPES, TOOTH_NUMBERS } from '@/config/teeth.js';


const TOOTH_WEAR_DETAIL_TYPES = freeze({
    TYPE: "type",
    ZONE: "zone"
});
const TOOTH_WEAR_TYPES = freeze({
    ABRASION: "abrasion",
    EROSION: "erosion",
});
const TOOTH_WEAR_ZONES = freeze({
    BUCCAL: "buccal",
    PALATAL: "palatal",
    LINGUAL: "lingual",
});

const TOOTH_WEAR_DETAILS = freeze({
    [TOOTH_WEAR_DETAIL_TYPES.TYPE]: Object.values(TOOTH_WEAR_TYPES),
    [TOOTH_WEAR_DETAIL_TYPES.ZONE]: {
        multiple: true,
        options: [
            {
                value: TOOTH_WEAR_ZONES.BUCCAL,
                labelKey: "dental.region.buccal"
            },
            {
                value: TOOTH_WEAR_ZONES.PALATAL,
                labelKey: "dental.region.palatal"
            },
            {
                value: TOOTH_WEAR_ZONES.LINGUAL,
                labelKey: "dental.region.lingual"
            }
        ]
    }
});

const definition = {
    toothTypes: [TOOTH_TYPES.INCISOR, TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};

TOOTH_NUMBERS.forEach(number => {
    // tooth wear does not have common selectable surfaces, they are bound to selected details
    definition.teeth[number] = {
        surfaces: { },
        details: TOOTH_WEAR_DETAILS
    };
});

const TOOTH_WEAR_DEFINITION = freeze(definition);


export {
    TOOTH_WEAR_DEFINITION,

    TOOTH_WEAR_DETAIL_TYPES,
    TOOTH_WEAR_TYPES,
    TOOTH_WEAR_ZONES,

    TOOTH_WEAR_DETAILS
};
