<template>

    <fieldset class="furcation selector">
        <legend>{{ t('title', 'periodontalProbing.furcation') }}</legend>

        <div class="options">
            <span
                v-for="option in options"
                :key="option"
                class="option"
            >
                <input
                    type="radio"
                    name="furcation"
                    :id="optionId(option)"
                    :value="option"
                    v-model="editableValue"
                    v-on:click="deselectIfSelected(option)"
                />
                <label :for="optionId(option)">{{ label(option) }}</label>
            </span>
        </div>

    </fieldset>

</template>
<script>
export default {
    props: {
        toothNumber: { required: true },
        value: { required: true }
    },
    data: () => {
        return {
            options:  [ 1, 2, 3 ]
        };
    },
    computed: {
        editableValue:
        {
            get() { return this.value; },
            set(value) { this.$emit('input', value); }
        }
    },
    methods:
    {
        label(option) {
            return this.t('stage' + option, "periodontalProbing.furcation");
        },
        optionId(option) {
            return `furcation-${option}`;
        },

        deselectIfSelected(option) {
            if (this.editableValue == option) {
                this.editableValue = 0;
            }
        }
    }
};
</script>
