import freeze from '@/utils/freeze.js';

import {
    VISUALIZATION_CONTEXTS,
    PROJECTIONS,
    TOOTH_SETS,
    TOOTH_POSITIONS_BY_TOOTH_SET
} from '@/config/teeth.js';

// tooth canvas widths differ by position in chart views, but are the same for all teeth in tooth wheel.
// widths in chart views are the same for permanent and deciduous teeth, so that the slots line up correctly
// for the same position in mixed dentition cases
const chartCanvasWidthsByToothPosition = {
    1: 180,
    2: 150,
    3: 180,
    4: 148,
    5: 142,
    6: 212,
    7: 196,
    8: 184
};
const toothWheelCanvasWidth = 322;

// tooth canvas heights differ only for incisal projection
const canvasHeightsByProjection = {
    [PROJECTIONS.BUCCAL]: 576,
    [PROJECTIONS.LINGUAL]: 576,
    [PROJECTIONS.PALATAL]: 576,
    [PROJECTIONS.INCISAL]: 260,
};

// this creates the full structure, so that a tooth's dimensions can be accessed directly with nested keys:
// TOOTH_CANVAS_DIMENSIONS[context][projection][set][position]
const TOOTH_CANVAS_DIMENSIONS = freeze(
    Object.values(VISUALIZATION_CONTEXTS).reduce((contexts, context) => {
        contexts[context] = Object.values(PROJECTIONS).reduce((projections, projection) => {
            projections[projection] = Object.values(TOOTH_SETS).reduce((toothSets, toothSet) => {
                toothSets[toothSet] = TOOTH_POSITIONS_BY_TOOTH_SET[toothSet].reduce((toothPositions, toothPosition) => {
                    const width = (context == VISUALIZATION_CONTEXTS.TOOTH) ? toothWheelCanvasWidth : chartCanvasWidthsByToothPosition[toothPosition];
                    const height = canvasHeightsByProjection[projection];
                    toothPositions[toothPosition] = { width, height };
                    return toothPositions;
                }, {});
                return toothSets;
            }, {});
            return projections;
        }, {});
        return contexts;
    }, {})
);

export {
    TOOTH_CANVAS_DIMENSIONS
};
