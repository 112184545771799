import { CHART_MODES } from '@/config/teeth.js';

import store from '@/store/store.js';

import PatientChart from '@/views/patient/chart.vue';

import UpperJaw from '@/views/patient/chart/upper-jaw.vue';
import LowerJaw from '@/views/patient/chart/lower-jaw.vue';
import FullMouth from '@/views/patient/chart/full-mouth.vue';

const chartRoutes = [{
    path: 'chart',
    name: 'chart',
    redirect: () => {
        const name = store.getters['navigation/storedChartRouteName'];
        const basePath = '/patients/:patient_id/chart';
        if (name == 'upper-jaw') {
            return basePath + '/upper-jaw';
        } else if (name == 'lower-jaw') {
            return basePath + '/lower-jaw';
        }

        return basePath;
    },
    component: PatientChart,
    props: { mode: CHART_MODES.OVERVIEW },
    meta: { back: { name: 'dashboard' }, chart: true, mode: CHART_MODES.OVERVIEW, title: 'Chart' },
    children: [
        {
            path: 'upper-jaw',
            name: 'upper-jaw',
            component: UpperJaw,
            meta: { back: { name: 'dashboard' }, 'mouth-view': 'upper-jaw', title: 'Upper jaw' }
        },
        {
            path: 'lower-jaw',
            name: 'lower-jaw',
            component: LowerJaw,
            meta: { back: { name: 'dashboard' }, 'mouth-view': 'lower-jaw', title: 'Lower jaw' }
        },
        {
            path: '',
            name: 'full-mouth',
            component: FullMouth,
            meta: { back: { name: 'dashboard' }, 'mouth-view': 'full-mouth', title: false }
        },
    ]
}];


const periodontalBackFunction = function() {
    if (this.$route.params.tooth_slug) {
        return { name: this.chartRouteName('periodontal-probing', this.$route.meta['mouth-view']) };
    } else {
        return { name: 'dashboard' };
    }
};

chartRoutes.push({
    path: 'chart/periodontal-probing',
    component: PatientChart,
    props: { mode: CHART_MODES.PERIODONTAL_PROBING },
    meta: { back: { name: 'chart' }, chart: true, mode: CHART_MODES.PERIODONTAL_PROBING, title: 'Chart', allowInRollback: false },
    children: [
        {
            path: 'upper-jaw/:tooth_slug?/:zone_name?',
            name: 'periodontal-probing-upper-jaw',
            component: UpperJaw,
            meta: { back: periodontalBackFunction, 'mouth-view': 'upper-jaw', title: 'Upper jaw' }
        },
        {
            path: 'lower-jaw/:tooth_slug?/:zone_name?',
            name: 'periodontal-probing-lower-jaw',
            component: LowerJaw,
            meta: { back: periodontalBackFunction, 'mouth-view': 'lower-jaw', title: 'Lower jaw' }
        },
        {
            path: ':tooth_slug?/:zone_name?',
            name: 'periodontal-probing-full-mouth',
            component: FullMouth,
            meta: { back: periodontalBackFunction, 'mouth-view': 'full-mouth', title: false }
        },
    ]
});


chartRoutes.push({
    path: 'chart/quickselect',
    component: PatientChart,
    props: { mode: CHART_MODES.QUICKSELECT },
    meta: { back: { name: 'dashboard' }, chart: true, mode: CHART_MODES.QUICKSELECT, title: 'Chart', allowInRollback: false },
    children: [
        {
            path: 'upper-jaw',
            name: 'quickselect-upper-jaw',
            component: UpperJaw,
            meta: { back: { name: 'dashboard' }, 'mouth-view': 'upper-jaw', title: 'Upper jaw'  }
        },
        {
            path: 'lower-jaw',
            name: 'quickselect-lower-jaw',
            component: LowerJaw,
            meta: { back: { name: 'dashboard' }, 'mouth-view': 'lower-jaw', title: 'Lower jaw'  }
        },
        {
            path: '',
            name: 'quickselect-full-mouth',
            component: FullMouth,
            meta: { back: { name: 'dashboard' }, 'mouth-view': 'full-mouth', title: false }
        },
    ]
});

export default chartRoutes;
