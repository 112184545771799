<template>

    <ul class="zones">

        <li v-for="pad in pads" :key="pad.index" :class="padClasses(pad)">
            <input
                type="checkbox"
                :id="inputId(pad.index)"
                :disabled="!pad.enabled"
                :value="pad.index"
                v-model="selectedPadIndexes"
            />
            <label :for="inputId(pad.index)">{{ pad.label }}</label>
        </li>


    </ul>

</template>
<script>
import { ZONES } from '@/config/teeth.js';

const padZones = Object.freeze({
    1: [ ZONES.CERVICAL_BUCCAL ],
    2: [ ZONES.BUCCAL ],
    3: [ ZONES.MESIAL],
    4: [ ZONES.INCISAL, ZONES.OCCLUSAL],
    5: [ ZONES.DISTAL ],
    6: [ ZONES.PALATAL, ZONES.LINGUAL ],
    7: [ ZONES.CERVICAL_PALATAL, ZONES.CERVICAL_LINGUAL ],
    8: [ ZONES.CLASS_4_MESIAL, ZONES.BUCCAL_CUSP, ZONES.MESIO_BUCCAL_CUSP ],
    9: [ ZONES.CLASS_4_DISTAL, ZONES.PALATAL_CUSP, ZONES.LINGUAL_CUSP, ZONES.DISTO_BUCCAL_CUSP ],
    10: [ ZONES.BUCCAL_SURFACE, ZONES.MESIO_PALATAL_CUSP, ZONES.MESIO_LINGUAL_CUSP ],
    11: [ ZONES.PALATAL_SURFACE, ZONES.LINGUAL_SURFACE, ZONES.DISTO_PALATAL_CUSP, ZONES.DISTO_LINGUAL_CUSP ]
});

export default {
    props: {
        value: { required: true },
    },
    data() {
        return {
        };
    },

    computed: {
        zones() {
            return this.value;
        },
        pads() {
            const zonesByPadIndex = this.zonesByPadIndex;
            return Object.keys(padZones).map(padIndex => {
                const padZone = zonesByPadIndex[padIndex];
                const pad = {
                    index: padIndex,
                    label: padZone ? this.$store.getters['patient/teeth/zoneLabel'](padZone.name) : undefined,
                    enabled: !!padZone
                };
                return pad;
            });
        },
        selectedPadIndexes: {
            get() {
                return Object.keys(this.zonesByPadIndex).filter(padIndex => this.zonesByPadIndex[padIndex].selected);
            },
            set(indexes) {
                const selectedZoneNames = Object.keys(this.zonesByPadIndex)
                    .filter(padIndex => indexes.includes(padIndex))
                    .map(padIndex => this.zonesByPadIndex[padIndex].name)
                ;

                this.$emit('input', selectedZoneNames);
            }
        },
        zonesByPadIndex() {
            return this.zones.reduce((map, zone) => {
                const padIndex = Object.keys(padZones).find(padIndex => padZones[padIndex].includes(zone.name));
                map[padIndex] = zone;
                return map;
            }, {});
        }
    },

    methods: {
        inputId(padIndex) {
            return 'pad-' + padIndex;
        },

        padClasses(pad) {
            const classes = [ "pad" ];

            if (!pad.enabled) {
                classes.push('disabled');
            }

            if (this.selectedPadIndexes.includes(pad.index)) {
                classes.push('selected');
            }

            return classes;
        }

    }


};
</script>
