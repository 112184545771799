import { RESTORATIONS } from '@/config/teeth.js';
import { PATHOLOGY_AND_RESTORATION_ACTIONS } from '@/config/pathologies-and-restorations.js';
import { RESTORATION_MATERIALS } from '@/config/restorations/general.js';
import { CROWN_TYPES, CROWN_DETAIL_TYPES } from '@/config/restorations/crown.js';


export default {

    namespaced: true,

    getters: {
        definition: (state, getters, rootState, rootGetters) => (toothNumber) => {
            return rootGetters['patient/teeth/state/restoration/type'](RESTORATIONS.CROWN, toothNumber).details || {};
        },
        params: () => () => {
            return {};
        },

        buildRestorationState: (state, getters, rootState, rootGetters) => (toothNumber) => {
            const params = { [CROWN_DETAIL_TYPES.TYPE]: CROWN_TYPES.PONTIC };
            return rootGetters['patient/teeth/state/restoration/orderedRestoration']({
                ...getters.defaultState(toothNumber),
                ...params
            });
        },

        defaultState: (state, getters, rootState, rootGetters) => (toothNumber) => {
            return rootGetters['patient/teeth/state/restoration/crown/defaultState'](toothNumber);
        },

        toothStateValues: (state, getters, rootState, rootGetters) => ({ toothNumber, stateValue, toothState }) => {
            return {
                state: stateValue,
                restoration: rootGetters['patient/teeth/state/restoration/saveableToothState']({
                    toothNumber,
                    appendableState: toothState
                })
            };
        },

        buildHistoryRecord: (state, getters, rootState, rootGetters) => ({ toothNumber, selectedSurfaces, details }) => {
            return rootGetters['patient/teeth/state/restoration/buildCreatableRecord']({
                action: PATHOLOGY_AND_RESTORATION_ACTIONS.SAVE,
                typeName: RESTORATIONS.CROWN,
                toothNumber,
                selectedSurfaces,
                details
            });
        }

    },

    // "41, Crown, Pontic"

    actions: {

        perform(context, { numbers }) {

            const creatableRecords = [];
            const teethStateValues = numbers.reduce((object, toothNumber) => {
                const definition = context.getters.definition(toothNumber);

                const toothState = context.getters.buildRestorationState( toothNumber );
                // toothState now contains something like
                // {"restoration":5, "surfaces": [......], "crown_type": 2, "created_at": "20......Z" }


                // normally details are not allowed to be skipped
                // (material must be specified first, only then the crown type can be specified etc)
                // but in this case material is not needed, and only the crown type is needed.
                // for this to work, a material gets initially simulated, so that the crown type detail works,
                // and then material gets removed later below
                const simulatedToothState = Object.assign({}, toothState);
                simulatedToothState[CROWN_DETAIL_TYPES.MATERIAL] = RESTORATION_MATERIALS.COMPOSIT;

                const details = context.rootGetters['patient/teeth/state/restoration/crown/detailsFromToothState']({
                    toothNumber,
                    definition,
                    toothState: simulatedToothState
                });
                // details now contains something like
                /// { "material": {
                //      "labelKey":"restoration.material",
                //      "options": [
                //              { "value": 0, "labelKey": "restoration.material.composit", label: "Composit",
                //              {...}
                //      ],
                //      "values": [ 0 ]
                //    },
                //    "crown_type": { .... }
                //  }

                // remove the simulated material from details, so that only crown type is left
                delete details[CROWN_DETAIL_TYPES.MATERIAL];

                const stateValue = context.rootGetters['patient/teeth/state/restoration/calculateToothStateValue']({
                    toothNumber,
                    toothState,
                    details
                });
                // stateValue now has an integer indicating the tooth's state (CROWN)

                const toothStateValues = context.getters['toothStateValues']({ toothNumber, stateValue, toothState });
                // toothStateValues now has the complete tooth state to replace in db (including previous stuff)
                object[toothNumber] = toothStateValues;

                // build a history record
                const historyRecord = context.getters['buildHistoryRecord']({ toothNumber, selectedSurfaces: toothState.surfaces, details });
                creatableRecords.push(historyRecord);
                return object;
            }, {});

            return context.dispatch('patient/teeth/state/restoration/saveStates', { teethStateValues, creatableRecords }, { root: true });
        },
    }

};
