<template>
    <div class="general-info">
        <derec-logo />

        <h1>{{ t('patientInfo', 'pdf') }}</h1>

        <table>
            <tbody>
                <tr>
                    <th scope="row">
                        {{ t('patientName', 'pdf') }}:
                    </th>
                    <td>
                        {{ patient.full_name }}
                    </td>
                </tr>

                <tr>
                    <th scope="row">
                        {{ t('dateOfBirth', 'pdf') }}:
                    </th>
                    <td>
                        {{ formatDate(patient.date_of_birth) }}
                    </td>
                </tr>

                <tr v-if="teamTitle">
                    <th scope="row">
                        {{ t('praxisName', 'pdf') }}:
                    </th>
                    <td>
                        {{ teamTitle }}
                    </td>
                </tr>

                <tr v-if="doctorName">
                    <th scope="row">
                        {{ t('treatingDoctor', 'pdf') }}:
                    </th>
                    <td>
                        {{ doctorName }}
                    </td>
                </tr>

                <tr class="current-time">
                    <th scope="row">
                        {{ t('exportCreated', 'pdf') }}:
                    </th>
                    <td>
                        {{ formattedCurrentTime }}
                    </td>
                </tr>

                <tr class="report-date" v-if="historicalDate">
                    <th scope="row">
                        {{ t('exportedDate', 'pdf') }}:
                    </th>
                    <td>
                        {{ formattedHistoricalDate }}
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
</template>
<script>
import DerecLogo from './derec-logo.vue';

export default {
    components: { DerecLogo },
    computed: {
        patient() {
            return this.$store.state.patient.object;
        },

        teamTitle() {
            return this.$store.getters['authentication/teamTitle'];
        },

        doctorName() {
            return this.$store.getters['authentication/userFullName'];
        },

        formattedCurrentTime() {
            const utcTimestamp = this.$store.getters['time/currentUtcTimestamp'];
            const isoDateString = this.dateFromUtcTimestamp(utcTimestamp);
            const localizedTeamDate = this.formatDate(isoDateString);
            const localizedTeamTime = this.timeFromUtcTimestamp(utcTimestamp);
            return localizedTeamDate + ', ' + localizedTeamTime;
        },

        historicalDate() {
            if (this.$store.getters['patient/teeth/state/isHistorical']) {
                return this.$store.state.patient.teeth.state.teethStateDate;
            }
            return undefined;
        },

        formattedHistoricalDate() {
            return this.formatDate(this.historicalDate);
        }

    }
};

</script>
