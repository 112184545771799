<template>
    <div data-view="overview">
        <ul class="tools">
            <li class="reset">
                <reset-tooth-button :tooth-number="toothNumber" />
            </li>
            <li class="to-be-extracted" v-if="isToothExtractable">
                <tooth-to-be-extracted-button :tooth-number="toothNumber" />
            </li>
            <li class="missing">
                <tooth-missing-button :tooth-number="toothNumber" />
            </li>
            <navigation-link
                :to="{ name: 'pathology', params: $route.params }"
                tag="li"
                :link-attributes="{ class: 'button link', 'data-icon': 'add' }"
                v-if="!isToothMissing"
            >
                {{ t('pathology', 'dentalDetailsVC') }}
            </navigation-link>
            <navigation-link
                :to="{ name: 'restoration', params: $route.params }"
                tag="li"
                :link-attributes="{ class: 'button link', 'data-icon': 'add' }"
            >
                {{ t('restoration', 'dentalDetailsVC') }}
            </navigation-link>
        </ul>

        <dental-panel :tooth="tooth" />

        <template v-if="!isToothMissing">
            <endodontic-list-panel :tooth="tooth" />

            <transition name="fade">
                <template v-if="isEndodonticTestInProgress">
                    <endodontic-test-panel :tooth="tooth" />
                </template>
                <template v-else>
                    <periodontal-panel :tooth="tooth" />
                </template>
            </transition>

        </template>
        <subscription-banner />
    </div>
</template>

<script>
import ResetToothButton from '@/components/patient/tooth/reset-button.vue';
import ToothToBeExtractedButton from '@/components/patient/tooth/to-be-extracted-button.vue';
import ToothMissingButton from '@/components/patient/tooth/missing-button.vue';

import DentalPanel from '@/components/patient/tooth/overview/dental-panel.vue';
import EndodonticListPanel from '@/components/patient/tooth/overview/endodontic/list-panel.vue';
import EndodonticTestPanel from '@/components/patient/tooth/overview/endodontic/test-panel.vue';
import PeriodontalPanel from '@/components/patient/tooth/overview/periodontal-panel.vue';
import SubscriptionBanner from "@/components/subscription-banner.vue";

export default {
    components: {
        SubscriptionBanner,
        ResetToothButton,
        ToothToBeExtractedButton,
        ToothMissingButton,
        DentalPanel,
        EndodonticListPanel,
        EndodonticTestPanel,
        PeriodontalPanel
    },
    props: {
        tooth: { required: true }
    },
    computed: {
        toothNumber() {
            return (this.tooth) ? this.tooth.number : null;
        },
        isToothMissing() {
            return this.$store.getters['patient/teeth/state/isToothMissing'](this.toothNumber);
        },
        isToothExtractable() {
            return this.$store.getters['patient/teeth/state/isToothExtractable'](this.toothNumber);
        },
        isEndodonticTestInProgress() {
            return this.$store.getters['patient/teeth/state/endodontic/isTestInProgress'](this.toothNumber);
        }
    },
    watch: {
        toothNumber() {
            this.closeEndodonticTest();
        }
    },
    methods: {
        closeEndodonticTest() {
            return this.$store.dispatch('patient/teeth/state/endodontic/endEditingTest');
        }

    }
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/patient/tooth/overview";
</style>
