export default {
  __name: 'sequential-mode-switch',
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  emits: ['input'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var internalValue = computed({
      get: function get() {
        return props.value;
      },
      set: function set(value) {
        emit("input", value);
      }
    });
    return {
      __sfc: true,
      props: props,
      emit: emit,
      internalValue: internalValue
    };
  }
};