import { ISSUE_TYPES, ISSUE_STATUSES } from '@/config/teeth.js';
import { PATHOLOGIES_AND_RESTORATIONS, PATHOLOGY_AND_RESTORATION_ACTIONS } from '@/config/pathologies-and-restorations.js';

export default {
    namespaced: true,
    state: {

        // these must be set when extending
        name: undefined,
        definedTypeNames: undefined,
    },

    getters: {

        storedToothState: (state, getters, rootState, rootGetters) => (toothNumber) => {
            const tooth = rootGetters['patient/teeth/state/tooth'](toothNumber) || {};
            return tooth[state.name] || getters.blankState(toothNumber);
        },

        typeStoreScope: (state, getters, rootState, rootGetters) => (typeName) => {
            const camelizedTypeName = rootGetters['filters/camelize'](typeName);
            return 'patient/teeth/state/' + state.name + '/' + camelizedTypeName;
        },

        tooth: (state, getters, rootState, rootGetters) => (toothNumber) => {
            return rootGetters['patient/teeth/tooth'](toothNumber);
        },

        typeNames: (state, getters) => (toothNumber) => {
            // each tooth type (incisor/premolar/molar) may have a different set of available pathologies/restorations
            const tooth = getters.tooth(toothNumber);
            if (!tooth) {
                return [];
            }

            return state.definedTypeNames.filter(typeName => {
                return PATHOLOGIES_AND_RESTORATIONS[typeName].toothTypes.includes(tooth.type);
            });
        },

        isTypeNameValid: (state, getters) => (typeName, toothNumber) => {
            return getters.typeNames(toothNumber).includes(typeName);
        },

        // returns the definition of the specific type, e.g. decay or filling
        type: (state, getters, rootState, rootGetters) => (typeName, toothNumber) => {
            if (getters.isTypeNameValid(typeName, toothNumber)) {
                const type = Object.assign(
                    {
                        name: typeName,
                        label: getters.typeLabel(typeName)
                    },
                    rootGetters[getters.typeStoreScope(typeName) + '/definition'](toothNumber)
                );
                return type;
            } else {
                return undefined;
            }
        },

        typeLabel: (state, getters, rootState, rootGetters) => (typeName) => {
            const translationKey = rootGetters['filters/camelize'](typeName);
            const translationScope = (state.name == 'pathology') ?  'pathology' : 'restoration.type';
            return rootGetters['i18n/t']( translationKey, translationScope );
        },

        types: (state, getters) => (toothNumber) => {
            return getters.typeNames(toothNumber).map(typeName => getters.type(typeName, toothNumber));
        },

        waiterName: (state) => (actionName) => {
            return actionName + ' ' + state.name;
        },

        creatableRecord: () => ({ action, values }) => {
            const record = {};
            if (action == PATHOLOGY_AND_RESTORATION_ACTIONS.MONITOR) {
                record.type = ISSUE_TYPES.MONITOR;
                record.status = ISSUE_STATUSES.ACTIVE;
            } else if (action == PATHOLOGY_AND_RESTORATION_ACTIONS.TREAT) {
                record.type = ISSUE_TYPES.URGENT;
                record.status = ISSUE_STATUSES.ACTIVE;
            } else if (action == PATHOLOGY_AND_RESTORATION_ACTIONS.SAVE) {
                record.type = ISSUE_TYPES.HISTORY;
            } else {
                return undefined;
            }
            return Object.assign(record, values);
        },


        creatableRecordFromEditableState: (state, getters, rootState, rootGetters) => ({ action, typeName }) => {
            const toothNumber = state.editableTooth.number;
            const selectedSurfaces = rootGetters[getters.typeStoreScope(typeName) + '/toothState'].surfaces;
            const details = rootGetters[getters.typeStoreScope(typeName) + '/details'](toothNumber);

            return getters.buildCreatableRecord({ action, typeName, toothNumber, selectedSurfaces, details });
        },

        buildCreatableRecord: (state, getters) => ({ action, typeName, toothNumber, selectedSurfaces, details }) => {
            const message = getters.creatableRecordMessage({ typeName, toothNumber, selectedSurfaces, details });
            return getters.creatableRecord({ action, values: { toothNumber, message }});
        },

        creatableRecordMessage: (state, getters, rootState, rootGetters) => ({ typeName, toothNumber, selectedSurfaces, details }) => {
            return rootGetters[getters.typeStoreScope(typeName) + '/creatableRecordMessage']({ toothNumber, selectedSurfaces, details });
        }

    },

    mutations: {

    },

    actions: {

        setTypeState( context, { typeName, typeState }) {
            return context.commit('setTypeState', { typeName, typeState });
        },

        endEditingTooth(context) {
            context.commit('endEditingTooth');
        },


        performAction(context, { name }) {
            const waiterName = context.getters.waiterName(name);
            context.dispatch('wait/start', waiterName, { root: true });
            return context.dispatch('saveState', { action: name } ).then(() => {
                return context.dispatch('endEditingTooth');
            }).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        },

        saveState(context, { action }) {
            const toothNumber = context.state.editableTooth.number;
            const stateValues = context.getters.saveableStateValues(toothNumber);

            const teethStateValues = { [toothNumber]: stateValues };

            const creatableRecords = context.getters.creatableRecords(action);

            return context.dispatch('saveStates', { teethStateValues, creatableRecords });
        },


        saveStates(context, { teethStateValues, creatableRecords }) {
            const updatePayload = { teethStateValues, creatableRecords };

            return context.dispatch('patient/teeth/state/updateTeethStates', updatePayload, { root: true });
        }

    }
};
