import freeze from '@/utils/freeze.js';
import clone from '@/utils/clone.js';

import { TOOTH_TYPES, ZONES, TOOTH_NUMBERS, TOOTH_SETS, TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE } from '@/config/teeth.js';

import {
    RESTORATION_DETAIL_TYPES,
    RESTORATION_MATERIALS,

    RESTORATION_DEFINITION_DEFAULT_DETAILS
} from '@/config/restorations/general.js';

import { DECAY_DEFINITION } from '@/config/pathologies/decay.js';

// "restoration": 0

// filling surfaces are almost identical to decay, with some small diferences
const fillingSurfaces = {};
TOOTH_NUMBERS.forEach(number => {
    fillingSurfaces[number] = clone(DECAY_DEFINITION.teeth[number].surfaces);
});

// permanent incisors all have the same override for buccal zone
const permanentIncisorNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.INCISOR];
permanentIncisorNumbers.forEach(number => {
    fillingSurfaces[number][ZONES.BUCCAL] = [4];
});

// deciduous incisors also have the same buccal override logic, but the numbers are different for each tooth
fillingSurfaces["51"][ZONES.BUCCAL] = [2, 3];
fillingSurfaces["61"] = clone(fillingSurfaces["51"]);

fillingSurfaces["52"][ZONES.BUCCAL] = [2, 3];
fillingSurfaces["62"] = clone(fillingSurfaces["52"]);

fillingSurfaces["53"][ZONES.BUCCAL] = [4, 5];
fillingSurfaces["63"] = clone(fillingSurfaces["53"]);


fillingSurfaces["81"][ZONES.BUCCAL] = [4, 5];
fillingSurfaces["71"] = clone(fillingSurfaces["81"]);

fillingSurfaces["82"][ZONES.BUCCAL] = [4, 5];
fillingSurfaces["72"] = clone(fillingSurfaces["82"]);

fillingSurfaces["83"][ZONES.BUCCAL] = [4, 5];
fillingSurfaces["73"] = clone(fillingSurfaces["83"]);





// filling has two more material options than other restorations
const fillingDetails = clone(RESTORATION_DEFINITION_DEFAULT_DETAILS);

fillingDetails[RESTORATION_DETAIL_TYPES.MATERIAL].options.splice(2, 0, {
    value: RESTORATION_MATERIALS.AMALGAM,
    labelKey: "restoration.material.amalgam"
});

fillingDetails[RESTORATION_DETAIL_TYPES.MATERIAL].options.push({
    value: RESTORATION_MATERIALS.TEMPORARY,
    labelKey: "restoration.material.temporary"
});


const definition = {
    toothTypes: [TOOTH_TYPES.INCISOR, TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};

TOOTH_NUMBERS.forEach(number => {
    definition.teeth[number] = {
        surfaces: fillingSurfaces[number],
        details: fillingDetails
    };
});

const FILLING_DEFINITION = freeze(definition);

export {
    FILLING_DEFINITION
};
