<template>
    <div class="legend" :class="{ open: isOpen }">
        <transition name="fade">
            <aside class="panel" v-if="isOpen">
                <legend-list />
            </aside>
        </transition>

        <panel-toggle-button
            :title="toggleButtonTranslation"
            :is-open="isOpen"
            v-on:toggle="toggle"
            icon="hint"
            icon-width="21"
            icon-height="21"
        />

    </div>
</template>

<script>
import CloseOnEscape from '@/mixins/closeOnEscape.js';
import LegendList from './list.vue';
import PanelToggleButton from '@/components/panel-toggle-button.vue';

export default {
    components: { LegendList, PanelToggleButton },
    mixins: [ CloseOnEscape ],
    data: () => {
        return {

        };
    },

    computed: {
        isOpen() {
            return this.$store.state.chart.legendIsOpen;
        },

        toggleButtonTranslation() {
            return this.isOpen
                ? this.t('Hide legend', 'dentalChartVC.legend')
                : this.t('Show legend', 'dentalChartVC.legend');
        }
    },

    methods: {
        close() {
            if (this.isOpen) {
                this.toggle();
            }
        },
        toggle() {
            this.$store.dispatch('chart/toggleLegend');
        }
    }
};
</script>
