<template>
    <aside class="medical-issues">
        <header>
            <h2 class="heading">{{ t('medicalIssues', 'patientVC') }}</h2>

            <button v-if="activeIssuesExist" class="button link edit" type="button" v-on:click="openDialog">
                {{ t('edit', 'common') }}
            </button>
        </header>

        <medical-issue-list :issues="activeIssues" v-if="activeIssuesExist" />

        <button v-else class="button link" data-icon="add" type="button" v-on:click="openDialog">
            {{ t('addIssue', 'patientVC') }}
        </button>

        <modal-dialog v-if="editingIssues" v-on:open="focusDialogCancel" v-on:cancel="closeDialog" :close-on-outside-click="false">
            <h1 class="title">{{ t('title', 'patientVC.medicalIssue') }}</h1>

            <form method="post" v-on:submit.prevent="saveIssues">
                <fieldset>
                    <ul>
                        <li class="issue" v-for="issue in editableIssues" :key="issue.value" :style="issue.style">
                            <p class="field">
                                <span class="control checkbox">
                                    <input type="checkbox" name="issues[]" v-model="issue.active" :id="'issue-' + issue.value" />
                                    <label :for="'issue-' + issue.value">{{ issue.label }}</label>
                                </span>
                            </p>
                        </li>
                    </ul>
                </fieldset>

                <p class="actions">
                    <waiting-button :for="waiterName" type="submit" class="button">
                        {{ t('save', 'patientVC.medicalIssue') }}
                    </waiting-button>

                    <button class="button link cancel" type="button" ref="cancel" v-on:click="closeDialog">{{ t('cancel', 'common') }}</button>
                </p>
            </form>
        </modal-dialog>

    </aside>
</template>

<script>
import ModalDialog from '@/components/modal-dialog.vue';
import MedicalIssueList from './medical-issues/list.vue';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { ModalDialog, MedicalIssueList },
    props: {
        patientId: { required: true }
    },
    data: function() {
        return {
            editingIssues: false,
            editableIssues: undefined
        };
    },
    computed: {
        definedIssues() {
            return this.$store.getters['patient/medicalIssues/defined'];
        },
        activeIssueValues() {
            return this.$store.getters['patient/medicalIssues/activeValues'];
        },
        activeIssues() {
            return this.$store.getters['patient/medicalIssues/active'];
        },
        activeIssuesExist() {
            return this.activeIssues.length > 0;
        }
    },
    created() {
        this.waiterName = WAITERS.SAVING_MEDICAL_ISSUES;
    },
    methods: {
        openDialog() {
            this.ensureTeethStateModifiability().then(() => {
                this.editingIssues = true;
                this.editableIssues = this.definedIssues.map(issue => {
                    return Object.assign(
                        {},
                        issue,
                        { active: this.activeIssueValues.includes(issue.value) }
                    );
                });
            }, () => {});
        },
        closeDialog() {
            this.editingIssues = false;
            this.editableIssues = undefined;
        },
        focusDialogCancel() {
            this.$refs.cancel.focus();
        },
        saveIssues() {
            if (this.$wait.is()) {
                return;
            }
            this.$wait.start(this.waiterName);

            const values = this.editableIssues.filter(issue => issue.active).map(issue => issue.value);

            this.$api.updateMedicalIssues(this.patientId, values).then(() => {
                this.$store.dispatch('patient/medicalIssues/setValues', values);
                this.closeDialog();
            }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.waiterName));
        }
    }
};
</script>
