import freeze from '@/utils/freeze.js';

import { TOOTH_TYPES, ISSUE_TYPES } from '@/config/teeth.js';

import {
    COLD_TEST_RESULTS,
    COLD_TEST_SUBRESULTS,
    PERCUSSION_TEST_RESULTS,
    ELECTRICITY_TEST_RESULTS
} from '@/config/endodontic-tests.js';

const PULP_TYPES = freeze({
    NONE: 0,
    MONITOR: 1, // called "observe" in ios
    URGENT: 2, // called "treat" in ios
    NECROTIC: 3,
    ROOT_CANAL_TREATMENT: 4  // called "rootTreat" in ios
});

const range = (from, to) => {
    return Array.from(Array(to - from + 1), (_, index) => from + index);
};

const ACTIVE_ELECTRICITY_TEST_RANGES = {
    [TOOTH_TYPES.INCISOR]:  range( ELECTRICITY_TEST_RESULTS.E_1, ELECTRICITY_TEST_RESULTS.E_4 ),
    [TOOTH_TYPES.PREMOLAR]: range( ELECTRICITY_TEST_RESULTS.E_2, ELECTRICITY_TEST_RESULTS.E_5 ),
    [TOOTH_TYPES.MOLAR]:    range( ELECTRICITY_TEST_RESULTS.E_3, ELECTRICITY_TEST_RESULTS.E_7 )
};


const texts = require('@/lang/en.json'); // use english locale for verifying that all diagnoses have valid translations
const definedTextKeys = Object.keys(texts);

const validDiagnosisKeys = [
    "toothType",
    "coldTestResult",
    "coldTestSubresult",

    "apicalPathology",
    "percussionTestResult",
    "electricityTestResult",

    "existingRootCanalTreatment",
    "previouslyInitiatedRootCanalTherapy",

    "pulpType",
    "issueType",
    "translationKey"

];
const requiredDiagnosisKeys = [
    "pulpType",
    "issueType",
    "translationKey"
];



const defineDiagnosis = (values) => {
    const diagnosis = new Proxy({}, {
        set(object, key, value) {
            if (!validDiagnosisKeys.includes(key)) {
                // ensure that there are no typos in keys
                throw "Invalid key in root canal diagnosis definition: " + key;
            } else if (key == "translationKey" && !definedTextKeys.includes(value)) {
                // ensure that unknown translation keys are not used
                throw "Root canal diagnosis key not defined in translations: " + value;
            } else {
                return Reflect.set(...arguments);
            }
        }
    });
    Object.keys(values).forEach(key => diagnosis[key] = values[key]);
    // ensure that all required keys are present in definition
    const keys = Object.keys(diagnosis);
    requiredDiagnosisKeys.forEach(key => {
        if (!keys.includes(key)) {
            throw 'Required root canal diagnosis key "' + key + '" missing from diagnosis definition: ' + JSON.stringify(values);
        }
    });
    return diagnosis;
};

const diagnoses = [];
const addDiagnosis = (rules) => {
    diagnoses.push(defineDiagnosis(rules));
};

// WARNING: the order of diagnoses is important.
// diagnoses are checked sequentially, and the first one that matches will be returned.
// this means that, e.g., if one diagnosis is for some state with apical pathology,
// and another one is for the same state without apical pathology,
// then the first one should be defined first, with apicalPathology: true,
// and the second one should be defined later, without specifying the apicalPathology condition.
// if they were defined in reverse order, then the second one would match both inputs,
// because it has no constraints on apicalPathology, and the code would never reach the first one.
// there is a test in spec/misc/root_canal_diagnostics_spec.rb that partially guards against this,
// by checking that each defined diagnosis can be reached by at least one combination of inputs.

// condition numbers refer to entries in the spreadsheet

// condition 5
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.POSITIVE,
    coldTestSubresult: COLD_TEST_SUBRESULTS.UNPLEASANT,

    pulpType: PULP_TYPES.MONITOR,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.unpleasantReactionToCold",
});

// condition 6
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.POSITIVE,
    coldTestSubresult: COLD_TEST_SUBRESULTS.PAIN_STIMULUS,

    pulpType: PULP_TYPES.MONITOR,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.reversiblePulpitis",
});

// conditions 7, 9
addDiagnosis({
    // this covers 2 separate diagnoses in the spreadsheet, which are the same except for apical yes/no
    // so the apical condition can be simply skipped here

    coldTestResult: COLD_TEST_RESULTS.POSITIVE,
    coldTestSubresult: COLD_TEST_SUBRESULTS.PAIN_LINGERING,
    percussionTestResult: PERCUSSION_TEST_RESULTS.PAINFUL,

    pulpType: PULP_TYPES.URGENT,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.irreversiblePulpitisWithSymptomaticApicalPeriodontitis",
});

// condition 8
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.POSITIVE,
    coldTestSubresult: COLD_TEST_SUBRESULTS.PAIN_LINGERING,
    apicalPathology: true,
    percussionTestResult: PERCUSSION_TEST_RESULTS.NOT_PAINFUL,

    pulpType: PULP_TYPES.URGENT,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.irreversiblePulpitisWithAsymptomaticApicalPeriodontitis",
});



// condition 10
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.POSITIVE,
    coldTestSubresult: COLD_TEST_SUBRESULTS.PAIN_LINGERING,
    percussionTestResult: PERCUSSION_TEST_RESULTS.NOT_PAINFUL,

    pulpType: PULP_TYPES.URGENT,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.irreversiblePulpitisWithNormalApicalTissues",
});

// condition 11
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.UNCERTAIN,
    apicalPathology: true,
    percussionTestResult: PERCUSSION_TEST_RESULTS.PAINFUL,

    pulpType: PULP_TYPES.NECROTIC,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.pulpNecrosisWithSymptomaticApicalPeriodontitis"
});

// condition 12
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.UNCERTAIN,
    apicalPathology: true,
    percussionTestResult: PERCUSSION_TEST_RESULTS.NOT_PAINFUL,

    pulpType: PULP_TYPES.NECROTIC,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.pulpNecrosisWithAsymptomaticApicalPeriodontitis"
});


// condition 13
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.UNCERTAIN,
    percussionTestResult: PERCUSSION_TEST_RESULTS.PAINFUL,

    pulpType: PULP_TYPES.NECROTIC,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.pulpNecrosisWithSymptomaticApicalPeriodontitis"
});

// condition 14
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.UNCERTAIN,
    percussionTestResult: PERCUSSION_TEST_RESULTS.NOT_PAINFUL,

    pulpType: PULP_TYPES.MONITOR,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.uncertainPulpStatusWithNormalApicalTissues"
});

// conditions 16, 18, 20
addDiagnosis({
    // this covers 3 separate diagnoses in the spreadsheet, which are the same except for the tooth type
    // so the tooth type can simply be skipped here
    coldTestResult: COLD_TEST_RESULTS.UNCERTAIN,
    apicalPathology: true,
    electricityTestResult: [ ELECTRICITY_TEST_RESULTS.E_10 ],

    pulpType: PULP_TYPES.NECROTIC,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.pulpNecrosisWithAsymptomaticApicalPeriodontitis"
});

// condition 15
addDiagnosis({
    toothType: TOOTH_TYPES.INCISOR,

    coldTestResult: COLD_TEST_RESULTS.UNCERTAIN,
    apicalPathology: true,
    electricityTestResult: ACTIVE_ELECTRICITY_TEST_RANGES[TOOTH_TYPES.INCISOR],

    pulpType: PULP_TYPES.NONE,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.nonEndodonticApicalPathology"
});

// condition 17
addDiagnosis({
    toothType: TOOTH_TYPES.PREMOLAR,

    coldTestResult: COLD_TEST_RESULTS.UNCERTAIN,
    apicalPathology: true,
    electricityTestResult: ACTIVE_ELECTRICITY_TEST_RANGES[TOOTH_TYPES.PREMOLAR],

    pulpType: PULP_TYPES.NONE,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.nonEndodonticApicalPathology"
});

// condition 19
addDiagnosis({
    toothType: TOOTH_TYPES.MOLAR,

    coldTestResult: COLD_TEST_RESULTS.UNCERTAIN,
    apicalPathology: true,
    electricityTestResult: ACTIVE_ELECTRICITY_TEST_RANGES[TOOTH_TYPES.MOLAR],

    pulpType: PULP_TYPES.NONE,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.nonEndodonticApicalPathology"
});


// conditions 22, 24, 26
addDiagnosis({
    // this covers 3 separate diagnoses in the spreadsheet, which are the same except for the tooth type
    // so the tooth type can simply be skipped here
    coldTestResult: COLD_TEST_RESULTS.UNCERTAIN,
    electricityTestResult: [ ELECTRICITY_TEST_RESULTS.E_10 ],

    pulpType: PULP_TYPES.NECROTIC,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.pulpNecrosisWithNormalApicalTissues"
});

// conditions 34, 36, 38
addDiagnosis({
    // this covers 3 separate diagnoses in the spreadsheet, which are the same except for the tooth type
    // so the tooth type can simply be skipped here
    coldTestResult: COLD_TEST_RESULTS.NEGATIVE,
    apicalPathology: true,
    electricityTestResult: [ ELECTRICITY_TEST_RESULTS.E_10 ],

    pulpType: PULP_TYPES.NECROTIC,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.pulpNecrosisWithAsymptomaticApicalPeriodontitis"
});

// conditions 28, 30, 32
addDiagnosis({
    // this covers 3 separate diagnoses in the spreadsheet, which are the same except for the tooth type
    // so the tooth type can simply be skipped here
    coldTestResult: COLD_TEST_RESULTS.NEGATIVE,
    electricityTestResult: [ ELECTRICITY_TEST_RESULTS.E_10 ],

    pulpType: PULP_TYPES.NECROTIC,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.pulpNecrosisWithNormalApicalTissues"
});

// condition 33
addDiagnosis({
    toothType: TOOTH_TYPES.INCISOR,

    coldTestResult: COLD_TEST_RESULTS.NEGATIVE,
    apicalPathology: true,
    electricityTestResult: ACTIVE_ELECTRICITY_TEST_RANGES[TOOTH_TYPES.INCISOR],

    pulpType: PULP_TYPES.NONE,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.nonEndodonticApicalPathology"
});

// condition 35
addDiagnosis({
    toothType: TOOTH_TYPES.PREMOLAR,

    coldTestResult: COLD_TEST_RESULTS.NEGATIVE,
    apicalPathology: true,
    electricityTestResult: ACTIVE_ELECTRICITY_TEST_RANGES[TOOTH_TYPES.PREMOLAR],

    pulpType: PULP_TYPES.NONE,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.nonEndodonticApicalPathology"
});

// condition 37
addDiagnosis({
    toothType: TOOTH_TYPES.MOLAR,

    coldTestResult: COLD_TEST_RESULTS.NEGATIVE,
    apicalPathology: true,
    electricityTestResult:  ACTIVE_ELECTRICITY_TEST_RANGES[TOOTH_TYPES.MOLAR],

    pulpType: PULP_TYPES.NONE,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.nonEndodonticApicalPathology"
});

// conditions 39, 41
addDiagnosis({
    // this covers 2 separate diagnoses in the spreadsheet, which are the same except for apical yes/no
    // so the apical condition can be simply skipped here

    coldTestResult: COLD_TEST_RESULTS.NEGATIVE,
    percussionTestResult: PERCUSSION_TEST_RESULTS.PAINFUL,

    pulpType: PULP_TYPES.NECROTIC,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.pulpNecrosisWithSymptomaticApicalPeriodontitis"
});

// condition 42
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.NEGATIVE,
    apicalPathology: true,
    percussionTestResult: PERCUSSION_TEST_RESULTS.NOT_PAINFUL,

    pulpType: PULP_TYPES.NECROTIC,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.pulpNecrosisWithAsymptomaticApicalPeriodontitis"
});

// condition 40
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.NEGATIVE,
    percussionTestResult: PERCUSSION_TEST_RESULTS.NOT_PAINFUL,

    pulpType: PULP_TYPES.MONITOR,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.uncertainPulpStatus"
});

// conditions 43, 45
addDiagnosis({
    // this covers 2 separate diagnoses in the spreadsheet, which are the same except for apical yes/no
    // so the apical condition can be simply skipped here
    coldTestResult: COLD_TEST_RESULTS.NOT_APPLICABLE,
    percussionTestResult: PERCUSSION_TEST_RESULTS.PAINFUL,
    existingRootCanalTreatment: true,

    pulpType: PULP_TYPES.ROOT_CANAL_TREATMENT,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.previouslyTreatedToothWithSymptomaticApicalPeriodontitis"
});

// condition 46
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.NOT_APPLICABLE,
    apicalPathology: true,
    percussionTestResult: PERCUSSION_TEST_RESULTS.NOT_PAINFUL,
    existingRootCanalTreatment: true,

    pulpType: PULP_TYPES.ROOT_CANAL_TREATMENT,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.previouslyTreatedToothWithAsymptomaticApicalPeriodontitis"
});

// condition 44
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.NOT_APPLICABLE,
    percussionTestResult: PERCUSSION_TEST_RESULTS.NOT_PAINFUL,
    existingRootCanalTreatment: true,

    pulpType: PULP_TYPES.ROOT_CANAL_TREATMENT,
    issueType: ISSUE_TYPES.MONITOR,
    translationKey: "rootCanal.issues.previouslyTreatedToothWithNormalApicalTissues"
});


// conditions 47, 49
addDiagnosis({
    // this covers 2 separate diagnoses in the spreadsheet, which are the same except for apical yes/no
    // so the apical condition can be simply skipped here
    coldTestResult: COLD_TEST_RESULTS.NOT_APPLICABLE,
    percussionTestResult: PERCUSSION_TEST_RESULTS.PAINFUL,
    previouslyInitiatedRootCanalTherapy: true,

    pulpType: PULP_TYPES.URGENT,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.previouslyInitiatedTherapyWithSymptomaticApicalPeriodontitis"
});

// conditions 50
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.NOT_APPLICABLE,
    apicalPathology: true,
    percussionTestResult: PERCUSSION_TEST_RESULTS.NOT_PAINFUL,
    previouslyInitiatedRootCanalTherapy: true,

    pulpType: PULP_TYPES.URGENT,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.previouslyInitiatedTherapyWithAsymptomaticApicalPeriodontitis"
});

// conditions 48
addDiagnosis({
    coldTestResult: COLD_TEST_RESULTS.NOT_APPLICABLE,
    percussionTestResult: PERCUSSION_TEST_RESULTS.NOT_PAINFUL,
    previouslyInitiatedRootCanalTherapy: true,

    pulpType: PULP_TYPES.URGENT,
    issueType: ISSUE_TYPES.URGENT,
    translationKey: "rootCanal.issues.previouslyInitiatedTherapyWithNormalApicalTissues"
});


const ROOT_CANAL_DIAGNOSTIC_MATRIX = freeze(diagnoses);

export {
    PULP_TYPES,
    ROOT_CANAL_DIAGNOSTIC_MATRIX,
    ACTIVE_ELECTRICITY_TEST_RANGES
};
