<template>
    <div data-view="schedule">
        Schedule table
    </div>
</template>

<script>
export default {

};
</script>
