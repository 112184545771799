import merge from 'lodash/merge';
import PathologyType from '../pathologyType.js';
import { PATHOLOGIES } from '@/config/teeth.js';

export default merge({}, PathologyType, {

    state: {
        name: PATHOLOGIES.DEVELOPMENT_DISORDER
    },

    getters: {
    },

    mutations: {

    },

    actions: {

    }

});
