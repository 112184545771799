import merge from 'lodash/merge';
import PathologyType from '../pathologyType.js';
import { PATHOLOGIES } from '@/config/teeth.js';
import { APICAL_PRESENCES } from '@/config/pathologies/apical.js';

export default merge({}, PathologyType, {

    state: {
        name: PATHOLOGIES.APICAL
    },

    getters: {
        presence: (state, getters) => (toothNumber) => {
            const value = getters.parent('toothState', toothNumber)[state.name].data.split('/')[1];
            if (value == APICAL_PRESENCES.YES) {
                return true;
            } else if (value == APICAL_PRESENCES.NO) {
                return false;
            } else {
                return null;
            }
        }
    },

    mutations: {

    },

    actions: {

    }

});
