<template>
    <table class="periodontal-issues" :data-projection="projection" :data-tooth-number="toothNumber">
        <tr v-for="issueName in issueNames" :key="issueName" class="issue" :data-type="issueName">

            <td
                v-for="zoneName in zoneNames"
                :key="zoneName"
                class="indicator"
                :class="{ active: issueIsActive(zoneName, issueName) }"
                :data-type="issueName"
                :data-zone="zoneName"
                :title="indicatorTitle(zoneName, issueName)"
            >

            </td>
        </tr>
    </table>

</template>
<script>
export default {
    props: {
        toothNumber: { required: true },
        projection: { required: true }
    },

    data: () => {
        return {

        };
    },

    computed: {
        tooth() {
            return this.$store.getters['patient/teeth/tooth'](this.toothNumber);
        },

        quadrant() {
            return this.tooth.quadrant;
        },

        issueNames() {
            return ["plaque", "bleeding", "pus"];
        },

        zoneNames() {
            let names = [ "disto-" + this.projection, this.projection, "mesio-" + this.projection ];
            if (this.quadrant == 2 || this.quadrant == 3) {
                names.reverse();
            }
            return names;
        },

        activeIssueNamesByZones() {
            let activeIssueNamesByZone = {};
            this.zoneNames.forEach(zoneName => {
                const zone = this.$store.getters['patient/teeth/state/periodontal/zone'](this.toothNumber, zoneName);
                const issueNames = zone.issues.map(
                    issueValue => this.$store.getters['patient/teeth/state/periodontal/issueName'](issueValue)
                );
                activeIssueNamesByZone[zoneName] = issueNames;
            });
            return activeIssueNamesByZone;
        }

    },
    methods: {
        issueIsActive(zoneName, issueName) {
            return this.activeIssueNamesByZones[zoneName].includes(issueName);
        },
        indicatorTitle(zoneName, issueName) {
            const zoneLabel = this.$store.getters['patient/teeth/zoneLabel'](zoneName);
            return zoneLabel + '; ' + this.t(issueName, 'dental');
        }

    }
};
</script>
