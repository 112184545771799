import store from '@/store/store.js';

import Profile from '@/views/profile.vue';
import Settings from '@/views/settings.vue';
import Loading from '@/views/loading.vue';
import NotFound from '@/views/not-found.vue';
import Billing from '@/views/billing.vue';

const backToPreviousOrPatients = () => {
    const previousRoute = store.state.navigation.previousRoute;
    if (previousRoute) {
        return previousRoute;
    } else {
        return store.getters['navigation/patientsRoute'];
    }
};

export default [
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            back: backToPreviousOrPatients,
            transition: { sequenceNumber: 3, from: 'right' },
            title: 'My profile',
            allowInIntegratorMode: false
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
            back: backToPreviousOrPatients,
            transition: { sequenceNumber: 3, from: 'right' },
            title: 'Settings',
            allowInIntegratorMode: false
        }
    },
    {
        path: '/billing',
        name: 'billing',
        component: Billing,
        meta: {
            back: backToPreviousOrPatients,
            transition: { sequenceNumber: 3, from: 'right' },
            title: 'Billing',
            allowInIntegratorMode: false
        }
    },
    {
        path: '/loading',
        name: 'loading',
        component: Loading,
        meta: { title: false } // this route gets accessed before texts have been loaded, so do not use a title here
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFound,
        meta: { allowAnonymousUser: false, title: "Page not found" }
    }
];
