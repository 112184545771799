export default {
  __name: 'sextant-pad',
  props: {
    sextant: {
      type: String,
      required: true
    },
    score: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  },
  emits: ['click'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;

    // pad display logic:
    // level null, furcation null - N/A / N/A
    // level null, furcation false - N/A / N/A  -> should not happen. if level is null, false furcation should be forced to null
    // level null, furcation true  - "", *

    // level 0, furcation null   - 0, "" -> should not happen. if level is a number, furcation should be forced to false
    // level 0, furcation false  - 0, ""
    // level 0, furcation true   - 0, *
    var dataExists = computed(function () {
      return props.score.level !== null || props.score.furcation;
    });
    var store = useStore();
    var notAvailableText = computed(function () {
      return store.getters['i18n/t']('na', 'basicPeriodontalExamination');
    });
    var levelText = computed(function () {
      var text = dataExists.value ? props.score.level : notAvailableText.value;
      if (text === null) {
        text = '\xa0'; // non-breaking space
      }
      return text;
    });
    var furcationText = computed(function () {
      return dataExists.value ? props.score.furcation ? "*" : "" : notAvailableText.value;
    });
    var emitClick = function emitClick() {
      emit('click', props.sextant);
    };
    return {
      __sfc: true,
      props: props,
      dataExists: dataExists,
      store: store,
      notAvailableText: notAvailableText,
      levelText: levelText,
      furcationText: furcationText,
      emit: emit,
      emitClick: emitClick
    };
  }
};