import teeth from './patient/teeth.js';
import treatmentPlan from './patient/treatmentPlan.js';
import history from './patient/history.js';
import medicalIssues from './patient/medicalIssues.js';
import oralHealth from './patient/oralHealth.js';
import basicPeriodontalExamination from './patient/basicPeriodontalExamination.js';
import softTissue from './patient/softTissue.js';

export default {
    namespaced: true,

    modules: {
        teeth,
        treatmentPlan,
        history,
        medicalIssues,
        oralHealth,
        basicPeriodontalExamination,
        softTissue
    },

    state: {
        id: undefined,
        object: undefined, // it is important to initialize to undefined instead of null (needed for shouldExist),
        dataProcessingAgreementHtml: undefined,
        dataProcessingAgreementLoaded: false
    },

    getters: {
        exists: state => !!state.id && !!state.object && state.object.id == state.id,
        shouldExist: state => !!state.id && state.object !== undefined,
        fullName: state => state.object ? state.object.full_name : undefined,
        hasAcceptedDataProcessing: state => state.object ? state.object.data_processing_accepted : undefined,

    },

    mutations: {
        setId(state, id) {
            state.id = id;
        },
        clearId(state) {
            state.id = undefined;
        },
        setObject(state, object) {
            state.object = object;
        },
        clearObject(state) {
            state.object = undefined;
        },
        markObjectAsMissing(state) {
            state.object = null;
        },
        setDataProcessingAgreementHtml(state, html) {
            state.dataProcessingAgreementHtml = html;
            state.dataProcessingAgreementLoaded = true;
        },
        clearDataProcessingAgreement(state) {
            state.dataProcessingAgreementHtml = undefined;
            state.dataProcessingAgreementLoaded = false;
        },
        markDataProcessingAgreementAsAccepted(state) {
            if (state.object) {
                state.object.data_processing_accepted = true;
            }
        }
    },

    actions: {
        setId(context, id) {
            context.commit('setId', id);
            context.commit('clearDataProcessingAgreement');
            context.dispatch('loadCurrent');
        },
        clearId(context) {
            context.commit('clearId');
            context.dispatch('clear');
        },
        load(context, data) {
            context.commit('setId', data.id);

            const patientKeys = [ "id", "full_name", "date_of_birth", "person_code", "address", "gender", "data_processing_accepted" ];
            const patient = Object.keys(data).filter(key => patientKeys.includes(key)).reduce((object, key) => {
                object[key] = data[key];
                return object;
            }, {});
            context.commit('setObject', patient);

            context.dispatch('teeth/state/clear');
            context.dispatch('teeth/state/setTeethStateHistory', {
                dates: (data.manipulation_dates || []).concat().sort(),
                states: data.teeth || {}
            });

            context.dispatch('teeth/state/setLatestHistoricalStateAsCurrent');

            const issueRecords = data.issue_records ? data.issue_records : [];
            context.dispatch('treatmentPlan/replace', issueRecords);

            const historyRecords = data.history_records ? data.history_records : [];
            context.dispatch('history/replace', historyRecords);

            const softTissueRecords = data.soft_tissue_records ? data.soft_tissue_records : [];
            context.dispatch('softTissue/setItemsFromApiResponse', { apiResources: softTissueRecords, replace: true });

            const medicalIssues = (data.attribute_values && data.attribute_values.medical_issues) ? data.attribute_values.medical_issues : [];
            context.dispatch('medicalIssues/setValues', medicalIssues);

            const oralHealthIssues = (data.attribute_values && data.attribute_values.oral_health) ? data.attribute_values.oral_health : {};
            context.dispatch('oralHealth/setValues', oralHealthIssues);

            const basicPeriodontalExaminationRecords = data.basic_periodontal_examination_records ? data.basic_periodontal_examination_records : [];
            context.dispatch('basicPeriodontalExamination/setItemsFromApiResponse', { apiResources: basicPeriodontalExaminationRecords, replace: true });
        },
        clear(context) {
            context.commit('clearObject');
        },
        loadCurrent(context) {
            const id = context.state.id;
            if (id) {
                this.$api.getPatient(id).then((response) => {
                    // API response does not contain an id,
                    // so add it here to prevent loading the wrong response in a race condition
                    response.data.id = id;
                    context.dispatch('load', response.data);
                }).catch((error) => {
                    if (
                        error
                        &&
                        error.response
                        &&
                        (
                            // patient not found
                            error.response.status == 404
                            ||
                            error.response.status == 403 // patient belongs to another team
                        )
                    ) {
                        context.commit('markObjectAsMissing');
                    } else {
                        throw error;
                    }
                }).catch(this.apiErrorHandler);
            } else {
                context.dispatch('clear');
            }
        },
        setDataProcessingAgreementHtml(context, html) {
            return context.commit('setDataProcessingAgreementHtml', html);
        },
        markDataProcessingAgreementAsAccepted(context) {
            return context.commit('markDataProcessingAgreementAsAccepted');
        }
    }
};
