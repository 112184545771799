<template>
    <p class="types">
        <router-link :to="linkRoute(type)" v-for="type in types" :key="type.name" replace :class="linkClasses(type)">
            {{ type.label }}
        </router-link>
    </p>
</template>
<script>
export default {
    props: {
        types: { required: true },
        baseRoute: { required: true }
    },
    computed: {
    },
    methods: {
        linkRoute(type) {
            if (type.selected) {
                return this.baseRoute;
            } else {
                return type.route;
            }
        },
        linkClasses(type) {
            if (type.selected) {
                return ['router-link-exact-active', 'router-link-active'];
            } else {
                return [];
            }
        }
    }
};
</script>
