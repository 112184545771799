<template>
    <transition name="fade" appear>
        <div class="overlay" v-on:click.self="cancelByOverlayIfAllowed">
            <div class="dialog" :class="type" role="dialog" ref="dialog">
                <slot />
            </div>
        </div>
    </transition>
</template>
<script>
import CloseOnEscape from '@/mixins/closeOnEscape.js';
export default {
    components: {  },
    mixins: [ CloseOnEscape ],
    props: {
        type: String,
        closeOnOutsideClick: { default: true }
    },
    data() {
        return {

        };
    },
    computed: {
    },
    mounted() {
        this.$emit('open');
    },
    documentListeners: {
        focusin: 'ensureFocusStaysInside'
    },
    methods:
    {
        ensureFocusStaysInside(event) {
            if (!this.$refs.dialog.contains(event.target)) {
                const focusableElementSelector = [
                    "button:not([disabled])",
                    "[href]:not([disabled])",
                    "input:not([disabled])",
                    "select:not([disabled])",
                    "textarea:not([disabled])",
                    "[tabindex]:not([tabindex='-1']):not([disabled])"
                ].join(", ");

                const firstFocusableElement = this.$refs.dialog.querySelectorAll(focusableElementSelector)[0];
                if (firstFocusableElement) {
                    firstFocusableElement.focus();
                }
            }
        },
        close() {
            // this gets called by CloseOnEscape mixin
            this.cancel('escape');
        },
        cancelByOverlayIfAllowed() {
            if (this.closeOnOutsideClick) {
                this.cancel('overlay');
            }
        },
        cancel(cause) {
            this.$emit('cancel', { cause });
        }
    }
};
</script>
