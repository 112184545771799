export default
{
    install(Vue) {
        Vue.config.optionMergeStrategies.documentListeners = Vue.config.optionMergeStrategies.methods;

        Vue.mixin(
        {
            beforeMount() {
                if (this.$options.documentListeners) {
                    this.toggleDocumentListeners(true);
                }
            },
            beforeDestroy() {
                if (this.$options.documentListeners) {
                    this.toggleDocumentListeners(false);
                }
            },
            methods:
            {
                toggleDocumentListeners(on) {
                    var listeners = this.$options.documentListeners;

                    Object.keys(listeners).forEach((event) => {
                        var handler = this[listeners[event]];
                        if (on) {
                            document.addEventListener(event, handler);
                        } else {
                            document.removeEventListener(event, handler);
                        }
                    });
                },
            }
        });
    }
};
