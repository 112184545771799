import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

const unsupportedUserAgentPattern = / Edge\//;

const isSafari = (userAgent) => userAgent.match(/Safari/) && !userAgent.match(/Chrom(e|ium)/);

const ignoreableErrorPatterns = [
    {
        messagePattern: /Sound load error/,
        userAgentMatcher: isSafari
    }
];

export default {
    initialize(config) {
        Sentry.init({
            dsn: config.dsn,
            environment: config.environment,
            integrations: [new Integrations.Vue({Vue, attachProps: true})],
            beforeSend(event) {

                if (window.navigator.userAgent && window.navigator.userAgent.match(unsupportedUserAgentPattern)) {
                    return null;
                }

                const errorMessage = (event.exception && event.exception.values && event.exception.values[0] && event.exception.values[0].value) ? event.exception.values[0].value : undefined;

                if (errorMessage && ignoreableErrorPatterns.some(ignoreable => {
                    if (!errorMessage.match(ignoreable.messagePattern)) {
                        return false;
                    }
                    if (!ignoreable.userAgentMatcher || (window.navigator.userAgent && ignoreable.userAgentMatcher(window.navigator.userAgent))) {
                        return true;
                    }
                    return false;
                })) {

                    return null;
                }
                return event;
            }
        });

        document.addEventListener('sentry-error', (event) => {
            Sentry.withScope(scope => {
                scope.setExtras(event.detail.params);
                Sentry.captureException(new Error(event.detail.error));
            });
        });
    }
};
