<template>
    <div>
        <button class="button link" data-icon="missing" type="button" v-on:click="askForConfirmation">
            {{ t(labelKey, 'dentalDetailsVC') }}
        </button>

        <confirmation-dialog :for="waiterName" v-if="confirmableAction" v-on:confirm="confirm" v-on:close="closeConfirmationDialog">
            <h1 class="title"> {{ t(confirmationMessageKey, 'dentalDetailsVC') }} </h1>
        </confirmation-dialog>
    </div>
</template>
<script>
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { ConfirmationDialog },
    props: {
        toothNumber: { required: true }
    },
    data() {
        return {
            confirmableAction: undefined
        };
    },
    computed:
    {
        isToothMissing() {
            return this.$store.getters['patient/teeth/state/isToothMissing'](this.toothNumber);
        },
        action() {
            return this.isToothMissing ? 'restore' : 'remove';
        },
        labelKey() {
            return this.action == 'remove' ? 'missing' : 'notMissing';
        },
        waiterName() {
            return this.action == 'remove' ? WAITERS.MARKING_TOOTH_AS_MISSING : WAITERS.RESTORING_TOOTH;
        },
        confirmationMessageKey() {
            return this.action == 'remove' ? 'markToothAsMissingMessage' : 'restoreToothMessage';
        }

    },
    methods:
    {
        askForConfirmation() {
            this.ensureTeethStateModifiability().then(() => {
                this.confirmableAction = this.action;
            }, () => {});
        },

        closeConfirmationDialog() {
            this.confirmableAction = this.undefined;
        },

        confirm() {
            const storeAction = (this.action == 'remove') ? 'markToothAsMissing' : 'restoreTooth';
            return this.$store.dispatch('patient/teeth/state/' + storeAction, this.toothNumber).finally(() => {
                this.closeConfirmationDialog();
            });
        }


    }

};
</script>
