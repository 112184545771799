import { WAITERS } from '@/config/waiters.js';
import { SOFT_TISSUE_ZONES_IN_ORDER } from '@/config/soft-tissue.js';
import clone from '@/utils/clone.js';
import SoftTissueZone from '@/components/patient/dashboard/soft-tissue/zone.vue';
import UnsavedChangesDialog from '@/components/patient/dashboard/soft-tissue/unsaved-changes-dialog.vue';
import RollbackDateNote from '@/components/patient/rollback/date-note.vue';
import RollbackEditingNote from '@/components/patient/rollback/editing-note.vue';
export default {
  __name: 'soft-tissue',
  setup: function setup(__props) {
    var zones = clone(SOFT_TISSUE_ZONES_IN_ORDER);
    var waiterName = WAITERS.SAVING_SOFT_TISSUE_RECORDS;
    var store = useStore();
    var changesExist = computed(function () {
      return store.getters['patient/softTissue/changesExist'];
    });
    var editingIsAvailable = computed(function () {
      return store.getters['patient/softTissue/editingIsAvailable'];
    });
    var savingIsAvailable = computed(function () {
      return store.getters['patient/softTissue/savingIsAvailable'];
    });
    var _useNavigation = useNavigation(),
      goToRoute = _useNavigation.goToRoute;
    var interruptedNavigationCallback = null;
    var goAway = function goAway() {
      if (interruptedNavigationCallback) {
        var callback = interruptedNavigationCallback;
        interruptedNavigationCallback = null;
        return callback();
      }
      return goToRoute({
        name: 'dashboard'
      });
    };
    var submitForm = function submitForm() {
      store.dispatch('patient/softTissue/saveChangedDescriptions').then(function () {
        goAway();
      });
    };
    var shouldShowUnsavedChangesDialog = ref(false);
    var closeUnsavedChangesDialog = function closeUnsavedChangesDialog() {
      shouldShowUnsavedChangesDialog.value = false;
      interruptedNavigationCallback = null;
    };
    onBeforeRouteLeave(function (x, y, next) {
      if (!changesExist.value) {
        next();
      }
      shouldShowUnsavedChangesDialog.value = true;
      interruptedNavigationCallback = next;
      return false;
    });
    var cancel = function cancel() {
      store.dispatch('patient/softTissue/clearAllChanges');
      shouldShowUnsavedChangesDialog.value = false;
      goAway();
    };
    return {
      __sfc: true,
      zones: zones,
      waiterName: waiterName,
      store: store,
      changesExist: changesExist,
      editingIsAvailable: editingIsAvailable,
      savingIsAvailable: savingIsAvailable,
      goToRoute: goToRoute,
      interruptedNavigationCallback: interruptedNavigationCallback,
      goAway: goAway,
      submitForm: submitForm,
      shouldShowUnsavedChangesDialog: shouldShowUnsavedChangesDialog,
      closeUnsavedChangesDialog: closeUnsavedChangesDialog,
      cancel: cancel,
      SoftTissueZone: SoftTissueZone,
      UnsavedChangesDialog: UnsavedChangesDialog,
      RollbackDateNote: RollbackDateNote,
      RollbackEditingNote: RollbackEditingNote
    };
  }
};