<template>
    <main data-view="billing">
        <div class="content">
            <h1>{{ t('Billing', 'billingVC') }}</h1>
            <waiting-overlay v-if="$wait.is(waiterName)" />
            <div v-else>
                <ul class="prices">
                    <li :key="index" v-for="(price, key, index) in prices" :data-id="price.id">
                        <h3>{{ t('billed.' + price.interval, 'billingVC') }}</h3>
                        <h4>{{ formatPrice(price) }} / {{ t(price.interval, 'billingVC') }}</h4>

                        <button-spinner v-if="price.pending" class="pending-loader" :size="24" />

                        <p class="message">
                            <span v-if="price.message">{{ price.message }}</span>
                        </p>

                        <button v-if="price.payable" v-on:click="pay" class="button">
                            {{ t('Start now', 'billingVC') }}
                        </button>

                        <button v-else-if="price.pending" v-on:click="cancel" class="button">
                            {{ t('Cancel payment', 'billingVC') }}
                        </button>

                        <button v-else-if="price.cancelable" v-on:click="cancel" class="button">
                            {{ t('Cancel subscription', 'billingVC') }}
                        </button>
                    </li>
                </ul>
            </div>

            <back-button type="close" />
        </div>
    </main>
</template>
<script>
import CloseOnEscape from '@/mixins/closeOnEscape.js';
import WaitingOverlay from '@/components/waiting-overlay';
import { HalfCircleSpinner } from 'epic-spinners';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { 'ButtonSpinner': HalfCircleSpinner, WaitingOverlay },
    mixins: [ CloseOnEscape ],
    data() {
        return {
            prices: [],
        };
    },

    computed: {

    },

    created() {
        this.waiterName = WAITERS.PROCESSING_BILLING_OPERATION;
        this.loadData();
    },

    methods:
    {
        assignPrices(prices) {
            this.prices = prices.map(price => {
                if (price.pending) {
                    price.message = this.t('Payment pending', 'billingVC');
                } else if (price.cancelable) {
                    price.message = this.t('Your plan renews at', 'billingVC') + ' ' + this.formatDate(this.dateFromUtcTimestamp(price.subscriptionExpireAt));
                } else if (price.subscriptionExpireAt) {
                    price.message = this.t('Your plan expires at', 'billingVC') + ' ' + this.formatDate(this.dateFromUtcTimestamp(price.subscriptionExpireAt));
                }

                return price;
            });
        },

        formatPrice(price) {
            const currencySymbol = new Intl.NumberFormat('en', {style: 'currency', currency:price.currency})
                .formatToParts('1')
                .find(part => part.type='currency')
                .value;

            const amount = price.amount / 100;

            return amount + currencySymbol;
        },

        loadData() {
            this.$wait.start(this.waiterName);
            this.$api.subscriptionsRead()
                .then((response) => this.assignPrices(response.data))
                .catch(this.apiErrorHandler)
                .finally(() => this.$wait.end(this.waiterName));
        },

        pay(event) {
            this.$wait.start(this.waiterName);
            const priceId = event.target.parentElement.dataset.id;

            this.$api.subscriptionCreate(priceId)
                .then((response) => window.location.href = response.data.url )
                .catch((error) => {
                    this.$wait.end(this.waiterName);
                    throw error;
                })
                .catch(this.apiErrorHandler);
        },

        cancel(event) {
            this.$wait.start(this.waiterName);
            const priceId = event.target.parentElement.dataset.id;

            this.$api.subscriptionCancel(priceId)
                // wait a little bit (due to possible Stripe callbacks) and reload data
                .then(() => setTimeout(this.loadData, 2000) )
                .catch((error) => {
                    this.$wait.end(this.waiterName);
                    throw error;
                })
                .catch(this.apiErrorHandler);
        },

        close() {
            this.goToRoute(this.backRoute);
        },
    }
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/billing";
</style>
