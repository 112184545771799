import freeze from '@/utils/freeze.js';

import {
    PROJECTIONS,
    TOOTH_SETS,
    TOOTH_POSITIONS_BY_TOOTH_SET
} from '@/config/teeth.js';


import { IMAGE_QUADRANTS } from '@/config/visualization/general.js';

import { FRACTURE_TYPES, FRACTURE_POSITIONS } from '@/config/pathologies/fracture.js';


const RULER = freeze({
    lines: 15,
    height: 26
});

const permanentTeethGeometry = {
    // 1st quadrant (teeth 11-18)
    1: {
        1: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 72, right: 66, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 68, right: 66, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -2
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 60, y: 464},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 72, y: 464}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 10, y1: 140, x2: 166, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 100, x2: 176, y2: 100},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 6, y1: 100, x2: 172, y2: 100}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 80, y1: 220, x2: 60, y2: 466}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 80, y1: 192, x2: 80, y2: 466}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 32, y1: 300, x2: 124, y2: 300},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 40, y1: 300, x2: 130, y2: 300}
                        ]
                    }
                }
            },
        },
        2: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 54, right: 56, center_offset: -12},
                [PROJECTIONS.PALATAL]: {left: 50, right: 56, center_offset: -12}
            },
            depthOffset: {
                default: -1,
                buccal: -2
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 38, y: 420},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 50, y: 420}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 6, y1: 140, x2: 138, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 100, x2: 142, y2: 100},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 6, y1: 100, x2: 138, y2: 100}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 58, y1: 220, x2: 40, y2: 420}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 60, y1: 192, x2: 60, y2: 420}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 18, y1: 300, x2: 82, y2: 300},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 26, y1: 300, x2: 88, y2: 300}
                        ]
                    }
                }
            },
        },
        3: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 62, right: 56, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 58, right: 60, center_offset: -4}
            },
            depthOffset: {
                default: -1,
                buccal: -2
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 54, y: 530},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 64, y: 530}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 2, y1: 140, x2: 180, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 100, x2: 176, y2: 100},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 4, y1: 100, x2: 170, y2: 100}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 80, y1: 220, x2: 60, y2: 530}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 80, y1: 192, x2: 70, y2: 530}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 40, y1: 360, x2: 114, y2: 360},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 46, y1: 360, x2: 118, y2: 360}
                        ]
                    }
                }
            },
        },
        4: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 56, right: 52, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 52, right: 56, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -2
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 54, y: 516},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 60, y: 514}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 2, y1: 140, x2: 144, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 120, x2: 142, y2: 120},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 6, y1: 120, x2: 140, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 70, y1: 220, x2: 52, y2: 518}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 70, y1: 192, x2: 58, y2: 518}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 36, y1: 360, x2: 96, y2: 360},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 40, y1: 360, x2: 100, y2: 360}
                        ]
                    }
                }
            },
        },
        5: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 50, right: 54, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 50, right: 54, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -2
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 52, y: 492},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 58, y: 492}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 0, y1: 140, x2: 138, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 120, x2: 138, y2: 120},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 6, y1: 120, x2: 136, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 70, y1: 220, x2: 52, y2: 496}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 70, y1: 192, x2: 58, y2: 496}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 36, y1: 360, x2: 92, y2: 360},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 38, y1: 360, x2: 94, y2: 360}
                        ]
                    }
                }
            }
        },
        6: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 76, right: 82, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 78, right: 84, center_offset: 0}
            },
            depthOffset: {
                default: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 48, y: 478},
                    {x: 122, y: 478}
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 100, y: 506}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 4, y1: 140, x2: 210, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 6, y1: 120, x2: 204, y2: 120},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 6, y1: 120, x2: 210, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 70, y1: 182, x2: 44, y2: 480},
                            {x1: 150, y1: 180, x2: 128, y2: 482},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 120, y1: 192, x2: 100, y2: 510}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 28, y1: 330, x2: 178, y2: 330},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 22, y1: 330, x2: 170, y2: 330}
                        ]
                    }
                }
            }
        },
        7: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 76, right: 72, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 72, right: 70, center_offset: 0}
            },
            depthOffset: {
                default: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 46, y: 432},
                    {x: 112, y: 436},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 86, y: 448}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 4, y1: 140, x2: 190, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 6, y1: 120, x2: 190, y2: 120},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 6, y1: 120, x2: 190, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 60, y1: 180, x2: 44, y2: 436},
                            {x1: 140, y1: 180, x2: 118, y2: 436},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 90, y1: 192, x2: 92, y2: 450}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 30, y1: 300, x2: 156, y2: 300},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 28, y1: 300, x2: 150, y2: 300}
                        ]
                    }
                }
            },
        },
        8: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 88, right: 84, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 88, right: 86, center_offset: 0}
            },
            depthOffset: {
                default: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 42, y: 432},
                    {x: 94, y: 440}
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 42, y: 426},
                    {x: 92, y: 436}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 4, y1: 140, x2: 180, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 140, x2: 182, y2: 140},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 2, y1: 140, x2: 182, y2: 140}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 60, y1: 184, x2: 42, y2: 430},
                            {x1: 132, y1: 180, x2: 96, y2: 442},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 60, y1: 188, x2: 40, y2: 428},
                            {x1: 110, y1: 192, x2: 94, y2: 442}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 20, y1: 300, x2: 140, y2: 300},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 18, y1: 300, x2: 138, y2: 300}
                        ]
                    }
                }
            },
        },
    },
    // 4th quadrant (teeth 41-48)
    4: {
        1: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 44, right: 44, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 42, right: 50, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -2
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 76, y: 422},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 92, y: 420}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 30, y1: 140, x2: 148, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 38, y1: 140, x2: 140, y2: 140},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 38, y1: 140, x2: 144, y2: 140}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 90, y1: 220, x2: 76, y2: 426},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 90, y1: 192, x2: 94, y2: 426}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 56, y1: 300, x2: 118, y2: 300},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 64, y1: 300, x2: 128, y2: 300}
                        ]
                    }
                }
            },
        },
        2: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 44, right: 46, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 44, right: 50, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -2
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 26, y: 436},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 42, y: 438}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 12, y1: 140, x2: 138, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 22, y1: 140, x2: 126, y2: 140},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 24, y1: 140, x2: 130, y2: 140}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 76, y1: 220, x2: 28, y2: 440},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 80, y1: 192, x2: 42, y2: 440}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 28, y1: 300, x2: 92, y2: 300},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 36, y1: 300, x2: 104, y2: 300}
                        ]
                    }
                }
            },
        },
        3: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 62, right: 60, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 64, right: 62, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -2
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 82, y: 482},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 96, y: 482}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 24, y1: 140, x2: 160, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 20, y1: 120, x2: 160, y2: 120},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 18, y1: 100, x2: 162, y2: 100}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 90, y1: 220, x2: 82, y2: 486},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 100, y1: 192, x2: 96, y2: 486}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 60, y1: 340, x2: 116, y2: 340},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 66, y1: 340, x2: 124, y2: 340}
                        ]
                    }
                }
            },
        },
        4: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 62, right: 54, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 66, right: 54, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -2
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 56, y: 520},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 64, y: 516}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 2, y1: 140, x2: 146, y2: 140}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 4, y1: 120, x2: 142, y2: 120},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 2, y1: 100, x2: 142, y2: 100}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 70, y1: 220, x2: 52, y2: 520},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 70, y1: 192, x2: 62, y2: 520}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 28, y1: 350, x2: 100, y2: 350},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 28, y1: 350, x2: 100, y2: 350}
                        ]
                    }
                }
            },
        },
        5: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 60, right: 54, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 62, right: 58, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -2
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 34, y: 516},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 40, y: 516}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 2, y1: 120, x2: 140, y2: 120}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 130, x2: 140, y2: 130},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 2, y1: 120, x2: 140, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 66, y1: 220, x2: 34, y2: 520},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 64, y1: 192, x2: 40, y2: 520}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 24, y1: 350, x2: 90, y2: 350},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 22, y1: 350, x2: 90, y2: 350}
                        ]
                    }
                }
            },
        },
        6: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 92, right: 96, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 94, right: 92, center_offset: 0}
            },
            depthOffset: {
                default: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 56, y: 522},
                    {x: 154, y: 506}
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 52, y: 524},
                    {x: 144, y: 510},
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 2, y1: 120, x2: 210, y2: 120}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 130, x2: 208, y2: 130},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 2, y1: 120, x2: 208, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 70, y1: 184, x2: 52, y2: 522},
                            {x1: 146, y1: 180, x2: 162, y2: 504},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 68, y1: 184, x2: 46, y2: 522},
                            {x1: 146, y1: 184, x2: 150, y2: 508}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 36, y1: 350, x2: 196, y2: 350},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 30, y1: 350, x2: 196, y2: 350}
                        ]
                    }
                }
            },
        },
        7: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 84, right: 80, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 84, right: 80, center_offset: 0}
            },
            depthOffset: {
                default: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 16, y: 438},
                    {x: 76, y: 450}
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 18, y: 436},
                    {x: 70, y: 452}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 2, y1: 120, x2: 194, y2: 120}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 8, y1: 130, x2: 194, y2: 130},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 10, y1: 130, x2: 196, y2: 130}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 66, y1: 184, x2: 10, y2: 440},
                            {x1: 136, y1: 180, x2: 78, y2: 450},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 66, y1: 184, x2: 10, y2: 436},
                            {x1: 136, y1: 184, x2: 72, y2: 454}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 350, x2: 136, y2: 350},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 2, y1: 350, x2: 136, y2: 350}
                        ]
                    }
                }
            },
        },
        8: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 80, right: 80, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 80, right: 82, center_offset: 0}
            },
            depthOffset: {
                default: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 66, y: 398},
                    {x: 122, y: 404}
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 62, y: 400},
                    {x: 118, y: 406}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 2, y1: 120, x2: 182, y2: 120}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 140, x2: 180, y2: 140},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 2, y1: 130, x2: 182, y2: 130}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 60, y1: 184, x2: 66, y2: 398},
                            {x1: 130, y1: 180, x2: 126, y2: 406},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 60, y1: 184, x2: 60, y2: 398},
                            {x1: 130, y1: 184, x2: 120, y2: 408}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 44, y1: 290, x2: 170, y2: 290},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 40, y1: 290, x2: 166, y2: 290}
                        ]
                    }
                }
            },
        }
    }
};

const deciduousTeethGeometry = {
    // 1st quadrant (teeth 51-55)
    1: {
        1: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 56, right: 50, center_offset: 6},
                [PROJECTIONS.PALATAL]: {left: 55, right: 50, center_offset: 6}
            },
            depthOffset: {
                default: -1,
                buccal: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 75, y: 420}
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 77, y: 418}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 22, y1: 130, x2: 155, y2: 130}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 22, y1: 120, x2: 156, y2: 120},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 26, y1: 120, x2: 158, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 93, y1: 205, x2: 75, y2: 420}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 94, y1: 197, x2: 80, y2: 420}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 55, y1: 285, x2: 127, y2: 285},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 56, y1: 290, x2: 123, y2: 290}
                        ]
                    }
                }
            }
        },
        2: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 45, right: 42, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 50, right: 41, center_offset: 2}
            },
            depthOffset: {
                default: -1,
                buccal: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 61, y: 455},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 53, y: 457}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 21, y1: 130, x2: 125, y2: 130}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 18, y1: 120, x2: 129, y2: 120}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 19, y1: 120, x2: 129, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 73, y1: 205, x2: 61, y2: 458}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 73, y1: 200, x2: 53, y2: 460}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 33, y1: 285, x2: 103, y2: 285}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 27, y1: 290, x2: 94, y2: 290}
                        ]
                    }
                }
            }
        },
        3: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 62, right: 56, center_offset: 9},
                [PROJECTIONS.PALATAL]: {left: 59, right: 60, center_offset: -1}
            },
            depthOffset: {
                default: -1,
                buccal: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 57, y: 493},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 56, y: 493}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 12, y1: 130, x2: 168, y2: 130}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 18, y1: 120, x2: 168, y2: 120},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 10, y1: 120, x2: 162, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 99, y1: 205, x2: 56, y2: 495}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 88, y1: 200, x2: 56, y2: 497}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 42, y1: 315, x2: 124, y2: 315}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 36, y1: 315, x2: 120, y2: 315}
                        ]
                    }
                }
            }
        },
        4: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 53, right: 46, center_offset: 8},
                [PROJECTIONS.PALATAL]: {left: 56, right: 46, center_offset: 4}
            },
            depthOffset: {
                default: -1,
                buccal: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 14, y: 330},
                    {x: 122, y: 347},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 75, y: 368}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 14, y1: 130, x2: 130, y2: 130}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 15, y1: 130, x2: 143, y2: 130},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 11, y1: 120, x2: 141, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 50, y1: 180, x2: 10, y2: 330},
                            {x1: 112, y1: 175, x2: 127, y2: 340}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 77, y1: 200, x2: 75, y2: 370}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 8, y1: 260, x2: 142, y2: 260}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 3, y1: 280, x2: 140, y2: 280}
                        ]
                    }
                }
            }
        },
        5: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 40, right: 45, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 43, right: 43, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 18, y: 303},
                    {x: 111, y: 312},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 67, y: 310}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 5, y1: 130, x2: 140, y2: 130}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 15, y1: 130, x2: 128, y2: 130},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 13, y1: 120, x2: 137, y2: 120}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 48, y1: 179, x2: 10, y2: 295},
                            {x1: 102, y1: 175, x2: 120, y2: 300}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 70, y1: 195, x2: 68, y2: 315 }
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 9, y1: 240, x2: 130, y2: 240}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 11, y1: 240, x2: 137, y2: 240}
                        ]
                    }
                }
            }
        }
    },
    // 4th quadrant (teeth 81-85)
    4: {
        1: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 41, right: 45, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 42, right: 45, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 84, y: 407},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 84, y: 407}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 40, y1: 130, x2: 136, y2: 130}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 45, y1: 135, x2: 135, y2: 135},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 44, y1: 135, x2: 134, y2: 135}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 89, y1: 205, x2: 83, y2: 410}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 88, y1: 205, x2: 81, y2: 409}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 60, y1: 285, x2: 111, y2: 285},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 60, y1: 285, x2: 112, y2: 285}
                        ]
                    }
                }
            }
        },
        2: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 38, right: 42, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 38, right: 42, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 71, y: 392},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 68, y: 390}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 29, y1: 130, x2: 116, y2: 130}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 32, y1: 135, x2: 114, y2: 135},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 32, y1: 135, x2: 114, y2: 135}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 77, y1: 205, x2: 70, y2: 390}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 77, y1: 197, x2: 65, y2: 390}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 52, y1: 280, x2: 98, y2: 280},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 47, y1: 280, x2: 93, y2: 280}
                        ]
                    }
                }
            }
        },
        3: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 55, right: 50, center_offset: -7},
                [PROJECTIONS.PALATAL]: {left: 49, right: 52, center_offset: -5}
            },
            depthOffset: {
                default: -1,
                buccal: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 92, y: 472},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 93, y: 472}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 32, y1: 130, x2: 148, y2: 130}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 27, y1: 135, x2: 145, y2: 135},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 29, y1: 135, x2: 145, y2: 135}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 80, y1: 208, x2: 92, y2: 475}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 85, y1: 198, x2: 90, y2: 473}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 38, y1: 295, x2: 125, y2: 295}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 38, y1: 300, x2: 125, y2: 300}
                        ]
                    }
                }
            }
        },
        4: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 60, right: 56, center_offset: 5},
                [PROJECTIONS.PALATAL]: {left: 54, right: 62, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 17, y: 330},
                    {x: 132, y: 343},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 17, y: 330},
                    {x: 129, y: 344}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 4, y1: 130, x2: 141, y2: 130}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 12, y1: 135, x2: 139, y2: 135},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 13, y1: 135, x2: 141, y2: 135}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 40, y1: 175, x2: 15, y2: 333},
                            {x1: 104, y1: 185, x2: 132, y2: 346}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 40, y1: 175, x2: 15, y2: 333},
                            {x1: 103, y1: 185, x2: 130, y2: 347}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 5, y1: 255, x2: 139, y2: 255},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 5, y1: 255, x2: 139, y2: 255}
                        ]
                    }
                }
            }
        },
        5: {
            points: {
                [PROJECTIONS.BUCCAL]: {left: 59, right: 60, center_offset: 0},
                [PROJECTIONS.PALATAL]: {left: 65, right: 60, center_offset: 0}
            },
            depthOffset: {
                default: -1,
                buccal: -1
            },
            apical: {
                [PROJECTIONS.BUCCAL]: [
                    {x: 36, y: 378},
                    {x: 120, y: 366},
                ],
                [PROJECTIONS.PALATAL]: [
                    {x: 36, y: 384},
                    {x: 119, y: 374}
                ]
            },
            fracture: {
                [FRACTURE_TYPES.CROWN]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.INCISAL]: [
                            {x1: 2, y1: 130, x2: 141, y2: 130}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 2, y1: 140, x2: 142, y2: 140},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 2, y1: 150, x2: 142, y2: 150}
                        ]
                    }
                },
                [FRACTURE_TYPES.ROOT]: {
                    [FRACTURE_POSITIONS.VERTICAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 45, y1: 185, x2: 37, y2: 382},
                            {x1: 98, y1: 185, x2: 123, y2: 368}
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 47, y1: 190, x2: 33, y2: 386},
                            {x1: 93, y1: 188, x2: 119, y2: 375}
                        ]
                    },
                    [FRACTURE_POSITIONS.HORIZONTAL]: {
                        [PROJECTIONS.BUCCAL]: [
                            {x1: 20, y1: 270, x2: 130, y2: 270},
                        ],
                        [PROJECTIONS.PALATAL]: [
                            {x1: 17, y1: 265, x2: 128, y2: 265}
                        ]
                    }
                }
            }
        }
    }
};

const teethDefinitions = {
    [TOOTH_SETS.PERMANENT]: permanentTeethGeometry,
    [TOOTH_SETS.DECIDUOUS]: deciduousTeethGeometry
};

Object.values(TOOTH_SETS).forEach(toothSet => {
    [2, 3].forEach(quandrant =>  {
        teethDefinitions[toothSet][quandrant] = {};

        TOOTH_POSITIONS_BY_TOOTH_SET[toothSet].forEach(toothPosition =>  {
            const imageQuadrant = IMAGE_QUADRANTS[quandrant];
            let baseTooth = teethDefinitions[toothSet][imageQuadrant][toothPosition];

            teethDefinitions[toothSet][quandrant][toothPosition] = {
                depthOffset: baseTooth.depthOffset,
                apical: baseTooth.apical,
                fracture: baseTooth.fracture
            };

            teethDefinitions[toothSet][quandrant][toothPosition].points = {
                [PROJECTIONS.BUCCAL]: {
                    left: baseTooth.points[PROJECTIONS.BUCCAL].right,
                    right: baseTooth.points[PROJECTIONS.BUCCAL].left,
                    center_offset: baseTooth.points[PROJECTIONS.BUCCAL].center_offset * -1
                },
                [PROJECTIONS.PALATAL]: {
                    left: baseTooth.points[PROJECTIONS.PALATAL].right,
                    right: baseTooth.points[PROJECTIONS.PALATAL].left,
                    center_offset: baseTooth.points[PROJECTIONS.PALATAL].center_offset * -1
                },
            };
        });
    });

});

const TEETH_GEOMETRY = freeze(teethDefinitions);

export {
    TEETH_GEOMETRY,
    RULER
};
