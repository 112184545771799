import merge from 'lodash/merge';
import PathologyAndRestorationType from './pathologyAndRestorationType.js';

export default merge({}, PathologyAndRestorationType, {

    state: {
        parentName: 'pathology'
    },

    getters:
    {
        toothState: (state, getters) => {
            return getters.parentState.editableTooth.state[state.name] || {};
        },

        blankState: (state) => {
            return {
                data: state.name,
                surfaces: []
            };
        },

        calculateState: (state, getters) => ({ selectedZoneNames, details }) => {
            const calculatedState = JSON.parse(JSON.stringify(getters.baseState)); // clone to not change the original instance

            const toothNumber = getters.toothNumber;

            if (typeof details == 'undefined') {
                details = getters.details(toothNumber);
            }
            calculatedState.data = getters.calculateDetailsString(details);

            if (typeof selectedZoneNames == 'undefined') {
                selectedZoneNames = getters.selectedZoneNames();
            }

            const previouslySelectedZoneNames = getters.selectedZoneNames(state.name);
            const previousSurfaces = getters.toothState.surfaces || [];
            calculatedState.surfaces = getters.calculateSurfaces({ toothNumber, selectedZoneNames, previouslySelectedZoneNames, previousSurfaces, details });
            return calculatedState;
        },


        // DETAIL CALCULATIONS
        details: (state, getters) => (toothNumber) => {
            const allDetailValues = getters.allDetailValues(toothNumber);
            const definition = getters.parent('type', state.name, toothNumber).details || {};

            // collect definitions and their values one by one
            // only return details that are appliccable for current state and can be shown in the selector
            let previousDetailValues;
            const details = Object.keys(definition).reduce((details, detailName) => {
                const detailDefinition = definition[detailName];
                const detailValues = allDetailValues[0];

                if (
                    // each detail is only available if it is either the first one
                    // or if the previous detail has its value set
                    ((Object.keys(details).length == 0) || (previousDetailValues && previousDetailValues.length > 0))
                    &&
                    // in addition, each detail may have custom rules for when it is available
                    (getters.isDetailAvailable({ toothNumber, detailName, detailDefinition, details }))
                ) {

                    const detail = JSON.parse(JSON.stringify(detailDefinition));

                    const validValues = detail.options.map(option => option.value);
                    if (detailValues instanceof Array && detailValues.every(value => validValues.includes(value))) {
                        detail.values = detailValues;
                        allDetailValues.shift();
                    } else {
                        detail.values = [];
                    }

                    previousDetailValues = detail.values;
                    details[detailName] = detail;
                }
                return details;
            }, {});

            return details;
        },


        allDetailValues: (state, getters) => (toothNumber) => {
            // returns an array of arrays, e.g., [ ["abrasion"], ["buccal,palatal"] ]

            const typeState = getters.parent('toothState', toothNumber)[state.name] || getters.blankState;
            const values = typeState.data.split('/').map(x => x.split(","));

            // remove first one, as it contains the type name
            values.shift();
            return values;
        },


        calculateDetailsString: (state, getters, rootState, rootGetters) => (details) => {
            return Object.keys(getters.filterOutTrailingDetails(details)).reduce((array, detailName) => {
                if (details[detailName].values.length > 0) {
                    array.push(details[detailName].values.join(","));
                }
                return array;
            }, [rootGetters['filters/camelize'](state.name)]).join("/");

        },

    },

    actions:
    {


    }

});
