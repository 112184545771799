<template>
    <div data-view="get-started">
        <back-button />

        <form class="get-started panel" novalidate="novalidate" v-on:submit.prevent="submit">
            <section class="content">
                <h1>{{ t('accountInformation', 'getStartedVC') }}</h1>

                <input-field
                    name="name"
                    type="text"
                    v-model="name"
                    :label="t('name', 'getStartedVC')"
                    :errors="errorMessages.name"
                    ref="nameInput"
                />

                <input-field
                    name="email"
                    type="text"
                    v-model="email"
                    :label="t('email', 'getStartedVC')"
                    :errors="errorMessages.email"
                />

                <input-field
                    name="password"
                    type="password"
                    v-model="password"
                    :label="t('password', 'getStartedVC')"
                    :errors="errorMessages.password"
                />

                <input-field
                    name="password_confirmation"
                    type="password"
                    v-model="passwordConfirmation"
                    :label="t('passwordConfirmation', 'getStartedVC')"
                    :errors="errorMessages.password_confirmation"
                />

                <input-field
                    name="affiliate"
                    type="text"
                    v-model="affiliate"
                    readonly="1"
                    v-if="affiliate !== undefined"
                    :label="t('affiliate', 'getStartedVC')"
                    :errors="errorMessages.affiliate_registration_code"
                />

                <input-field
                    name="invitation"
                    type="text"
                    v-model="invitation"
                    readonly="1"
                    v-if="invitation !== undefined"
                    :label="t('invitation', 'getStartedVC')"
                    :errors="errorMessages.invitation_id"
                />
            </section>

            <div class="footer">
                <div class="conditions">
                </div>
                <input name="termsAccepted" v-model="termsAccepted" type="hidden" value="1" />

                <p class="action">
                    <waiting-button :for="waiterName" type="submit" class="button">
                        {{ t('register', 'getStartedVC') }}
                    </waiting-button>
                </p>
            </div>
        </form>

        <p class="links">
            <span class="text">{{ t('haveAccount', 'registrationVC') }}</span>
            <router-link :to="{ name: 'sign-in' }">{{ t('signIn', 'registrationVC') }}</router-link>
        </p>
    </div>
</template>

<script>
import { WAITERS } from '@/config/waiters.js';

export default {
    components: {  },

    data: () => {
        return {
            name: "",
            email: "",
            password: "",
            passwordConfirmation: "",
            invitation: undefined,
            affiliate: undefined,
            termsAccepted: "1",
            errorMessages: {}
        };
    },
    created() {
        this.waiterName = WAITERS.SENDING_CONFIRMATION_EMAIL;
    },

    mounted() {
        this.invitation = this.$route.query.invitation;
        this.affiliate = this.$route.query.affiliate;

        this.$refs.nameInput.focus();
    },

    methods: {
        submit() {
            this.errorMessages = {};
            let registerParameters = {
                name: this.name,
                email: this.email,
                password: this.password,
                password_confirmation: this.passwordConfirmation,
                terms: this.termsAccepted,
            };

            if (this.affiliate) {
                registerParameters.affiliate_registration_code = this.affiliate;
            }

            if (this.invitation) {
                registerParameters.invitation_id = this.invitation;
            }



            this.$wait.start(this.waiterName);

            this.$api.registration(registerParameters).then(() => {
                const signInParameters = { email: this.email, password: this.password };
                this.$store.dispatch('authentication/signIn', signInParameters).catch(error => {
                    throw error;
                });
            }).catch((error) => {
                if (
                    error
                    &&
                    error.response
                    &&
                    error.response.status == 422
                ) {
                    this.errorMessages = error.response.data.errors;
                } else {
                    throw error;
                }
            }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.waiterName));
        }
    },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/account/get-started";
</style>
