<template>
    <div class="full-mouth jaw-box">
        <ol v-for="(jawTeeth, jaw) in teethByJaw" class="jaw" :key="jaw" :data-type="jaw">
            <li
                v-for="(tooth, index) in jawTeeth"
                :key="tooth.number"
                :class="{ tooth: true, selected: tooth.selected }"
                :data-number="tooth.number"
            >
                <endodontic-issues
                    v-if="jaw == 'upper' && endodonticLayerActive && isToothPresent(tooth.number)"
                    :tooth-number="tooth.number"
                    :ticker="endodonticIssueTimerValue"
                />

                <tooth-trigger :tooth="tooth" :mode="mode">
                    <tooth-number v-if="jaw == 'lower'" :tooth="tooth" />

                    <tooth-visualization
                        v-for="projection in visualizationProjectionsByJaw[jaw]"
                        :key="projection"
                        :number="tooth.number"
                        :previous-number="(index > 0) ? jawTeeth[index - 1].number : undefined"
                        :next-number="(index < jawTeeth.length - 1) ? jawTeeth[index + 1].number : undefined"
                        :projection="projection"
                        :layers="layers"
                        :draw-blank-images="shouldDrawBlankImages"
                        :surrounded-by-erupted-teeth="tooth.surroundedByEruptedTeeth"
                        :context="visualizationContext"
                    />

                    <tooth-number v-if="jaw == 'upper'" :tooth="tooth" />
                </tooth-trigger>


                <endodontic-issues
                    v-if="jaw == 'lower' && endodonticLayerActive && isToothPresent(tooth.number)"
                    :tooth-number="tooth.number"
                    :ticker="endodonticIssueTimerValue"
                />
            </li>

        </ol>
    </div>
</template>
<script>
import ToothVisualization from '@/components/patient/tooth/visualization.vue';
import EndodonticIssues from '@/components/patient/chart/endodontic-issues.vue';
import ToothTrigger from '@/components/patient/chart/tooth-trigger.vue';
import ToothNumber from '@/components/patient/chart/tooth-number.vue';
import { JAWS, VISUALIZATION_CONTEXTS, PROJECTIONS, CHART_MODES } from '@/config/teeth.js';

export default {
    components: { ToothVisualization, EndodonticIssues, ToothTrigger, ToothNumber },
    mixins: [ ],
    props: {
        teeth: { required: true },
        mode: { required: true, validator: value => Object.values(CHART_MODES).includes(value) },
        layers: { required: true }
    },
    data: () => {
        return {
            endodonticIssueTimerValue: 0,
            visualizationContext: VISUALIZATION_CONTEXTS.MOUTH
        };
    },

    computed:
    {
        teethByJaw() {
            return Object.values(this.teeth).reduce((byJaw, tooth) => {
                byJaw[tooth.jaw].push(tooth);
                return byJaw;
            }, { [JAWS.UPPER]: [], [JAWS.LOWER]: [] });
        },

        visualizationProjectionsByJaw() {
            return {
                [JAWS.UPPER]: [ PROJECTIONS.BUCCAL, PROJECTIONS.INCISAL ],
                [JAWS.LOWER]: [ PROJECTIONS.INCISAL, PROJECTIONS.BUCCAL ]
            };
        },

        endodonticLayerActive() {
            return this.layers.includes('endodontic');
        },

        patientTeethStateDate() {
            return this.$store.state.patient.teeth.state.teethStateDate;
        },

        shouldDrawBlankImages() {
            return this.mode == CHART_MODES.QUICKSELECT;
        }
    },

    watch: {
        patientTeethStateDate() {
            this.tickEndodonticIssueTimer();
        }
    },

    created() {
        if (this.mode != CHART_MODES.REPORT) {
            this.endodonticIssueTimer = setInterval(() => {
                this.tickEndodonticIssueTimer();
            }, 800);
        }
    },

    beforeDestroy() {
        if (this.mode != CHART_MODES.REPORT) {
            clearInterval(this.endodonticIssueTimer);
        }
    },

    methods: {
        isToothPresent(toothNumber) {
            return this.$store.getters['patient/teeth/state/isToothPresent'](toothNumber);
        },

        tickEndodonticIssueTimer() {
            this.endodonticIssueTimerValue = (this.endodonticIssueTimerValue == 0) ? 1 : 0;
        }
    }

};
</script>
