<template>
    <modal-dialog type="confirmation" v-on:open="focusCancel" v-on:cancel="close">
        <slot />

        <form method="post" v-on:submit.prevent="confirm">

            <p class="actions">
                <button class="button link" type="button" ref="cancel" v-on:click="close">
                    {{ cancellationLabel ? cancellationLabel : t('no', 'common') }}
                </button>

                <waiting-button :for="waiterName" type="submit" class="button">
                    {{ confirmationLabel ? confirmationLabel : t('yes', 'common') }}
                </waiting-button>
            </p>

        </form>
    </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modal-dialog.vue';
export default {
    components: { ModalDialog },
    props: {
        for: { required: true },
        cancellationLabel: { required: false },
        confirmationLabel: { required: false }
    },
    computed:
    {
        waiterName() { return this.for; },
    },
    methods:
    {
        focusCancel() {
            this.$refs.cancel.focus();
        },

        close() {
            this.$emit('close');
        },

        confirm() {
            this.$emit('confirm');
        }
    }
};
</script>
