import freeze from '@/utils/freeze.js';

const CHART_LAYERS = freeze({
    ENDODONTIC: "endodontic",
    DENTAL: "dental",
    PERODONTIAL: "periodontal"
});

const CHART_MODES = freeze({
    OVERVIEW: "overview",
    QUICKSELECT: "quickselect",
    PERIODONTAL_PROBING: "periodontal-probing",
    REPORT: "report"
});

const TOOTH_SETS = freeze({
    DECIDUOUS: "deciduous", // baby teeth
    PERMANENT: "permanent"  // adult teeth
});

const TOOTH_TYPES = freeze({
    INCISOR: "incisor",
    PREMOLAR: "premolar",
    MOLAR: "molar"
});

const TOOTH_STATES = freeze({
    NORMAL: 0,
    MISSING: 1,
    CROWN: 2,
    VENEER: 3, // should be removed eventually. no need for this state, a restoration record should be enough
    IMPLANT: 4,
    SINGLE_CROWN: 5,
    TO_BE_EXTRACTED: 6
});

const TOOTH_STATE_NAMES = freeze({
    [TOOTH_STATES.NORMAL]: "normal",
    [TOOTH_STATES.MISSING]: "missing",
    [TOOTH_STATES.CROWN]: "crown",
    [TOOTH_STATES.VENEER]: "veneer",
    [TOOTH_STATES.IMPLANT]: "implant",
    [TOOTH_STATES.SINGLE_CROWN]: "single-crown",
    [TOOTH_STATES.TO_BE_EXTRACTED]: "to-be-extracted"
});

const ERUPTION_STATES = freeze({
    PENDING: "pending",
    ERUPTED: "erupted"
});

const JAWS = freeze({
    UPPER: "upper",
    LOWER: "lower",
});

const TOOTH_SLOTS_BY_JAW = freeze({
    [JAWS.UPPER]: [
        '18', '17', '16', '15', '14', '13', '12', '11',
        '21', '22', '23', '24', '25', '26', '27', '28',
    ],
    [JAWS.LOWER]: [
        '48', '47', '46', '45', '44', '43', '42', '41',
        '31', '32', '33', '34', '35', '36', '37', '38'
    ]
});

// tooth slot order is different when used for chart views and when used for tooth wheel
const TOOTH_SLOT_SORTING_MODES = freeze({
    CHART: "chart",
    WHEEL: "wheel"
});

const TOOTH_SLOTS = freeze({
    [TOOTH_SLOT_SORTING_MODES.CHART]: TOOTH_SLOTS_BY_JAW[JAWS.UPPER].concat( TOOTH_SLOTS_BY_JAW[JAWS.LOWER] ),
    [TOOTH_SLOT_SORTING_MODES.WHEEL]: TOOTH_SLOTS_BY_JAW[JAWS.UPPER].concat( TOOTH_SLOTS_BY_JAW[JAWS.LOWER].concat().reverse() )
});

const TOOTH_NUMBERS_BY_SLOT = freeze({
    '18': [ '18' ],
    '17': [ '17' ],
    '16': [ '16' ],
    '15': [ '15', '55' ],
    '14': [ '14', '54' ],
    '13': [ '13', '53' ],
    '12': [ '12', '52' ],
    '11': [ '11', '51' ],

    '21': [ '21', '61' ],
    '22': [ '22', '62' ],
    '23': [ '23', '63' ],
    '24': [ '24', '64' ],
    '25': [ '25', '65' ],
    '26': [ '26' ],
    '27': [ '27' ],
    '28': [ '28' ],

    '48': [ '48' ],
    '47': [ '47' ],
    '46': [ '46' ],
    '45': [ '45', '85' ],
    '44': [ '44', '84' ],
    '43': [ '43', '83' ],
    '42': [ '42', '82' ],
    '41': [ '41', '81' ],

    '31': [ '31', '71' ],
    '32': [ '32', '72' ],
    '33': [ '33', '73' ],
    '34': [ '34', '74' ],
    '35': [ '35', '75' ],
    '36': [ '36' ],
    '37': [ '37' ],
    '38': [ '38' ]
});

const TOOTH_SLOTS_BY_TOOTH_NUMBER = freeze(
    Object.keys(TOOTH_NUMBERS_BY_SLOT).reduce((object, slotNumber) => {
        TOOTH_NUMBERS_BY_SLOT[slotNumber].forEach(toothNumber => {
            object[toothNumber] = slotNumber;
        });
        return object;
    }, {})
);

const TOOTH_SLOTS_WITH_MULTIPLE_TOOTH_NUMBERS = freeze(
    Object.keys(TOOTH_NUMBERS_BY_SLOT).filter(slotNumber => TOOTH_NUMBERS_BY_SLOT[slotNumber].length > 1)
);


const TOOTH_NUMBERS_BY_JAW = freeze(
    Object.keys(TOOTH_SLOTS_BY_JAW).reduce((result, jaw) => {
        result[jaw] = TOOTH_SLOTS_BY_JAW[jaw].reduce((array, slotNumber) => {
            array.push(...TOOTH_NUMBERS_BY_SLOT[slotNumber]);
            return array;
        }, []);
        return result;
    }, {})
);

const JAWS_BY_TOOTH_NUMBER = freeze(
    Object.keys(TOOTH_NUMBERS_BY_JAW).reduce((object, jaw) => {
        TOOTH_NUMBERS_BY_JAW[jaw].forEach(toothNumber => {
            object[toothNumber] = jaw;
        });
        return object;
    }, {})
);

const TOOTH_NUMBERS = freeze(
    Object.keys(TOOTH_NUMBERS_BY_JAW).reduce((array, jaw) => {
        array.push(...TOOTH_NUMBERS_BY_JAW[jaw]);
        return array;
    }, [])
);


const TOOTH_SETS_BY_TOOTH_NUMBER = freeze(
    TOOTH_NUMBERS.reduce((object, number) => {
        const group = parseInt(number[0]);
        object[number] = (group > 4) ? TOOTH_SETS.DECIDUOUS : TOOTH_SETS.PERMANENT;
        return object;
    }, {})
);

const TOOTH_NUMBERS_BY_TOOTH_SET = freeze(
    Object.values(TOOTH_SETS).reduce((object, set) => {
        object[set] = TOOTH_NUMBERS.filter(number => TOOTH_SETS_BY_TOOTH_NUMBER[number] == set);
        return object;
    }, {})
);

const TOOTH_QUADRANTS_BY_TOOTH_NUMBER = freeze(
    TOOTH_NUMBERS.reduce((object, number) => {
        const group = parseInt(number[0]);
        object[number] = (group > 4) ? group - 4 : group;
        return object;
    }, {})
);

const TOOTH_POSITIONS_BY_TOOTH_NUMBER = freeze(
    TOOTH_NUMBERS.reduce((object, number) => {
        const position = parseInt(number[1]);
        object[number] = position;
        return object;
    }, {})
);

const TOOTH_POSITIONS_BY_TOOTH_SET = freeze({
    [TOOTH_SETS.DECIDUOUS]: [1, 2, 3, 4, 5],
    [TOOTH_SETS.PERMANENT]: [1, 2, 3, 4, 5, 6, 7, 8]
});

const TOOTH_TYPES_BY_TOOTH_SET_JAW_AND_TOOTH_POSITION = freeze({
    // use arrays with positions as indexes, because javascript does not support numbers as object keys
    [TOOTH_SETS.DECIDUOUS]: {
        // for deciduous teeth the molar in position 4 in the upper jaw should be treated as a premolar,
        // and position 4 in the lower jaw should be treated as a molar
        [JAWS.UPPER]: [
            null,

            // 1-3 incisor
            TOOTH_TYPES.INCISOR, // 1
            TOOTH_TYPES.INCISOR, // 2
            TOOTH_TYPES.INCISOR, // 3

            // 4 premolar
            TOOTH_TYPES.PREMOLAR, // 4

            // 5 molar
            TOOTH_TYPES.MOLAR, // 5
        ],
        [JAWS.LOWER]: [
            null,

            // 1-3 incisor
            TOOTH_TYPES.INCISOR, // 1
            TOOTH_TYPES.INCISOR, // 2
            TOOTH_TYPES.INCISOR, // 3

            // 4-5 molar
            TOOTH_TYPES.MOLAR, // 4
            TOOTH_TYPES.MOLAR, // 5
        ]
    },
    [TOOTH_SETS.PERMANENT]: {
        // for permanent teeth all tooth types are the same in upper and lower jaws
        [JAWS.UPPER]: [
            null,

            // 1-3 incisor
            TOOTH_TYPES.INCISOR, // 1
            TOOTH_TYPES.INCISOR, // 2
            TOOTH_TYPES.INCISOR, // 3

            // 4-5 premolar
            TOOTH_TYPES.PREMOLAR, // 4
            TOOTH_TYPES.PREMOLAR, // 5

            // 6-8 molar
            TOOTH_TYPES.MOLAR, // 6
            TOOTH_TYPES.MOLAR, // 7
            TOOTH_TYPES.MOLAR, // 8
        ],
        [JAWS.LOWER]: [
            null,

            // 1-3 incisor
            TOOTH_TYPES.INCISOR, // 1
            TOOTH_TYPES.INCISOR, // 2
            TOOTH_TYPES.INCISOR, // 3

            // 4-5 premolar
            TOOTH_TYPES.PREMOLAR, // 4
            TOOTH_TYPES.PREMOLAR, // 5

            // 6-8 molar
            TOOTH_TYPES.MOLAR, // 6
            TOOTH_TYPES.MOLAR, // 7
            TOOTH_TYPES.MOLAR, // 8
        ]
    }
});

const TOOTH_TYPES_BY_TOOTH_NUMBER = freeze(
    TOOTH_NUMBERS.reduce((object, number) => {
        const set = TOOTH_SETS_BY_TOOTH_NUMBER[number];
        const jaw = JAWS_BY_TOOTH_NUMBER[number];
        const position = parseInt(number[1]);
        object[number] = TOOTH_TYPES_BY_TOOTH_SET_JAW_AND_TOOTH_POSITION[set][jaw][position];
        return object;
    }, {})
);


const TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE = freeze(
    TOOTH_NUMBERS.reduce((object, number) => {
        const set = TOOTH_SETS_BY_TOOTH_NUMBER[number];
        const type = TOOTH_TYPES_BY_TOOTH_NUMBER[number];
        if (!object[set]) {
            object[set] = {};
        }
        if (!object[set][type]) {
            object[set][type] = [];
        }
        object[set][type].push(number);
        return object;
    }, {})
);

const NOTATION_SYSTEMS = freeze({
    ISO: "iso",
    USA: "usa"
});

const TOOTH_LABELS = freeze({

    [NOTATION_SYSTEMS.ISO]: TOOTH_NUMBERS.reduce((object, number) => {
        object[number] = number;
        return object;
    }, {}),

    [NOTATION_SYSTEMS.USA]: {
        '18': '1',
        '17': '2',
        '16': '3',
        '15': '4',
        '14': '5',
        '13': '6',
        '12': '7',
        '11': '8',
        '21': '9',
        '22': '10',
        '23': '11',
        '24': '12',
        '25': '13',
        '26': '14',
        '27': '15',
        '28': '16',

        '48': '32',
        '47': '31',
        '46': '30',
        '45': '29',
        '44': '28',
        '43': '27',
        '42': '26',
        '41': '25',
        '31': '24',
        '32': '23',
        '33': '22',
        '34': '21',
        '35': '20',
        '36': '19',
        '37': '18',
        '38': '17',


        '55': 'A',
        '54': 'B',
        '53': 'C',
        '52': 'D',
        '51': 'E',
        '61': 'F',
        '62': 'G',
        '63': 'H',
        '64': 'I',
        '65': 'J',

        '85': 'T',
        '84': 'S',
        '83': 'R',
        '82': 'Q',
        '81': 'P',
        '71': 'O',
        '72': 'N',
        '73': 'M',
        '74': 'L',
        '75': 'K',
    }
});

const VISUALIZATION_CONTEXTS = freeze({
    MOUTH: "mouth",
    JAW: "jaw",
    TOOTH: "tooth"
});

const PROJECTIONS = freeze({
    BUCCAL: "buccal",
    INCISAL: "incisal",
    PALATAL: "palatal",
    LINGUAL: "lingual"
});

const ZONES = freeze({
    CERVICAL_BUCCAL: "cervical-buccal",
    BUCCAL: "buccal",
    MESIAL: "mesial",
    INCISAL: "incisal",
    OCCLUSAL: "occlusal",
    DISTAL: "distal",
    PALATAL: "palatal",
    LINGUAL: "lingual",
    CERVICAL_PALATAL: "cervical-palatal",
    CERVICAL_LINGUAL: "cervical-lingual",
    CLASS_4_MESIAL: "class-4-mesial",
    BUCCAL_CUSP: "buccal-cusp",
    MESIO_BUCCAL_CUSP: "mesio-buccal-cusp",
    CLASS_4_DISTAL: "class-4-distal",
    PALATAL_CUSP: "palatal-cusp",
    LINGUAL_CUSP: "lingual-cusp",
    DISTO_BUCCAL_CUSP: "disto-buccal-cusp",
    BUCCAL_SURFACE: "buccal-surface",
    MESIO_PALATAL_CUSP: "mesio-palatal-cusp",
    MESIO_LINGUAL_CUSP: "mesio-lingual-cusp",
    PALATAL_SURFACE: "palatal-surface",
    LINGUAL_SURFACE: "lingual-surface",
    DISTO_PALATAL_CUSP: "disto-palatal-cusp",
    DISTO_LINGUAL_CUSP: "disto-lingual-cusp"
});

const ZONES_BY_JAW = freeze({
    // zones are almost the same for both jaws,
    // except anything with PALATAL is upper jaw only, and anything with LINGUAL is lower jaw only
    [JAWS.UPPER]: [
        ZONES.CERVICAL_BUCCAL,
        ZONES.BUCCAL,
        ZONES.MESIAL,
        ZONES.INCISAL,
        ZONES.OCCLUSAL,
        ZONES.DISTAL,
        ZONES.PALATAL,
        ZONES.CERVICAL_PALATAL,
        ZONES.CLASS_4_MESIAL,
        ZONES.BUCCAL_CUSP,
        ZONES.MESIO_BUCCAL_CUSP,
        ZONES.CLASS_4_DISTAL,
        ZONES.PALATAL_CUSP,
        ZONES.DISTO_BUCCAL_CUSP,
        ZONES.BUCCAL_SURFACE,
        ZONES.MESIO_PALATAL_CUSP,
        ZONES.PALATAL_SURFACE,
        ZONES.DISTO_PALATAL_CUSP,
    ],
    [JAWS.LOWER]: [
        ZONES.CERVICAL_BUCCAL,
        ZONES.BUCCAL,
        ZONES.MESIAL,
        ZONES.INCISAL,
        ZONES.OCCLUSAL,
        ZONES.DISTAL,
        ZONES.LINGUAL,
        ZONES.CERVICAL_LINGUAL,
        ZONES.CLASS_4_MESIAL,
        ZONES.BUCCAL_CUSP,
        ZONES.MESIO_BUCCAL_CUSP,
        ZONES.CLASS_4_DISTAL,
        ZONES.LINGUAL_CUSP,
        ZONES.DISTO_BUCCAL_CUSP,
        ZONES.BUCCAL_SURFACE,
        ZONES.MESIO_LINGUAL_CUSP,
        ZONES.LINGUAL_SURFACE,
        ZONES.DISTO_LINGUAL_CUSP
    ]
});

const FACES = freeze({
    BUCCAL: "buccal",
    PALATAL_OR_LINGUAL: "palatal-or-lingual"
});

const PATHOLOGIES = freeze({
    DECAY: "decay",
    FRACTURE: "fracture",
    TOOTH_WEAR: "tooth-wear",
    DISCOLORATION: "discoloration",
    APICAL: "apical",
    DEVELOPMENT_DISORDER: "development-disorder"
});

const RESTORATIONS = freeze({
    FILLING: "filling",
    INLAY: "inlay",
    ONLAY: "onlay",
    VENEER: "veneer",
    PARTIAL_CROWN: "partial-crown",
    CROWN: "crown"
});

const RESTORATION_VALUES = freeze({
    [RESTORATIONS.FILLING]: 0,
    [RESTORATIONS.INLAY]: 1,
    [RESTORATIONS.ONLAY]: 2,
    [RESTORATIONS.VENEER]: 3,
    [RESTORATIONS.PARTIAL_CROWN]: 4,
    [RESTORATIONS.CROWN]: 5,
});

const ISSUE_TYPES = freeze({
    MONITOR: "monitor", // used for issue records
    URGENT: "urgent",   // used for issue records
    HISTORY: "history"  // used for history records
});

const ISSUE_STATUSES = freeze({
    ACTIVE: "active",
    RESOLVED: "resolved"
});




export {
    CHART_LAYERS,
    CHART_MODES,
    TOOTH_SETS,
    TOOTH_TYPES,
    TOOTH_STATES,
    TOOTH_STATE_NAMES,
    ERUPTION_STATES,
    JAWS,
    TOOTH_NUMBERS,
    TOOTH_NUMBERS_BY_JAW,
    JAWS_BY_TOOTH_NUMBER,
    TOOTH_SLOTS,
    TOOTH_SLOT_SORTING_MODES,
    TOOTH_NUMBERS_BY_SLOT,
    TOOTH_SLOTS_BY_TOOTH_NUMBER,
    TOOTH_SLOTS_WITH_MULTIPLE_TOOTH_NUMBERS,
    TOOTH_SETS_BY_TOOTH_NUMBER,
    TOOTH_NUMBERS_BY_TOOTH_SET,
    TOOTH_QUADRANTS_BY_TOOTH_NUMBER,
    TOOTH_POSITIONS_BY_TOOTH_NUMBER,
    TOOTH_TYPES_BY_TOOTH_NUMBER,
    TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE,
    TOOTH_POSITIONS_BY_TOOTH_SET,
    NOTATION_SYSTEMS,
    TOOTH_LABELS,
    VISUALIZATION_CONTEXTS,
    PROJECTIONS,
    ZONES,
    ZONES_BY_JAW,
    FACES,
    PATHOLOGIES,
    RESTORATIONS,
    RESTORATION_VALUES,
    ISSUE_TYPES,
    ISSUE_STATUSES,
};
