// provide support for injecting local storage values for pdf creation
const getFromQueryOrLocalStorage = ({ key, defaultValue }) => {
    const value = new URLSearchParams(window.location.search).get(key);

    if (typeof value === "string") {
        localStorage.setItem(key, value.substring(0, 36));
    }

    return localStorage.getItem(key) || defaultValue;
};
export default getFromQueryOrLocalStorage;
