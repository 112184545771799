import { TOOTH_TYPES, RESTORATIONS } from '@/config/teeth.js';
import { PATHOLOGY_AND_RESTORATION_ACTIONS } from '@/config/pathologies-and-restorations.js';

export default {

    namespaced: true,

    getters: {
        filteredToothNumbers: (state, getters, rootState, rootGetters) => (numbers) => {
            // filter out molars and unerupted / missing teeth (unerupted should not get this far, but check anyway)
            return numbers.filter(number => {
                const isMolar = rootGetters['patient/teeth/tooth'](number).type == TOOTH_TYPES.MOLAR;
                const isPresent = rootGetters['patient/teeth/state/isToothPresent'](number);
                return isPresent && !isMolar;
            });
        },


        definition: (state, getters, rootState, rootGetters) => (toothNumber) => {
            return rootGetters['patient/teeth/state/restoration/type'](RESTORATIONS.VENEER, toothNumber).details || {};
        },

        params: () => () => {
            return {};
        },


        buildRestorationState: (state, getters, rootState, rootGetters) => (toothNumber) => {
            // default state for veneer contains buccal zone already selected
            // so it exactly matches what is needed here
            return rootGetters['patient/teeth/state/restoration/orderedRestoration'](getters.defaultState(toothNumber));
        },

        defaultState: (state, getters, rootState, rootGetters) => (toothNumber) => {
            return rootGetters['patient/teeth/state/restoration/veneer/defaultState'](toothNumber);
        },

        toothStateValues: (state, getters, rootState, rootGetters) => ({ toothNumber, stateValue, toothState }) => {
            return {
                state: stateValue,
                restoration: rootGetters['patient/teeth/state/restoration/saveableToothState']({
                    toothNumber,
                    appendableState: toothState
                })
            };
        },

        buildHistoryRecord: (state, getters, rootState, rootGetters) => ({ toothNumber, selectedSurfaces, details }) => {
            return rootGetters['patient/teeth/state/restoration/buildCreatableRecord']({
                action: PATHOLOGY_AND_RESTORATION_ACTIONS.SAVE,
                typeName: RESTORATIONS.VENEER,
                toothNumber,
                selectedSurfaces,
                details
            });
        }

    },


    actions: {

        perform(context, { numbers }) {

            const creatableRecords = [];

            const toothNumbers = context.getters.filteredToothNumbers(numbers);
            if (toothNumbers.length < 1) {
                return;
            }

            const teethStateValues = toothNumbers.reduce((object, toothNumber) => {
                const toothState = context.getters.buildRestorationState(toothNumber);
                // toothState now contains something like
                // {"restoration":3, "surfaces": [......], "created_at": "...." }

                const details = {}; // no details are needed for veneer in quickselect (no material, quality etc)

                const stateValue = context.rootGetters['patient/teeth/state/restoration/calculateToothStateValue']({
                    toothNumber,
                    toothState,
                    details
                });
                // stateValue now has an integer indicating the tooth's state

                const toothStateValues = context.getters['toothStateValues']({ toothNumber, stateValue, toothState });
                // toothStateValues now has the complete tooth state to replace in db (including previous stuff)
                object[toothNumber] = toothStateValues;


                // build a history record
                const historyRecord = context.getters['buildHistoryRecord']({ toothNumber, selectedSurfaces: toothState.surfaces, details });
                creatableRecords.push(historyRecord);

                return object;
            }, {});

            return context.dispatch('patient/teeth/state/restoration/saveStates', { teethStateValues, creatableRecords }, { root: true });
        },

    }

};
