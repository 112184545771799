<script>
/* eslint vue/no-mutating-props: 0 */

import {
    JAWS,
    PROJECTIONS,
    CHART_LAYERS,
    ERUPTION_STATES,
    RESTORATIONS
} from '@/config/teeth.js';

import {
    TOOTH_WEAR_TYPES
} from '@/config/pathologies/tooth-wear.js';

import {
    FRACTURE_TYPES,
    FRACTURE_POSITIONS
} from '@/config/pathologies/fracture.js';

import {
    APICAL_PRESENCES
} from '@/config/pathologies/apical.js';


import {
    CROWN_SURFACES
} from '@/config/restorations/crown.js';


import {
    COLORS,
    IMAGE_TYPES
} from '@/config/visualization/general.js';

import {
    TEETH_GEOMETRY,
    RULER
} from '@/config/visualization/geometry.js';

import {
    TOOTH_SURFACES
} from '@/config/visualization/surfaces.js';

import {
    TOOTH_EROSION_SURFACES,
    EROSION_PATTERN_CANVAS
} from '@/config/visualization/erosion.js';

import {
    TOOTH_CANVAS_DIMENSIONS
} from '@/config/visualization/canvas.js';

import { PULP_TYPES } from '@/config/root-canal.js';

const SVG_PATHS = require('@/assets/images/svg-paths.json');

export default {
    props: {
        tooth: { required: true },
        previousTooth: { required: true },
        nextTooth: { required: true },
        periodontalValues: { required: true },
        siblingPeriodontalValues: { required: true },
        furcationValue: { required: true },
        mobilityValue: { required: true },
        projection: { required: true },
        imageType: { required: true },
        context: { required: true },
        layers: { required: true },
        canvasContext: { required: true },
        pathology: { required: true },
        restoration: { required: true },
        rootCanal: { required: true },
        eruption: { required: true },
        drawBlankImages: { required: true },
        surroundedByEruptedTeeth: { required: true }
    },
    data: () => {
        return {
        };
    },
    computed:
    {
        isToothErupted() {
            return this.eruption == ERUPTION_STATES.ERUPTED;
        },
        isPreviousToothErupted() {
            if (!this.previousTooth) {
                return false;
            }
            return this.$store.getters['patient/teeth/state/isToothErupted'](this.previousTooth.number);
        },
        isNextToothErupted() {
            if (!this.nextTooth) {
                return false;
            }
            return this.$store.getters['patient/teeth/state/isToothErupted'](this.nextTooth.number);
        },


        isToothMissing() {
            return this.$store.getters['patient/teeth/state/isToothMissing'](this.tooth.number);
        },
        isPreviousToothMissing() {
            if (!this.previousTooth) {
                return false;
            }
            return this.$store.getters['patient/teeth/state/isToothMissing'](this.previousTooth.number);
        },
        isNextToothMissing() {
            if (!this.nextTooth) {
                return false;
            }
            return this.$store.getters['patient/teeth/state/isToothMissing'](this.nextTooth.number);
        },


        isToothPresent() {
            return this.isToothErupted && !this.isToothMissing;
        },
        isPreviousToothPresent() {
            return this.isPreviousToothErupted && !this.isPreviousToothMissing;
        },
        isNextToothPresent() {
            return this.isNextToothErupted && !this.isNextToothMissing;
        },

        isToothNormal() {
            return this.imageType == IMAGE_TYPES.BASE;
        },

        isToothNormalOrImplant() {
            return this.isToothNormal || this.imageType == IMAGE_TYPES.IMPLANT;
        },

        shouldDrawTooth() {
            return this.isToothErupted || this.drawBlankImages;
        },

        shouldDrawProbingDepth() {
            return this.isToothPresent;
        },

        shouldDrawGingivalMargin() {
            return this.isToothErupted || this.drawBlankImages || this.surroundedByEruptedTeeth;
        },

        toothImagePath() {
            return this.tooth.images[this.imageType][this.projection];
        },

        toothSurfaces() {
            return TOOTH_SURFACES[this.tooth.number];
        },

        projectionSurfaces() {
            return this.toothSurfaces[this.lingualToPalatal(this.projection)];
        },

        toothImage() {
            return this.image(this.toothImagePath);
        },

        furcationImagePath() {
            return 'furcation/stage-' + this.furcationValue;
        },

        furcationImage() {
            return this.image(this.furcationImagePath);
        },

        mobilityImagePath() {
            return 'mobility/class-' + this.mobilityValue;
        },

        mobilityImage() {
            return this.image(this.mobilityImagePath);
        },

        toothImageCanvasPadding(){
            // horizontal padding always centers the image on the canvas
            const horizontal = (this.canvasContext.canvas.width - this.toothImage.width) / 2;

            let vertical;

            if (this.imageType == IMAGE_TYPES.BLANK) {
                // blank slot images are vertically centered
                vertical = (this.canvasContext.canvas.height - this.toothImage.height) / 2;
            } else {
                // erupted teeth images for non-incisal projections always have vertical padding from tooth bottom
                // in exactly one ruler step height
                vertical = (this.projection !== PROJECTIONS.INCISAL) ? RULER.height : 0;
            }

            return { horizontal, vertical };
        },

        teethGeometryConfig() {
            return TEETH_GEOMETRY;

            // :DEBUG: disable return line above and uncomment this to enable development overrides for TEETH_GEOMETRY

            // const override = this.$store.state.patient.teeth.state.developmentOverrides.teethGeometry;
            // if (override) {
            //     return override;
            // } else {
            //     return TEETH_GEOMETRY;
            // }
        },

        toothGeometryConfig() {
            return this.teethGeometryConfig[this.tooth.set][this.tooth.quadrant][this.tooth.position];
        },

        toothErosionSurfaces() {
            return TOOTH_EROSION_SURFACES[this.tooth.number];

            // :DEBUG: disable return line above and uncomment this to enable development overrides for  TOOTH_EROSION_SURFACES

            // const override = this.$store.state.patient.teeth.state.developmentOverrides.toothErosionSurfaces;
            // let source;
            // if (override) {
            //     source = override;
            // } else {
            //     source = TOOTH_EROSION_SURFACES;
            // }
            // return source[this.tooth.number];
        },

        previousToothGeometry() {
            return this.previousTooth ? this.geometryForTooth(this.previousTooth) : null;
        },

        toothGeometry() {
            return this.geometryForTooth(this.tooth);
        },

        nextToothGeometry() {
            return this.nextTooth ? this.geometryForTooth(this.nextTooth) : null;
        },

        linePoints() {
            var leftZone = (this.shouldInvertSide ? "mesio-" : "disto-") + this.projection;
            var rightZone = (this.shouldInvertSide ? "disto-" : "mesio-") + this.projection;
            var sibblingLeftZone = leftZone;
            var sibblingRightZone = rightZone;

            // use correct sibling zones for center tooth
            if (this.tooth.position == 1) {
                if (this.tooth.quadrant == 1 || this.tooth.quadrant == 4) {
                    sibblingLeftZone = "mesio-" + this.projection;
                }
                if (this.tooth.quadrant == 2 || this.tooth.quadrant == 3) {
                    sibblingRightZone = "mesio-" + this.projection;
                }
            }

            var leftSideGingivalMargin = 0;
            var leftGingivalMargin = this.isToothPresent ? this.periodontalValues[leftZone].margin : 0;
            var centerGingivalMargin = this.isToothPresent ? (this.periodontalValues[this.projection].margin + this.depthOffset) : 0;
            var rightGingivalMargin = this.isToothPresent ? this.periodontalValues[rightZone].margin : 0;
            var rightSideGingivalMargin = 0;

            var leftSideProbingDepth = 0;
            var leftProbingDepth = (this.periodontalValues[leftZone].depth * -1) + leftGingivalMargin;
            var centerProbingDepth = (this.periodontalValues[this.projection].depth * -1) + centerGingivalMargin;
            var rightProbingDepth = (this.periodontalValues[rightZone].depth * -1) + rightGingivalMargin;
            var rightSideProbingDepth = 0;

            var leftSideWithSibling = null;
            var rightSideWithSibling = null;

            if (this.siblingPeriodontalValues.previous && this.isPreviousToothErupted) {
                var previousSiblingValues = this.siblingPeriodontalValues.previous[sibblingRightZone];
                leftSideGingivalMargin = this.isPreviousToothPresent ? previousSiblingValues.margin : 0;
                leftSideProbingDepth = (previousSiblingValues.depth * -1) + leftSideGingivalMargin;

                // for correct previous tooth curve connection first point
                // must be relative value of previous tooth right point
                leftSideWithSibling = this.previousToothGeometry.horizontal.right - this.previousToothGeometry.canvasDimensions.width;
            }

            if (this.siblingPeriodontalValues.next && this.isNextToothErupted) {
                var nextSiblingValues = this.siblingPeriodontalValues.next[sibblingLeftZone];
                rightSideGingivalMargin = this.isNextToothPresent ? nextSiblingValues.margin : 0;
                rightSideProbingDepth = (nextSiblingValues.depth * -1) + rightSideGingivalMargin;

                // for correct next tooth curve connection last point
                // must be relative value of next tooth left point
                rightSideWithSibling = this.canvasContext.canvas.width + this.nextToothGeometry.horizontal.left;
            }

            // calculate gingival margin points
            var points = {
                gingivalMargin: {
                    1: {
                        x: leftSideWithSibling ? leftSideWithSibling : this.toothGeometry.horizontal.leftSide,
                        y: this.levelToYPosition(leftSideGingivalMargin)
                    },
                    2: {
                        x: this.toothGeometry.horizontal.left,
                        y: this.levelToYPosition(leftGingivalMargin)
                    },
                    3: {
                        x: this.toothGeometry.horizontal.center,
                        y: this.levelToYPosition(centerGingivalMargin)
                    },
                    4: {
                        x: this.toothGeometry.horizontal.right,
                        y: this.levelToYPosition(rightGingivalMargin)
                    },
                    5: {
                        x: rightSideWithSibling ? rightSideWithSibling : this.toothGeometry.horizontal.rightSide,
                        y: this.levelToYPosition(rightSideGingivalMargin)
                    }
                }
            };

            // calculate proding depth points
            points["probingDepth"] = {
                1: {
                    x: points["gingivalMargin"][1].x,
                    y: this.levelToYPosition(leftSideProbingDepth)
                },
                2: {
                    x: points["gingivalMargin"][2].x,
                    y: this.levelToYPosition(leftProbingDepth)
                },
                3: {
                    x: points["gingivalMargin"][3].x,
                    y: this.levelToYPosition(centerProbingDepth)
                },
                4: {
                    x: points["gingivalMargin"][4].x,
                    y: this.levelToYPosition(rightProbingDepth)
                },
                5: {
                    x: points["gingivalMargin"][5].x,
                    y: this.levelToYPosition(rightSideProbingDepth)
                }
            };

            return points;
        },

        lineWidth() {
            return this.context === 'tooth' ? 3 : 6;
        },

        depthOffset() {
            // use either custom depth offset or default one
            if (Object.keys(this.toothGeometryConfig.depthOffset).includes(this.projection)) {
                return this.toothGeometryConfig.depthOffset[this.projection];
            }

            return this.toothGeometryConfig.depthOffset.default;
        },

        shouldInvertSide() {
            if (this.tooth.quadrant == 2) {
                return true;
            }
            if (this.tooth.quadrant == 3) {
                return true;
            }

            return false;
        }
    },

    created() {
        this.debug = false;
    },

    methods:
    {
        image(imagePath) {
            return this.$store.getters['images/image'](imagePath);
        },

        hasLayer(layerName) {
            return this.layers.includes(layerName);
        },

        redraw() {
            if (!this.$store.state.images.loaded) {
                return false;
            }

            // always reset canvas content
            this.canvasContext.clearRect(0, 0, this.canvasContext.canvas.width, this.canvasContext.canvas.height);

            // draw tooth image
            if (this.shouldDrawTooth) {
                this.drawTooth();
            }

            if (this.shouldDrawDashes()) {
                this.drawDashes();
            }

            // :DEBUG: disable this section to hide all periodontal lines and areas in all visualisations
            if (this.hasLayer(CHART_LAYERS.PERODONTIAL) && this.periodontalValues) {
                if (this.shouldDrawProbingDepth) {
                    this.drawProbingDepthArea();
                    this.drawProbingDepth();
                }
                if (this.shouldDrawGingivalMargin) {
                    this.drawGingivalMargin();
                    this.drawDebugImagePoints();
                }
            }

            if (this.hasLayer(CHART_LAYERS.PERODONTIAL) && this.projection !== PROJECTIONS.INCISAL) {
                if (this.isToothNormal && this.furcationValue > 0) {
                    this.drawFurcationImage();
                }

                if (this.isToothNormalOrImplant && this.mobilityValue > 0) {
                    this.drawMobilityImage();
                }
            }

        },


        drawTooth() {
            // image is positioned on canvas by adding padding
            const canvasPadding = this.toothImageCanvasPadding;

            // assign image inverting boolean if needed
            var flipVertically = this.shouldInvertImage();

            // not used currently
            var flipHorizontally = this.tooth.mirrorImage;

            // set horizontal scale to -1 if flip horizontal
            var scaleHorizontally = flipHorizontally ? -1 : 1;

            // set verical scale to -1 if flip vertical
            var scaleVertically = flipVertically ? -1 : 1;

            // set x position to -100% if flip horizontal
            var xPosition = flipHorizontally ? (this.toothImage.width * -1) - canvasPadding.horizontal : canvasPadding.horizontal;

            // set y position to -100% if flip vertical
            var yPosition = flipVertically ? (this.toothImage.height + canvasPadding.vertical) * -1 : canvasPadding.vertical;

            // draw and rotate image
            this.canvasContext.scale(scaleHorizontally, scaleVertically);

            if (this.debug) {
                this.canvasContext.setLineDash([]);
                this.canvasContext.lineWidth = 1;
                this.canvasContext.strokeStyle = COLORS.transparency;
                this.canvasContext.fillStyle = "#84FF92";
                this.canvasContext.beginPath();
                this.canvasContext.moveTo(xPosition, yPosition);
                this.canvasContext.lineTo(xPosition, yPosition + this.toothImage.height);
                this.canvasContext.lineTo(xPosition + this.toothImage.width, yPosition + this.toothImage.height);
                this.canvasContext.lineTo(xPosition + this.toothImage.width, yPosition);
                this.canvasContext.lineTo(xPosition, yPosition);
                this.canvasContext.closePath();
                this.canvasContext.fill();
                this.canvasContext.stroke();
            }

            // set x, y relative positions calculated from actual tooth image against canvas size
            this.canvasContext.translate(xPosition, yPosition);

            // apical must be drawn behind tooth
            if (this.isToothNormal && this.hasLayer(CHART_LAYERS.DENTAL)) {
                this.drawApical();
            }

            this.canvasContext.drawImage(this.toothImage, 0, 0);

            if (this.isToothPresent && this.hasLayer(CHART_LAYERS.DENTAL)) {
                // draw wear as first layer
                this.drawWear(); // stay

                let surfaces = [];

                if (this.isToothNormal) {
                    surfaces = surfaces.concat(this.discolorationSurfaces());
                    surfaces = surfaces.concat(this.decaySurfaces());
                }

                surfaces = surfaces.concat(this.restorationSurfaces());


                this.drawSurfaces(surfaces);

                // draw fracture over other surfaces
                if (this.isToothNormal) {
                    this.drawFracture();
                }
            }

            // draw root canal over all other layers
            if (this.isToothNormal && this.hasLayer(CHART_LAYERS.ENDODONTIC)) {
                this.drawRootCanal();
            }

            this.canvasContext.setTransform(1, 0, 0, 1, 0, 0);

            if (this.debug) {
                this.canvasContext.setLineDash([]);
                this.canvasContext.lineWidth = 4;
                this.canvasContext.strokeStyle = "#ff0000";
                this.canvasContext.beginPath();
                this.canvasContext.moveTo(0, 0);
                this.canvasContext.lineTo(this.canvasContext.canvas.width, 0);
                this.canvasContext.lineTo(this.canvasContext.canvas.width, this.canvasContext.canvas.height);
                this.canvasContext.lineTo(0, this.canvasContext.canvas.height);
                this.canvasContext.lineTo(0, 0);
                this.canvasContext.stroke();
            }
        },

        drawSurfaces(surfaces) {
            let drawableSurfaces = {};

            // filter unique newest surfaces
            surfaces.forEach((surface) => {
                if (drawableSurfaces[surface] == undefined || drawableSurfaces[surface].date < surface.date) {
                    drawableSurfaces[surface.surface] = surface;
                }
            });

            // filter out overlapped surfaces with older date
            let drawableSurfacesToDelete = [];

            Object.values(drawableSurfaces).forEach((surface) => {
                this.toothSurfaces.overlaps[surface.surface].forEach((overlappingSurface) => {
                    if (drawableSurfaces[overlappingSurface] != undefined && surface.date < drawableSurfaces[overlappingSurface].date){
                        drawableSurfacesToDelete.push(surface.surface);
                    }
                });
            });

            // delete overlapped surfaces
            drawableSurfacesToDelete.forEach((surface) => {
                delete drawableSurfaces[surface];
            });

            // create array for sorting
            let sortableSurfaces = [];
            Object.values(drawableSurfaces).forEach((surface) => {
                sortableSurfaces.push(surface);
            });

            // sort surfaces by date
            sortableSurfaces.sort(function (a, b) {
                return (a.date < b.date) ? -1 : (a.date > b.date) ? 1 : 0;
            });

            // draw all surfaces one by one
            sortableSurfaces.forEach((surface) => {
                this.drawPaths([this.toothSurfacePath(surface.surface)], surface.color);
            });
        },

        restorationSurfaces() {
            // store surface -> color map
            let surfaces = [];

            this.restoration.forEach((restoration) => {
                let date = new Date(restoration.created_at);

                // for crowns, the surfaces are always taken from the definition (all of them)
                // and not from the tooth state in store. the surfaces in the store are only for compatibility purposes
                const restorationSurfaces = (restoration.type == RESTORATIONS.CROWN) ? this.crownSurfaces() : restoration.surfaces;

                restorationSurfaces.forEach((surface) => {
                    const overlaps = this.toothSurfaces.overlaps[surface];
                    if (this.projectionSurfaces.includes(surface) && !overlaps.some(v=> restorationSurfaces.indexOf(v) !== -1)) {
                        let material = restoration.material == undefined ? 'unknown' : restoration.material;
                        surfaces.push({date: date, color: COLORS.fillings[material], surface: surface});
                    }
                });
            });

            return surfaces;
        },

        crownSurfaces() {
            return CROWN_SURFACES[this.tooth.number];
        },

        drawRootCanal() {
            if (!this.rootCanal.pulpType || this.projection == PROJECTIONS.INCISAL) {
                return;
            }

            let type = (this.rootCanal.pulpType == PULP_TYPES.ROOT_CANAL_TREATMENT) ? 'treatment' : 'pulp';
            let pathKey = [
                'teeth',
                'root-canal',
                this.tooth.set,
                this.tooth.jaw,
                this.tooth.position,
                this.lingualToPalatal(this.projection),
                type].join('/');
            let path = new Path2D(SVG_PATHS[pathKey]);

            this.drawPaths([path], COLORS.rootCanal[this.rootCanal.pulpType]);
        },

        decaySurfaces() {
            let surfaces = [];

            let date = new Date(this.pathology.decay.created_at);

            // collect paths from all surfaces
            this.pathology.decay.surfaces.forEach((surface) => {
                const overlaps = this.toothSurfaces.overlaps[surface];
                // draw only if projection has this surface and surface has no overlaps due to other
                // surfaces shown
                if (this.projectionSurfaces.includes(surface) && !overlaps.some(v=> this.pathology.decay.surfaces.indexOf(v) !== -1)) {
                    surfaces.push({date: date, color: COLORS.decay, surface: surface});
                }
            });

            return surfaces;
        },

        discolorationSurfaces() {
            const discoloration = this.pathology['discoloration'].values;
            let surfaces = [];

            if (discoloration.type == undefined) {
                return surfaces;
            }

            // use Unix epoch to have discoloration as lowest priority possible for all surfaces
            let date = new Date('1970');

            // collect all non overlapping surfaces
            this.toothSurfaces[this.lingualToPalatal(this.projection)].forEach((surface) => {
                if (this.toothSurfaces.overlaps[surface].length == 0) {
                    surfaces.push({date: date, color: COLORS.discoloration[discoloration.type], surface: surface});
                }
            });

            return surfaces;
        },

        drawWear(){
            const wear = this.pathology['tooth-wear'].values;

            if (wear.type == undefined || wear.zone == undefined) {
                return;
            }

            const wearProjection = this.lingualToPalatal(this.projection);

            if (wear.type == TOOTH_WEAR_TYPES.ABRASION && this.projection != PROJECTIONS.INCISAL) {
                // abrasion doesn't have any visuals for incisal projection, each wear zone matches single projection
                wear.zone.forEach((wearZone) => {
                    if (this.projection == wearZone) {
                        let imagePath = 'teeth/abrasion/' + this.tooth.set + '/' + this.tooth.jaw + "/" + this.tooth.position + '/' + wearProjection;
                        let wearImage = this.image(imagePath);
                        this.canvasContext.drawImage(wearImage, 0, 0);
                    }
                });
            } else if (wear.type == TOOTH_WEAR_TYPES.EROSION) {
            // erosion wear zone may include multiple projections, so loop until we have surfaces that matches current projection
                let paths = [];

                wear.zone.forEach((wearZone) => {
                    const zone = this.lingualToPalatal(wearZone);

                    const projectionSurfaces = this.toothErosionSurfaces[zone][wearProjection];

                    projectionSurfaces.forEach((surface) => {
                        paths.push(this.toothSurfacePath(surface));
                    });
                });

                this.drawPaths(paths, EROSION_PATTERN_CANVAS);
            }
        },

        drawPaths(paths, colorOrPatternCanvasElement) {
            if (paths.length == 0) {
                return;
            }

            const path = new Path2D();
            paths.forEach(surfacePath => path.addPath(surfacePath));

            // set color or create canvas pattern
            if (colorOrPatternCanvasElement instanceof HTMLCanvasElement) {
                this.canvasContext.fillStyle = this.canvasContext.createPattern(colorOrPatternCanvasElement, 'repeat');
            } else {
                this.canvasContext.fillStyle = colorOrPatternCanvasElement;
            }

            this.canvasContext.lineWidth = 0;
            this.canvasContext.strokeStyle = COLORS.transparency;
            this.canvasContext.fill(path);
            this.canvasContext.stroke(path);
        },

        drawFracture() {
            const fracture = this.pathology.fracture.values;

            if (fracture.type == undefined || fracture.position == undefined) {
                return;
            }

            const amplitude = (fracture.type == FRACTURE_TYPES.ROOT) ? 7 : 15;
            const linesCount = (fracture.type == FRACTURE_TYPES.ROOT && fracture.position == FRACTURE_POSITIONS.VERTICAL) ? 20 : 10;
            const fractureConfig = this.toothGeometryConfig.fracture[fracture.type][fracture.position];
            const fractureProjection = this.lingualToPalatal(this.projection);


            let lines = [];

            if (Object.keys(fractureConfig).includes(fractureProjection)) {
                fractureConfig[fractureProjection].forEach((lineSource) => {
                    let line = {
                        x1: lineSource.x1,
                        y1: this.yToAbsolute(lineSource.y1),
                        x2: lineSource.x2,
                        y2: this.yToAbsolute(lineSource.y2),
                    };

                    lines.push(line);
                });
            }

            lines.forEach((line) => {
                this.drawFractureLine(line, linesCount, amplitude);
            });
        },

        drawApical() {
            const apical = this.pathology.apical.values;

            if (apical.presence != APICAL_PRESENCES.YES || this.projection == PROJECTIONS.INCISAL) {
                return;
            }

            this.toothGeometryConfig.apical[this.lingualToPalatal(this.projection)].forEach((point) => {
                this.canvasContext.beginPath();
                this.canvasContext.lineWidth = 1;
                this.canvasContext.strokeStyle = COLORS.fractureLine;
                this.canvasContext.fillStyle = COLORS.transparency;
                this.canvasContext.arc(point.x, this.yToAbsolute(point.y), 15, 0, 2 * Math.PI);
                this.canvasContext.stroke();
            });
        },

        // recalculate relative Y value (jaw independet) to absolute value for reversed tooth Y axis
        yToAbsolute(y) {
            if (this.tooth.jaw == JAWS.UPPER) {
                return this.toothImage.height - y;
            }

            return y;
        },

        toothSurfacePath(surface) {
            return new Path2D(SVG_PATHS['teeth/surfaces/' + this.tooth.set + '/' + this.tooth.jaw + '/' + this.tooth.position + '/' + surface]);
        },

        lingualToPalatal(projection) {
            return projection == PROJECTIONS.LINGUAL ? PROJECTIONS.PALATAL : projection;
        },

        drawFractureLine(line, linesCount, amplitude) {
            let xDistance = line.x2 - line.x1;
            let yDistance = line.y2 - line.y1;
            let xInterval = xDistance / linesCount;
            let yInterval = yDistance / linesCount;
            let isVertical = Math.abs(yDistance) > Math.abs(xDistance);
            let inverted = true;

            this.canvasContext.setLineDash([]);
            this.canvasContext.lineWidth = 1;
            this.canvasContext.strokeStyle = COLORS.fractureLine;
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(line.x1, line.y1);

            var z = inverted ? -1 : 1;
            for (var i = 1; i <= linesCount; i++) {
                z = -1 * z;
                var nextPoint = {
                    x: line.x1 + xInterval * i,
                    y: line.y1 + yInterval * i
                };

                if ( i < linesCount) {
                    if (isVertical) {
                        nextPoint.x += amplitude * z;
                        nextPoint.y += xInterval * z / 3 * (-1);
                    } else {
                        nextPoint.x += yInterval * z * (-1);
                        nextPoint.y += amplitude * z;
                    }
                }
                this.canvasContext.lineTo(nextPoint.x, nextPoint.y);
            }

            this.canvasContext.stroke();
        },

        drawPointsCurve(pointsType, p1Index, p2Index, inverseCurve) {
            let p1 = this.linePoints[pointsType][p1Index];
            let p2 = this.linePoints[pointsType][p2Index];

            let p1x = inverseCurve ? p2.x : p1.x;
            let p2x = inverseCurve ? p1.x : p2.x;

            let halfPointCurve = p1x + (p2x - p1x) / 2;

            let cp1y = p1.y;
            let cp1x = halfPointCurve;

            let cp2y = p2.y;
            let cp2x = halfPointCurve;

            let x = p2.x;
            let y = p2.y;

            this.canvasContext.bezierCurveTo(cp1x, cp1y, cp2x, cp2y,  x, y);
        },

        drawProbingDepthArea() {
            this.canvasContext.setLineDash([]);
            this.canvasContext.lineWidth = 1;
            this.canvasContext.strokeStyle = COLORS.transparency;
            this.canvasContext.fillStyle = COLORS.probingDepthArea;
            this.canvasContext.beginPath();

            this.canvasContext.moveTo(this.linePoints["probingDepth"][1].x, this.linePoints["probingDepth"][1].y);

            this.drawPointsCurve("probingDepth", 1, 2, false);
            this.drawPointsCurve("probingDepth", 2, 3, false);
            this.drawPointsCurve("probingDepth", 3, 4, true);
            this.drawPointsCurve("probingDepth", 4, 5, true);

            this.canvasContext.lineTo(this.linePoints["gingivalMargin"][5].x, this.linePoints["gingivalMargin"][5].y);

            this.drawPointsCurve("gingivalMargin", 5, 4, false);
            this.drawPointsCurve("gingivalMargin", 4, 3, false);
            this.drawPointsCurve("gingivalMargin", 3, 2, true);
            this.drawPointsCurve("gingivalMargin", 2, 1, true);

            this.canvasContext.closePath();
            this.canvasContext.fill();
            this.canvasContext.stroke();
        },

        drawProbingDepth() {
            this.canvasContext.setLineDash([]);
            this.canvasContext.lineWidth = this.lineWidth;
            this.canvasContext.strokeStyle = COLORS.probingDepthLine;
            this.canvasContext.beginPath();

            this.canvasContext.moveTo(0, this.linePoints["probingDepth"][1].y);
            this.canvasContext.lineTo(this.linePoints["probingDepth"][1].x, this.linePoints["probingDepth"][1].y);

            this.drawPointsCurve("probingDepth", 1, 2, false);
            this.drawPointsCurve("probingDepth", 2, 3, false);
            this.drawPointsCurve("probingDepth", 3, 4, true);
            this.drawPointsCurve("probingDepth", 4, 5, true);

            this.canvasContext.lineTo(this.canvasContext.canvas.width, this.linePoints["probingDepth"][5].y);
            this.canvasContext.stroke();
        },

        drawGingivalMargin() {
            this.canvasContext.setLineDash([]);
            this.canvasContext.lineWidth = this.lineWidth;
            this.canvasContext.strokeStyle = COLORS.gingivalMarginLine;
            this.canvasContext.beginPath();

            this.canvasContext.moveTo(0, this.linePoints["gingivalMargin"][1].y);
            this.canvasContext.lineTo(this.linePoints["gingivalMargin"][1].x, this.linePoints["gingivalMargin"][1].y);

            this.drawPointsCurve("gingivalMargin", 1, 2, false);
            this.drawPointsCurve("gingivalMargin", 2, 3, false);
            this.drawPointsCurve("gingivalMargin", 3, 4, true);
            this.drawPointsCurve("gingivalMargin", 4, 5, true);

            this.canvasContext.lineTo(this.canvasContext.canvas.width, this.linePoints["gingivalMargin"][5].y);
            this.canvasContext.stroke();
        },

        levelToYPosition(level) {
            return ((level + RULER.lines) * RULER.height);
        },

        shouldInvertImage() {
            if (this.tooth.jaw == JAWS.LOWER && this.projection == PROJECTIONS.LINGUAL) {
                return true;
            }
            if (this.tooth.jaw == JAWS.LOWER && this.projection == PROJECTIONS.BUCCAL) {
                return true;
            }

            return false;
        },

        shouldInvertFurcationImage() {
            if (this.tooth.jaw == JAWS.UPPER && this.projection == PROJECTIONS.PALATAL) {
                return true;
            }
            if (this.tooth.jaw == JAWS.LOWER && this.projection == PROJECTIONS.BUCCAL) {
                return true;
            }

            return false;
        },

        drawFurcationImage() {
            // add horizontal padding to center image
            var horizontalPadding = (this.canvasContext.canvas.width - this.furcationImage.width) / 2;

            // assign image inverting boolean if needed
            var flipVertically = this.shouldInvertFurcationImage();

            // not used currently
            var flipHorizontally = false;

            // set horizontal scale to -1 if flip horizontal
            var scaleHorizontally = flipHorizontally ? -1 : 1;

            // set verical scale to -1 if flip vertical
            var scaleVertically = flipVertically ? -1 : 1;

            // set x position to -100% if flip horizontal
            var xPosition = flipHorizontally ? (this.furcationImage.width * -1) - horizontalPadding : horizontalPadding;

            // set y position to -100% if flip vertical
            var yPosition = flipVertically ? (this.levelToYPosition(-4) + this.furcationImage.height / 2 ) * -1 : this.levelToYPosition(-4) - this.furcationImage.height / 2;

            // draw and rotate image
            this.canvasContext.scale(scaleHorizontally, scaleVertically);
            this.canvasContext.drawImage(this.furcationImage, xPosition, yPosition);
            this.canvasContext.setTransform(1, 0, 0, 1, 0, 0);
        },

        drawMobilityImage() {
            // add horizontal padding to center image
            const horizontalPadding = (this.canvasContext.canvas.width - this.mobilityImage.width) / 2;

            // set x position to -100% if flip horizontal
            const xPosition = horizontalPadding;

            // same as for furcation, but offset
            let yPosition = this.levelToYPosition(-4) - this.mobilityImage.height / 2;
            yPosition += 80;

            // draw and rotate image
            this.canvasContext.drawImage(this.mobilityImage, xPosition, yPosition);
            this.canvasContext.setTransform(1, 0, 0, 1, 0, 0);
        },

        drawDashes() {
            this.canvasContext.setLineDash([8, 4]);
            this.canvasContext.lineWidth = 1;
            this.canvasContext.strokeStyle = COLORS.dashLine;

            Array.from(Array(RULER.lines)).forEach((x, i) => {
                var y = this.levelToYPosition(-i);
                this.canvasContext.beginPath();
                this.canvasContext.moveTo(0, y);
                this.canvasContext.lineTo(400, y);
                this.canvasContext.stroke();
            });

        },

        shouldDrawDashes() {
            return (this.projection !== PROJECTIONS.INCISAL && this.context === 'tooth');
        },

        drawDebugImagePoints() {
            if (!this.debug) {
                return;
            }

            this.canvasContext.setLineDash([]);
            this.canvasContext.lineWidth = 1;
            this.canvasContext.strokeStyle = "#0088ff";

            // left image point
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(this.toothGeometry.horizontal.left, 0);
            this.canvasContext.lineTo(this.toothGeometry.horizontal.left, this.canvasContext.canvas.height);
            this.canvasContext.stroke();

            // center image point
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(this.toothGeometry.horizontal.center, 0);
            this.canvasContext.lineTo(this.toothGeometry.horizontal.center, this.canvasContext.canvas.height);
            this.canvasContext.stroke();

            // right image point
            this.canvasContext.beginPath();
            this.canvasContext.moveTo(this.toothGeometry.horizontal.right, 0);
            this.canvasContext.lineTo(this.toothGeometry.horizontal.right, this.canvasContext.canvas.height);
            this.canvasContext.stroke();
        },

        geometryForTooth(tooth) {
            var pointsConfig = this.teethGeometryConfig[tooth.set][tooth.quadrant][tooth.position].points[this.lingualToPalatal(this.projection)];

            var geometry = {
                canvasDimensions: TOOTH_CANVAS_DIMENSIONS[this.context][this.projection][tooth.set][tooth.position],
                horizontal: {}
            };

            geometry.horizontal.center = geometry.canvasDimensions.width / 2 + pointsConfig.center_offset;
            geometry.horizontal.left = geometry.horizontal.center - pointsConfig.left;
            geometry.horizontal.right = geometry.horizontal.center + pointsConfig.right;
            geometry.horizontal.leftSide = geometry.horizontal.left - (geometry.horizontal.center - geometry.horizontal.left);
            geometry.horizontal.rightSide = geometry.horizontal.right + (geometry.horizontal.right - geometry.horizontal.center);

            return geometry;
        }
    },

    render(createElement) {
        if (this.canvasContext) {
            this.redraw();
        }
        return createElement();
    }

};
</script>
