import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        deserializerName: undefined,
        idAttribute: "id",
        items: []
    },

    getters: {
        items: (state) => {
            return state.items;
        },
        itemsBy: (state) => (attribute, value) => {
            return state.items.filter(item => item[attribute] === value);
        },
        item: (state) => (id) => {
            return state.items.find(item => item[state.idAttribute] === id);
        },
        numberOfItems: (state) => {
            return state.items.length;
        },
        itemIndex: (state) => (item) => {
            return state.items.findIndex(entry => entry[state.idAttribute] == item[state.idAttribute]);
        }
    },

    mutations: {
        clear (state) {
            state.items = [];
        },
        set (state, item) {
            var idAttribute = state.idAttribute;
            var deserializedItem = (state.deserializerName) ? this.app.deserialize[state.deserializerName](item) : item;

            var index = state.items.findIndex(entry => entry[idAttribute] == item[idAttribute]);
            if (index === -1) {
                state.items.push(deserializedItem);
            } else {
                Vue.set(state.items, index, deserializedItem);
            }
        },
        prepend (state, item) {
            // adds item to the beginning of array (removing it if before, if it already exists somewhere)
            var idAttribute = state.idAttribute;
            var deserializedItem = (state.deserializerName) ? this.app.deserialize[state.deserializerName](item) : item;

            var index = state.items.findIndex(entry => entry[idAttribute] == item[idAttribute]);
            if (index > -1) {
                state.items.splice(index, 1);
            }
            state.items.unshift(deserializedItem);
        },
        remove (state, id) {
            var idAttribute = state.idAttribute;
            var index = state.items.findIndex(entry => entry[idAttribute] == id);
            if (index !== -1) {
                state.items.splice(index, 1);
            }
        },
        modify (state, { id, attribute, value }) {
            var idAttribute = state.idAttribute;
            var index = state.items.findIndex(entry => entry[idAttribute] == id);
            if (index !== -1) {
                Vue.set(state.items[index], attribute, value);
            }
        }
    },

    actions: {

        // add or replace a specific item by id
        set (context, item) {
            context.commit('set', item);
        },

        // only set item if it already exists in store
        update (context, item) {
            if (context.getters.item(item[context.state.idAttribute])) {
                context.commit('set', item);
            }
        },

        // remove a specific item by id
        remove (context, id) {
            context.commit('remove', id);
        },

        // remove all existing items that have the given attribute value
        removeBy (context, options) {
            context.getters.itemsBy(options.attribute, options.value).map(item => item[context.state.idAttribute]).forEach(id => {
                context.commit('remove', id);
            });
        },

        // add all given items
        load (context, items) {
            items.forEach(item => context.dispatch('set', item));
        },

        // clear all existing items, then add all given items
        replace (context, items) {
            context.commit('clear');
            items.forEach(item => context.dispatch('set', item));
        },

        // clear all existing items with given attribute, then add all given items
        replaceBy(context, options) {
            context.dispatch('removeBy', { attribute: options.attribute, value: options.value } );
            options.items.forEach(item => context.dispatch('set', item ));
        },

        // clear all existing items
        clear (context) {
            context.commit('clear');
        },

        reset(context) {
            context.dispatch('clear');
        }

    },
};
