<template>
    <div class="tooth-wheel" ref="container">
        <ol class="navigation">
            <navigation-link
                v-for="wheelTooth in teeth"
                :to="toothRoute(wheelTooth)"
                tag="li"
                :key="wheelTooth.number"
                replace
            >
                {{ wheelTooth.label }}
            </navigation-link>
        </ol>

        <iscroll-view
            class="viewport"
            :options="iscrollOptions"
            v-on:scrollStart="scrollStarted"
            v-on:scrollEnd="scrollEnded"
            v-on:flick="scrollEnded"
            ref="wheel"
            :data-tooth-number="currentToothNumber"
        >
            <div class="track" ref="track">
                <ul class="teeth">
                    <template v-for="wheelTooth in teeth">
                        <li
                            class="tooth"
                            :data-number="wheelTooth.number"
                            :data-jaw="wheelTooth.jaw"
                            :key="wheelTooth.number"
                            :class="{ active: wheelTooth.number == currentToothNumber }"
                            :style="{ height: toothHeight + 'px' }"
                        >
                            <tooth-visualization
                                v-for="projection in projections(wheelTooth)"
                                :key="projection"
                                :number="wheelTooth.number"
                                :projection="projection"
                                :context="visualizationContext"
                                :layers="visualizationLayers"
                                :draw-blank-images="false"
                                :surrounded-by-erupted-teeth="false"
                            />
                        </li>
                    </template>
                </ul>
            </div>
        </iscroll-view>
    </div>
</template>
<script>
import Vue from 'vue';

import IScrollView from 'vue-iscroll-view';
import IScroll from 'iscroll';

Vue.use(IScrollView, IScroll);

import ToothVisualization from '@/components/patient/tooth/visualization.vue';
import { JAWS, VISUALIZATION_CONTEXTS, PROJECTIONS } from '@/config/teeth.js';

export default  {
    components: {
        ToothVisualization
    },
    props: {
        tooth: { required: true }
    },
    data() {
        return {
            resizeHandlerBound: false,

            ownToothNumber: undefined,
            currentlyScrolling: false,
            currentlySnappingWheel: false,

            iscrollOptions: {
                deceleration: 0.01,
                mouseWheel: true,
                useTransition: false
            },

            viewportHeight: 0,
            toothHeight: undefined,

            visualizationContext: VISUALIZATION_CONTEXTS.TOOTH

        };
    },
    computed:
    {

        teeth() {
            return this.$store.getters['patient/teeth/relevantInWheelOrder'].filter(tooth => {
                return this.$store.getters['patient/teeth/state/isToothErupted'](tooth.number);
            });
        },

        propToothNumber() {
            return this.tooth.number;
        },

        currentToothNumber() {
            return (typeof this.ownToothNumber == 'undefined') ? this.propToothNumber : this.ownToothNumber;
        },

        visualizationLayers() {
            return this.$store.getters['chart/layers'];
        }
    },
    documentListeners: {
        'speed-wheel-scroll-to-tooth-number': 'handleSpeedWheelScrollToToothNumberRequest'
    },
    watch:
    {
        propToothNumber(newNumber) {
            if (!this.currentlyScrolling) {
                this.scrollToToothNumber(newNumber, 200);
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.initialize();

            if (!this.resizeHandlerBound) {
                window.addEventListener('resize', this.initialize);
                this.resizeHandlerBound = true;
            }
        });
    },
    beforeDestroy() {
        if (this.resizeHandlerBound) {
            window.removeEventListener('resize', this.initialize);
        }
    },

    methods:
    {
        initialize() {
            this.calculateDimensions();
            this.$nextTick(() => {
                // this is needed on next tick, so that the calculated toothHeight has been set in style
                // before the scroll wheel gets refreshed
                this.refreshWheel();
                this.scrollToToothNumber(this.currentToothNumber);
            });
        },

        projections(tooth) {
            if (tooth.jaw === JAWS.UPPER) {
                return [PROJECTIONS.BUCCAL, PROJECTIONS.INCISAL, PROJECTIONS.PALATAL];
            } else if (tooth.jaw === JAWS.LOWER) {
                return [PROJECTIONS.LINGUAL, PROJECTIONS.INCISAL, PROJECTIONS.BUCCAL];
            }
        },

        calculateDimensions() {
            if (this.$refs.container && this.$refs.track && this.teeth) {
                this.viewportHeight = this.$refs.container.clientHeight;
                this.toothHeight = this.viewportHeight;
            }
        },

        refreshWheel() {
            if (this.$refs.wheel.iscroll) {
                this.$refs.wheel.iscroll.refresh();
            }
        },

        scrollStarted() {
            this.currentlyScrolling = true;
        },
        scrollEnded() {
            // this gets called when user ends scrolling
            // or when a programmatic scrollTo ends if it has a non-zero delay.
            // this does NOT get called when using scrollTo with 0 delay
            if (this.currentlySnappingWheel) {
                this.currentlySnappingWheel = false;
                // only emit value after snapping has ended and the value is fixed
                this.emitChange();
            } else if (this.currentlyScrolling) {
                // scrolling ended, but the snap needs yet to be called
                this.currentlyScrolling = false;
                this.snapWheel();
            }
        },

        snapWheel() {
            this.currentlySnappingWheel = true;
            this.calculateOwnToothNumber();
            this.scrollToToothNumber(this.ownToothNumber, 100);
        },

        emitChange() {
            this.$emit('change', { toothNumber: this.ownToothNumber } );
        },

        calculateOwnToothNumber() {
            // calculate to which tooth is the wheel currently scrolled to.
            // if wheel position is not exact, calculate which tooth is the closest (by rounding)
            let position = Math.abs(Math.min(0, this.$refs.wheel.iscroll.y));
            let toothIndex = Math.round( position / this.toothHeight );

            if (this.ownToothNumber) {
                // if user has scrolled up or down just a bit (less than one full tooth height)
                // and is actually still at the same tooth when stopping,
                // then consider it a move to the next/previous tooth, even if the user has not scrolled
                // more than half of the height and the old tooth is still the closest.

                // this is so that even a small touch up or down already causes the navigation
                // and the user does not have to scroll for more than 50% of the screen height to get anywhere

                let previousIndex = this.getToothIndexFromToothNumber(this.ownToothNumber);
                let previousPosition = previousIndex * this.toothHeight;

                if (toothIndex === previousIndex && position != previousPosition) {
                    if (position < previousPosition) {
                        toothIndex--;
                    } else {
                        toothIndex++;
                    }
                }
            }

            if (toothIndex < 0) {
                toothIndex = 0;
            } else if (toothIndex >= this.teeth.length) {
                toothIndex = this.teeth.length - 1;
            }
            this.ownToothNumber = this.teeth[toothIndex].number;
        },

        getToothIndexFromToothNumber(toothNumber) {
            // returns sequential index in the tooth array of the tooth matching the given number
            let index = this.teeth.findIndex(tooth => tooth.number == toothNumber);
            if (index < 0) {
                index = 0;
            }
            return index;
        },

        scrollToToothNumber(toothNumber, delay = 0) {
            if (!this.$refs.wheel) {
                return;
            }

            let toothIndex = this.getToothIndexFromToothNumber(toothNumber);
            let targetOffset = (this.toothHeight * toothIndex) * -1;
            this.ownToothNumber = toothNumber;
            this.$refs.wheel.iscroll.scrollTo(0, targetOffset, delay);
        },

        handleSpeedWheelScrollToToothNumberRequest(event) {
            // this is used to programmatically scroll the speed wheel to a position from tests
            // where the dragging does not work and this.$refs.wheel is not available
            this.currentlyScrolling = true;
            // delay is mandatory, otherwise scrolEnded does not get triggered
            this.scrollToToothNumber(event.detail.toothNumber, 50);
        }

    }
};
</script>
