import Vue from 'vue';
import Vuex from 'vuex';

import filters from './modules/filters.js';
import general from './modules/general.js';
import time from './modules/time.js';
import api from './modules/api.js';
import i18n from './modules/i18n.js';
import authentication from './modules/authentication.js';
import patientList from './modules/patientList.js';
import patient from './modules/patient.js';
import navigation from './modules/navigation.js';
import chart from './modules/chart.js';
import images from './modules/images.js';
import audio from './modules/audio.js';
import settings from './modules/settings.js';
import externalUrls from './modules/externalUrls.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        filters,
        general,
        api,
        time,
        i18n,
        authentication,
        patientList,
        patient,
        navigation,
        chart,
        images,
        audio,
        settings,
        externalUrls
    }
});
