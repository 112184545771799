<template>
    <div data-view="welcome">
        <p class="actions">
            <router-link :to="{ name: 'get-started'}" class="button">{{ t( 'createNewAccount', 'startVC' ) }}</router-link>
        </p>

        <p class="actions">
            <router-link :to="{ name: 'sign-in'}" class="button">{{ t( 'signInWithEmail', 'startVC' ) }}</router-link>
        </p>

        <div id="appleid-signin" v-show="oauthInitialized" class="signin-button" data-color="black" data-border="true" data-type="sign in"></div>
    </div>
</template>

<script>

export default {
    data: () => {
        return {
            oauthInitialized: false,
        };
    },

    mounted() {
        let recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');
        document.head.appendChild(recaptchaScript);

        document.addEventListener('AppleIDSigInLoaded', this.appleSignInLoaded);
        document.addEventListener('AppleIDSignInOnSuccess', this.appleSignInOnSuccess);
    },

    beforeDestroy () {
        document.removeEventListener('AppleIDSigInLoaded', this.appleSignInLoaded);
        document.removeEventListener('AppleIDSignInOnSuccess', this.appleSignInOnSuccess);
    },

    methods: {
        appleSignInLoaded(){
            let self = this;

            this.$api.getOauthToken().then(data => {
                self.oauthInitialized = true;

                window.AppleID.auth.init({
                    clientId : data.data.appleSignIn.clientId,
                    scope : 'name email',
                    redirectURI : data.data.appleSignIn.url,
                    state : data.data.token,
                    nonce : data.data.token,
                    usePopup : true
                });

            });
        },

        appleSignInOnSuccess(data){
            const oauthDetails = data.detail.authorization;

            this.$api.createOauthAppleSignInSessionToken(oauthDetails.code, oauthDetails.state).then(response => {
                this.$route.params.nextUrl = "/patients";
                this.$store.dispatch('authentication/setSessionToken', response.data.session_token);
            });
        },
    }
};
</script>
