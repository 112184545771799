<template>
    <button class="toggle" type="button" :title="title" v-on:click="toggle">
        <transition name="fade">
            <svg-image
                v-if="isOpen"
                key="close"
                name="close"
                :title="title"
                width="21"
                height="21"
            />
            <svg-image
                v-else
                :key="icon"
                :name="icon"
                :title="title"
                :width="iconWidth"
                :height="iconHeight"
            />
        </transition>
    </button>
</template>
<script>
export default {
    props: {
        title: { required: true },
        isOpen: { required: true },
        icon: { required: true },
        iconWidth: { required: true },
        iconHeight: { required: true }
    },
    methods: {
        toggle() {
            this.$emit('toggle');
        }
    }
};
</script>
