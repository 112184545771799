import { PULP_TYPES, ROOT_CANAL_DIAGNOSTIC_MATRIX } from '@/config/root-canal.js';
import { ENDODONTIC_TESTS, ENDODONTIC_TEST_VALUES, COLD_TEST_SUBRESULTS } from '@/config/endodontic-tests.js';
import { ISSUE_TYPES, ISSUE_STATUSES, NOTATION_SYSTEMS } from '@/config/teeth.js';
import { WAITERS } from '@/config/waiters.js';

import uuid from 'uuid/v4';

const testRootCanalDiagnosis = (params, testableDiagnosis) => {
    // should return true or false depending on whether the state given in params matches the given diagnosis suggestion
    const diagnosisParamNames = Object.keys(testableDiagnosis);
    const defaultParamNames = [
        "toothType",
        "coldTestResult",
        "coldTestSubresult",
        "apicalPathology",
        "percussionTestResult",
        "existingRootCanalTreatment",
        "previouslyInitiatedRootCanalTherapy"
    ];
    // for default params, a diagnostic param matches if it either has the same value in params as in diagnosis
    // or if it is not mentioned in the diagnosis at all (accepts any value)
    const allDefaultParamsMatch = defaultParamNames.every(paramName => {
        return !diagnosisParamNames.includes(paramName) || testableDiagnosis[paramName] == params[paramName];
    });
    if (!allDefaultParamsMatch) {
        return false;
    }
    // electricity needs to be checked separately, because diagnosis may have multiple acceptable values
    // and the value in the definition is always an array
    if (diagnosisParamNames.includes("electricityTestResult")) {
        if (!testableDiagnosis.electricityTestResult.includes(params.electricityTestResult)) {
            return false;
        }
    }

    return true;
};


export default {
    namespaced: true,
    state: {
        suggestedDiagnoses: []
    },
    getters: {

        toothState: (state, getters, rootState, rootGetters) => (toothNumber) => {
            const toothState = rootGetters['patient/teeth/state/tooth'](toothNumber) || {};

            const result = {
                pulpType: toothState.root_chanel || PULP_TYPES.NONE,
                issueId: toothState.root_chanel_issue_id,
            };

            return result;
        },

        visualizationValues: (state, getters) => (toothNumber) => {
            const toothState = getters.toothState(toothNumber);
            return {
                pulpType: toothState.pulpType,
            };
        },

        diagnosticMatrix: () => {
            return ROOT_CANAL_DIAGNOSTIC_MATRIX;
        },

        calculateDiagnosis: (state, getters, rootState, rootGetters) => ({ toothNumber, params }) => {
            const diagnosis = getters.findDiagnosis({ params });

            if (diagnosis) {
                // always use ISO notation for diagnosis message and title.
                // it will be converted to USA notation on output, if needed
                const toothLabel = rootGetters['patient/teeth/toothLabelInGivenNotation'](toothNumber, NOTATION_SYSTEMS.ISO);

                const result = {
                    uuid: uuid(), // only used internally to refer to this suggestion. not sent to server
                    toothNumber,
                    pulpType: diagnosis.pulpType,
                    issueType: diagnosis.issueType,
                    title: rootGetters['i18n/t']('tooth', 'dental') + " " + toothLabel,
                    message: rootGetters['i18n/t'](diagnosis.translationKey, undefined, { values: [ toothLabel ] })
                };
                return result;
            }

            return null;
        },
        findDiagnosis: (state, getters) => ({ params }) => {
            // iterate through diagnostic matrix and return the first diagnosis that matches the given params
            return getters.diagnosticMatrix.find(diagnosis => testRootCanalDiagnosis(params, diagnosis));
        },
        diagnosisParams: (state, getters, rootState, rootGetters) => ({ toothNumber }) => {
            const coldTestSubresult = getters.endodonticResultString({
                toothNumber,
                testName: ENDODONTIC_TESTS.COLD,
                valueKey: 'subresult'
            });
            const electricityTestResult = rootGetters['patient/teeth/state/endodontic/test']({
                toothNumber,
                testName: ENDODONTIC_TESTS.ELECTRICITY,
                forceStateFromMainStore: true
            }).result;
            const params = {
                toothType: rootGetters['patient/teeth/tooth'](toothNumber).type,
                coldTestResult: getters.endodonticResultString({ toothNumber, testName: ENDODONTIC_TESTS.COLD, valueKey: 'result' }),
                coldTestSubresult,
                apicalPathology: rootGetters['patient/teeth/state/pathology/apical/presence'](toothNumber),
                percussionTestResult: getters.endodonticResultString({ toothNumber, testName: ENDODONTIC_TESTS.PERCUSSION, valueKey: 'result' }),
                electricityTestResult,
                existingRootCanalTreatment: coldTestSubresult == COLD_TEST_SUBRESULTS.EXISTING_ROOT_CANAL_TREATMENT,
                previouslyInitiatedRootCanalTherapy: coldTestSubresult == COLD_TEST_SUBRESULTS.PREVIOUSLY_INITIATED_THERAPY
            };
            return params;
        },
        endodonticResultString: (state, getters, rootState, rootGetters) => ({ toothNumber, testName, valueKey }) => {
            // returns test value as string, e.g. for cold test result 3 it returns "uncertain"

            // main store usage needs to be forced here,
            // so that editable state from endodontic module is not used.
            // this is important when clearing a test by passing a forced state,
            // because the editable state will not match the forced state at that moment
            const endodonticTest = rootGetters['patient/teeth/state/endodontic/test']({ toothNumber, testName, forceStateFromMainStore: true });
            const value = endodonticTest[valueKey];
            const values = ENDODONTIC_TEST_VALUES[testName];
            return Object.keys(values).find(key => values[key] === value);
        },
        diagnosisAlreadySuggested: (state) => ({ diagnosis }) => {
            // this is a safety measure against unforeseen scenarios with duplicate suggestions.
            // normally a new suggestion with the same message for the same tooth
            // should not arrive if another one is active (and therefore showing confirmation dialog)
            const result = state.suggestedDiagnoses.some(item => {
                return item.toothNumber == diagnosis.toothNumber && item.message == diagnosis.message;
            });
            return result;
        },
        diagnosisHasActiveIssueRecord: (state, getters, rootState, rootGetters) => ({ diagnosis }) => {
            // this checks whether the given diagnosis already has been approved
            // and has an active treatment plan issue record.
            // comparison is done by tooth number + message text
            const records = rootGetters['patient/treatmentPlan/orderedActiveItems'];
            const result = records.some(record => {
                return record.source_tooth_number == diagnosis.toothNumber && record.content == diagnosis.message;
            });
            return result;
        },
        generateNewIssueRecordId: () => () => {
            return uuid();
        },

        isCurrentRootCanalIssueRecord: (state, getters) => (issueRecord) => {
            return (getters.toothState(issueRecord.source_tooth_number).issueId === issueRecord.issue_record_id);
        },
        now: (state, getters, rootState, rootGetters) => () => {
            return rootGetters['time/currentUtcTimestamp'];
        }
    },
    mutations: {
        suggestDiagnosis(state, { diagnosis }) {
            state.suggestedDiagnoses.push(diagnosis);
        },
        clearSuggestedDiagnosis(state, { uuid }) {
            const index = state.suggestedDiagnoses.findIndex(diagnosis => diagnosis.uuid == uuid);
            if (index > -1) {
                state.suggestedDiagnoses.splice(index, 1);
            }
        }
    },
    actions: {

        // this should only be called after a single endo test performed from the single tooth view.
        // this should not get called after endo tests performed via quickselect
        updateAfterEndodonticTest(context, { toothNumber, testName, shouldAnalyze }) {
            const coldTestSubresult = context.getters.endodonticResultString({
                toothNumber,
                testName: ENDODONTIC_TESTS.COLD,
                valueKey: 'subresult'
            });
            const stateValues = {
                // looks like these two values could be always calculated live from actual endo cold test values,
                // but they need to be stored in the database for iOS compatibility,
                // because the iOS app uses them directly instead of checking via endodontic values
                root_chanel_treatment: coldTestSubresult == COLD_TEST_SUBRESULTS.EXISTING_ROOT_CANAL_TREATMENT,
                root_chanel_therapy: coldTestSubresult == COLD_TEST_SUBRESULTS.PREVIOUSLY_INITIATED_THERAPY
            };

            if (testName == ENDODONTIC_TESTS.COLD) {
                if (coldTestSubresult == COLD_TEST_SUBRESULTS.EXISTING_ROOT_CANAL_TREATMENT) {
                    // if cold test has just been manually set to not applicable -> existing root canal treatment,
                    // the pulp type should be set to treatment as well.
                    stateValues.root_chanel = PULP_TYPES.ROOT_CANAL_TREATMENT;

                } else if (context.getters.toothState(toothNumber).pulpType == PULP_TYPES.ROOT_CANAL_TREATMENT) {
                    // if cold test gets set to any other value, AND the pulp type is currently set to treatment,
                    // then the pulp type should be cleared.
                    // NOTE: pulp type should not be changed if cold test gets set to other value,
                    // and the pulp type is currently something else,
                    // because that would incorrectly affect other pulp states while doing unrelated cold tests
                    stateValues.root_chanel = PULP_TYPES.NONE;
                }
            }

            const updatePayload = { toothNumber, stateValues };

            const promise = context.dispatch('patient/teeth/state/updateToothState', updatePayload, { root: true } );
            if (shouldAnalyze) {
                return promise.then(() => { return context.dispatch('analyze', { toothNumber }); });
            } else {
                return promise;
            }
        },

        analyze(context, { toothNumber }) {
            const params = context.getters.diagnosisParams({ toothNumber });
            const diagnosis = context.getters.calculateDiagnosis({ toothNumber, params });

            if (
                diagnosis
                &&
                !context.getters.diagnosisAlreadySuggested({ diagnosis })
                &&
                !context.getters.diagnosisHasActiveIssueRecord({ diagnosis })
            ) {
                context.commit('suggestDiagnosis', { diagnosis });
            }
        },

        confirmDiagnosis(context, { uuid }) {
            const diagnosis = context.state.suggestedDiagnoses.find(diagnosis => diagnosis.uuid == uuid);
            if (!diagnosis) {
                return;
            }

            const waiterName = WAITERS.SAVING_ROOT_CANAL_DIAGNOSIS;

            context.dispatch('wait/start', waiterName, { root: true });
            const creatableRecords = [];

            // if an active treatment record already exists matching the current root canal issue id
            // then delete it and create a history record for it
            const currentState = context.getters.toothState(diagnosis.toothNumber);
            const currentIssueRecord = context.rootGetters['patient/treatmentPlan/activeItemById'](currentState.issueId);

            if (currentIssueRecord) {
                const newHistoryRecord = {
                    type: ISSUE_TYPES.HISTORY,
                    message: currentIssueRecord.content,
                    toothNumber: diagnosis.toothNumber
                };
                creatableRecords.push(newHistoryRecord);
            }


            const newIssueRecord = {
                type: diagnosis.issueType,
                status: ISSUE_STATUSES.ACTIVE,
                message: diagnosis.message,
                toothNumber: diagnosis.toothNumber,
                id: context.getters.generateNewIssueRecordId()
            };
            creatableRecords.push(newIssueRecord);

            const newRootCanalState = {
                root_chanel: diagnosis.pulpType,
                root_chanel_issue_id: newIssueRecord.id,
            };

            const updatePayload = {
                toothNumber: diagnosis.toothNumber,
                stateValues: newRootCanalState,
                waiterName: undefined,
                creatableRecords
            };

            const tasks = [];
            if (currentIssueRecord) {
                tasks.push( context.dispatch('patient/treatmentPlan/deleteRecord', currentIssueRecord.issue_record_id, { root: true }) );
            }
            tasks.push( context.dispatch('patient/teeth/state/updateToothState', updatePayload, { root: true }) );

            return Promise.all(tasks).then(() => {
                context.commit('clearSuggestedDiagnosis', { uuid });
            }).finally(() => {
                context.dispatch('wait/end', waiterName, { root: true });
            });
        },

        rejectDiagnosis(context, { uuid }) {
            context.commit('clearSuggestedDiagnosis', { uuid });
        },


        updateAfterTreatment(context, { toothNumber, resolvedIssue }) {
            if (!context.getters.isCurrentRootCanalIssueRecord(resolvedIssue)) {
                return Promise.resolve();
            }

            // after resolving a root canal treatment issue,
            // some tooth state values need to be automatically updated

            const issueIsUrgent = resolvedIssue.type == ISSUE_TYPES.URGENT;

            let newPulpType;
            if (issueIsUrgent) {
                // urgent issues always change pulp to treated
                newPulpType = PULP_TYPES.ROOT_CANAL_TREATMENT;
            } else {
                // monitored issues retain pulp state if it is treated
                // and reset the pulp to none if it was anything else
                const currentPulpType = context.rootGetters['patient/teeth/state/rootCanal/toothState'](toothNumber).pulpType;
                if (currentPulpType == PULP_TYPES.ROOT_CANAL_TREATMENT) {
                    newPulpType = PULP_TYPES.ROOT_CANAL_TREATMENT;
                } else {
                    newPulpType = PULP_TYPES.NONE;
                }
            }

            const stateValues = {
                root_chanel: newPulpType,

                // ios does not clear this uuid after the issue gets resolved,
                // but it does not seem to be used after that, so let's clear it
                root_chanel_issue_id: null,

                // needed for ios. not used in web-app
                // these depend on endodontic cold test which will be set below via endodontic module,
                // so these can be set preemptively in order to avoid having to do another callback afterwards
                root_chanel_treatment: true,
                root_chanel_therapy: false
            };

            const updatePayload = { toothNumber, stateValues };

            return context.dispatch('patient/teeth/state/updateToothState', updatePayload, { root: true } ).then(() => {
                // urgent issues also reset the values of endodontic tests. monitoring issues do not.
                if (issueIsUrgent) {
                    return context.dispatch('patient/teeth/state/endodontic/updateStateAfterRootCanalTreatment', { toothNumber }, { root: true } );
                } else {
                    return Promise.resolve();
                }
            });
        }

    }
};
