import freeze from '@/utils/freeze.js';

const MONTH_KEYS = freeze({
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December"
});

export default {
    namespaced: true,

    state: {
        localeNames: {
            "pt": "Portugués",
            "es": "Español",
            "it": "Italiano",
            "fr": "Français",
            "de": "Deutsch",
            "en": "English",
            "lv": "Latviski",
        },
        defaultLocale: "en",
        locale: undefined,
        defaultScope: undefined,
        texts: {},
        englishTexts: {}
    },

    getters: {
        locales: (state) => {
            return Object.keys(state.localeNames);
        },
        browserLocale: (state, getters) => {
            const browserLocales = [
                ...(window.navigator.languages || []),
                window.navigator.language,
                window.navigator.browserLanguage,
                window.navigator.userLanguage,
                window.navigator.systemLanguage
            ].filter(Boolean).map(locale => locale.substr(0, 2));

            return browserLocales.find(locale => getters.locales.includes(locale));
        },
        t: (state, getters) => (key, scope, options) => {

            let value = getters['text'](key, scope, options);
            if (value == undefined || value == "") {
                const locale = state.locale;

                const params = { locale, key, scope, options };
                if (value == undefined) {
                    console.error('Missing i18n key ' + JSON.stringify(params));
                }

                // fall back to English text, if it is present
                if (locale != "en") {
                    value = getters['text'](key, scope, options, true);
                    if (value != undefined && value != "") {
                        return value;
                    }
                }

                return key;
            } else {
                return value;
            }
        },
        text: (state) => (key, scope, options, useEnglishFallback) => {
            if (options == undefined) { options = {}; }

            if (scope == undefined) {
                // blank scope means use default
                scope = state.defaultScope;
            } else if (scope.charAt(0) == ".") {
                // scopes starting with "." means scopes relative to the default
                if (state.defaultScope != undefined) {
                    scope = state.defaultScope + scope;
                } else {
                    scope = scope.substring(1);
                }
            }
            var keyLevels = (scope == undefined) ? [] : [scope];
            keyLevels.push(key);

            var fullKey = keyLevels.join(".");

            var text = (useEnglishFallback) ? state.englishTexts[fullKey] : state.texts[fullKey];

            // variable replacement
            if (text != undefined && text.indexOf('%@') != -1) {
                var values = options.values || [];
                var placeHolderRegexp = new RegExp('%@');
                values.forEach((value) => {
                    text = text.replace(placeHolderRegexp, value);
                });
            }
            return text;
        },
        formatDate: (state, getters) => (isoDateString) => {
            let year, month, date;
            [year, month, date] = (isoDateString) ? (isoDateString.match(/^(\d{4})-(\d{2})-(\d{2})$/) || []).slice(1) : [];
            if (!year || !Object.keys(MONTH_KEYS).includes(month)) {
                return isoDateString;
            }
            return getters.t(MONTH_KEYS[month], 'date.months') + ' ' + date.replace(/^0/, '') + ', ' + year;
        }
    },

    mutations: {
        setLocale(state, locale) {
            if (!Object.keys(state.localeNames).includes(locale)) {
                locale = state.defaultLocale;
            }
            this.$api.setLocale(locale);
            if (state.locale !== locale) {
                state.texts = require('@/lang/' + locale + '.json');
                state.englishTexts = require('@/lang/en.json');
                state.locale = locale;
                localStorage.setItem('locale', locale);
                this.dispatch('audio/setLocale', locale);
            }
        },
        setDefaultScope(state, scope) {
            state.defaultScope = scope;
        },
        setText(state, options) {
            let target = state.texts;
            options.scope.split(".").forEach((scopePart) => {
                target = target[scopePart];
            });
            target[options.key] = options.value;
        }
    },

    actions: {
        loadDefaultLocale(context, locale) {
            if (!context.state.locale) {
                let defaultLocale = locale || localStorage.getItem('locale') || context.getters.browserLocale;
                context.commit('setLocale', defaultLocale);
            }
        },
        setLocale(context, locale) {
            context.commit('setLocale', locale);
        }
    }
};
