<template>
    <button type="button" class="audio" :class="{ active: isAudioEnabled }" v-on:click="toggleAudio">
        <svg-image
            :name="imageName"
            :title="imageTitle"
            width="21"
            height="21"
        />
    </button>
</template>

<script>
export default {
    data: () => {
        return {

        };
    },
    computed: {
        isAudioEnabled() {
            return this.$store.state.patient.teeth.state.periodontal.audioEnabled;
        },
        imageTitle() {
            const key = (this.isAudioEnabled) ? 'Turn audio off' : 'Turn audio on';
            return this.t(key, 'periodontalProbing');
        },
        imageName() {
            return (this.isAudioEnabled) ? 'audio-on' : 'audio-off';
        }
    },
    methods: {
        toggleAudio() {
            this.$store.dispatch('patient/teeth/state/periodontal/toggleAudio');
        }
    }
};
</script>
