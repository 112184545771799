<template>
    <section data-view="data-processing-agreement">

        <template v-if="agreementIsOpen">
            <modal-dialog type="legal" v-on:open="focusActions" v-on:cancel="closeAgreement" :close-on-outside-click="false">

                <header>
                    <h1>{{ t('title', 'terms.patient') }}</h1>
                </header>

                <div class="content">
                    <waiting-overlay v-if="$wait.is(loadingWaiterName)" />
                    <div v-else class="terms" v-html="termsHtml" />
                </div>

                <form class="actions" v-on:submit.prevent="accept" ref="actions">

                    <button class="button link cancel" type="button" v-on:click="closeAgreement">
                        {{ t('cancel', 'common') }}
                    </button>

                    <waiting-button :for="acceptanceWaiterName" type="submit" class="button" v-if="termsLoaded">
                        {{ t('agree', 'terms.patient') }}
                    </waiting-button>

                </form>

            </modal-dialog>

        </template>
        <template v-else>
            <div class="warning">
                <p>
                    {{ t('warning', 'terms.patient') }}
                </p>
                <button type="button" class="button danger" v-on:click="openAgreement">
                    {{ t('view', 'terms.patient') }}
                </button>
            </div>
        </template>
    </section>
</template>
<script>
import ModalDialog from '@/components/modal-dialog.vue';
import WaitingOverlay from '@/components/waiting-overlay';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { WaitingOverlay, ModalDialog },
    data: () => {
        return {
            agreementIsOpen: false
        };
    },
    computed: {
        termsHtml() {
            return this.$store.state.patient.dataProcessingAgreementHtml;
        },
        termsLoaded() {
            return this.$store.state.patient.dataProcessingAgreementLoaded;
        }
    },

    created() {
        this.loadingWaiterName = WAITERS.LOADING_PATIENT_DATA_PROCESSING_AGREEMENT;
        this.acceptanceWaiterName = WAITERS.ACCEPTING_PATIENT_DATA_PROCESSING_AGREEMENT;
    },

    methods: {
        openAgreement() {
            this.agreementIsOpen = true;
            if (!this.termsLoaded) {
                this.loadTerms();
            }
        },

        closeAgreement() {
            this.agreementIsOpen = false;
        },

        focusActions() {
            this.$refs.actions.focus();
        },

        loadTerms() {
            this.$wait.start(this.loadingWaiterName);

            this.$api.getPatientDataProcessingAgreement(this.$store.state.patient.id).then(response => {
                return this.$store.dispatch('patient/setDataProcessingAgreementHtml', response.data.text);
            }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.loadingWaiterName));
        },

        accept() {
            this.$wait.start(this.acceptanceWaiterName);
            const patientId = this.$store.state.patient.id;
            this.$api.acceptPatientDataProcessingAgreement(patientId).then(() => {
                if (this.$store.state.patient.id == patientId) {
                    // make sure the patient is still the same
                    return this.$store.dispatch('patient/markDataProcessingAgreementAsAccepted');
                }
            }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.acceptanceWaiterName));
        }
    }

};
</script>
