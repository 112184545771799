import ModalDialog from '@/components/modal-dialog.vue';
import RollbackEditingNote from '@/components/patient/rollback/editing-note.vue';
export default {
  __name: 'rollback-warning',
  setup: function setup(__props) {
    var store = useStore();
    var close = function close() {
      store.dispatch('patient/teeth/state/closeRollbackWarning');
    };
    return {
      __sfc: true,
      store: store,
      close: close,
      ModalDialog: ModalDialog,
      RollbackEditingNote: RollbackEditingNote
    };
  }
};