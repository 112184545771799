<template>
    <div data-view="reset-password">
        <div class="confirmation-email">
            <back-button />

            <template v-if="showPasswordResetForm">
                <form class="panel" novalidate="novalidate" v-on:submit.prevent="submitPasswordReset">
                    <h2 class="heading">
                        {{ t('changePasswordTitle', 'passwordResetVC') }}
                    </h2>

                    <input-field
                        ref="passwordInput"
                        name="password"
                        type="password"
                        v-model="password"
                        :label="t('password', 'getStartedVC')"
                        :errors="errorMessages.password"
                    />

                    <input-field
                        name="password_confirmation"
                        type="password"
                        v-model="passwordConfirmation"
                        :label="t('passwordConfirmation', 'getStartedVC')"
                        :errors="errorMessages.password_confirmation"
                    />

                    <p class="actions">
                        <waiting-button :for="resettingWaiterName" type="submit" class="button">
                            {{ t('change', 'passwordResetVC') }}
                        </waiting-button>

                        <errors v-if="hasOtherErrors" :errors="otherErrors" />
                    </p>
                </form>
            </template>

            <template v-if="showResetEmailForm">
                <form class="panel" novalidate="novalidate" v-on:submit.prevent="submitResetEmail">
                    <h2 class="heading">
                        {{ t('sendResetEmailTitle', 'passwordResetVC') }}
                    </h2>

                    <input-field
                        ref="emailInput"
                        name="email"
                        type="email"
                        v-model="email"
                        :label="t('email', 'passwordResetVC')"
                        :errors="errorMessages.email"
                    />

                    <p class="actions">
                        <waiting-button :for="sendingEmailWaiterName" type="submit" class="button">
                            {{ t('next', 'passwordResetVC') }}
                        </waiting-button>

                        <errors v-if="hasOtherErrors" :errors="otherErrors" />
                    </p>

                </form>
            </template>

            <template v-if="showResetEmailSuccess">
                <div class="panel">
                    <h2 class="heading">{{ t('title', 'passwordResetVC.finish') }}</h2>

                    <p class="text" v-html="t('text', 'passwordResetVC.finish')" />
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import Errors from '@/components/fields/errors.vue';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { Errors },

    data: () => {
        return {
            email: "",
            password: "",
            passwordConfirmation: "",
            resetEmailSent: false,
            passwordResetToken: null,
            errorMessages: {},
        };
    },

    computed: {
        showPasswordResetForm() { return !!this.passwordResetToken; },
        showResetEmailForm() { return !this.passwordResetToken && !this.resetEmailSent; },
        showResetEmailSuccess() { return this.resetEmailSent; },
        otherErrors() {
            let errors = [];

            for (const [key, value] of Object.entries(this.errorMessages)) {
                if (this[key] === undefined) {
                    errors.push(...value);
                }
            }

            return errors;
        },
        hasOtherErrors() { return this.otherErrors.length > 0; },
    },

    mounted() {
        if (this.showPasswordResetForm) {
            this.$refs.passwordInput.focus();
        } else {
            this.$refs.emailInput.focus();
        }
    },

    created() {
        this.resettingWaiterName = WAITERS.RESETTING_PASSWORD;
        this.sendingEmailWaiterName = WAITERS.SENDING_CONFIRMATION_EMAIL;
        if (this.$route.params.token) {
            this.passwordResetToken = this.$route.params.token;
        }
    },

    methods: {
        submitPasswordReset() {
            this.errorMessages = {};

            if (this.password) {
                this.$wait.start(this.resettingWaiterName);
                this.$api.resetPassword(this.passwordResetToken, this.password, this.passwordConfirmation).then((result) => {
                    const signInParameters = { email: result.data.email, password: this.password };
                    this.$store.dispatch('authentication/signIn', signInParameters).catch(error => {
                        throw error;
                    });

                }).catch((error) => {
                    if (this.hasErrorCode(error, 422)) {
                        this.errorMessages = error.response.data.errors;
                    } else {
                        throw error;
                    }
                }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.resettingWaiterName));

            } else {
                this.$refs.passwordInput.focus();
            }
        },

        submitResetEmail() {
            this.errorMessages = {};

            if (this.email) {
                this.$wait.start(this.sendingEmailWaiterName);
                this.$api.sendPasswordResetEmail(this.email).then(() => {
                    this.resetEmailSent = true;
                }).catch((error) => {
                    if (this.hasErrorCode(error, 422)) {
                        this.errorMessages = error.response.data.errors;
                    } else {
                        throw error;
                    }
                }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.sendingEmailWaiterName));

            } else {
                this.$refs.emailInput.focus();
            }
        },

        hasErrorCode(error, errorCode) {
            return (error && error.response  && error.response.status == errorCode);
        },
    }
};
</script>
