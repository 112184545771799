import freeze from '@/utils/freeze.js';

// this is not an exhaustive list.
// there are also some waiters whose names are constructed dynamically,
// either by appending string values to a name from this list
// or by building the name completely from scratch.

const WAITERS = freeze({
    SIGNING_IN: 'signing in',
    SIGNING_OUT: 'signing out',

    RESETTING_PASSWORD: 'resetting password',
    SENDING_CONFIRMATION_EMAIL: 'sending confirmation email',

    LOADING_TERMS: 'loading terms',
    ACCEPTING_TERMS: 'accepting terms',

    SAVING_PROFILE: 'saving profile',

    PROCESSING_BILLING_OPERATION: 'processing billing operation',
    REDIRECTING_TO_SUBSCRIPTION: 'redirecting to subscription',



    SEARCHING_PATIENTS: 'searching patients',
    LOADING_PATIENTS: 'loading patients',

    SAVING_PATIENT: 'saving patient',
    DELETING_PATIENT: 'deleting patient',
    LOADING_PATIENT_DATA_PROCESSING_AGREEMENT: 'loading patient data processing agreement',
    ACCEPTING_PATIENT_DATA_PROCESSING_AGREEMENT: 'accepting patient data processing agreement',



    SAVING_ORAL_HEALTH_ISSUES: 'saving oral health issues',
    SAVING_BASIC_PERIODONTAL_EXAMINATION_RECORDS: 'saving basic periodontal examination records',
    SAVING_MEDICAL_ISSUES: 'saving medical issues',
    SAVING_SOFT_TISSUE_RECORDS: 'saving soft tissue records',

    SAVING_QUICKSELECT_ACTION: 'saving quickselect action',

    RESETTING_TOOTH: 'resetting tooth',
    MARKING_TOOTH_AS_TO_BE_EXTRACTED: 'marking tooth as to be extracted',
    MARKING_TOOTH_AS_MISSING: 'marking tooth as missing',
    RESTORING_TOOTH: 'restoring tooth',
    UPDATING_TOOTH_ERUPTION_STATE: 'updating tooth eruption state',

    ADDING_HISTORY_RECORD: 'adding history record',
    DELETING_HISTORY_RECORD: 'deleting history record',

    ADDING_TREATMENT_PLAN_RECORD: 'adding treatment plan record',
    RESOLVING_TREATMENT_PLAN_RECORD: 'resolving treatment plan record',
    DELETING_TREATMENT_PLAN_RECORD: 'deleting treatment plan record',

    SAVING_PERIODONTAL_STATE: 'saving periodontal state',
    SAVING_ENDODONTIC_STATE: 'saving endodontic state',

    SAVING_ROOT_CANAL_DIAGNOSIS: 'saving root canal diagnosis',

    GENERATING_PDF: 'generating pdf',

});

export {
    WAITERS
};