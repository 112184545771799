import merge from 'lodash/merge';
import ItemStoreModule from '../../lib/itemStoreModule.js';

export default merge({}, ItemStoreModule, {

    getters: {
        orderedItems: (state, getters) => {
            return state.items.concat().sort((a, b) => {
                // sort in reverse order by creation time first
                // and then in ascending order by order in the original item array
                // (the array initially arrives sorted in descending order already, but may have new entries added)
                let result = b.created_at.localeCompare(a.created_at);

                if (result === 0) {
                    result = getters.itemIndex(a) - getters.itemIndex(b);
                }
                return result;
            });
        },

        recordMessage: (state, getters, rootState, rootGetters) => (record) => {
            return rootGetters['patient/teeth/convertToothLabelToCurrentNotation']({
                message: record.content,
                toothNumber: record.source_tooth_number
            });
        }
    }

});
