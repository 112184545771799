import freeze from '@/utils/freeze.js';
import clone from '@/utils/clone.js';

const ENDODONTIC_TESTS = freeze({
    COLD: "cold",
    PERCUSSION: "percussion",
    PALPATION: "palpation",
    HEAT: "heat",
    ELECTRICITY: "electricity"
});

const COLD_TEST_RESULTS = freeze({
    POSITIVE: "positive",
    UNCERTAIN: "uncertain",
    NEGATIVE: "negative",
    NOT_APPLICABLE: "not-applicable"
});

const HEAT_TEST_RESULTS = freeze(clone(COLD_TEST_RESULTS));

const HEAT_TEST_SUBRESULTS = freeze({
    WITHIN_LIMITS: "within-limits",
    UNPLEASANT: "unpleasant",
    PAIN_STIMULUS: "pain-stimulus",
    PAIN_LINGERING: "pain-lingering",
});

let coldTestSubresults = clone(HEAT_TEST_SUBRESULTS);
coldTestSubresults.EXISTING_ROOT_CANAL_TREATMENT = "existing-root-canal-treatment";
coldTestSubresults.PREVIOUSLY_INITIATED_THERAPY =  "previously-initiated-therapy";

const COLD_TEST_SUBRESULTS = freeze(coldTestSubresults);


const PERCUSSION_TEST_RESULTS = freeze({
    NOT_PAINFUL: "no-painful",
    UNPLEASANT: "unpleasant",
    PAINFUL: "painful"
});

const PALPATION_TEST_RESULTS = freeze(clone(PERCUSSION_TEST_RESULTS));


const ELECTRICITY_TEST_RESULTS = freeze({
    E_1: 1,
    E_2: 2,
    E_3: 3,
    E_4: 4,
    E_5: 5,
    E_6: 6,
    E_7: 7,
    E_8: 8,
    E_9: 9,
    E_10: 10
});

const COLD_HEAT_TEST_VALUES = freeze({
    [COLD_TEST_RESULTS.POSITIVE]: 1,
    [COLD_TEST_SUBRESULTS.WITHIN_LIMITS]: 2,
    [COLD_TEST_RESULTS.UNCERTAIN]: 3,
    [COLD_TEST_SUBRESULTS.UNPLEASANT]: 4,
    [COLD_TEST_RESULTS.NEGATIVE]: 5,
    [COLD_TEST_SUBRESULTS.PAIN_STIMULUS]: 6,
    [COLD_TEST_RESULTS.NOT_APPLICABLE]: 7,
    [COLD_TEST_SUBRESULTS.PAIN_LINGERING]: 8,
    [COLD_TEST_SUBRESULTS.EXISTING_ROOT_CANAL_TREATMENT]: 9,
    [COLD_TEST_SUBRESULTS.PREVIOUSLY_INITIATED_THERAPY]: 10
});

const PERCUSSION_PALPATION_TEST_VALUES = freeze({
    [PERCUSSION_TEST_RESULTS.NOT_PAINFUL]: 1,
    [PERCUSSION_TEST_RESULTS.UNPLEASANT]: 2,
    [PERCUSSION_TEST_RESULTS.PAINFUL]: 3
});

const ENDODONTIC_TEST_VALUES = freeze({
    [ENDODONTIC_TESTS.COLD]: COLD_HEAT_TEST_VALUES,
    [ENDODONTIC_TESTS.HEAT]: COLD_HEAT_TEST_VALUES,
    [ENDODONTIC_TESTS.PERCUSSION]: PERCUSSION_PALPATION_TEST_VALUES,
    [ENDODONTIC_TESTS.PALPATION]: PERCUSSION_PALPATION_TEST_VALUES
});


export {
    ENDODONTIC_TESTS,

    COLD_TEST_RESULTS,
    COLD_TEST_SUBRESULTS,

    HEAT_TEST_RESULTS,
    HEAT_TEST_SUBRESULTS,

    PERCUSSION_TEST_RESULTS,
    PALPATION_TEST_RESULTS,

    ELECTRICITY_TEST_RESULTS,

    COLD_HEAT_TEST_VALUES,
    PERCUSSION_PALPATION_TEST_VALUES,

    ENDODONTIC_TEST_VALUES
};
