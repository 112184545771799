import store from '@/store/store.js';

import Patient from '@/views/patient.vue';

import ChartRoutes from '@/routes/patient/chart.js';
import DashboardRoutes from '@/routes/patient/dashboard.js';
import ToothRoutes from '@/routes/patient/tooth.js';

import Report from '@/views/patient/report.vue';
import ReportPrint from '@/views/patient/report/print.vue';

import { THEMES } from '@/config/app.js';

export default [{
    path: '/patients/:patient_id',
    name: 'patient',
    redirect: '/patients/:patient_id/dashboard',
    component: Patient,
    meta: {
        transition: { sequenceNumber: 4, from: 'right' },
        title: () => store.getters['patient/fullName']
    },
    children: [
        ChartRoutes,
        DashboardRoutes,
        ToothRoutes,
        [{
            path: 'report',
            name: 'report',
            component: Report,
            meta: { back: { name: 'dashboard' }, title: 'Report', theme: THEMES.LIGHT },
        },
        {
            path: 'report/print/:date',
            name: 'print',
            component: ReportPrint,
            meta: {
                showPatientSidebar: false,
                restrictViewport: false,
                transition: false,
                theme: THEMES.LIGHT,
                title: () => [
                    store.getters['i18n/t']('Patient report', 'pdf')
                ].join(" | ")
            }
        },
        ]
    ].flat()
}];
