<template>
    <transition :name="transitionName" :mode="transitionMode">
        <slot />
    </transition>
</template>
<script>
export default {
    props: {
        level: { required: true }
    },
    data: () => {
        return {
            transitionName: null,
            transitionMode: null
        };
    },
    computed:
    {

    },
    watch:
    {
        '$route'(to, from) {
            if (from.name == "loading" || to.name == "loading") {
                // loading screen always uses a quick fade
                this.transitionName = 'fade';
                this.transitionMode = 'out-in';
            } else {

                var fromRoute = from.matched[this.level];
                var toRoute = to.matched[this.level];

                var fromTransition = (fromRoute && fromRoute.meta) ? fromRoute.meta.transition : undefined;
                var toTransition = (toRoute && toRoute.meta) ? toRoute.meta.transition : undefined;

                var transitionName;
                var transitionDirection;

                if (fromTransition && toTransition) {
                    var fromNumber = fromTransition.sequenceNumber;
                    var toNumber   = toTransition.sequenceNumber;


                    // always take the transition type from the next route in sequence
                    if (fromNumber && toNumber) {
                        var origin;

                        if (toNumber > fromNumber) {
                            transitionDirection = 'forward';
                            origin = toTransition.from;
                        } else if (toNumber < fromNumber) {
                            transitionDirection = 'backward';
                            origin = fromTransition.from;
                        }

                        if (origin == "right") { // next block comes in from the right
                            transitionName = 'horizontal-page-slide';
                        }
                    }
                }

                if (transitionName && transitionDirection) {
                    this.transitionName = transitionName + "-" + transitionDirection;
                    this.transitionMode = '';
                } else {
                    // transition type unknown
                    // or direction unknown (maybe same number, or number unspecified)
                    // apply no transition
                    this.transitionName = '';
                    this.transitionMode = '';
                }
            }
        }
    },
    created() {
    },
    methods:
    {
    }
};
</script>
