<template>
    <div class="mode-selector">
        <ul class="menu">
            <navigation-link
                v-for="mode in modes"
                :key="mode"
                tag="li"
                :to="route(mode)"
                replace
                :exact="overviewMode(mode)"
            >
                {{ label(mode) }}
            </navigation-link>
        </ul>

    </div>
</template>
<script>
import { CHART_MODES } from '@/config/teeth.js';

export default {
    mixins: [ ],
    props: {
        current: { required: true },
    },
    data() {
        return {

        };
    },

    computed:
    {
        routeName() {
            return this.$route.name;
        },
        modes() {
            return this.$store.getters['chart/selectableModes'];
        }
    },

    watch: {
    },
    methods:
    {
        label(mode) {
            return this.t(this.$options.filters.camelize(mode), 'dentalChartVC.examination');
        },

        route(mode) {
            return this.chartRoute(mode, this.$route.meta['mouth-view'], { tooth_slug: undefined, zone_name: undefined });
        },

        overviewMode(mode) {
            return mode == CHART_MODES.OVERVIEW;
        }
    },

};
</script>
