import Account from '@/views/account.vue';
import AccountWelcome from '@/views/account/welcome.vue';
import AccountGetStarted from '@/views/account/get-started.vue';
import AccountSignIn from '@/views/account/sign-in.vue';
import AccountResetPassword from '@/views/account/reset-password.vue';
import AccountTokenSignIn from '@/views/account/token-sign-in.vue';

export default [{
    path: '/auth/',
    redirect: 'welcome',
    component: Account,
    meta: { allowAnonymousUser: true, allowAuthenticatedUser: false, transition: { sequenceNumber: 1 }, title: false },
    children: [
        {
            path: '/welcome',
            name: 'welcome',
            component: AccountWelcome,
            meta: {
                allowAnonymousUser: true,
                allowAuthenticatedUser: false,
                title: false
            }
        },

        {
            path: '/sign-in',
            name: 'sign-in',
            component: AccountSignIn,
            meta: {
                allowAnonymousUser: true,
                allowAuthenticatedUser: false,
                back: { name: 'welcome' },
                title: 'Sign in'
            }
        },

        {
            path: '/get-started',
            name: 'get-started',
            component: AccountGetStarted,
            meta: {
                allowAnonymousUser: true,
                allowAuthenticatedUser: false,
                back: { name: 'welcome' },
                title: 'Get started'
            }
        },

        {
            path: '/reset-password/:token?',
            name: 'reset-password',
            component: AccountResetPassword,
            meta: {
                allowAnonymousUser: true,
                allowAuthenticatedUser: false,
                back: { name: 'sign-in' },
                logo: false,
                title: 'Reset password'
            }
        },


        {
            path: '/session/:sign_in_token',
            name: 'token-sign-in',
            component: AccountTokenSignIn,
            meta: {
                useExistingSession: false,
                allowAnonymousUser: true,
                allowAuthenticatedUser: true,
                logo: false,
                title: false,
            }

        },

    ]

}];
