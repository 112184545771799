<template>
    <span class="errors">
        <span class="error" v-for="message in errors" :key="message">
            {{ message }}
        </span>
    </span>
</template>

<script>
export default {
    props: {
        errors: Array
    }
};
</script>
