import merge from 'lodash/merge';
import PathologyType from '../pathologyType.js';
import { PATHOLOGIES } from '@/config/teeth.js';

export default merge({}, PathologyType, {

    state: {
        name: PATHOLOGIES.DISCOLORATION
    },

    getters: {
        calculateSurfaces: () => () => {
            // discoloration surfaces are not stored and are always empty
            // the visualization code in drawing.vue collects all surfaces of the affected tooth for displaying,
            // depending on whether a type (color) is selected
            return [];
        },

    },

    mutations: {

    },

    actions: {

    }

});
