<template>
    <header>
        <div class="profile">
            <p class="name">
                {{ userFullName }}
            </p>
            <p class="actions">
                <button type="button" v-on:click="toggleTools">
                    <svg-image
                        name="tools"
                        :title="t('Tools', 'mainVC')"
                        width="23"
                        height="24"
                    />
                </button>
            </p>
        </div>

        <transition name="sidebar-slide">
            <user-tools v-if="toolsActivated" v-on:close="deactivateTools" />
        </transition>

        <nav class="main">
            <ul>
                <!--
                <navigation-link tag="li" :to="{ name: 'schedule' }" replace>{{ t('schedule', 'mainVC.tabs') }}</navigation-link>
                -->
                <navigation-link tag="li" :to="patientsRoute" replace :class="{'router-link-exact-active': patientsRouteActive}">{{ t('patients', 'mainVC.tabs') }}</navigation-link>
            </ul>
        </nav>
    </header>
</template>

<script>
import UserTools from '@/components/user/tools.vue';
export default {
    components: { UserTools },
    data() {
        return {
            toolsActivated: false
        };
    },
    computed: {
        userFullName() {
            return this.$store.getters['authentication/userFullName'];
        },
        patientsRouteActive() {
            return this.$route.name == "patients";
        },
        patientsRoute() {
            if (this.patientsRouteActive) {
                return { name: "patients" };
            } else {
                return this.$store.getters['navigation/patientsRoute'];
            }
        }
    },
    methods: {
        toggleTools() {
            this.toolsActivated = !this.toolsActivated;
        },
        deactivateTools() {
            this.toolsActivated = false;
        }
    }
};
</script>
