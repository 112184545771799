import Vue from 'vue';

import { TOOTH_STATES, ISSUE_TYPES, ERUPTION_STATES } from '@/config/teeth.js';
import { WAITERS } from '@/config/waiters.js';

import pathology from './state/pathology.js';
import restoration from './state/restoration.js';
import endodontic from './state/endodontic.js';
import periodontal from './state/periodontal.js';
import rootCanal from './state/rootCanal.js';

import { transformTeethStateKeys, TEETH_STATE_TRANSFORMATIONS } from '@/utils/teethStateTransformation.js';

const blankState = {
    teethState: {},

    teethStateDate: undefined,
    teethStateIsModifiable: undefined,
    teethStateIsModified: undefined,
    modifiedCurrentTeethState: undefined,

    history: {
        todaysDate: undefined,
        dates: [],
        states: {}
    },
    shouldShowRollbackWarning: false,
};

export default {

    namespaced: true,

    modules: {
        pathology,
        restoration,
        endodontic,
        periodontal,
        rootCanal
    },

    state: {
        teethState: blankState.teethState,

        teethStateDate: blankState.teethStateDate,
        teethStateIsModifiable: blankState.teethStateIsModifiable,
        teethStateIsModified: blankState.teethStateIsModified,
        modifiedCurrentTeethState: blankState.modifiedCurrentTeethState,

        history: {
            todaysDate: blankState.history.todaysDate,
            dates: blankState.history.dates,
            states: blankState.history.states
        },
        shouldShowRollbackWarning: false,

        developmentOverrides: {}
    },

    getters: {
        numbers: (state, getters, rootState, rootGetters) => rootGetters['patient/teeth/numbers'],

        tooth: (state, getters) => (number) => {
            return (getters.numbers.includes(number)) ? state.teethState[number] : undefined;
        },

        toothState: (state, getters, rootState) => (number) => {
            // if tooth is being edited in restoration,
            // then the tooth's state should be taken from the live editing store
            if (rootState.patient.teeth.state.restoration.editableTooth.number === number) {
                return rootState.patient.teeth.state.restoration.editableTooth.stateValue;
            } else {
                return getters.storedToothState(number);
            }
        },

        storedToothState: (state, getters) => (number) => {
            const tooth = getters.tooth(number);
            return (tooth && tooth.state) ? tooth.state : 0;
        },

        eruption: (state, getters) => (number) => {
            const tooth = getters.tooth(number);
            return (tooth && tooth.eruption) ? tooth.eruption : ERUPTION_STATES.ERUPTED;
        },

        isToothErupted: (state, getters) => (number) => {
            return getters.eruption(number) == ERUPTION_STATES.ERUPTED;
        },

        isToothMissing: (state, getters) => (number) => {
            return getters.toothState(number) === TOOTH_STATES.MISSING;
        },

        isToothToBeExtracted: (state, getters) => (number) => {
            return getters.toothState(number) === TOOTH_STATES.TO_BE_EXTRACTED;
        },

        isToothPresent: (state, getters) => (number) => {
            return getters.isToothErupted(number) && !getters.isToothMissing(number);
        },

        isToothExtractable: (state, getters) => (number) => {
            return getters.isToothErupted(number) && (
                [
                    TOOTH_STATES.NORMAL,
                    TOOTH_STATES.TO_BE_EXTRACTED,
                    TOOTH_STATES.VENEER
                ].includes(getters.toothState(number))
            );
        },

        isToothAvailable: (state, getters) => (number) => {
            return getters.isToothErupted(number) && getters.isToothRelevant(number);
        },

        isToothRelevant: (state, getters, rootState, rootGetters) => (number) => {
            return rootGetters['patient/teeth/isToothRelevant'](number);
        },

        modifiedTeethStateExists: (state) => {
            return !!state.modifiedCurrentTeethState;
        },
        isModifiable: (state) => {
            return state.teethStateIsModifiable;
        },
        isHistorical: (state, getters) => {
            return !getters.isModifiable;
        },
        teethStateDate: (state) => {
            return state.teethStateDate;
        }
    },

    mutations: {
        loadDevelopmentOverrides(state) {
            Vue.set(state, 'developmentOverrides', window.developmentOverrides);
        },
        clear(state) {
            Object.keys(blankState).forEach(key => {
                if (key == 'history') {
                    state.history.todaysDate = blankState.history.todaysDate;
                    state.history.dates = blankState.history.dates;
                    state.history.states = blankState.history.states;
                } else {
                    state[key] = blankState[key];
                }
            });
        },
        setTeethStateHistory(state, { dates, states }) {
            state.history.todaysDate = new Date().toISOString().substring(0, 10);
            state.history.dates = dates;
            state.history.states = states;
        },
        addTodaysDateToHistoryIfNeeded(state) {
            const todaysDate = state.history.todaysDate;
            if (todaysDate && !state.history.dates.includes(todaysDate)) {
                state.history.dates.push(todaysDate);
            }
        },
        setTeethState(state, { teethState, teethStateDate, teethStateIsModified }) {
            // this stores everything in a single giant teethState object - tooth numbers as keys, everything else inside.
            // overwrites anything existing, so the passed argument must be a full state and not partial changes

            const todaysDate = state.history.todaysDate;
            const loadableState = JSON.parse(JSON.stringify(teethState));

            if (teethStateIsModified) {
                // only today's state can be accepted as modified
                // clear it from modification
                if (teethStateDate == todaysDate) {
                    state.modifiedCurrentTeethState = undefined;
                    state.teethStateIsModified = true;
                } else {
                    throw new Error('Cannot set an earlier teeth state as modified');
                }
            } else { // setting to an unmodified state (from history or current date)

                // if today is currently loaded and has been modified
                if ((state.teethStateDate == todaysDate) && state.teethStateIsModified) {
                    // copy it to modified state to preserve it
                    state.modifiedCurrentTeethState = JSON.parse(JSON.stringify(state.teethState));
                }
                state.teethStateIsModified = false;
            }

            state.teethState = loadableState;
            state.teethStateDate = teethStateDate;
            state.teethStateIsModifiable = state.teethStateDate == state.history.todaysDate;

            this.$api.togglePatientModifiability( state.teethStateIsModifiable );
        },

        setTeethStates(state, teethStateValues) {
            if (!state.teethStateIsModifiable) {
                // normally this should not happen, as any write operations should have checked this earlier.
                // this is just to prevent any data corruption
                throw "Cannot modify a historical tooth state";
            }

            // this sets only the given state values for the given tooth numbers
            // any other pre-existing values for these or other teeth are left untouched
            Object.keys(teethStateValues).forEach(toothNumber => {
                const stateValues = teethStateValues[toothNumber];

                if (!state.teethState[toothNumber]) {
                    Vue.set(state.teethState, toothNumber, {});
                }

                Object.keys(stateValues).forEach(stateKey => {
                    Vue.set(state.teethState[toothNumber], stateKey, stateValues[stateKey]);
                });

            });

            state.teethStateIsModified = true;
        },

        resetToothToState(state, { toothNumber, stateValues }) {
            if (!state.teethStateIsModifiable) {
                // normally this should not happen, as any write operations should have checked this earlier.
                // this is just to prevent any data corruption
                throw "Cannot modify a historical tooth state";
            }
            Vue.set(state.teethState, toothNumber, stateValues );

            state.teethStateIsModified = true;
        },

        toggleRollbackWarning(state, shouldOpen) {
            state.shouldShowRollbackWarning = shouldOpen;
        }
    },


    actions: {
        clear(context) {
            return context.commit('clear');
        },
        setTeethStateHistory(context, { dates, states } ) {
            const transformedStates = Object.keys(states).reduce((object, key) => {
                // this may get called repeatedly on an already transformed state
                // when loading stored state for print jobs.
                // it is ok, because the keys will already have been transformed, and this will simply do nothing
                object[key] = transformTeethStateKeys(states[key], TEETH_STATE_TRANSFORMATIONS.FROM_API);
                return object;
            }, {});
            return context.commit('setTeethStateHistory', { dates, states: transformedStates });
        },

        addTodaysDateToHistoryIfNeeded(context) {
            return context.commit('addTodaysDateToHistoryIfNeeded');
        },

        setHistoricalTeethState(context, date) {
            // not all dates will have teeth states, because the list of dates may contain dates with only BPE records
            // so if the selected date is not available, select the latest available date before it.
            // if there is no available state dates, set an empty state
            const availableTeethDates = Object.keys(context.state.history.states).sort();
            const lastMatchingAvailableDate = availableTeethDates.findLast(availableDate => availableDate <= date);
            const teethState = (lastMatchingAvailableDate) ? context.state.history.states[lastMatchingAvailableDate] : {};
            return context.commit('setTeethState', { teethState, teethStateDate: date, teethStateIsModified: false } );
        },

        setLatestHistoricalStateAsCurrent(context) {
            const availableTeethDates = Object.keys(context.state.history.states).sort();
            const latestManipulationDate = availableTeethDates.pop();

            const teethState = (latestManipulationDate) ? context.state.history.states[latestManipulationDate] : {};
            const todaysDate = context.state.history.todaysDate;
            return context.commit('setTeethState', { teethState, teethStateDate: todaysDate, teethStateIsModified: false });
        },

        restoreCurrentTeethState(context) {
            if (context.state.modifiedCurrentTeethState) {
                const teethState = context.state.modifiedCurrentTeethState;
                const todaysDate = context.state.history.todaysDate;
                return context.commit('setTeethState', { teethState, teethStateDate: todaysDate, teethStateIsModified: true });
            } else if (context.state.teethStateIsModified && context.state.history.todaysDate == context.state.teethStateDate) {
                // current state has been modified, but it is already loaded
                // (happens when closing rollback with no date specified and leaving it on 'Current state')
                return Promise.resolve();
            } else {
                return context.dispatch('setLatestHistoricalStateAsCurrent');
            }
        },

        markToothAsToBeExtracted(context, toothNumber) {
            if (context.getters.isToothToBeExtracted(toothNumber)) {
                return Promise.resolve();
            }

            return context.dispatch('updateToothState', {
                toothNumber,
                stateValues: { state: TOOTH_STATES.TO_BE_EXTRACTED },
                waiterName: WAITERS.MARKING_TOOTH_AS_TO_BE_EXTRACTED,
            }).then(() => {
                // create an automatic history record when marking a tooth for extraction
                context.dispatch('patient/history/addToothRecord', {
                    toothNumber,
                    message: context.rootGetters['i18n/t']("toBeExtracted", "dental.toothState")
                }, { root: true });
            });
        },

        markToothAsNotToBeExtracted(context, toothNumber) {
            if (!context.getters.isToothToBeExtracted(toothNumber)) {
                return Promise.resolve();
            }

            return context.dispatch('updateToothState', {
                toothNumber,
                stateValues: { state: TOOTH_STATES.NORMAL }, // (#32)
                waiterName: WAITERS.RESTORING_TOOTH,
            });
        },

        markToothAsMissing(context, toothNumber) {
            if (context.getters.isToothMissing(toothNumber)) {
                return Promise.resolve();
            }

            return context.dispatch('updateToothState', {
                toothNumber,
                stateValues: { state: TOOTH_STATES.MISSING },
                waiterName: WAITERS.MARKING_TOOTH_AS_MISSING,
            }).then(() => {
                // create an automatic history record when removing a tooth
                context.dispatch('patient/history/addToothRecord', {
                    toothNumber,
                    message: context.rootGetters['i18n/t']("missing", "dental.toothState")
                }, { root: true });
            });
        },

        restoreTooth(context, toothNumber) {
            return context.dispatch('updateToothState', {
                toothNumber,
                stateValues: { state: TOOTH_STATES.NORMAL }, // (#32)
                waiterName: WAITERS.RESTORING_TOOTH,
            });
        },

        updateToothEruptionState(context, { toothNumber, eruptionState }) {
            return context.dispatch('updateToothState', {
                toothNumber,
                stateValues: { eruption: eruptionState },
                waiterName: WAITERS.UPDATING_TOOTH_ERUPTION_STATE,
            });
        },

        resetTooth(context, toothNumber) {
            const waiterName = WAITERS.RESETTING_TOOTH;
            const patientId = context.rootState.patient.object.id;
            context.dispatch('wait/start', waiterName, { root: true });
            const stateValues = { state: TOOTH_STATES.NORMAL };
            return this.$api.resetToothToState(patientId, toothNumber, stateValues).then(() => {
                context.commit('resetToothToState', { toothNumber, stateValues });
            }).catch(this.apiErrorHandler).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        },

        updateToothState(context, { toothNumber, stateValues, waiterName, creatableRecords }) {
            const teethStateValues = { [toothNumber]: stateValues };
            return context.dispatch('updateTeethStates', { teethStateValues, waiterName, creatableRecords });
        },

        updateTeethStates(context, { teethStateValues, waiterName, creatableRecords }) {
            if (!context.getters.isModifiable) {
                // normally this should not happen, as any write operations should have checked this earlier.
                // this is just to prevent any data corruption
                throw "Cannot modify a historical tooth state";
            }
            const patientId = context.rootState.patient.object.id;
            if (waiterName) {
                context.dispatch('wait/start', waiterName, { root: true });
            }

            const clonedTeethStateValues = JSON.parse(JSON.stringify(teethStateValues));
            const stateValuesForApi = Object.keys(clonedTeethStateValues).reduce((object, toothNumber) => {
                object[toothNumber] = transformTeethStateKeys(clonedTeethStateValues[toothNumber], TEETH_STATE_TRANSFORMATIONS.TO_API);
                return object;
            }, {});

            return this.$api.updateTeethStates(patientId, stateValuesForApi).then(() => {
                let result = context.commit('setTeethStates', teethStateValues);
                if (creatableRecords && creatableRecords.length > 0) {
                    result = Promise.all(creatableRecords.map(record => {
                        const storeScope = (record.type == ISSUE_TYPES.HISTORY) ? 'patient/history' : 'patient/treatmentPlan';
                        return context.dispatch(storeScope + '/addRecord', record, { root: true });
                    }));
                }
                return result;
            }).catch(this.apiErrorHandler).finally(() => {
                if (waiterName) {
                    context.dispatch('wait/end', waiterName, { root: true });
                }
            });
        },

        openRollbackWarning(context)  { context.commit('toggleRollbackWarning', true); },
        closeRollbackWarning(context) { context.commit('toggleRollbackWarning', false); }

    }

};
