import state from './teeth/state.js';
import quickselect from './teeth/quickselect.js';

import { IMAGE_QUADRANTS, IMAGE_TYPES } from '@/config/visualization/general.js';

import {
    TOOTH_SETS,
    TOOTH_SLOTS,
    TOOTH_SLOT_SORTING_MODES,
    TOOTH_NUMBERS_BY_SLOT,
    TOOTH_SLOTS_BY_TOOTH_NUMBER,
    TOOTH_NUMBERS_BY_JAW,
    TOOTH_SETS_BY_TOOTH_NUMBER,
    TOOTH_QUADRANTS_BY_TOOTH_NUMBER,
    TOOTH_POSITIONS_BY_TOOTH_NUMBER,
    TOOTH_TYPES_BY_TOOTH_NUMBER,
    JAWS,
    PROJECTIONS,
    NOTATION_SYSTEMS,
    TOOTH_LABELS
} from '@/config/teeth.js';

const UPPER_JAW_NUMBERS = TOOTH_NUMBERS_BY_JAW[JAWS.UPPER];
const LOWER_JAW_NUMBERS = TOOTH_NUMBERS_BY_JAW[JAWS.LOWER];

export default {

    namespaced: true,

    modules: {
        state,
        quickselect
    },

    state: {
    },

    getters: {
        tooth: (state, getters) => (number) => {
            // this returns the tooth's generic definition without anything about the current patient's state,
            // e.g., there is no data here indicating whether the tooth is erupted
            if (getters.numbers.includes(number)) {
                const jaw = UPPER_JAW_NUMBERS.includes(number) ? JAWS.UPPER : JAWS.LOWER;
                const palatalOrLingual = (jaw === JAWS.UPPER) ? PROJECTIONS.PALATAL : PROJECTIONS.LINGUAL;

                const set = TOOTH_SETS_BY_TOOTH_NUMBER[number];
                const slot = TOOTH_SLOTS_BY_TOOTH_NUMBER[number];

                // quadrant is always 1-4, indicating the location in the mouth.
                // the values are 1-4 for deciduous teeth as well, so quadrant for tooth 53 is 1, not 5, etc
                const quadrant = TOOTH_QUADRANTS_BY_TOOTH_NUMBER[number];
                const position = TOOTH_POSITIONS_BY_TOOTH_NUMBER[number];
                const type = TOOTH_TYPES_BY_TOOTH_NUMBER[number];
                const reversed = (quadrant == 2 || quadrant == 3);

                // reuse the same images for left and right sides
                const imageQuadrant = IMAGE_QUADRANTS[quadrant];
                const mirrorImage = quadrant != imageQuadrant;


                let tooth = {
                    number,
                    slug: "iso-" + number,
                    label: getters.toothLabel(number),
                    set,
                    slot,
                    jaw,
                    quadrant,
                    position,
                    type,
                    palatalOrLingual,
                    reversed,
                    mirrorImage,
                    images: {
                        [IMAGE_TYPES.BASE]: {
                            buccal: getters.imagePath(IMAGE_TYPES.BASE, set, jaw, position, PROJECTIONS.BUCCAL),
                            incisal: getters.imagePath(IMAGE_TYPES.BASE, set, jaw, position, PROJECTIONS.INCISAL),
                            [palatalOrLingual]: getters.imagePath(IMAGE_TYPES.BASE, set, jaw, position, palatalOrLingual)
                        },
                        [IMAGE_TYPES.IMPLANT]: {
                            buccal: getters.imagePath(IMAGE_TYPES.IMPLANT, set, jaw, position, PROJECTIONS.BUCCAL),
                            incisal: getters.imagePath(IMAGE_TYPES.IMPLANT, set, jaw, position, PROJECTIONS.INCISAL),
                            [palatalOrLingual]: getters.imagePath(IMAGE_TYPES.IMPLANT, set, jaw, position, palatalOrLingual)
                        },
                        [IMAGE_TYPES.CROWN]: {
                            buccal: getters.imagePath(IMAGE_TYPES.CROWN, set, jaw, position, PROJECTIONS.BUCCAL),
                            incisal: getters.imagePath(IMAGE_TYPES.CROWN, set, jaw, position, PROJECTIONS.INCISAL),
                            [palatalOrLingual]: getters.imagePath(IMAGE_TYPES.CROWN, set, jaw, position, palatalOrLingual)
                        },
                        [IMAGE_TYPES.OUTLINE]: {
                            buccal: getters.imagePath(IMAGE_TYPES.OUTLINE, set, jaw, position, PROJECTIONS.BUCCAL),
                            incisal: getters.imagePath(IMAGE_TYPES.OUTLINE, set, jaw, position, PROJECTIONS.INCISAL),
                            [palatalOrLingual]: getters.imagePath(IMAGE_TYPES.OUTLINE, set, jaw, position, palatalOrLingual)
                        },
                        [IMAGE_TYPES.BLANK]: {
                            buccal: getters.blankImagePath,
                            incisal: getters.blankImagePath,
                            [palatalOrLingual]: getters.blankImagePath
                        }
                    }
                };
                return tooth;
            } else {
                return undefined;
            }
        },
        imagePath: () => (type, set, jaw, position, projection) => {

            // all palatal images are called 'lingual' in ios file names
            const projectionPath = (projection == PROJECTIONS.PALATAL) ? PROJECTIONS.LINGUAL : projection;

            return 'teeth/' + type + '/' + set + '/' + jaw + '/' + position + '/' + projectionPath;
        },
        blankImagePath: () => {
            return 'teeth/blank';
        },

        toothBySlug: (state, getters) => (slug) => {
            if (slug) {
                const matches = slug.match(/^(iso-)(\d+)$/);
                if (matches) {
                    return getters.tooth(matches[2]);
                }
            }
            return undefined;
        },

        numbers: () => UPPER_JAW_NUMBERS.concat(LOWER_JAW_NUMBERS),
        relevantNumbers: (state, getters, rootState, rootGetters) => {
            // returns an array of teeth numbers, using exactly one tooth for each slot.
            // 1) if only one tooth is possible in that slot, use that
            // 2) if two teeth are possible and only one is erupted, use the erupted one
            // 3) if both are possible and both are erupted, use permanent
            // 4) if both are possible and none are erupted, use deciduous
            return TOOTH_SLOTS[TOOTH_SLOT_SORTING_MODES.CHART].map(slotNumber => {
                const slotTeethNumbers = TOOTH_NUMBERS_BY_SLOT[slotNumber];
                let relevantToothNumber = slotTeethNumbers[0];  // 1)
                if (slotTeethNumbers.length == 2) {
                    const eruptionStatesBySet = slotTeethNumbers.reduce((object, toothNumber) => {
                        const set = TOOTH_SETS_BY_TOOTH_NUMBER[toothNumber];
                        object[set] = {
                            number: toothNumber,
                            erupted: rootGetters['patient/teeth/state/isToothErupted'](toothNumber)
                        };
                        return object;
                    }, {});
                    const eruptedSetNames = Object.keys(eruptionStatesBySet).filter(set => eruptionStatesBySet[set].erupted);
                    switch (eruptedSetNames.length) {
                    case 2:
                        relevantToothNumber = eruptionStatesBySet[TOOTH_SETS.PERMANENT].number; // 3)
                        break;
                    case 0:
                        relevantToothNumber = eruptionStatesBySet[TOOTH_SETS.DECIDUOUS].number;  // 4)
                        break;
                    default:
                        relevantToothNumber = eruptionStatesBySet[eruptedSetNames[0]].number;  // 2)
                        break;
                    }
                }
                return relevantToothNumber;
            });
        },
        isToothRelevant: (state, getters) => (number) => getters.relevantNumbers.includes(number),
        relevant: (state, getters) => {
            return getters.relevantNumbers.map(toothNumber => getters.tooth(toothNumber));
        },
        relevantInWheelOrder: (state, getters) => {
            // resort relevant teeth in slot order used for wheel
            const relevantTeeth = getters.relevant;

            return TOOTH_SLOTS[TOOTH_SLOT_SORTING_MODES.WHEEL].map(slot => {
                return relevantTeeth.find(tooth => tooth.slot == slot);
            });
        },
        upperJaw: (state, getters) => getters.relevant.filter(tooth => tooth.jaw == JAWS.UPPER),
        lowerJaw: (state, getters) => getters.relevant.filter(tooth => tooth.jaw == JAWS.LOWER),

        toothLabel: (state, getters, rootState) => (number) => {
            return getters.toothLabelInGivenNotation(number, rootState.settings.currentNotationSystem);
        },
        toothLabelInGivenNotation: () => (number, notation) => {
            return TOOTH_LABELS[notation][number];
        },
        zoneLabel: (state, getters, rootState, rootGetters) => (zoneName) => {
            const translationKey = zoneName.match(/class-4/) ? zoneName.replace(/-/g, '') : rootGetters['filters/camelize'](zoneName);
            return rootGetters['i18n/t']( translationKey, 'dental.region').replace(/-/g, ' '); // fix inconsistent translations
        },

        convertToothLabelToCurrentNotation: (state, getters, rootState ) => ({ message, toothNumber }) => {

            if (!toothNumber || !message) {
                return message;
            }

            const sourceNotation = NOTATION_SYSTEMS.ISO;
            const targetNotation = rootState.settings.currentNotationSystem;
            if (sourceNotation == targetNotation) {
                return message;
            }

            const sourceLabel = TOOTH_LABELS[sourceNotation][toothNumber];
            const targetLabel = TOOTH_LABELS[targetNotation][toothNumber];

            return message.replace(new RegExp('\\b' + sourceLabel + '\\b', 'g'), targetLabel);
        }

    }

};
