<template>
    <main data-view="account">
        <navigation-link tag="h1" class="logo" :to="{ name: 'root' }" v-if="$route.meta.logo !== false">
            <svg-image
                name="derec-logo"
                title="derec"
                width="168"
                height="44"
            />
        </navigation-link>
        <router-view />
    </main>
</template>

<script>
export default {
    components: { },
    data() {
        return {
        };
    },
    mounted() {

    }
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/account";
</style>
