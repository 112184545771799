<template>
    <p class="level-selector" :data-value="value">
        <label v-for="n in 5" :key="n">
            <input type="radio" :name="name" :value="n" v-model="editableValue" />
            <span>{{ n }}</span>
        </label>
    </p>
</template>
<script>
import uuid from 'uuid/v4';
export default {
    props: {
        value: { required: true }
    },
    data() {
        return {
        };
    },
    computed: {
        name() { return uuid(); },
        editableValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>
