export default {
    namespaced: true,

    state: {
        values: undefined,
        definitions: [
            {
                value: 0,
                name: "high-blood-pressure"
            },
            {
                value: 1,
                name: "angina-pectoris"
            },
            {
                value: 2,
                name: "heart-attack"
            },
            {
                value: 3,
                name: "irregular-heartbeat"
            },
            {
                value: 4,
                name: "heart-surgery"
            },
            {
                value: 5,
                name: "heart-failure"
            },
            {
                value: 6,
                name: "damaged-heart-valve"
            },
            {
                value: 7,
                name: "high-cholesterol"
            },
            {
                value: 8,
                name: "heart-infection"
            },
            {
                value: 9,
                name: "abnormal-bleeding"
            },
            {
                value: 10,
                name: "prolonged-bleeding"
            },
            {
                value: 11,
                name: "asthma"
            },
            {
                value: 12,
                name: "emphysema-or-bronchitis"
            },
            {
                value: 13,
                name: "difficulty-breathing"
            },
            {
                value: 14,
                name: "diabetes"
            },
            {
                value: 15,
                name: "thyroid-problem"
            },
            {
                value: 16,
                name: "kidney-disorder"
            },
            {
                value: 17,
                name: "dialysis"
            },
            {
                value: 18,
                name: "past-use-of-steroids"
            },
            {
                value: 19,
                name: "delayed-healing"
            },
            {
                value: 20,
                name: "arthritis"
            },
            {
                value: 21,
                name: "artificial-joint"
            },
            {
                value: 22,
                name: "sjogrens-syndrome"
            },
            {
                value: 23,
                name: "osteoporosis"
            },
            {
                value: 24,
                name: "acid-reflux"
            },
            {
                value: 25,
                name: "stomach-ulcer"
            },
            {
                value: 26,
                name: "liver-disease"
            },
            {
                value: 27,
                name: "hepatitis"
            },
            {
                value: 28,
                name: "glaucoma"
            },
            {
                value: 29,
                name: "impaired-vision"
            },
            {
                value: 30,
                name: "impaired-hearing"
            },
            {
                value: 31,
                name: "hiv-positive-or-aids"
            },
            {
                value: 32,
                name: "sexually-transmitted-disease"
            },
            {
                value: 33,
                name: "local-anesthetic"
            },
            {
                value: 34,
                name: "antibiotics"
            },
            {
                value: 35,
                name: "aspirin-or-ibuprofen"
            },
            {
                value: 36,
                name: "acetaminophen"
            },
            {
                value: 37,
                name: "codeine-narcotics"
            },
            {
                value: 38,
                name: "metals"
            },
            {
                value: 39,
                name: "latex"
            },
            {
                value: 40,
                name: "pregnancy"
            },
            {
                value: 41,
                name: "cancer"
            },
            {
                value: 42,
                name: "cancer-treatment"
            },
            {
                value: 43,
                name: "nursing-infant"
            },
            {
                value: 44,
                name: "tobacco-use"
            },
            {
                value: 45,
                name: "alcohol-use"
            },
            {
                value: 46,
                name: "street-or-recreational-drug-use"
            }
        ]
    },

    getters: {
        defined: (state, getters, rootState, rootGetters) => {
            return state.definitions.map(definition => {
                const issue = JSON.parse(JSON.stringify(definition));
                issue.label = rootGetters['i18n/t'](issue.name, "medicalIssue");
                return issue;
            });
        },
        activeValues: state => state.values || [],
        active: (state, getters) => getters.defined.filter(issue => getters.activeValues.includes(issue.value))
    },

    mutations: {
        setValues(state, values) {
            state.values = values;
        },
    },

    actions: {
        setValues(context, values) {
            context.commit('setValues', values);
        }
    }
};
