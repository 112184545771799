export default {
    namespaced: true,

    state: {
        values: undefined
    },

    getters: {

    },

    mutations: {
        setValues(state, values) {
            state.values = values;
        },
    },

    actions: {
        setValues(context, values) {
            context.commit('setValues', values);
        }
    }
};
