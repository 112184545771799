export default {
  __name: 'date-note',
  setup: function setup(__props) {
    var store = useStore();
    var formattedRollbackDate = computed(function () {
      var date = store.getters['patient/teeth/state/teethStateDate'];
      return store.getters['i18n/formatDate'](date);
    });
    return {
      __sfc: true,
      store: store,
      formattedRollbackDate: formattedRollbackDate
    };
  }
};