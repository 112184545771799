<template>
    <main data-view="loading">
        <waiting-overlay />
    </main>
</template>

<script>
import WaitingOverlay from '@/components/waiting-overlay';
export default {
    components: { WaitingOverlay }
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/loading";
</style>