import {
    ENDODONTIC_TESTS,

    COLD_TEST_RESULTS,
    COLD_TEST_SUBRESULTS,

    PERCUSSION_TEST_RESULTS,

    ELECTRICITY_TEST_RESULTS
} from '@/config/endodontic-tests.js';

import { ACTIVE_ELECTRICITY_TEST_RANGES } from '@/config/root-canal.js';

export default {

    namespaced: true,

    state: {

    },

    getters: {
        parent: (state, getters, rootState, rootGetters) => (parentGetterName, ...args) => {
            const getter = rootGetters['patient/teeth/state/endodontic/' + parentGetterName];
            if (typeof getter == 'function') {
                return getter(...args);
            } else {
                return getter;
            }
        },

        active: (state, getters, rootState, rootGetters) => (toothNumber) => {
            const results = getters.parent('testNames').reduce((object, testName) => {
                object[testName] = getters.testResults({toothNumber, testName});
                return object;
            }, {});

            const toothType = rootGetters['patient/teeth/tooth'](toothNumber).type;
            return getters.parent('testNames').filter(testName => getters.isActive({ testName, results, toothType }));
        },

        testResults: (state, getters) => ({ toothNumber, testName }) => {
            const testState = getters.parent('loadedTestState', { toothNumber, testName });
            const firstStepOption = getters.parent('valueOption', { testName, value: testState.result });

            let secondStepOption = undefined;
            if (getters.parent('testHasTwoSteps', testName, firstStepOption)) {
                secondStepOption = getters.parent('valueOption', { testName, value: testState.subresult });
            }

            return { first: firstStepOption, second: secondStepOption };
        },

        isActive: () => ({ testName, results, toothType }) => {

            // cold and heat are always shown if positive and not withing limits
            if (
                [ENDODONTIC_TESTS.COLD, ENDODONTIC_TESTS.HEAT].includes(testName)
                &&
                (
                    results[testName].first == COLD_TEST_RESULTS.POSITIVE
                    &&
                    results[testName].second !== COLD_TEST_SUBRESULTS.WITHIN_LIMITS
                )
            ) {
                return true;
            }


            // percussion and palpation arw shown always if painful
            if (
                [ENDODONTIC_TESTS.PERCUSSION, ENDODONTIC_TESTS.PALPATION].includes(testName)
                &&
                (results[testName].first == PERCUSSION_TEST_RESULTS.PAINFUL)
            ) {
                return true;
            }


            // electricity indicator depends on cold test results
            if (testName == ENDODONTIC_TESTS.ELECTRICITY) {
                if ([COLD_TEST_RESULTS.UNCERTAIN, COLD_TEST_RESULTS.NEGATIVE].includes(results[ENDODONTIC_TESTS.COLD].first)) {
                    // if cold result is uncertain or negative,
                    // electricity should be shown depending on whether it is in the active range for given tooth type
                    // conditions 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35, 37
                    return ACTIVE_ELECTRICITY_TEST_RANGES[toothType].includes(results[ENDODONTIC_TESTS.ELECTRICITY].first);
                } else {
                    // otherwise the symbol should be shown only if the value is 10
                    return results[testName].first == ELECTRICITY_TEST_RESULTS.E_10;
                }
            }

            return false;
        },

    }

};
