import clone from '@/utils/clone.js';

import toBeExtracted from './quickselect/toBeExtracted.js';
import missing from './quickselect/missing.js';
import veneer from './quickselect/veneer.js';
import pontics from './quickselect/pontics.js';
import crown from './quickselect/crown.js';
import endoTests from './quickselect/endoTests.js';
import slotState from './quickselect/slotState.js';

import { WAITERS } from '@/config/waiters.js';

export default {

    namespaced: true,

    modules: {
        toBeExtracted,
        missing,
        veneer,
        pontics,
        crown,
        endoTests,
        slotState
    },

    state: {
        selectedToothNumbers: []
    },
    getters: {
        isToothNumberSelected: (state) => (toothNumber) => {
            return state.selectedToothNumbers.includes(toothNumber);
        },
        selectedToothNumbers: (state) => state.selectedToothNumbers,
        actionStorePath: () => (action) => 'patient/teeth/quickselect/' + action,
        actionParams: (state, getters, rootState, rootGetters) => (action, selectedParams) => {
            const storeGetter = getters.actionStorePath(action) + '/params';
            return rootGetters[storeGetter](selectedParams, state.selectedToothNumbers);
        },
        actionWaiterName: () => (action) => {
            return WAITERS.SAVING_QUICKSELECT_ACTION + ': ' + action;
        },
    },

    mutations: {
        selectToothNumber(state, toothNumber) {
            if (!state.selectedToothNumbers.includes(toothNumber)) {
                state.selectedToothNumbers.push(toothNumber);
            }
        },
        deselectToothNumber(state, toothNumber) {
            var index = state.selectedToothNumbers.findIndex(number => number == toothNumber);
            if (index > -1) {
                state.selectedToothNumbers.splice(index, 1);
            }
        },
        clearSelectedToothNumbers(state) {
            state.selectedToothNumbers = [];
        }
    },

    actions: {
        selectToothNumber(context, toothNumber) {
            context.commit('selectToothNumber', toothNumber);
        },
        deselectToothNumber(context, toothNumber) {
            context.commit('deselectToothNumber', toothNumber);
        },
        clearSelectedToothNumbers(context) {
            context.commit('clearSelectedToothNumbers');
        },
        performAction(context, { action, params }) {
            const numbers = clone(context.state.selectedToothNumbers).sort();
            if (numbers.length < 1) {
                return;
            }

            const waiterName = context.getters.actionWaiterName(action);

            const storeAction = context.getters.actionStorePath(action) + '/perform';

            context.dispatch('wait/start', waiterName, { root: true });
            return context.dispatch(storeAction, { numbers, params }, { root: true }).then(() => {
                context.dispatch('clearSelectedToothNumbers');
            }).finally(() => {
                context.dispatch('wait/end', waiterName, { root: true });
            });

        }

    }
};
