import freeze from '@/utils/freeze.js';

const SOFT_TISSUE_ZONES = freeze({
    UPPER_LIP: "upper-lip",
    LOWER_LIP: "lower-lip",
    R_COMMISSURE: "r-commissure",
    L_COMMISSURE: "l-commissure",
    UPPER_LABIAL_MUCOSA: "upper-labial-mucosa",
    UPPER_SULCI: "upper-sulci",
    UPPER_GINGIVAE: "upper-gingivae",
    HARD_PALATE: "hard-palate",
    SOFT_PALATE: "soft-palate",
    PHARYNX_AND_TONSILLAR_AREA: "pharynx-and-tonsillar-area",
    TONGUE_DORSUM: "tongue-dorsum",
    TONGUE_R_LATERAL_BORDER: "tongue-r-lateral-border",
    TONGUE_L_LATERAL_BORDER: "tongue-l-lateral-border",
    TONGUE_VENTRAL: "tongue-ventral",
    FLOOR_OF_MOUTH: "floor-of-mouth",
    R_BUCCAL_MUCOSA: "r-buccal-mucosa",
    L_BUCCAL_MUCOSA: "l-buccal-mucosa",
    LOWER_GINGIVAE: "lower-gingivae",
    LOWER_SULCI: "lower-sulci",
    LOWER_LABIAL_MUCOSA: "lower-labial-mucosa"
});

const SOFT_TISSUE_ZONES_IN_ORDER = freeze([
    SOFT_TISSUE_ZONES.UPPER_LIP,
    SOFT_TISSUE_ZONES.LOWER_LIP,
    SOFT_TISSUE_ZONES.R_COMMISSURE,
    SOFT_TISSUE_ZONES.L_COMMISSURE,
    SOFT_TISSUE_ZONES.UPPER_LABIAL_MUCOSA,
    SOFT_TISSUE_ZONES.UPPER_SULCI,
    SOFT_TISSUE_ZONES.UPPER_GINGIVAE,
    SOFT_TISSUE_ZONES.HARD_PALATE,
    SOFT_TISSUE_ZONES.SOFT_PALATE,
    SOFT_TISSUE_ZONES.PHARYNX_AND_TONSILLAR_AREA,
    SOFT_TISSUE_ZONES.TONGUE_DORSUM,
    SOFT_TISSUE_ZONES.TONGUE_R_LATERAL_BORDER,
    SOFT_TISSUE_ZONES.TONGUE_L_LATERAL_BORDER,
    SOFT_TISSUE_ZONES.TONGUE_VENTRAL,
    SOFT_TISSUE_ZONES.FLOOR_OF_MOUTH,
    SOFT_TISSUE_ZONES.R_BUCCAL_MUCOSA,
    SOFT_TISSUE_ZONES.L_BUCCAL_MUCOSA,
    SOFT_TISSUE_ZONES.LOWER_GINGIVAE,
    SOFT_TISSUE_ZONES.LOWER_SULCI,
    SOFT_TISSUE_ZONES.LOWER_LABIAL_MUCOSA
]);


export {
    SOFT_TISSUE_ZONES,
    SOFT_TISSUE_ZONES_IN_ORDER
};