import freeze from '@/utils/freeze.js';

const QUICKSELECT_ACTIONS = freeze({
    TO_BE_EXTRACTED: "toBeExtracted",
    MISSING: "missing",
    VENEER: "veneer",
    PONTICS: "pontics",
    CROWN: "crown",
    ENDO_TESTS: "endoTests",
    SLOT_STATE: "slotState"
});

const QUICKSELECT_ACTIONS_IN_ORDER = freeze([
    QUICKSELECT_ACTIONS.TO_BE_EXTRACTED,
    QUICKSELECT_ACTIONS.MISSING,
    QUICKSELECT_ACTIONS.VENEER,
    QUICKSELECT_ACTIONS.PONTICS,
    QUICKSELECT_ACTIONS.CROWN,
    QUICKSELECT_ACTIONS.ENDO_TESTS,
    QUICKSELECT_ACTIONS.SLOT_STATE
]);


export {
    QUICKSELECT_ACTIONS,
    QUICKSELECT_ACTIONS_IN_ORDER
};
