import clone from '@/utils/clone.js';

import { RESTORATIONS } from '@/config/teeth.js';
import { PATHOLOGY_AND_RESTORATION_ACTIONS } from '@/config/pathologies-and-restorations.js';
import { CROWN_DETAILS, CROWN_DETAIL_TYPES, CROWN_BASES } from '@/config/restorations/crown.js';

export default {

    namespaced: true,

    getters: {
        definition: (state, getters, rootState, rootGetters) => (toothNumber) => {
            return rootGetters['patient/teeth/state/restoration/type'](RESTORATIONS.CROWN, toothNumber).details || {};
        },

        params: () => (selectedParams) => {
            const details = clone(CROWN_DETAILS);

            if (selectedParams[CROWN_DETAIL_TYPES.BASE] != CROWN_BASES.IMPLANT) {
                delete details[CROWN_DETAIL_TYPES.IMPLANT_TYPE];
            }
            return details;
        },

        buildRestorationState: (state, getters, rootState, rootGetters) => ({ toothNumber, params }) => {
            return rootGetters['patient/teeth/state/restoration/orderedRestoration']({
                ...getters.defaultState(toothNumber),
                ...params
            });
        },

        defaultState: (state, getters, rootState, rootGetters) => (toothNumber) => {
            return rootGetters['patient/teeth/state/restoration/crown/defaultState'](toothNumber);
        },

        toothStateValues: (state, getters, rootState, rootGetters) => ({ toothNumber, stateValue, toothState }) => {
            return {
                state: stateValue,
                restoration: rootGetters['patient/teeth/state/restoration/saveableToothState']({
                    toothNumber,
                    appendableState: toothState
                })
            };
        },

        buildHistoryRecord: (state, getters, rootState, rootGetters) => ({ toothNumber, selectedSurfaces, details }) => {
            return rootGetters['patient/teeth/state/restoration/buildCreatableRecord']({
                action: PATHOLOGY_AND_RESTORATION_ACTIONS.SAVE,
                typeName: RESTORATIONS.CROWN,
                toothNumber,
                selectedSurfaces,
                details
            });
        }

    },


    actions: {

        perform(context, { numbers, params }) {

            const creatableRecords = [];
            const teethStateValues = numbers.reduce((object, toothNumber) => {
                const definition = context.getters.definition(toothNumber);

                const toothState = context.getters.buildRestorationState({ toothNumber, params });
                // toothState now contains something like
                // {"restoration":5, "surfaces": [......], "material": 1, "crown_type": 1, "created_at": "20......Z" }

                const details = context.rootGetters['patient/teeth/state/restoration/crown/detailsFromToothState']({
                    toothNumber,
                    definition,
                    toothState
                });
                // details now contains something like
                /// { "material": {
                //      "labelKey":"restoration.material",
                //      "options": [
                //              { "value": 0, "labelKey": "restoration.material.composit", label: "Composit",
                //              {...}
                //      ],
                //      "values": [ 1 ]
                //    },
                //    "crown_type": { .... }
                //  }


                const stateValue = context.rootGetters['patient/teeth/state/restoration/calculateToothStateValue']({
                    toothNumber,
                    toothState,
                    details
                });
                // stateValue now has an integer indicating the tooth's state (NORMAL, IMPLANT etc)

                const toothStateValues = context.getters['toothStateValues']({ toothNumber, stateValue, toothState });
                // toothStateValues now has the complete tooth state to replace in db (including previous stuff)
                object[toothNumber] = toothStateValues;

                // build a history record
                const historyRecord = context.getters['buildHistoryRecord']({ toothNumber, selectedSurfaces: toothState.surfaces, details });
                creatableRecords.push(historyRecord);
                return object;
            }, {});

            return context.dispatch('patient/teeth/state/restoration/saveStates', { teethStateValues, creatableRecords }, { root: true });
        },
    }

};
