<template>
    <div>
        <button class="button link" data-icon="reset" type="button" v-on:click="askForConfirmation">
            {{ t('reset', 'dentalDetailsVC') }}
        </button>

        <confirmation-dialog :for="waiterName" v-if="confirmableAction" v-on:confirm="confirm" v-on:close="closeConfirmationDialog">
            <h1 class="title"> {{ t('resetToothMessage', 'dentalDetailsVC') }} </h1>
        </confirmation-dialog>
    </div>
</template>
<script>
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { ConfirmationDialog },
    props: {
        toothNumber: { required: true }
    },
    data() {
        return {
            confirmableAction: undefined
        };
    },
    computed:
    {

    },
    created() {
        this.waiterName = WAITERS.RESETTING_TOOTH;
    },

    methods:
    {
        askForConfirmation() {
            this.ensureTeethStateModifiability().then(() => {
                this.confirmableAction = 'reset';
            }, () => {});
        },

        closeConfirmationDialog() {
            this.confirmableAction = this.undefined;
        },

        confirm() {
            return this.$store.dispatch('patient/teeth/state/resetTooth', this.toothNumber).finally(() => {
                this.closeConfirmationDialog();
            });
        }


    }

};
</script>
