import freeze from '@/utils/freeze.js';
import clone from '@/utils/clone.js';
import range from '@/utils/range.js';
import { TOOTH_TYPES, TOOTH_NUMBERS_BY_TOOTH_SET, TOOTH_SETS } from '@/config/teeth.js';

import {
    RESTORATION_DETAIL_TYPES,
    RESTORATION_DEFINITION_DEFAULT_DETAILS
} from '@/config/restorations/general.js';

// "restoration": 5

const CROWN_DETAIL_TYPES = freeze({
    MATERIAL: "material",
    TYPE: "crown_type",
    BASE: "crown_base",
    IMPLANT_TYPE: "implant_type"
});
const CROWN_TYPES = freeze({
    SINGLE_CROWN: 0,
    ABUTMENT: 1,
    PONTIC: 2
});
const CROWN_BASES = freeze({
    NATURAL: 0,
    IMPLANT: 1
});
const CROWN_IMPLANT_TYPES = freeze({
    BONE_LEVEL: 0,
    TISSUE_LEVEL: 1
});

const CROWN_DETAILS = freeze({
    [CROWN_DETAIL_TYPES.MATERIAL]: RESTORATION_DEFINITION_DEFAULT_DETAILS[RESTORATION_DETAIL_TYPES.MATERIAL],

    [CROWN_DETAIL_TYPES.TYPE]: {
        labelKey: "restoration.crown.type",
        options: [
            { value: CROWN_TYPES.SINGLE_CROWN,  labelKey: "restoration.crown.single" },
            { value: CROWN_TYPES.ABUTMENT,      labelKey: "restoration.crown.abutment" },
            { value: CROWN_TYPES.PONTIC,        labelKey: "restoration.crown.pontic" },
        ]
    },
    [CROWN_DETAIL_TYPES.BASE]: {
        labelKey: "resporation.crown.base",
        options: [
            { value: CROWN_BASES.NATURAL,       labelKey: "restoration.crown.natural" },
            { value: CROWN_BASES.IMPLANT,       labelKey: "restoration.crown.implant" },
        ]
    },
    [CROWN_DETAIL_TYPES.IMPLANT_TYPE]: {
        labelKey: "restoration.crown.implant.type",
        options: [
            { value: CROWN_IMPLANT_TYPES.BONE_LEVEL,      labelKey: "restoration.crown.implant.bone" },
            { value: CROWN_IMPLANT_TYPES.TISSUE_LEVEL,    labelKey: "restoration.crown.implant.tissue" },
        ]
    }

});


const permanentToothNumbers = TOOTH_NUMBERS_BY_TOOTH_SET[TOOTH_SETS.PERMANENT];
const deciduousToothNumbers = TOOTH_NUMBERS_BY_TOOTH_SET[TOOTH_SETS.DECIDUOUS];

const crownToothNumbers = permanentToothNumbers.concat(deciduousToothNumbers);

const crownSurfaces = { };


// crowns do not have zones and do not have zone-dependent surface lists.
// crowns use surfaces only for visualizations in the app and the API does not store them in the db.

// when drawing crown surfaces, the visualization code does not look at the tooth state values in the store,
// but takes the displayable surfaces directly from CROWN_SURFACES in this config.

// crown surfaces mostly contain all surfaces of a given tooth, except for the first and last one
// (cervical buccal and cervical palatal), which should not be included when visualizing.
// an exception is tooth 84 which has its surface numbering in the wrong order, so cervical buccal is surface 11 instead of 1.
// also excluded are extra surfaces (28+) of permanent teeth which had been added later in iOS as a workaround for something.

// for permanent teeth, crown surfaces are the same for all tooth types (surfaces 2 to 26),
// because surfaces numbers match for all types (1 to 27, ignoring custom extras) and the excludable ones are 1 and 27+
permanentToothNumbers.forEach(number => {
    crownSurfaces[number] = range(2, 26);
});

// for deciduous teeth, crown surfaces are also all surfaces except the cervical ones at the ends,
// but these numbers may differ for each tooth
const deciduousIncisorCrownSurfaces = range(2, 33);

crownSurfaces["51"] = deciduousIncisorCrownSurfaces;
crownSurfaces["61"] = clone(crownSurfaces["51"]);

crownSurfaces["52"] = deciduousIncisorCrownSurfaces;
crownSurfaces["62"] = clone(crownSurfaces["52"]);

crownSurfaces["53"] = deciduousIncisorCrownSurfaces;
crownSurfaces["63"] = clone(crownSurfaces["53"]);

crownSurfaces["54"] = range(2, 26);
crownSurfaces["64"] = clone(crownSurfaces["54"]);

crownSurfaces["55"] = range(2, 27);
crownSurfaces["65"] = clone(crownSurfaces["55"]);



crownSurfaces["81"] = deciduousIncisorCrownSurfaces;
crownSurfaces["71"] = clone(crownSurfaces["81"]);

crownSurfaces["82"] = deciduousIncisorCrownSurfaces;
crownSurfaces["72"] = clone(crownSurfaces["82"]);

crownSurfaces["83"] = deciduousIncisorCrownSurfaces;
crownSurfaces["73"] = clone(crownSurfaces["83"]);

crownSurfaces["84"] = range(1, 10).concat(range(12, 30));
crownSurfaces["74"] = clone(crownSurfaces["84"]);

crownSurfaces["85"] = range(2, 28);
crownSurfaces["75"] = clone(crownSurfaces["85"]);


const CROWN_SURFACES = freeze(crownSurfaces);

const definition = {
    toothTypes: [TOOTH_TYPES.INCISOR, TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};


crownToothNumbers.forEach(number => {
    definition.teeth[number] = {
        surfaces: {},
        details: CROWN_DETAILS
    };
});

const CROWN_DEFINITION = freeze(definition);

export {
    CROWN_DEFINITION,
    CROWN_SURFACES,

    CROWN_DETAIL_TYPES,
    CROWN_TYPES,
    CROWN_BASES,
    CROWN_IMPLANT_TYPES,

    CROWN_DETAILS,
};
