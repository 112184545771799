import freeze from '@/utils/freeze.js';
import clone from '@/utils/clone.js';

import {
    PROJECTIONS,
    TOOTH_SETS,
    TOOTH_TYPES,
    TOOTH_TYPES_BY_TOOTH_NUMBER,
    TOOTH_NUMBERS_BY_TOOTH_SET
} from '@/config/teeth.js';

import {
    COLORS
} from '@/config/visualization/general.js';


import {
    TOOTH_SURFACES,
    PERMANENT_TOOTH_SURFACES_BY_TOOTH_TYPE,
    filterNonOverlappingSurfacesForPermanentTeeth
} from '@/config/visualization/surfaces.js';

import { TOOTH_WEAR_ZONES } from '@/config/pathologies/tooth-wear.js';

const toothErosionSurfaces = {};

// for permanent teeth, erosion surfaces are the same for all teeth of the same tooth type
const permanentToothErosionSurfacesByToothType = freeze({
    [TOOTH_TYPES.INCISOR]: {
        [TOOTH_WEAR_ZONES.BUCCAL]: {
            [PROJECTIONS.BUCCAL]: filterNonOverlappingSurfacesForPermanentTeeth(TOOTH_TYPES.INCISOR, PROJECTIONS.BUCCAL),
            [PROJECTIONS.PALATAL]: [],
            [PROJECTIONS.INCISAL]: [10, 11, 12, 13],
        },
        [TOOTH_WEAR_ZONES.PALATAL]: {
            [PROJECTIONS.BUCCAL]: [],
            [PROJECTIONS.PALATAL]: filterNonOverlappingSurfacesForPermanentTeeth(TOOTH_TYPES.INCISOR, PROJECTIONS.PALATAL),
            [PROJECTIONS.INCISAL]: [14, 15, 16, 17],
        },
    },
    [TOOTH_TYPES.PREMOLAR]: {
        [TOOTH_WEAR_ZONES.BUCCAL]: {
            [PROJECTIONS.BUCCAL]: PERMANENT_TOOTH_SURFACES_BY_TOOTH_TYPE[TOOTH_TYPES.PREMOLAR].buccal,
            [PROJECTIONS.PALATAL]: [],
            [PROJECTIONS.INCISAL]: [11, 12],
        },
        [TOOTH_WEAR_ZONES.PALATAL]: {
            [PROJECTIONS.BUCCAL]: [],
            [PROJECTIONS.PALATAL]: PERMANENT_TOOTH_SURFACES_BY_TOOTH_TYPE[TOOTH_TYPES.PREMOLAR].palatal,
            [PROJECTIONS.INCISAL]: [13, 14],
        }
    },
    [TOOTH_TYPES.MOLAR]: {
        [TOOTH_WEAR_ZONES.BUCCAL]: {
            [PROJECTIONS.BUCCAL]: filterNonOverlappingSurfacesForPermanentTeeth(TOOTH_TYPES.MOLAR, PROJECTIONS.BUCCAL),
            [PROJECTIONS.PALATAL]: [],
            [PROJECTIONS.INCISAL]: [14, 15],
        },
        [TOOTH_WEAR_ZONES.PALATAL]: {
            [PROJECTIONS.BUCCAL]: [],
            [PROJECTIONS.PALATAL]: filterNonOverlappingSurfacesForPermanentTeeth(TOOTH_TYPES.MOLAR, PROJECTIONS.PALATAL),
            [PROJECTIONS.INCISAL]: [16, 17],
        }
    }
});

TOOTH_NUMBERS_BY_TOOTH_SET[TOOTH_SETS.PERMANENT].forEach(number => {
    toothErosionSurfaces[number] = permanentToothErosionSurfacesByToothType[TOOTH_TYPES_BY_TOOTH_NUMBER[number]];
});


// for deciduous teeth, erosion surfaces are the same for incisors in the same jaw,
// but different in each jaw,
// and different for each individual premolar and molar tooth

const deciduousIncisorErosionSurfacesUpperJaw = freeze({
    [TOOTH_WEAR_ZONES.BUCCAL]: {
        [PROJECTIONS.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [PROJECTIONS.PALATAL]: [],
        [PROJECTIONS.INCISAL]: [11, 12, 13, 14, 15, 16, 17],
    },
    [TOOTH_WEAR_ZONES.PALATAL]: {
        [PROJECTIONS.BUCCAL]: [],
        [PROJECTIONS.PALATAL]: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34],
        [PROJECTIONS.INCISAL]: [18, 19, 20, 21, 22, 23, 24]
    },
});

toothErosionSurfaces[51] = clone(deciduousIncisorErosionSurfacesUpperJaw);
toothErosionSurfaces[61] = clone(toothErosionSurfaces[51]);

toothErosionSurfaces[52] = clone(deciduousIncisorErosionSurfacesUpperJaw);
toothErosionSurfaces[62] = clone(toothErosionSurfaces[52]);

toothErosionSurfaces[53] = clone(deciduousIncisorErosionSurfacesUpperJaw);
toothErosionSurfaces[63] = clone(toothErosionSurfaces[53]);

toothErosionSurfaces[54] = {
    [TOOTH_WEAR_ZONES.BUCCAL]: {
        [PROJECTIONS.BUCCAL]: TOOTH_SURFACES[54][PROJECTIONS.BUCCAL],
        [PROJECTIONS.PALATAL]: [],
        [PROJECTIONS.INCISAL]: [9, 10, 11, 12],
    },
    [TOOTH_WEAR_ZONES.PALATAL]: {
        [PROJECTIONS.BUCCAL]: [],
        [PROJECTIONS.PALATAL]: TOOTH_SURFACES[54][PROJECTIONS.PALATAL],
        [PROJECTIONS.INCISAL]: [16, 17, 18, 19]
    },
};
toothErosionSurfaces[64] = toothErosionSurfaces[54];

toothErosionSurfaces[55] = {
    [TOOTH_WEAR_ZONES.BUCCAL]: {
        [PROJECTIONS.BUCCAL]: TOOTH_SURFACES[55][PROJECTIONS.BUCCAL],
        [PROJECTIONS.PALATAL]: [],
        [PROJECTIONS.INCISAL]: [11, 12],
    },
    [TOOTH_WEAR_ZONES.PALATAL]: {
        [PROJECTIONS.BUCCAL]: [],
        [PROJECTIONS.PALATAL]: TOOTH_SURFACES[55][PROJECTIONS.PALATAL],
        [PROJECTIONS.INCISAL]: [16, 17]
    },
};
toothErosionSurfaces[65] = toothErosionSurfaces[55];


const deciduousIncisorErosionSurfacesLowerJaw = freeze({
    [TOOTH_WEAR_ZONES.BUCCAL]: {
        [PROJECTIONS.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [PROJECTIONS.PALATAL]: [],
        [PROJECTIONS.INCISAL]: [18, 19, 20, 21, 22, 23, 24],
    },
    [TOOTH_WEAR_ZONES.PALATAL]: {
        [PROJECTIONS.BUCCAL]: [],
        [PROJECTIONS.PALATAL]: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34],
        [PROJECTIONS.INCISAL]: [11, 12, 13, 14, 15, 16, 17]
    },
});

toothErosionSurfaces[81] = clone(deciduousIncisorErosionSurfacesLowerJaw);
toothErosionSurfaces[71] = clone(toothErosionSurfaces[81]);

toothErosionSurfaces[82] = clone(deciduousIncisorErosionSurfacesLowerJaw);
toothErosionSurfaces[72] = clone(toothErosionSurfaces[82]);


toothErosionSurfaces[83] = clone(deciduousIncisorErosionSurfacesLowerJaw);
toothErosionSurfaces[73] = clone(toothErosionSurfaces[83]);

toothErosionSurfaces[84] = {
    [TOOTH_WEAR_ZONES.BUCCAL]: {
        [PROJECTIONS.BUCCAL]: TOOTH_SURFACES[84][PROJECTIONS.BUCCAL],
        [PROJECTIONS.PALATAL]: [],
        [PROJECTIONS.INCISAL]: [12, 13],
    },
    [TOOTH_WEAR_ZONES.PALATAL]: {
        [PROJECTIONS.BUCCAL]: [],
        [PROJECTIONS.PALATAL]: TOOTH_SURFACES[84][PROJECTIONS.PALATAL],
        [PROJECTIONS.INCISAL]: [17, 18]
    },
};
toothErosionSurfaces[74] = toothErosionSurfaces[84];

toothErosionSurfaces[85] = {
    [TOOTH_WEAR_ZONES.BUCCAL]: {
        [PROJECTIONS.BUCCAL]: TOOTH_SURFACES[85][PROJECTIONS.BUCCAL],
        [PROJECTIONS.PALATAL]: [],
        [PROJECTIONS.INCISAL]: [12, 13],
    },
    [TOOTH_WEAR_ZONES.PALATAL]: {
        [PROJECTIONS.BUCCAL]: [],
        [PROJECTIONS.PALATAL]: TOOTH_SURFACES[85][PROJECTIONS.PALATAL],
        [PROJECTIONS.INCISAL]: [17, 18]
    },
};
toothErosionSurfaces[75] = toothErosionSurfaces[85];


// the structure is TOOTH_EROSION_SURFACES[toothNumber][wearZone][wearProjection]
const TOOTH_EROSION_SURFACES = freeze(toothErosionSurfaces);



const buildCanvasPattern = (size, numberOfStripes, color1, color2) => {
    // Create a pattern, offscreen
    const patternCanvas = document.createElement('canvas');
    const patternContext = patternCanvas.getContext('2d');

    // Give the pattern a width and height of 50
    patternCanvas.width = size;
    patternCanvas.height = size;

    for (var i = 0; i < numberOfStripes * 2 ; i++){
        var thickness = size / numberOfStripes;
        patternContext.beginPath();
        patternContext.strokeStyle = i % 2 ? color1 : color2;
        patternContext.lineWidth = thickness;
        patternContext.lineCap = 'round';
        patternContext.moveTo(i * thickness + thickness / 2 - size, 0);
        patternContext.lineTo(0 + i * thickness + thickness / 2, size);
        patternContext.stroke();
    }

    patternContext.stroke();

    return patternCanvas;
};

const EROSION_PATTERN_CANVAS = buildCanvasPattern(50, 6, COLORS.erosionPatternEven, COLORS.erosionPatternOdd);


export {
    TOOTH_EROSION_SURFACES,
    EROSION_PATTERN_CANVAS
};
