<template>
    <main data-view="profile">
        <div class="content">
            <h1>{{ t('profile', 'sideMenu.account') }}</h1>

            <form method="post" v-on:submit.prevent="saveUser">

                <input-field
                    v-for="field in userFields"
                    :key="field.attribute"
                    :name="field.id"
                    :type="field.type"
                    :options="field.options"
                    v-model="userValues[field.attribute]"
                    :label="t(field.labelKey, 'settingsVC.profile')"
                    :errors="userErrors[field.attribute]"
                    :ref="field.ref"
                />

                <p class="actions">
                    <waiting-button :for="waiterName" type="submit" class="button">
                        {{ t('save', 'settingsVC.profile') }}
                    </waiting-button>
                </p>
            </form>

            <back-button type="close" />
        </div>
    </main>
</template>
<script>
import CloseOnEscape from '@/mixins/closeOnEscape.js';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { },
    mixins: [ CloseOnEscape ],
    props: {
    },
    data() {
        return {
            userValues: undefined,
            userErrors: {},
            userFields: [
                {
                    attribute: "full_name",
                    labelKey: "fullName",
                    id: "user-name",
                    ref: "userNameInput",
                },
                {
                    attribute: "email",
                    labelKey: "email",
                    id: "user-email",
                },
                {
                    attribute: "phone_number",
                    labelKey: "phone",
                    id: "user-phone",
                },
                {
                    attribute: "locale",
                    labelKey: "locale",
                    id: "user-locale",
                    type: "select",
                    options: this.$store.state.i18n.localeNames
                },
            ]
        };
    },
    computed: {
        userAttributes() { return this.userFields.map(field => field.attribute); },
    },
    watch:
    {
    },
    created() {
        this.waiterName = WAITERS.SAVING_PROFILE;
        this.loadUser();
    },
    mounted() {
        this.focusFullName();
    },
    methods:
    {
        loadUser() {
            this.userValues = this.buildUserValues(this.$store.state.authentication.user);
        },

        buildUserValues(user) {
            return JSON.parse(JSON.stringify(this.userAttributes.reduce((object, attribute) => {
                object[attribute] = (user) ? user[attribute] : null;
                return object;
            }, {})));
        },

        focusFullName() {
            this.$refs.userNameInput[0].focus();
        },

        close() {
            this.goToRoute(this.backRoute);
        },

        saveUser() {
            this.$wait.start(this.waiterName);

            this.$store.dispatch('authentication/updateProfile', this.userValues).then(() => {
                this.$store.dispatch('i18n/setLocale', this.userValues.locale);
                this.close();
            }, (error) => {
                if (error.errors) {
                    this.userErrors = error.errors;
                } else {
                    throw error;
                }
            }).finally(() => this.$wait.end(this.waiterName));

        }

    }
};
</script>
<style lang="scss">
@import "~@/assets/stylesheets/views/profile";
</style>
