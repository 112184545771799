<template>
    <periodontal-probing :tooth="tooth" view="tooth" />
</template>
<script>
import PeriodontalProbing from '@/components/patient/tooth/periodontal-probing.vue';
export default {
    components: { PeriodontalProbing },
    props: {
        tooth: { required: true }
    }
};
</script>
