import {Howl} from 'howler';

// this object is for actual sound instances of all known sounds. sounds names as keys.
// sound instances are stored outside the store,
// to avoid vuex slowing things down by attempting to make each native property of the sound object reactive
const soundInstances = {

};

let supportedLocales = ['en', 'de'];
let fallbackLocale = 'en';

let soundNames = [
    // measurements
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    'greater-12',

    // issues
    'bleeding',
    'plaque',
    'pus',
    'tartar',

    // zones
    'lingual',
    'disto-lingual',
    'mesio-lingual',
    'palatal',
    'disto-palatal',
    'mesio-palatal',
    'buccal',
    'disto-buccal',
    'mesio-buccal',

    // furcation
    'furcation',
    'grade-1',
    'grade-2',
    'grade-3',

    // tooth numbers
    'molar-tooth',
    'incisor-tooth',
    'premolar-tooth',
    '1-1',
    '1-2',
    '1-3',
    '1-4',
    '1-5',
    '1-6',
    '1-7',
    '1-8',
    '2-1',
    '2-2',
    '2-3',
    '2-4',
    '2-5',
    '2-6',
    '2-7',
    '2-8',
    '3-1',
    '3-2',
    '3-3',
    '3-4',
    '3-5',
    '3-6',
    '3-7',
    '3-8',
    '4-1',
    '4-2',
    '4-3',
    '4-4',
    '4-5',
    '4-6',
    '4-7',
    '4-8',
];

export default {
    namespaced: true,

    state: {
        locale: undefined,

        needed: false,
        loaded: false
    },

    getters: {

    },

    mutations: {
        setLocale(state, locale) {
            state.locale = locale;
        },
        markSoundsAsNeeded(state) {
            state.needed = true;
        },
        setLoadedState(state, loaded) {
            state.loaded = loaded;
        }
    },

    actions: {
        setLocale(context, locale) {
            if (context.state.locale != locale) {
                context.commit('setLocale', locale);
                context.commit('setLoadedState', false);
            }
            return context.dispatch('loadSoundsIfNeeded');
        },
        markSoundsAsNeeded(context) {
            context.commit('markSoundsAsNeeded');
            return context.dispatch('loadSoundsIfNeeded');
        },
        loadSoundsIfNeeded(context) {
            if (context.state.locale && context.state.needed && !context.state.loaded) {
                return context.dispatch('loadSounds');
            }
        },
        loadSounds(context) {
            context.commit('setLoadedState', false);

            let locale = context.state.locale;

            if (!supportedLocales.includes(locale)) {
                locale = fallbackLocale;
            }

            // delete previously loaded locale sounds
            for (const key of Object.keys(soundInstances)) {
                delete soundInstances[key];
            }

            soundNames.forEach(soundName => {
                var sound = new Howl({
                    src: [
                        require('@/assets/audio/' + locale + '/' + soundName + '.webm'),
                        require('@/assets/audio/' + locale + '/' + soundName + '.mp3')
                    ],
                    autoplay: false,
                    onload: () => {
                        soundInstances[soundName] = sound;
                        const loaded = (Object.keys(soundInstances).length == soundNames.length);
                        context.commit('setLoadedState', loaded);
                    },
                    onloaderror: (_, error) => {
                        var params = { soundName, error };
                        document.dispatchEvent(
                            new CustomEvent(
                                'sentry-error',
                                { detail: { error: 'Sound load error', params } }
                            )
                        );
                    },
                    onplayerror: (_, error) => {
                        var params = { soundName, error };
                        document.dispatchEvent(
                            new CustomEvent(
                                'sentry-error',
                                { detail: { error: 'Sound play error', params } }
                            )
                        );
                    },
                });
            });
        },
        play(context, soundNames) {
            if (!context.state.loaded) {
                return;
            }

            const soundName = soundNames.shift();

            const sound = soundInstances[soundName];
            if (soundNames.length > 0) {
                sound.once('end', () => {
                    context.dispatch('play', soundNames);
                });
            }

            sound.play();
        }
    }

};
