<template>
    <div class="single-jaw jaw-box">
        <periodontal-issue-legend row="top" v-if="periodontalLayerActive" />

        <ol class="jaw" :data-type="code">
            <li
                v-for="(tooth, index) in teeth"
                :key="tooth.number"
                :class="{ tooth: true, selected: tooth.selected }"
                :data-number="tooth.number"
            >
                <periodontal-issues
                    :tooth-number="tooth.number"
                    :projection="periodontalIssueProjections.top"
                    v-if="periodontalLayerActive && isToothPresent(tooth.number)"
                />

                <tooth-trigger :tooth="tooth" :mode="mode">
                    <tooth-visualization
                        v-for="projection in visualizationProjections"
                        :key="projection"
                        :number="tooth.number"
                        :previous-number="(index > 0) ? teeth[index - 1].number : undefined"
                        :next-number="(index < teeth.length - 1) ? teeth[index + 1].number : undefined"
                        :projection="projection"
                        :layers="layers"
                        :context="visualizationContext"
                        :draw-blank-images="shouldDrawBlankImages"
                        :surrounded-by-erupted-teeth="tooth.surroundedByEruptedTeeth"
                    />
                    <tooth-number :tooth="tooth" />
                </tooth-trigger>

                <periodontal-issues
                    :tooth-number="tooth.number"
                    :projection="periodontalIssueProjections.bottom"
                    v-if="periodontalLayerActive && isToothPresent(tooth.number)"
                />
            </li>
        </ol>

        <periodontal-issue-legend row="bottom" v-if="periodontalLayerActive" />
    </div>
</template>

<script>
import ToothVisualization from '@/components/patient/tooth/visualization.vue';
import PeriodontalIssues from '@/components/patient/chart/periodontal-issues.vue';
import PeriodontalIssueLegend from '@/components/patient/chart/periodontal-issue-legend.vue';
import ToothTrigger from '@/components/patient/chart/tooth-trigger.vue';
import ToothNumber from '@/components/patient/chart/tooth-number.vue';
import { JAWS, VISUALIZATION_CONTEXTS, PROJECTIONS, CHART_MODES } from '@/config/teeth.js';

export default {
    components: { ToothVisualization, PeriodontalIssues, PeriodontalIssueLegend, ToothTrigger, ToothNumber },
    props: {
        code: { required: true },
        teeth: { required: true },
        mode: { required: true, validator: value => Object.values(CHART_MODES).includes(value) },
        layers: { required: true }
    },
    data: () => {
        return {
            visualizationContext: VISUALIZATION_CONTEXTS.JAW
        };
    },
    computed:
    {
        periodontalIssueProjections() {
            if (this.code == JAWS.UPPER) {
                return {
                    top: PROJECTIONS.BUCCAL,
                    bottom: PROJECTIONS.PALATAL
                };
            } else if (this.code == JAWS.LOWER) {
                return {
                    top: PROJECTIONS.LINGUAL,
                    bottom: PROJECTIONS.BUCCAL
                };
            } else {
                return {};
            }
        },
        visualizationProjections() {
            if (this.code == JAWS.UPPER) {
                return [PROJECTIONS.BUCCAL, PROJECTIONS.INCISAL, PROJECTIONS.PALATAL];
            } else if (this.code == JAWS.LOWER) {
                return [PROJECTIONS.LINGUAL, PROJECTIONS.INCISAL, PROJECTIONS.BUCCAL];
            } else {
                return {};
            }
        },

        periodontalLayerActive() {
            return this.layers.includes('periodontal');
        },

        shouldDrawBlankImages() {
            return this.mode == CHART_MODES.QUICKSELECT;
        }
    },
    methods: {
        isToothPresent(toothNumber) {
            return this.$store.getters['patient/teeth/state/isToothPresent'](toothNumber);
        }
    }
};
</script>
