<template>
    <button
        :disabled="isDisabled"
        :class="{ waiting: $wait.is(waiterName) }"
        v-on="listeners"
    >
        <v-wait :for="waiterName">
            <template slot="waiting">
                <button-spinner :size="24" />
            </template>
            <slot />
        </v-wait>

    </button>
</template>

<script>

import { HalfCircleSpinner } from 'epic-spinners';

export default {
    components: { 'ButtonSpinner': HalfCircleSpinner },
    props: {
        for: { required: true },
        disabled: { required: false }
    },
    computed: {
        waiterName() { return this.for; },
        listeners() { return { ...this.$listeners }; },
        isDisabled() { return this.disabled || this.$wait.is(this.waiterName); }
    }
};
</script>
