export default {
    namespaced: true,

    state: {
        shouldShowBrowserWarning: false,
        viewportStatus: 'ok',
        viewportOrientationIsCorrect: true,
        viewportIsBigEnough: true,
    },

    getters: {
        viewportStatus: (state) => {
            if (state.viewportOrientationIsCorrect === false) {
                return 'bad-orientation';
            } else if (state.viewportIsBigEnough === false) {
                return 'bad-size';
            }

            return 'ok';
        },
    },

    mutations: {
        showBrowserWarning(state) {
            state.shouldShowBrowserWarning = true;
        },
        hideBrowserWarning(state) {
            state.shouldShowBrowserWarning = false;
        },
        viewportOrientationIsCorrect(state, value) {
            state.viewportOrientationIsCorrect = value;
        },
        viewportIsBigEnough(state, value) {
            state.viewportIsBigEnough = value;
        },
    },

    actions: {
        showBrowserWarning(context) {
            context.commit('showBrowserWarning');
        },
        hideBrowserWarning(context) {
            context.commit('hideBrowserWarning');
        },
        viewportOrientationIsCorrect(context, value) {
            context.commit('viewportOrientationIsCorrect', value);
        },
        viewportIsBigEnough(context, value) {
            context.commit('viewportIsBigEnough', value);
        },
    }
};
