<template>
    <div class="tools overlay" v-on:click.self="close">
        <aside class="panel">
            <h2 class="heading">{{ userFullName }}</h2>

            <ul class="menu">
                <li>
                    <h3 class="heading">{{ t('title', 'sideMenu.account') }}</h3>
                    <ul>
                        <navigation-link tag="li" :to="{ name: 'profile' }">{{ t('profile', 'sideMenu.account') }}</navigation-link>
                        <navigation-link tag="li" :to="{ name: 'settings' }">{{ t('settings', 'sideMenu.account') }}</navigation-link>
                        <navigation-link tag="li" :to="{ name: 'billing' }">{{ t('billing', 'sideMenu.account') }}</navigation-link>
                    </ul>
                </li>
                <li v-if="termsUrlExists || privacyUrlExists">
                    <ul>
                        <li v-if="termsUrlExists">
                            <a :href="termsUrl" target="_blank">
                                {{ t('terms',  'sideMenu.about') }}
                            </a>
                        </li>
                        <li v-if="privacyUrlExists">
                            <a :href="privacyUrl" target="_blank">
                                {{ t('privacy',  'sideMenu.about') }}
                            </a>
                        </li>
                        <li>
                            <a :href="contactUsUrl" target="_blank">
                                {{ t('contactUs',  'sideMenu.about') }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

            <p class="actions">
                <button type="button" v-on:click="signOut">
                    {{ t('logOut', 'sideMenu') }}
                </button>
            </p>
        </aside>

        <waiting-overlay v-if="$wait.is(signingOutWaiterName)" />

    </div>
</template>

<script>
import WaitingOverlay from '@/components/waiting-overlay';
import CloseOnEscape from '@/mixins/closeOnEscape.js';

import { WAITERS } from '@/config/waiters.js';

export default {
    components: { WaitingOverlay },
    mixins: [ CloseOnEscape ],
    data() {
        return {

        };
    },
    computed: {
        userFullName() {
            return this.$store.getters['authentication/userFullName'];
        },

        termsUrl() {
            return this.$store.getters['externalUrls/termsUrl'];
        },
        privacyUrl() {
            return this.$store.getters['externalUrls/privacyUrl'];
        },
        contactUsUrl() {
            return this.$store.getters['externalUrls/contactUsUrl'];
        },

        termsUrlExists() {
            return !!this.termsUrl;
        },
        privacyUrlExists() {
            return !!this.privacyUrl;
        },
    },
    created() {
        this.signingOutWaiterName = WAITERS.SIGNING_OUT;
    },
    methods: {
        close() {
            this.$emit('close');
        },
        signOut() {
            this.$store.dispatch('authentication/signOut');
        }
    }
};
</script>
