<template>
    <main data-view="token-sign-in">
        <waiting-overlay />
    </main>
</template>

<script>
import WaitingOverlay from '@/components/waiting-overlay';
export default {
    components: { WaitingOverlay },

    data: () => {
        return {

        };
    },
    created() {
        this.signInSession();
    },
    methods: {
        signInSession() {
            const signInParameters = { token: this.$route.params.sign_in_token };

            this.$store.dispatch('authentication/singInViaToken', signInParameters).then(() => {
                // nothing to do here.
                // after the session has been loaded, the router will take care of redirecting to the single patient
            }, () => {
                // on any error, refresh the page by going to root.
                // cannot use goToRoute here, because the whole app needs to be reinitialized,
                // since the current session had not been loaded when opening this token sign-in url
                window.location = "/";
            });
        }
    }
};


</script>
