import merge from 'lodash/merge';
import { PATHOLOGIES } from '@/config/teeth.js';
import PathologyType from '../pathologyType.js';
import { DECAY_DETAIL_TYPES, DECAY_STAGES, DECAY_CAVITATIONS } from '@/config/pathologies/decay.js';

export default merge({}, PathologyType, {

    state: {
        name: PATHOLOGIES.DECAY
    },

    getters: {
        isDetailAvailable: () => ({ detailName, details }) => {
            if (detailName == DECAY_DETAIL_TYPES.PULP) {
                const dentin = details.stage.values.includes(DECAY_STAGES.DENTIN);
                const cavitation = details.cavitation.values.includes(DECAY_CAVITATIONS.CAVITATION);
                return dentin && cavitation;
            } else {
                return true;
            }
        }
    },

    mutations: {

    },

    actions: {

    }

});
