<template>
    <section class="periodontal panel">
        <header>
            <h2 class="heading">{{ t('title', 'periodontalProbing') }} </h2>

            <router-link class="button link" data-icon="add" :to="toothRoute(tooth, 'periodontal-probing')">
                {{ t('addProbing', 'periodontalProbing') }}
            </router-link>
        </header>

        <ul class="zones">
            <zone-pad
                v-for="zoneName in zoneNames"
                :key="zoneName"
                :zone-name="zoneName"
                :tooth-number="toothNumber"
                :zone-route="zoneRoute(zoneName)"
                link-method="push"
            />
        </ul>
    </section>
</template>

<script>
import ZonePad from '@/components/patient/tooth/overview/periodontal/zone-pad.vue';
export default {
    components: { ZonePad },
    props: {
        tooth: { required: true }
    },
    data() {
        return {

        };
    },
    computed:
    {
        zoneNames() {
            return this.$store.getters['patient/teeth/state/periodontal/zoneNames'](this.toothNumber);
        },
        toothNumber() {
            return this.tooth.number;
        },
    },
    methods:
    {
        zoneRoute(zoneName) {
            return this.toothRoute(this.tooth, 'periodontal-probing', { zone_name: zoneName } );
        }

    }
};
</script>
