export default {
    props: {
        mode: { required: true }
    },
    computed: {
        activeChartLayers() {
            return this.$store.getters['chart/activeLayers'];
        }
    },
    created() {
        this.$store.dispatch('navigation/storeMouthView', {
            name: this.$route.meta['mouth-view'],
            patientId: this.$store.state.patient.id
        });
    },

    methods: {

    }
};
