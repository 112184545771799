<template>
    <div data-view="full-mouth">
        <full-mouth :teeth="teeth" :mode="mode" :layers="activeChartLayers" />
    </div>
</template>

<script>
import MouthView from '@/mixins/mouthView.js';
import FullMouth from '@/components/patient/chart/full-mouth.vue';

export default {
    components: { FullMouth },
    mixins: [ MouthView ],
    props: {
        teeth: { required: true }
    },
    data: () => {
        return {

        };
    },

    computed:
    {

    },

    created() {

    },

    beforeDestroy() {

    },

    methods: {

    }

};
</script>
