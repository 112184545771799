<template>
    <div class="details">

        <fieldset
            v-for="(detail, detailName) in details"
            :key="detailName"
            class="detail"
            :data-name="detailName"
            :data-multiple="areMultipleValuesAllowed(detailName)"
        >
            <legend v-if="detail.labelKey">{{ t(detail.labelKey) }}</legend>

            <span class="options">
                <span
                    v-for="option in detail.options"
                    :key="option.value"
                    class="option"
                    :class="{ selected: isOptionSelected(detailName, option.value)}"
                >
                    <input
                        :type="inputType(detailName)"
                        :name="detailName"
                        :id="inputId(detailName, option.value)"
                        :value="option.value"
                        :checked="isOptionSelected(detailName, option.value)"
                        v-on:input="handleInput(detailName, option.value, $event.target.checked)"
                        v-on:click="handleClick(detailName, option.value)"
                    />
                    <label :for="inputId(detailName, option.value)">{{ option.label }}</label>
                </span>
            </span>

        </fieldset>

    </div>

</template>
<script>


export default {
    props: {
        value: { required: true },
    },
    data() {
        return {

        };
    },

    computed: {
        details() {
            return this.value;
        },

    },

    methods: {
        inputType(detailName) {
            return this.areMultipleValuesAllowed(detailName) ? "checkbox" : "radio";
        },
        inputId(detailName, optionValue) {
            return detailName + '-' + optionValue;
        },
        areMultipleValuesAllowed(detailName) {
            return this.details[detailName].multiple;
        },
        selectedOptionValues(detailName) {
            return this.details[detailName].values;
        },
        isOptionSelected(detailName, optionValue) {
            return this.selectedOptionValues(detailName).includes(optionValue);
        },


        handleInput(detailName, optionValue, checked) {
            if (checked) {
                return this.selectOption(detailName, optionValue);
            } else {
                return this.deselectOptionIfSelected(detailName, optionValue);
            }
        },

        handleClick(detailName, optionValue) {
            if (!this.areMultipleValuesAllowed(detailName)) {
                return this.deselectOptionIfSelected(detailName, optionValue);
            }
        },


        selectOption(detailName, optionValue) {
            if (!this.isOptionSelected(detailName, optionValue)) {
                let values;
                if (this.areMultipleValuesAllowed(detailName)) {
                    // set values to an array of all selected values in definition order
                    const selectedValues = this.selectedOptionValues(detailName).concat([ optionValue ]);
                    values = this.details[detailName].options.map(option => option.value).filter(value => selectedValues.includes(value));
                } else {
                    values = [ optionValue ];
                }
                this.$emit('input', { detailName, values });
            }
        },
        deselectOptionIfSelected(detailName, optionValue) {
            if (this.isOptionSelected(detailName, optionValue)) {
                const values = this.selectedOptionValues(detailName).filter(value => value != optionValue);
                this.$emit('input', { detailName, values });
            }
        }
    }


};
</script>
