<template>
    <main data-view="tooth" :data-tooth-number="toothNumber">
        <template v-if="tooth && isToothAvailable">

            <confirmation-dialog
                :for="rootCanalDiagnosisWaiterName"
                v-if="suggestedRootCanalDiagnosis"
                v-on:confirm="confirmRootCanalDiagnosis"
                v-on:close="rejectRootCanalDiagnosis"
                :confirmation-label="t('agree','common')"
            >
                <h1 class="title"> {{ suggestedRootCanalDiagnosisTitle }} </h1>
                <p class="diagnosis"> {{ suggestedRootCanalDiagnosisMessage }} </p>
            </confirmation-dialog>


            <tooth-wheel
                v-on:change="handleToothWheelChange($event)"
                :tooth="tooth"
            />

            <transition name="fade" mode="out-in">
                <router-view :tooth="tooth" />
            </transition>
        </template>
        <p v-else-if="tooth && !isToothAvailable">
            {{ t('Tooth is not available', 'dentalDetailsVC') }}
        </p>
        <p v-else>
            {{ t('Tooth number not recognized', 'dentalDetailsVC') }}
        </p>
    </main>
</template>

<script>
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import ToothWheel from '@/components/tooth-wheel.vue';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { ConfirmationDialog, ToothWheel },
    inheritAttrs: false,
    computed:
    {
        tooth() {
            return this.$store.getters['patient/teeth/toothBySlug'](this.$route.params.tooth_slug);
        },
        toothNumber() {
            return (this.tooth) ? this.tooth.number : null;
        },
        isToothAvailable() {
            return this.$store.getters['patient/teeth/state/isToothAvailable'](this.toothNumber);
        },
        suggestedRootCanalDiagnosis() {
            return this.$store.state.patient.teeth.state.rootCanal.suggestedDiagnoses[0];
        },
        suggestedRootCanalDiagnosisTitle() {
            return this.$store.getters['patient/teeth/convertToothLabelToCurrentNotation']({
                message: this.suggestedRootCanalDiagnosis.title,
                toothNumber: this.suggestedRootCanalDiagnosis.toothNumber
            });

        },
        suggestedRootCanalDiagnosisMessage() {
            return this.$store.getters['patient/teeth/convertToothLabelToCurrentNotation']({
                message: this.suggestedRootCanalDiagnosis.message,
                toothNumber: this.suggestedRootCanalDiagnosis.toothNumber
            });
        }

    },
    created() {
        this.rootCanalDiagnosisWaiterName = WAITERS.SAVING_ROOT_CANAL_DIAGNOSIS;
    },
    beforeMount() {
        // expose diagnostic tools in global scope so that they can be accessed directly from tests
        window.rootCanalDiagnosticMatrix = this.$store.getters['patient/teeth/state/rootCanal/diagnosticMatrix'];
        window.findRootCanalDiagnosis = this.$store.getters['patient/teeth/state/rootCanal/findDiagnosis'];
    },
    beforeDestroy() {
        delete window.rootCanalDiagnosticMatrix;
        delete window.findRootCanalDiagnosis;
    },
    methods:
    {
        handleToothWheelChange(event) {
            const tooth = this.$store.getters['patient/teeth/tooth'](event.toothNumber);
            if (tooth) {
                this.goToRoute(this.toothRoute(tooth), { replace: true });
            }
        },
        confirmRootCanalDiagnosis() {
            return this.$store.dispatch('patient/teeth/state/rootCanal/confirmDiagnosis', { uuid: this.suggestedRootCanalDiagnosis.uuid });
        },
        rejectRootCanalDiagnosis() {
            return this.$store.dispatch('patient/teeth/state/rootCanal/rejectDiagnosis', { uuid: this.suggestedRootCanalDiagnosis.uuid });
        }
    }
};
</script>
<style lang="scss">
@import "~@/assets/stylesheets/views/patient/tooth";
</style>
