<template>
    <section class="dental panel">
        <h2 class="heading">{{ t('title', 'dentalDetailsVC') }} </h2>

        <ul v-if="recordsExist" class="records">
            <li
                v-for="record in records"
                class="record"
                :key="record.issue_record_id"
                :data-type="record.type"
                :class="{ 'root-canal': record.issue_record_id == rootCanalIssueId }"
                :data-id="record.issue_record_id"
            >
                <waiting-button class="done" :for="issueResolvingWaiterName(record.issue_record_id)" type="button" v-on:click="resolveRecord(record.issue_record_id)">
                    {{ t('Done', 'dentalDetailsVC.treatmentPlan') }}
                </waiting-button>
                {{ recordMessage(record) }}
            </li>
        </ul>

        <p class="text" v-else>
            {{ t('noTreatment', 'patientVC') }}
        </p>
    </section>
</template>

<script>
export default {
    components: {  },
    props: {
        tooth: { required: true }
    },
    data() {
        return {

        };
    },
    computed:
    {
        records() {
            return this.$store.getters['patient/treatmentPlan/orderedActiveItemsByToothNumber'](this.tooth.number);
        },
        recordsExist() {
            return this.records.length > 0;
        },
        rootCanalIssueId() {
            return this.$store.getters['patient/teeth/state/rootCanal/toothState'](this.tooth.number).issueId;
        }
    },

    methods:
    {
        recordMessage(record) {
            return this.$store.getters['patient/treatmentPlan/recordMessage'](record);
        },

        resolveRecord(recordId) {
            return this.ensureTeethStateModifiability().then(() => {
                return this.$store.dispatch('patient/treatmentPlan/resolveRecord', recordId);
            }, () => {});
        },

        issueResolvingWaiterName(recordId) {
            return this.$store.getters['patient/treatmentPlan/resolvingWaiterName'](recordId);
        }
    }

};
</script>
