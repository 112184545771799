import freeze from '@/utils/freeze.js';
import { PATHOLOGIES, RESTORATIONS } from '@/config/teeth.js';

import { DECAY_DEFINITION } from '@/config/pathologies/decay.js';
import { FRACTURE_DEFINITION } from '@/config/pathologies/fracture.js';
import { TOOTH_WEAR_DEFINITION } from '@/config/pathologies/tooth-wear.js';
import { DISCOLORATION_DEFINITION } from '@/config/pathologies/discoloration.js';
import { APICAL_DEFINITION } from '@/config/pathologies/apical.js';
import { DEVELOPMENT_DISORDER_DEFINITION } from '@/config/pathologies/development-disorder.js';

import { FILLING_DEFINITION } from '@/config/restorations/filling.js';
import { INLAY_DEFINITION } from '@/config/restorations/inlay.js';
import { ONLAY_DEFINITION } from '@/config/restorations/onlay.js';
import { VENEER_DEFINITION } from '@/config/restorations/veneer.js';
import { PARTIAL_CROWN_DEFINITION } from '@/config/restorations/partial-crown.js';
import { CROWN_DEFINITION } from '@/config/restorations/crown.js';


const PATHOLOGIES_AND_RESTORATIONS = freeze({
    [PATHOLOGIES.DECAY]: DECAY_DEFINITION,
    [PATHOLOGIES.FRACTURE]: FRACTURE_DEFINITION,
    [PATHOLOGIES.TOOTH_WEAR]: TOOTH_WEAR_DEFINITION,
    [PATHOLOGIES.DISCOLORATION]: DISCOLORATION_DEFINITION,
    [PATHOLOGIES.APICAL]: APICAL_DEFINITION,
    [PATHOLOGIES.DEVELOPMENT_DISORDER]: DEVELOPMENT_DISORDER_DEFINITION,

    [RESTORATIONS.FILLING]: FILLING_DEFINITION,
    [RESTORATIONS.INLAY]: INLAY_DEFINITION,
    [RESTORATIONS.ONLAY]: ONLAY_DEFINITION,
    [RESTORATIONS.VENEER]: VENEER_DEFINITION,
    [RESTORATIONS.PARTIAL_CROWN]: PARTIAL_CROWN_DEFINITION,
    [RESTORATIONS.CROWN]: CROWN_DEFINITION
});


const PATHOLOGY_AND_RESTORATION_ACTIONS = freeze({
    MONITOR: "monitor",
    TREAT: "treat",
    SAVE: "save"
});

export {
    PATHOLOGIES_AND_RESTORATIONS,

    PATHOLOGY_AND_RESTORATION_ACTIONS
};
