<template>
    <transition name="slide-top" appear>
        <div class="quickselect panel">

            <ul class="actions">
                <li
                    v-for="action in actions"
                    :key="action"
                    class="action"
                    :class="{ disabled: !actionEnabled(action) }"
                >
                    <component
                        :is="(actionHasParams(action)) ? 'button' : 'waiting-button'"
                        :for="(actionHasParams(action)) ? undefined : actionWaiterName(action)"
                        type="button"
                        :disabled="!actionEnabled(action)"
                        v-on:click="selectAction(action)"
                    >
                        {{ actionLabel(action) }}
                    </component>
                </li>
            </ul>

            <form method="post" class="details" v-if="selectableParam" v-on:submit.prevent="performSelectedAction">
                <button class="close" type="button" v-on:click="clearSelectedAction">
                    <svg-image name="close" :title="t('close', 'common')" width="21" height="21" />
                </button>

                <p class="options" v-bind="optionAttributes">
                    <span
                        v-for="option in selectableParamOptions"
                        :key="selectableParam + option.value"
                        class="option"
                        :class="{ selected: isOptionSelected(selectableParam, option.value)}"
                    >
                        <input
                            type="radio"
                            :name="selectableParam"
                            :id="inputId(selectableParam, option.value)"
                            :value="option.value"
                            :checked="isOptionSelected(selectableParam, option.value)"
                            v-on:input="handleOptionInput(selectableParam, option.value, $event.target.checked)"
                            v-on:click="handleOptionClick(selectableParam, option.value)"
                        />
                        <label :for="inputId(selectableParam, option.value)">{{ option.label }}</label>
                    </span>

                    <button class="button link clear" type="button" v-if="anyParamsSelected" v-on:click="clearLastParam">
                        {{ t('clear', 'common') }}
                    </button>
                </p>

                <p class="actions" v-if="savingIsAvailable">
                    <waiting-button
                        :for="actionWaiterName(selectedAction)"
                        class="button"
                        type="submit"
                    >
                        {{ t('save', 'dentalDetailsVC.dental') }}
                    </waiting-button>
                </p>
            </form>

        </div>
    </transition>

</template>
<script>
import Vue from 'vue';
import CloseOnEscape from '@/mixins/closeOnEscape.js';
import clone from '@/utils/clone.js';

import { QUICKSELECT_ACTIONS, QUICKSELECT_ACTIONS_IN_ORDER } from '@/config/quickselect.js';

export default  {
    components: { },
    mixins: [ CloseOnEscape ],
    props: {
        view: { required: true }
    },
    data() {
        return {
            actions: clone(QUICKSELECT_ACTIONS_IN_ORDER),

            selectedAction: undefined,
            selectedParams: {}
        };
    },
    computed: {
        selectedToothNumbers() {
            return this.$store.getters['patient/teeth/quickselect/selectedToothNumbers'];
        },
        anyTeethSelected() {
            return this.selectedToothNumbers.length > 0;
        },
        uneruptedToothNumbers() {
            return this.$store.getters['patient/teeth/relevantNumbers'].filter(toothNumber => {
                return !this.$store.getters['patient/teeth/state/isToothErupted'](toothNumber);
            });
        },
        anyUneruptedTeethSelected() {
            return this.selectedToothNumbers.some(number => this.uneruptedToothNumbers.includes(number));
        },
        anyUnextractableTeethSelected() {
            return this.selectedToothNumbers.some(number => !this.$store.getters['patient/teeth/state/isToothExtractable'](number));
        },
        enabledActions() {
            return this.actions.filter(action => this.actionEnabled(action));
        },
        selectedActionParams() {
            return (this.selectedAction) ? this.actionParams(this.selectedAction) : {};
        },
        optionAttributes() {
            const attributes = {};
            if (this.selectedAction == "endoTests") {
                attributes['data-endo-test'] = this.selectedParams.test;
            }
            return attributes;
        },
        selectedActionParamNames() {
            return Object.keys(this.selectedActionParams);
        },
        selectedActionHasParams() {
            return this.selectedActionParamNames.length > 0;
        },

        selectedParamNames() {
            return Object.keys(this.selectedParams);
        },
        anyParamsSelected() {
            return this.selectedParamNames.length > 0;
        },
        selectableParam() {
            // calculate the first param which does not have its value set yet.
            // if all params have their values set, then stay with the last one
            if (!this.selectedActionHasParams) {
                return undefined;
            }
            const paramNames = this.selectedActionParamNames;

            let paramName = paramNames.find(paramName => this.selectedParams[paramName] === undefined);
            if (!paramName) {
                paramName = paramNames[paramNames.length - 1];
            }

            return paramName;
        },

        selectableParamLabel() {
            const param = this.actionParams(this.selectedAction)[this.selectableParam];
            if (!param.labelKey) {
                return null;
            }
            return this.t(param.labelKey);
        },

        selectableParamOptions() {
            if (!this.selectableParam) {
                return [];
            }
            return this.selectedActionParams[this.selectableParam].options.map(option => {
                if (option.labelKey) {
                    option.label = this.t(option.labelKey);
                }
                return option;
            });
        },

        savingIsAvailable() {
            if (this.selectedAction == QUICKSELECT_ACTIONS.ENDO_TESTS ) {
                return this.selectedParams.test && this.selectedParams.result;
            } else if (this.selectedAction == QUICKSELECT_ACTIONS.SLOT_STATE) {
                return this.selectedParams.operation;
            } else {
                return true;
            }
        }
    },
    watch: {
        view() {
            // normally this should not happen, because quickselect mode should not allow switching jaws
            // but still, add this just in case somehow the view changes while quickselect is open
            this.clearSelectedNumbers();
        },
        enabledActions(enabledActions) {
            if (this.selectedAction && !enabledActions.includes(this.selectedAction)) {
                this.clearSelectedAction();
            }
        },
        selectableParamOptions(selectableParamOptions) {
            // clear the currently selected option, if it is no longer available
            if (this.selectableParam) {
                const selectedParamValue = this.selectedParams[this.selectableParam];
                if (selectedParamValue) {
                    const selectableParamOptionValues = selectableParamOptions.map(option => option.value);
                    if (!selectableParamOptionValues.includes(this.selectedParams[this.selectableParam])) {
                        this.clearLastParam();
                    }
                }
            }
        }
    },
    created() {
        this.clearSelectedNumbers();
    },
    beforeDestroy() {
        this.clearSelectedNumbers();
    },
    methods: {
        actionEnabled(action) {
            if (!this.anyTeethSelected) {
                return false;
            }

            if (this.anyUneruptedTeethSelected && action != QUICKSELECT_ACTIONS.SLOT_STATE) {
                return false;
            }

            if (action == QUICKSELECT_ACTIONS.TO_BE_EXTRACTED && this.anyUnextractableTeethSelected) {
                return false;
            }

            return true;
        },
        actionParams(action) {
            return this.$store.getters['patient/teeth/quickselect/actionParams'](action, this.selectedParams);
        },
        actionHasParams(action) {
            return Object.keys(this.actionParams(action)).length > 0;
        },
        actionLabel(action) {
            const text = this.t(action, 'quickselect.actions');

            if (this.actionHasParams(action)) {
                return text + ' >';
            } else {
                return text;
            }
        },
        actionWaiterName(action) {
            return this.$store.getters['patient/teeth/quickselect/actionWaiterName'](action);
        },
        clearSelectedNumbers() {
            this.$store.dispatch('patient/teeth/quickselect/clearSelectedToothNumbers');
        },
        selectAction(action) {
            this.selectedAction = action;
            this.selectedParams = {};
            if (!this.selectedActionHasParams) {
                this.performSelectedAction();
            }
        },
        clearSelectedAction() {
            this.selectedAction = undefined;
            this.selectedParams = {};
        },
        performSelectedAction() {
            const action = this.selectedAction;
            const params = this.selectedParams;
            this.$store.dispatch('patient/teeth/quickselect/performAction', { action, params }).then(() => {
                this.clearSelectedAction();
            });
        },

        inputId(param, optionValue) {
            return param + '-' + optionValue;
        },
        isOptionSelected(param, optionValue) {
            return this.selectedParams[param] == optionValue;
        },

        handleOptionInput(param, optionValue, checked) {
            if (checked) {
                return this.selectOption(param, optionValue);
            } else {
                return this.deselectOptionIfSelected(param, optionValue);
            }
        },

        handleOptionClick(detailName, optionValue) {
            return this.deselectOptionIfSelected(detailName, optionValue);
        },

        selectOption(param, optionValue) {
            Vue.set(this.selectedParams, param, optionValue);
        },
        deselectOptionIfSelected(param, optionValue) {
            if (this.selectedParams[param] == optionValue) {
                Vue.delete(this.selectedParams, param);
            }
        },
        clearLastParam() {
            Vue.delete(this.selectedParams, this.selectableParam);
            Vue.delete(this.selectedParams, this.selectedParamNames.pop() );
        },

        close() {
            if (this.selectedAction) {
                return this.clearSelectedAction();
            } else {
                const route = this.chartRoute('overview', this.$route.meta['mouth-view']);
                this.goToRoute(route, { replace: true });
            }
        }
    }
};
</script>
