export default {
    documentListeners: {
        keydown: 'closeOnEscape'
    },
    methods: {
        closeOnEscape(event) {
            if (event.keyCode == 27) {
                this.close();
            }
        },
    }
};
