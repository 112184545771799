import { SEXTANTS_IN_ORDER } from '@/config/basic-periodontal-examination.js';
import clone from '@/utils/clone.js';
import SextantPad from '@/components/patient/dashboard/basic-periodontal-examination/sextant-pad.vue';
import RollbackDateNote from '@/components/patient/rollback/date-note.vue';
export default {
  __name: 'sextant-pads',
  props: {
    mode: {
      type: String,
      required: true
    },
    scoresBySextant: {
      type: Object,
      required: true
    },
    selectedSextant: {
      type: String,
      required: false
    }
  },
  emits: ['sextant-click'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var sextants = computed(function () {
      return clone(SEXTANTS_IN_ORDER);
    });
    var padClicked = function padClicked(sextant) {
      emit('sextant-click', {
        sextant: sextant
      });
    };
    var editingIsAvailable = computed(function () {
      return store.getters['patient/basicPeriodontalExamination/editingIsAvailable'];
    });
    return {
      __sfc: true,
      store: store,
      sextants: sextants,
      emit: emit,
      padClicked: padClicked,
      editingIsAvailable: editingIsAvailable,
      SextantPad: SextantPad,
      RollbackDateNote: RollbackDateNote
    };
  }
};