import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
export var useNavigation = function useNavigation() {
  var store = useStore();
  var router = useRouter();

  // duplicated in mixins/navigation.js
  var goToRoute = function goToRoute(route, options) {
    var method = options && options.replace ? 'replace' : 'push';
    return router[method](route)["catch"](function (error) {
      if (error && error.name != 'NavigationDuplicated') {
        throw error;
      }
    });
  };

  // duplicated in mixins/navigation.js
  var ensureTeethStateModifiability = function ensureTeethStateModifiability() {
    if (store.getters['patient/teeth/state/isModifiable']) {
      return Promise.resolve();
    } else {
      store.dispatch('patient/teeth/state/openRollbackWarning');
      return Promise.reject();
    }
  };
  return {
    goToRoute: goToRoute,
    ensureTeethStateModifiability: ensureTeethStateModifiability
  };
};