import merge from 'lodash/merge';
import { RESTORATIONS } from '@/config/teeth.js';
import RestorationType from '../restorationType.js';

export default merge({}, RestorationType, {

    state: {
        name: RESTORATIONS.PARTIAL_CROWN
    },

    getters: {

    },

    mutations: {

    },

    actions: {

    }

});
