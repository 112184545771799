import ModalDialog from '@/components/modal-dialog.vue';
export default {
  __name: 'unsaved-changes-dialog',
  props: {
    waiterName: {
      type: String,
      required: true
    }
  },
  emits: ['save', 'discard', 'cancel'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var save = function save() {
      emit('save');
    };
    var discard = function discard() {
      emit('discard');
    };
    var cancel = function cancel() {
      emit('cancel');
    };
    var cancelButton = ref(null);
    var focusCancel = function focusCancel() {
      if (cancelButton.value) {
        cancelButton.value.focus();
      }
    };
    return {
      __sfc: true,
      emit: emit,
      save: save,
      discard: discard,
      cancel: cancel,
      cancelButton: cancelButton,
      focusCancel: focusCancel,
      ModalDialog: ModalDialog
    };
  }
};