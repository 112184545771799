import freeze from '@/utils/freeze.js';

const TEETH_STATE_TRANSFORMATIONS = freeze({
    FROM_API: "from-api",
    TO_API: "to-api"
});


const TRANSFORMABLE_KEYS = freeze({

    // ios app sends some keys to api as camelCase
    // and they are camelCase in the database.
    // API returns them as snake_case

    // web app uses kebab-case internally

    [TEETH_STATE_TRANSFORMATIONS.FROM_API]: {
        "tooth_wear": "tooth-wear",
        "development_disorder": "development-disorder"
    },
    [TEETH_STATE_TRANSFORMATIONS.TO_API]: {
        "tooth-wear": "tooth_wear",
        "development-disorder": "development_disorder"
    }
});

const transformTeethStateKeys = (stateBranch, direction) => {
    Object.keys(stateBranch).forEach((key) => {
        if (stateBranch[key] && typeof stateBranch[key] == 'object' && !(stateBranch[key] instanceof Array)) {
            stateBranch[key] = transformTeethStateKeys(stateBranch[key], direction);
        }
        if (Object.keys(TRANSFORMABLE_KEYS[direction]).includes(key)) {
            stateBranch[TRANSFORMABLE_KEYS[direction][key]] = stateBranch[key];
            delete stateBranch[key];
        }
    });
    return stateBranch;
};


export { transformTeethStateKeys, TEETH_STATE_TRANSFORMATIONS };
