<template>
    <div data-view="patients" :data-total-number-of-patients="$store.state.patientList.totalNumberOfPatients" :data-patient-limit="$store.getters['authentication/actualPatientLimit']">

        <div class="actions">
            <form
                class="search"
                method="get"
                v-on:submit.prevent="search"
                :class="{ waiting: $wait.is(searchingWaiterName) }"
            >
                <search-spinner v-if="$wait.is(searchingWaiterName)" :size="24" />
                <input type="search" name="search" :placeholder="t('searchPatients', 'patientsVC')" v-model.trim="searchQuery" />
            </form>

            <button class="button" data-icon="add" type="button" v-on:click="editNewPatient">{{ t('newPatient', 'patientsVC') }}</button>
        </div>

        <template v-if="shouldShowPatientList">
            <div v-if="anyPatientsShown" class="content">
                <table class="patients">
                    <thead>
                        <tr>
                            <th class="name" scope="column">{{ t('name', 'patientsVC') }}</th>
                            <th class="id" scope="column">{{ t('idNumber', 'patientsVC') }}</th>
                            <th class="date" scope="column">{{ t('dateOfBirth', 'patientsVC') }}</th>
                            <th class="address" scope="column">{{ t('address', 'patientsVC') }}</th>
                            <th class="actions" scope="column"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="patient in patientList" :key="patient.patient_id">
                            <th class="name" scope="row">
                                <router-link :to="{ name: 'patient', params: { patient_id: patient.patient_id } }">
                                    {{ patient.full_name }}
                                </router-link>
                            </th>
                            <td class="id">
                                {{ patient.person_code }}
                            </td>
                            <td class="date">
                                {{ formatDate(patient.date_of_birth) }}
                            </td>
                            <td class="address">
                                {{ patient.address }}
                            </td>
                            <td class="actions">
                                <button
                                    type="button"
                                    class="tools-toggle"
                                    :title="patientToolsToggleTranslation(patient.patient_id)"
                                    v-on:click.stop="togglePatientTools(patient.patient_id)"
                                >
                                    {{ patientToolsToggleTranslation(patient.patient_id) }}
                                </button>

                                <patient-tools
                                    v-if="patientToolsAreOpen(patient.patient_id)"
                                    v-on:close="closePatientTools"
                                    v-on:edit="editExistingPatient(patient)"
                                    v-on:delete="askForPatientDeletion(patient)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p v-if="morePatientsExistThanShown" class="more">
                    {{ t('Use search to see more patients', 'patientsVC') }}
                </p>
            </div>
            <p v-else class="not-found">
                {{ t('patientsNotFound', 'patientsVC') }}
            </p>
        </template>

        <patient-dialog v-if="editingPatient" :patient="editablePatient" v-on:close="closePatientDialog" />

        <patient-limit-dialog v-else-if="showingPatientLimitDialog" v-on:close="closePatientLimitDialog" />

        <confirmation-dialog :for="deletionWaiterName" v-if="deletablePatient" v-on:confirm="deletePatient" v-on:close="closePatientDeletionDialog">
            <h1 class="title"> {{ t('delete', 'common') }} </h1>
            <p>
                {{ deletablePatient.full_name }}
            </p>
        </confirmation-dialog>

        <subscription-banner />
    </div>
</template>
<script>
import PatientDialog from '@/components/user/patient-dialog.vue';
import PatientLimitDialog from '@/components/user/patient-limit-dialog.vue';
import PatientTools from '@/components/user/patient-tools.vue';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import SubscriptionBanner from '@/components/subscription-banner.vue';
import debounce from 'lodash/debounce';
import { HalfCircleSpinner } from 'epic-spinners';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: {
        PatientDialog,
        PatientLimitDialog,
        PatientTools,
        ConfirmationDialog,
        'SearchSpinner': HalfCircleSpinner,
        SubscriptionBanner
    },
    data() {
        return {
            patientListDisplayedAtLeastOnce: false,

            showingPatientLimitDialog: false,

            editablePatient: undefined,
            deletablePatient: undefined,

            toolablePatientId: undefined,
        };
    },
    computed: {
        searchQuery: {
            get() { return this.$route.query.search; },
            set(value) {
                let query = Object.assign({}, this.$route.query);
                if (value && value.length > 0) {
                    query.search = value;
                } else {
                    delete query.search;
                }
                this.$store.dispatch('patientList/setAttemptedQuery', value);
                this.goToRoute({ query }, { replace: true });
            }
        },

        patientList() {
            return this.$store.getters['patientList/items'];
        },

        shouldShowPatientList() {
            // this prevents initial flash of incorrect results when loading a page with search params
            // (when loaded query is different than the one in route).
            // afterwards, when the list has been displayed at least once,
            // it should always be displayed

            return this.patientListDisplayedAtLeastOnce || this.searchQuery == this.$store.state.patientList.query;
        },

        anyPatientsShown() {
            return this.$store.getters['patientList/numberOfItems'] > 0;
        },

        morePatientsExistThanShown() {
            const numberOfItems = this.$store.getters['patientList/numberOfItems'];
            const totalNumberOfItems = this.$store.state.patientList.totalNumberOfItems;

            return numberOfItems > 0 && totalNumberOfItems > 0 && numberOfItems < totalNumberOfItems;
        },

        editingPatient() { return !!this.editablePatient; },
    },
    watch: {
        searchQuery() {
            this.debouncedSearch();
        },
        shouldShowPatientList(value) {
            if (value) {
                this.patientListDisplayedAtLeastOnce = true;
            }
        }
    },
    created() {
        this.searchingWaiterName = WAITERS.SEARCHING_PATIENTS;
        this.deletionWaiterName = WAITERS.DELETING_PATIENT;

        this.debouncedSearch = debounce(() => {
            this.search();
        }, 200);

        if (this.searchQuery) {
            this.search();
        }
        if (this.shouldShowPatientList) {
            this.patientListDisplayedAtLeastOnce = true;
        }
    },
    methods:
    {
        editNewPatient() {
            if (this.$store.getters['authentication/patientLimitReached']) {
                this.showingPatientLimitDialog = true;
            } else {
                this.editablePatient = {};
            }
        },

        editExistingPatient(patient) {
            this.editablePatient = patient;

            this.closePatientTools();
        },

        closePatientDialog() {
            this.editablePatient = undefined;
        },

        closePatientLimitDialog() {
            this.showingPatientLimitDialog = false;
        },

        askForPatientDeletion(patient) {
            this.deletablePatient = patient;

            this.closePatientTools();
        },

        closePatientDeletionDialog() {
            this.deletablePatient = undefined;
        },

        deletePatient() {
            this.$wait.start(this.deletionWaiterName);
            this.$api.deletePatient(this.deletablePatient.patient_id).then(() => {
                this.$store.dispatch('patientList/refresh');
                this.$store.dispatch('patientList/decreaseTotalNumberOfPatients');
                this.closePatientDeletionDialog();
            }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.deletionWaiterName));
        },

        search() {
            return this.$store.dispatch('patientList/search', this.searchQuery );
        },

        togglePatientTools(id) {
            if (this.toolablePatientId === id) {
                this.closePatientTools();

                return;
            }

            this.toolablePatientId = id;
        },

        closePatientTools() {
            this.toolablePatientId = undefined;
        },

        patientToolsAreOpen(id) {
            return this.toolablePatientId === id;
        },

        patientToolsToggleTranslation(id) {
            return this.patientToolsAreOpen(id)
                ? this.t('Hide tools', 'patientsVC')
                : this.t('Show tools', 'patientsVC');
        },

    }
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/user/patients";
</style>
