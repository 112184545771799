<template>
    <div class="treatment-plan" v-if="recordsExist">

        <h2>{{ t('treatmentPlan', 'patientVC.tabs') }}</h2>

        <ul class="records">
            <li
                v-for="record in records"
                :key="record.issue_record_id"
                class="record"
                :data-type="record.type"
                :data-id="record.issue_record_id"
            >
                <p class="text">{{ recordMessage(record) }}</p>
                <p class="date">{{ formatDate(dateFromUtcTimestamp(record.created_at)) }}</p>
            </li>
        </ul>

    </div>
</template>
<script>

export default {
    components: { },

    computed: {
        records() {
            return this.$store.getters['patient/treatmentPlan/orderedActiveItems'];
        },
        recordsExist() {
            return this.records.length > 0;
        }
    },

    methods: {
        recordMessage(record) {
            return this.$store.getters['patient/treatmentPlan/recordMessage'](record);
        }
    }
};

</script>
