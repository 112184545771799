import merge from 'lodash/merge';
import { PATHOLOGIES } from '@/config/teeth.js';
import PathologyAndRestoration from './pathologyAndRestoration.js';

import decay from './pathology/decay.js';
import fracture from './pathology/fracture.js';
import toothWear from './pathology/toothWear.js';
import discoloration from './pathology/discoloration.js';
import apical from './pathology/apical.js';
import developmentDisorder from './pathology/developmentDisorder.js';

export default merge({}, PathologyAndRestoration, {

    modules: {
        decay,
        fracture,
        toothWear,
        discoloration,
        apical,
        developmentDisorder
    },

    state: {
        name: "pathology",
        definedTypeNames: Object.values(PATHOLOGIES),

        // this holds the full pathology state for the tooth being edited
        editableTooth: {
            number: undefined,
            state: undefined
        }
    },

    getters: {

        // returns the editable state if the given tooth is being edited, or from the main store if not
        toothState: (state, getters) => (toothNumber) => {
            if (state.editableTooth.number == toothNumber) {
                return state.editableTooth.state;
            } else {
                return getters.storedToothState(toothNumber);
            }
        },

        superStoredToothState: PathologyAndRestoration.getters.storedToothState,
        storedToothState: (state, getters) => (toothNumber) => {
            // merge actually stored state into a blank state, so that all types have at least some values
            const blankState = getters.blankState(toothNumber);
            const storedState = getters.superStoredToothState(toothNumber);
            return Object.assign({}, blankState, storedState);
        },

        blankState: (state, getters, rootState, rootGetters) => (toothNumber) => {
            return getters['typeNames'](toothNumber).reduce((object, typeName) => {

                const blankTypeStateGetter = getters.typeStoreScope(typeName) + '/blankState';
                object[typeName] = rootGetters[blankTypeStateGetter];
                return object;
            }, {});
        },

        visualizationValues: (state, getters, rootState, rootGetters) => (toothNumber) => {
            const toothState = JSON.parse(JSON.stringify(getters.toothState(toothNumber)));
            const visualizationValues = {};
            Object.keys(toothState).forEach(typeName => {
                const typeStoreScope = getters.typeStoreScope(typeName);
                // simplify details structure to key -> value pairs in an object
                const details = rootGetters[typeStoreScope + '/details'](toothNumber);
                const surfaces = toothState[typeName].surfaces;
                const values = Object.keys(details).reduce((values, detailName) => {
                    let value = details[detailName].values;
                    if (details[detailName].multiple) {
                        if (value.length > 0) {
                            values[detailName] = value;
                        }
                    } else {
                        values[detailName] = value[0];
                    }

                    return values;
                }, {});

                visualizationValues[typeName] = {
                    surfaces,
                    values
                };
                const time = toothState[typeName].created_at;
                if (time) {
                    visualizationValues[typeName].created_at = time;
                }
            });
            return visualizationValues;
        },

        areActionsEnabled: () => () => {
            // pathology actions are enabled always, because saving empty values for pathologies is allowed.
            // note that action buttons are disabled in the vue component code if no type is selected at all
            return true;
        },

        saveableStateValues: (state) => () => {
            return { pathology: state.editableTooth.state };
        },

        creatableRecords: (state, getters) => (action) => {
            // a pathology should be added to history/treatment plan only if the saveable state is not blank
            // and if its values have been changed during editing

            const changedEntries = getters.changedEntries();
            return Object.keys(changedEntries).reduce((creatableRecords, typeName) => {
                if (!getters.isEntryBlank(typeName, changedEntries[typeName])) {
                    const creatableRecord = getters.creatableRecordFromEditableState({ action, typeName });
                    creatableRecords.push(creatableRecord);
                }
                return creatableRecords;
            }, []);

        },

        changedEntries: (state, getters) => () => {
            const editableState = state.editableTooth.state;
            const storedState = getters.storedToothState(state.editableTooth.number);

            const changedEntries = Object.keys(editableState).reduce((changedEntries, typeName) => {
                if (JSON.stringify(editableState[typeName]) != JSON.stringify(storedState[typeName])) {
                    changedEntries[typeName] = editableState[typeName];
                }
                return changedEntries;
            }, {});

            return changedEntries;
        },

        isEntryBlank: (state, getters, rootState, rootGetters) => (typeName, values) => {
            // blank means that the pathology has no surfaces and no details specified
            const camelizedTypeName = rootGetters['filters/camelize'](typeName);
            if (values.data == camelizedTypeName && values.surfaces.length < 1) {
                return true;
            }
            return false;
        }

    },

    mutations: {
        startEditingTooth( state, { toothNumber, toothState }) {
            state.editableTooth.number = toothNumber;
            state.editableTooth.state = toothState;
        },
        endEditingTooth( state ) {
            state.editableTooth.number = undefined;
            state.editableTooth.state = undefined;
        },
        setTypeState( state, { typeName, typeState } ) {
            state.editableTooth.state[typeName] = typeState;
        }
    },

    actions: {
        startEditingTooth(context, { toothNumber }) {
            if (context.state.editableTooth.number == toothNumber) {
                return; // already editing this tooth.
            }
            const toothState = context.getters.toothState(toothNumber);
            // clone the tooth state so that the editable copy does not point back to the main store
            const clonedState = JSON.parse(JSON.stringify(toothState));
            context.commit('startEditingTooth', { toothNumber, toothState: clonedState } );
        },

        setType() {
            // this does not have to do anything for pathology.
            // blank method is here for interface compatibility
            return;
        },

        superSaveState: PathologyAndRestoration.actions.saveState,
        saveState(context, { action }) {
            const toothNumber = context.state.editableTooth.number;
            const apicalChanged = Object.keys(context.getters.changedEntries()).includes(PATHOLOGIES.APICAL);
            return context.dispatch('superSaveState', { action }).then(() => {
                if (apicalChanged) {
                    context.dispatch('patient/teeth/state/rootCanal/analyze', { toothNumber }, { root: true });
                }
            });

        }


    }

});
