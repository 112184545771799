<template>
    <div class="browser-warning">
        <p v-html="messageHtml" />
        <button type="button" v-on:click="hide">
            <svg-image
                name="close"
                :title="t('close', 'common')"
                width="21"
                height="21"
            />
        </button>
    </div>
</template>
<script>
export default {

    computed: {
        messageHtml() {
            const message = this.t("Your browser is not supported. In order to have the best experience we recommend using the latest version of Google Chrome", "system");
            const escapedMessage = this.$options.filters.escapeHtml(message);
            return escapedMessage.replace(/Google Chrome/, '<a href="https://www.google.com/chrome/">Google Chrome</a>');
        }
    },
    methods: {

        hide() {
            this.$store.dispatch('general/hideBrowserWarning');
        }
    }
};
</script>
