<template>

    <div class="rollback" :class="{ open: isOpen }" :data-date="selectedDateOption">

        <transition name="slide-top" appear v-if="isOpen">
            <aside class="panel">

                <button
                    class="button"
                    type="button"
                    v-on:click="switchToPreviousDate"
                    :disabled="!previousDateAvailable"
                    :title="t('Previous', 'dentalChartVC.rollback')"
                >
                    ◄
                </button>

                <input-field
                    name="date"
                    type="select"
                    :options="dateOptions"
                    v-model="selectedDateOption"
                    :label="t('Select date', 'dentalChartVC.rollback')"
                />

                <button
                    class="button"
                    type="button"
                    v-on:click="switchToNextDate"
                    :disabled="!nextDateAvailable"
                    :title="t('Next', 'dentalChartVC.rollback')"
                >
                    ►
                </button>

            </aside>
        </transition>

        <panel-toggle-button
            :title="toggleButtonTranslation"
            :is-open="isOpen"
            v-if="hasAnyStates"
            v-on:toggle="toggle"
            icon="history"
            icon-width="24"
            icon-height="22"
        />

    </div>

</template>
<script>
import PanelToggleButton from '@/components/panel-toggle-button.vue';

const CURRENT_STATE_KEY = 'current';

export default {
    components: { PanelToggleButton },
    data() {
        return {

        };
    },
    computed: {
        hasAnyStates() {
            return this.$store.state.patient.teeth.state.history.dates.length > 0;
        },

        isOpen() {
            return this.$store.getters['chart/rollbackIsOpen'];
        },

        toggleButtonTranslation() {
            return this.isOpen
                ? this.t('Close rollback', 'dentalChartVC.rollback')
                : this.t('Open rollback', 'dentalChartVC.rollback');
        },

        dateOptions() {
            const options = {};

            const todaysDate = this.$store.state.patient.teeth.state.history.todaysDate;
            const dates = this.$store.state.patient.teeth.state.history.dates.concat().reverse();

            const todayExistsInDates = dates.includes(todaysDate);
            const teethStateIsModified = this.$store.state.patient.teeth.state.teethStateIsModified;
            const modifiedStateExists = this.$store.getters['patient/teeth/state/modifiedTeethStateExists'];

            if (teethStateIsModified || modifiedStateExists || todayExistsInDates) {
                options[CURRENT_STATE_KEY] = this.t('currentState', 'dentalChartVC.rollback');
            }

            dates.forEach(date => {
                if (date != todaysDate) {
                    // todays date is never shown as a date. it always points to current state
                    options[date] = this.formatDate(date);
                }
            });

            return options;
        },

        selectedDateOption: {
            get() {
                const date = this.$store.state.patient.teeth.state.teethStateDate;
                if (Object.keys(this.dateOptions).includes(date)) {
                    return date;
                }
                return Object.keys(this.dateOptions)[0];
            },
            set(value) {
                if (value == CURRENT_STATE_KEY || value == Object.keys(this.dateOptions)[0]) {
                    // when selecting the newest option or forcing the current state, restore the current in store
                    return this.$store.dispatch('patient/teeth/state/restoreCurrentTeethState');
                } else {
                    // otherwise go to the selected historical date
                    return this.$store.dispatch('patient/teeth/state/setHistoricalTeethState', value);
                }
            }

        },

        previousDateAvailable() {
            return !!this.getOffsetDateOption(-1);
        },

        nextDateAvailable() {
            return !!this.getOffsetDateOption(1);
        },

    },

    beforeMount() {
        if (this.isOpen) {
            // if when coming from another view, the panel is already open (because a historical state is loaded)
            // then set the forced open state to true as well, so that the panel does not close when selecting current state
            this.$store.dispatch('chart/toggleRollback', true);
        }
    },

    beforeDestroy() {
        // this will close the panel's forced open state, but preserve the selected date if any exists,
        // so that the panel remains open when coming back to this view
        this.$store.dispatch('chart/toggleRollback', false);
    },

    methods: {
        toggle() {
            const expectedState = !this.isOpen;
            if (this.isOpen) {
                // force current state (this will go to the latest even if "current" is not in the options list)
                this.selectedDateOption = CURRENT_STATE_KEY;
            }
            this.$store.dispatch('chart/toggleRollback', expectedState);
        },

        getOffsetDateOption(offset) {
            const options = Object.keys(this.dateOptions).concat().reverse();
            const position = options.indexOf(this.selectedDateOption);
            const offsetPosition = position + offset;
            if (offsetPosition > -1 && offsetPosition < options.length) {
                return options[offsetPosition];
            } else {
                return null;
            }
        },

        switchToPreviousDate() {
            this.switchToDate(-1);
        },
        switchToNextDate() {
            this.switchToDate(1);
        },
        switchToDate(offset) {
            const offsetDateOption = this.getOffsetDateOption(offset);
            if (offsetDateOption) {
                this.selectedDateOption = offsetDateOption;
            }
        }

    }
};
</script>
