<template>

    <div class="logo">
        <img :src="logoPath" :alt="t('derec', 'common')" />
    </div>

</template>
<script>
export default {
    data() {
        return {
            logoPath: require('@/assets/images/pdf/derec-logo.png')
        };
    }
};
</script>
