<template>
    <modal-dialog type="history-record" v-on:open="focusDescription" v-on:cancel="close" :close-on-outside-click="false">
        <h1 class="title">{{ t( 'title', 'patientVC.addHistoryRecord') }}</h1>

        <form method="post" v-on:submit.prevent="saveRecord">
            <input-field
                name="record-description"
                type="textarea"
                v-model="description"
                :label="t('description', 'patientVC.addHistoryRecord')"
                ref="description"
            />

            <p class="actions">
                <waiting-button
                    :for="waiterName"
                    type="submit"
                    class="button"
                    :disabled="descriptionIsBlank"
                    :class="{ disabled: descriptionIsBlank }"
                >
                    {{ t('addRecord', 'patientVC.addHistoryRecord') }}
                </waiting-button>

                <button class="button link cancel" type="button" v-on:click="close">{{ t('cancel', 'common') }}</button>
            </p>

        </form>
    </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modal-dialog.vue';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { ModalDialog },
    props: {
    },
    data() {
        return {
            description: undefined
        };
    },
    computed: {
        descriptionIsBlank() { return !this.description; }
    },
    watch:
    {

    },
    created() {
        this.waiterName = WAITERS.ADDING_HISTORY_RECORD;
    },
    mounted() {

    },
    methods:
    {
        focusDescription() {
            this.$refs.description.focus();
        },

        close() {
            this.$emit('close');
        },

        saveRecord() {
            return this.$store.dispatch('patient/history/addRecord', { message: this.description } ).then(() => {
                this.close();
            });
        }

    }
};
</script>
