import merge from 'lodash/merge';
import { RESTORATIONS, TOOTH_STATES } from '@/config/teeth.js';
import { CROWN_DETAIL_TYPES, CROWN_TYPES, CROWN_BASES } from '@/config/restorations/crown.js';

import RestorationType from '../restorationType.js';

export default merge({}, RestorationType, {

    state: {
        name: RESTORATIONS.CROWN
    },

    getters: {
        calculateSurfaces: () => () => {
            // crown surfaces are not stored and are always empty
            // the visualization code in drawing.vue collects all needed surfaces of the affected tooth for displaying
            return [];
        },

        isDetailAvailable: () => ({ detailName, details }) => {

            if (detailName == CROWN_DETAIL_TYPES.IMPLANT_TYPE) {
                if (!details.crown_base || !details.crown_base.values.includes(CROWN_BASES.IMPLANT)) {
                    return false;
                }
            }

            return true;
        },


        requireSurfacesForDetailSelection: () => {
            return false;
        },

        calculateStateValue: ()  => ({ details }) => { // (#32)
            if (
                (details[CROWN_DETAIL_TYPES.BASE])
                &&
                (details[CROWN_DETAIL_TYPES.BASE].values)
                &&
                (details[CROWN_DETAIL_TYPES.BASE].values.includes(CROWN_BASES.IMPLANT))
            ) {
                return TOOTH_STATES.IMPLANT;
            } else if (
                (details[CROWN_DETAIL_TYPES.TYPE])
                &&
                (details[CROWN_DETAIL_TYPES.TYPE].values)
                &&
                (details[CROWN_DETAIL_TYPES.TYPE].values.includes(CROWN_TYPES.PONTIC))
            ) {
                return TOOTH_STATES.CROWN;
            }

            return TOOTH_STATES.SINGLE_CROWN;
        },

        creatableRecordMessageZoneNames: () => () => {
            return [];
        }

    },

    mutations: {

    },

    actions: {

    }


});
