import freeze from '@/utils/freeze.js';

import {
    TOOTH_STATES
} from '@/config/teeth.js';

import { PULP_TYPES } from '@/config/root-canal.js';

import { DISCOLORATION_TYPES } from '@/config/pathologies/discoloration.js';

import { RESTORATION_MATERIALS } from '@/config/restorations/general.js';


// reuse quadrant 1 and 4 images for quadrants 2 and 3 because they are the same, only mirrored (flipped horizontally)
const IMAGE_QUADRANTS = freeze({ 1: 1, 2: 1, 3: 4, 4: 4 });

const IMAGE_TYPES = freeze({
    BASE: "base",
    OUTLINE: "outline",
    CROWN: "crown",
    IMPLANT: "implant",
    BLANK: "blank"
});

const IMAGE_TYPES_BY_TOOTH_STATE = freeze({
    [TOOTH_STATES.NORMAL]: IMAGE_TYPES.BASE,
    [TOOTH_STATES.MISSING]: IMAGE_TYPES.OUTLINE,
    [TOOTH_STATES.CROWN]: IMAGE_TYPES.CROWN,
    [TOOTH_STATES.VENEER]: IMAGE_TYPES.BASE,
    [TOOTH_STATES.IMPLANT]: IMAGE_TYPES.IMPLANT,
    [TOOTH_STATES.SINGLE_CROWN]: IMAGE_TYPES.BASE,
    [TOOTH_STATES.TO_BE_EXTRACTED]: IMAGE_TYPES.BASE
});


const COLORS = freeze({
    rootCanal: {
        [PULP_TYPES.MONITOR]: "rgba(255, 208, 12, 0.6)",
        [PULP_TYPES.URGENT]: "rgba(225, 56, 48, 0.6)",
        [PULP_TYPES.NECROTIC]: "rgba(78, 85, 101, 0.6)",
        [PULP_TYPES.ROOT_CANAL_TREATMENT]: "rgba(74, 144, 226, 0.6)",
    },
    discoloration: {
        [DISCOLORATION_TYPES.GRAY]: "rgba(210, 210, 210, 0.6)",
        [DISCOLORATION_TYPES.RED]: "rgba(250, 225, 224, 0.6)",
        [DISCOLORATION_TYPES.YELLOW]: "rgba(255, 248, 218, 0.6)"
    },
    fillings: {
        unknown: "rgba(130, 165, 222, 0.6)", // default color
        [RESTORATION_MATERIALS.COMPOSIT]: "rgba(51, 165, 222, 0.6)",
        [RESTORATION_MATERIALS.CERAMIC]: "rgba(127, 51, 222, 0.6)",
        [RESTORATION_MATERIALS.AMALGAM]: "rgba(130, 136, 143, 0.6)",
        [RESTORATION_MATERIALS.GOLD]: "rgba(255, 208, 10, 0.6)",
        [RESTORATION_MATERIALS.NON_PRECIOUS_METAL]: "rgba(176, 185, 195, 0.6)",
        [RESTORATION_MATERIALS.TEMPORARY]: "rgba(96, 232, 234, 0.6)",
    },
    erosionPatternEven: "rgba(255, 255, 255, 0.8)",
    erosionPatternOdd: "rgba(230, 230, 230, 0.8)",
    decay: "rgba(255, 122, 122, 0.5)",
    transparency: "rgba(255, 255, 255, 0)",
    probingDepthArea: "rgba(51, 166, 222, 0.5)",
    probingDepthLine: "#33a5de",
    gingivalMarginLine: "#e13830",
    fractureLine: "#ff0000",
    dashLine: "rgba(122, 132, 153, 0.3)"
});


export {
    IMAGE_QUADRANTS,
    IMAGE_TYPES,
    IMAGE_TYPES_BY_TOOTH_STATE,
    COLORS
};
