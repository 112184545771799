export default {
    namespaced: true,

    state: {
        urls: {
            public: {
                terms: {
                    en: "en/terms-and-conditions"
                },
                privacy: {
                    en: "en/privacy-policy-for-the-website-and-the-services"
                },
                contactUs: {
                    en: "en/support",
                    de: "en/support",
                    pt: "en/support",
                    fr: "en/support",
                    it: "en/support",
                    es: "en/support",
                },
            },
        }
    },

    getters: {
        publicUrl: (state, getters, rootState) => (type) => {
            const locale = rootState.i18n.locale;
            const path = state.urls.public[type][locale];
            return (path) ? `https://www.derec.ch/${path}` : null;
        },

        termsUrl: (state, getters) => getters.publicUrl('terms'),
        privacyUrl: (state, getters) => getters.publicUrl('privacy'),
        contactUsUrl: (state, getters) => getters.publicUrl('contactUs'),
    }
};
