<template>

    <fieldset class="margin selector" :class="{ ready: storeIsReady }"  :data-tooth="toothNumber" :data-zone="zoneName">
        <legend v-if="showLegend">{{ t('margin', 'periodontalProbing') }}</legend>

        <div class="options">
            <span
                v-for="option in options"
                :key="option"
                class="option"
                :disabled="!storeIsReady"
            >

                <!--
                    binding on input event is used to emit value normally,
                    but it does not emit if the checkbox is clicked without changing the value.

                    the value update needs to be emitted on every click, even if the value has not changed.
                    this is needed to trigger navigation to the next area/tooth in sequential auto-mode
                    even when the user clicks on an already selected pad (not changing the value).

                    thus an additional click event is used to emit the value on every click,
                    but only if the value has not changed (to prevent duplicate emits).
                -->
                <input
                    type="radio"
                    name="margin"
                    :id="optionId(option)"
                    :value="option"
                    :checked="editableValue == option"
                    v-on:input="setValue"
                    v-on:click="setValueIfNotChanged"
                    :disabled="!storeIsReady"
                />
                <label :for="optionId(option)">{{ label(option) }}</label>
            </span>

            <button type="button" v-on:click="toggleOptionSet"> +/- </button>
        </div>


    </fieldset>

</template>
<script>
export default {
    props: {
        toothNumber: { required: true },
        zoneName: { required: true },
        value: { required: true },
        showLegend: { required: true }
    },
    data: () => {
        return {
            optionSets: [
                [ 0, -1, -2, -3, -4, -5, -6, -7, -8, -9, -10, -11, -12, -13 ],
                [ 0, 1, 2, 3, 4, 5, 6, 7 ]
            ],
            forcedOptionSetIndex: undefined
        };
    },
    computed: {
        editableValue:
        {
            get() {
                return parseInt(this.value);
            },
            set(value) { this.$emit('input', parseInt(value)); }
        },

        currentOptionSetIndex() {
            return (this.forcedOptionSetIndex === undefined) ? this.optionSetIndexForValue : this.forcedOptionSetIndex;
        },

        optionSetIndexForValue() {
            return this.value > 0 && this.optionSets[1].includes(this.value) ? 1 : 0;
        },

        options() {
            return this.optionSets[this.currentOptionSetIndex];
        },

        storeIsReady() {
            return this.$store.getters['patient/teeth/state/periodontal/toothNumberBeingEdited'] == this.toothNumber;
        }
    },
    watch: {
        toothNumber() {
            this.forcedOptionSetIndex = undefined;
        },
        zoneName() {
            this.forcedOptionSetIndex = undefined;
        }
    },
    methods:
    {
        label(option) {
            return this.$store.getters['patient/teeth/state/periodontal/marginLabel'](option);
        },
        toggleOptionSet() {
            if (!this.forcedOptionSetIndex) {
                this.forcedOptionSetIndex = 1;
            } else {
                this.forcedOptionSetIndex = 0;
            }
        },
        optionId(option) {
            return `margin-${option}`;
        },
        setValueIfNotChanged(event) {
            if (this.editableValue == event.target.value) {
                this.setValue(event);
            }
        },
        setValue(event) {
            this.editableValue = event.target.value;
        }
    }
};
</script>
