import freeze from '@/utils/freeze.js';
import clone from '@/utils/clone.js';

import { TOOTH_TYPES, ZONES, TOOTH_SETS, TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE } from '@/config/teeth.js';

import { RESTORATION_DEFINITION_DEFAULT_DETAILS } from '@/config/restorations/general.js';

// "restoration": 3

const definition = {
    toothTypes: [TOOTH_TYPES.INCISOR, TOOTH_TYPES.PREMOLAR],
    teeth: { }
};

const permanentIncisorNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.INCISOR];
const deciduousIncisorNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.DECIDUOUS][TOOTH_TYPES.INCISOR];
const permanentPremolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.PREMOLAR];
const deciduousPremolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.DECIDUOUS][TOOTH_TYPES.PREMOLAR];

const veneerToothNumbers = permanentIncisorNumbers.concat(
    deciduousIncisorNumbers,
    permanentPremolarNumbers,
    deciduousPremolarNumbers
);

const veneerSurfaces = { };

// for permanent teeth, veneer surfaces are the same for all teeth of the same tooth type

permanentIncisorNumbers.forEach(number => {
    veneerSurfaces[number] = {
        [ZONES.BUCCAL]: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20],
        [ZONES.PALATAL]: [7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26],
        [ZONES.LINGUAL]: [7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]
    };
});

permanentPremolarNumbers.forEach(number => {
    veneerSurfaces[number] = {
        [ZONES.BUCCAL]: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 15, 17, 18, 20],
        [ZONES.PALATAL]: [13, 14, 15, 16, 17, 18, 19, 20],
        [ZONES.LINGUAL]: [13, 14, 15, 16, 17, 18, 19, 20],
    };
});


// for deciduous teeth, veneer surfaces are the same for incisors in the same jaw,
// but different in each jaw,
// and different for each individual premolar tooth

const deciduousIncisorVeneerSurfacesUpperJaw = {
    [ZONES.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].concat([11, 12, 13, 14, 15, 16, 17, 18, 19, 20], [25, 26, 27]),
    [ZONES.PALATAL]: [8, 9, 10].concat([15, 16, 17, 18, 19, 20, 21, 22, 23, 24], [25, 26, 27, 28, 29, 30, 31, 32, 33, 34] ),
};

veneerSurfaces["51"] = clone(deciduousIncisorVeneerSurfacesUpperJaw);
veneerSurfaces["61"] = clone(veneerSurfaces["51"]);

veneerSurfaces["52"] = clone(deciduousIncisorVeneerSurfacesUpperJaw);
veneerSurfaces["62"] = clone(veneerSurfaces["52"]);

veneerSurfaces["53"] = clone(deciduousIncisorVeneerSurfacesUpperJaw);
veneerSurfaces["63"] = clone(veneerSurfaces["53"]);

veneerSurfaces["54"] = {
    [ZONES.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8].concat([9, 10, 11, 12, 13, 14, 15, 16, 18], [20, 22, 23, 26]),
    [ZONES.PALATAL]: [20, 21, 22, 23, 24, 25, 26, 27].concat([17, 19], [])
};
veneerSurfaces["64"] = clone(veneerSurfaces["54"]);


const deciduousIncisorVeneerSurfacesLowerJaw = {
    [ZONES.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].concat([15, 16, 17, 18, 19, 20, 21, 22, 23, 24], [25, 26, 27]),
    [ZONES.LINGUAL]: [8, 9, 10].concat([11, 12, 13, 14, 15, 16, 17, 18, 19, 20], [25, 26, 27, 28, 29, 30, 31, 32, 33, 34] ),
};

veneerSurfaces["81"] = clone(deciduousIncisorVeneerSurfacesLowerJaw);
veneerSurfaces["71"] = clone(veneerSurfaces["81"]);

veneerSurfaces["82"] = clone(deciduousIncisorVeneerSurfacesLowerJaw);
veneerSurfaces["72"] = clone(veneerSurfaces["82"]);

veneerSurfaces["83"] = clone(deciduousIncisorVeneerSurfacesLowerJaw);
veneerSurfaces["73"] = clone(veneerSurfaces["83"]);


veneerToothNumbers.forEach(number => {
    definition.teeth[number] = {
        surfaces: veneerSurfaces[number],
        exclusive: [ ZONES.BUCCAL, ZONES.PALATAL, ZONES.LINGUAL ],
        default: [ ZONES.BUCCAL ],
        details: RESTORATION_DEFINITION_DEFAULT_DETAILS
    };
});

const VENEER_DEFINITION = freeze(definition);

export {
    VENEER_DEFINITION
};
