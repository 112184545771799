<template>
    <main data-view="dashboard">
        <div class="content primary">
            <div class="header">
                <div class="text">
                    <h1 class="name">
                        {{ patient.full_name }}
                    </h1>
                    <p class="details">
                        {{ genderText }} {{ ageText }}
                    </p>
                </div>

                <p class="actions">
                    <router-link class="button" data-icon="jaws" :to="$store.getters['navigation/storedChartRoute']">{{ t('chart', 'patientVC') }}</router-link>
                </p>
            </div>

            <nav class="dashboard">
                <ul>
                    <navigation-link tag="li" :to="{ name: 'treatment-plan' }" replace>{{ t('treatmentPlan', 'patientVC.tabs') }}</navigation-link>
                    <navigation-link tag="li" :to="{ name: 'history' }" replace>{{ t('history', 'patientVC.tabs') }}</navigation-link>
                    <navigation-link tag="li" :to="{ name: 'soft-tissue' }" replace>{{ t('softTissue', 'patientVC.tabs') }}</navigation-link>
                </ul>
            </nav>

            <router-view :key="patient.id" />
        </div>

        <div class="content secondary">
            <oral-health :patient-id="patient.id" />

            <basic-periodontal-examination :patient-id="patient.id" />

            <medical-issues :patient-id="patient.id" />
        </div>

        <data-processing-agreement v-if="!hasPatientAcceptedDataProcessing" />
        <subscription-banner />
    </main>
</template>

<script>
import OralHealth from '@/components/patient/dashboard/oral-health.vue';
import BasicPeriodontalExamination from '@/components/patient/dashboard/basic-periodontal-examination.vue';
import MedicalIssues from '@/components/patient/dashboard/medical-issues.vue';
import DataProcessingAgreement from '@/components/patient/dashboard/data-processing-agreement.vue';
import SubscriptionBanner from "@/components/subscription-banner.vue";

export default {

    components: {
        OralHealth,
        BasicPeriodontalExamination,
        MedicalIssues,
        DataProcessingAgreement,
        SubscriptionBanner
    },

    props: {
        patient: { required: true }
    },
    computed:
    {
        genderText() {
            return (this.patient.gender) ? this.t(this.patient.gender, "patientVC.gender") + ", ": '';
        },

        ageText() {
            let age = undefined;

            if (this.patient.date_of_birth) {

                const today = new Date();
                const birthDate = new Date(this.patient.date_of_birth);
                age = today.getFullYear() - birthDate.getFullYear();

                const m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
            }
            return (age !== undefined) ? age + this.t('y', 'patientVC.age') : '';
        },

        hasPatientAcceptedDataProcessing() {
            return this.$store.getters['patient/hasAcceptedDataProcessing'];
        }
    }
};
</script>
<style lang="scss">
@import "~@/assets/stylesheets/views/patient/dashboard";
</style>
