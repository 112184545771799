<template>
    <div :data-view="view">

        <div class="main">
            <h2 class="heading">
                {{ t('title', view ) }}
            </h2>

            <back-button type="close" />

            <type-selector :types="types" :base-route="baseRoute" />

            <details-selector v-model="details" />

            <p class="actions">
                <waiting-button
                    :for="waiterName(actionName)"
                    v-for="actionName in actionNames"
                    :key="actionName"
                    type="submit"
                    class="button"
                    :class="actionName"
                    :disabled="!actionsEnabled"
                    v-on:click="performAction(actionName)"
                >
                    {{ actionLabel(actionName) }}
                </waiting-button>
            </p>

        </div>

        <zone-selector v-model="zones" />
        <subscription-banner />

    </div>
</template>

<script>
import TypeSelector from '@/components/patient/tooth/pathology-and-restoration/type-selector.vue';
import DetailsSelector from '@/components/patient/tooth/pathology-and-restoration/details-selector.vue';
import ZoneSelector from '@/components/patient/tooth/pathology-and-restoration/zone-selector.vue';
import CloseOnEscape from '@/mixins/closeOnEscape.js';
import { PATHOLOGY_AND_RESTORATION_ACTIONS } from '@/config/pathologies-and-restorations.js';
import SubscriptionBanner from "@/components/subscription-banner.vue";

export default {
    components: {SubscriptionBanner, TypeSelector, DetailsSelector, ZoneSelector },
    mixins: [ CloseOnEscape ],
    props: {
        tooth: { required: true }
    },
    data() {
        return {
            view: undefined,
            selectedTypeName: undefined,
        };
    },
    computed: {
        routeView() {
            // pathology or restoration
            return this.$route.name;
        },
        routeTypeName() {
            return this.$route.params.type_name;
        },
        baseRoute() {
            return this.typeRoute(undefined);
        },

        storeModuleName() {
            return this.view;
        },
        otherStoreModuleName() {
            return ['pathology', 'restoration'].filter(view => view != this.storeModuleName)[0];
        },
        storeScope() {
            return 'patient/teeth/state/' + this.storeModuleName;
        },
        otherStoreScope() {
            return 'patient/teeth/state/' + this.otherStoreModuleName;
        },

        toothNumber() {
            return this.tooth.number;
        },
        typeNames() {
            return this.$store.getters[this.storeScope + '/typeNames'](this.toothNumber);
        },
        selectedType() {
            return this.$store.getters[this.storeScope + '/type'](this.selectedTypeName, this.toothNumber);
        },

        selectedTypeStoreScope() {
            return this.storeScope + '/' + this.$options.filters.camelize(this.selectedType.name);
        },

        types() {
            return this.$store.getters[this.storeScope + '/types'](this.toothNumber).map(type => {
                return Object.assign(
                    {},
                    type,
                    {
                        route: this.typeRoute(type.name),
                        selected: type.name == this.selectedTypeName
                    }
                );
            });
        },

        details: {
            get() {
                if (this.selectedType) {
                    return this.$store.getters[this.selectedTypeStoreScope + '/details'](this.toothNumber);
                } else {
                    return [];
                }
            },

            set({ detailName, values }) {
                this.$store.dispatch(this.selectedTypeStoreScope + '/setDetail', {
                    toothNumber: this.toothNumber,
                    detailName,
                    values
                });
            }
        },

        zones: {
            get() {
                const type = this.selectedType;
                if (type) {
                    const definedZoneNames = this.$store.getters[this.selectedTypeStoreScope + '/definedZoneNames'](this.toothNumber);
                    const selectedZoneNames = this.$store.getters[this.selectedTypeStoreScope + '/selectedZoneNames'](this.toothNumber);
                    const forcedZoneNames = type.forced;

                    return definedZoneNames.filter(zoneName => !forcedZoneNames.includes(zoneName)).map(zoneName => {
                        return {
                            name: zoneName,
                            selected: selectedZoneNames.includes(zoneName)
                        };
                    });
                } else {
                    return [];
                }
            },
            set(selectedZoneNames) {
                return this.$store.dispatch(this.selectedTypeStoreScope + '/setSelectedZoneNames', {
                    toothNumber: this.toothNumber,
                    selectedZoneNames
                });
            }
        },

        actionNames() {
            return Object.values(PATHOLOGY_AND_RESTORATION_ACTIONS);
        },
        actionsEnabled() {
            return this.selectedType && this.$store.getters[this.storeScope + '/areActionsEnabled']();
        }
    },
    watch: {
        toothNumber() {
            this.selectRouteType();
            this.startEditingTooth();
        },
        routeView() {
            this.setView();
        },
        view() {
            this.selectRouteType();
            this.startEditingTooth();
        },
        routeTypeName() {
            this.selectRouteType();
        },
        selectedTypeName(typeName){
            this.$store.dispatch(this.storeScope + '/setType', { toothNumber: this.toothNumber, typeName });
        }
    },
    created() {
        this.setView();
        this.selectRouteType();
        this.startEditingTooth();
    },
    beforeDestroy() {
        this.endEditingTooth();
    },
    methods: {
        setView() {
            this.view = this.routeView;
        },
        selectRouteType() {
            // this corrects unrecognized type names in URL (reroutes to no type)
            if (this.$store.getters[this.storeScope + '/isTypeNameValid'](this.routeTypeName, this.toothNumber)) {
                this.selectedTypeName = this.routeTypeName;
            } else if (this.routeTypeName) {
                this.goToRoute(this.baseRoute, { replace: true });
            } else {
                this.selectedTypeName = undefined;
            }
        },

        typeRoute(typeName) {
            return this.toothRoute(this.tooth, this.$route.name, { type_name: typeName || null } );
        },

        startEditingTooth() {
            this.$store.dispatch(this.otherStoreScope + '/endEditingTooth');
            this.$store.dispatch(this.storeScope + '/startEditingTooth', { toothNumber: this.toothNumber } );
        },
        endEditingTooth() {
            this.$store.dispatch(this.storeScope + '/endEditingTooth');
        },

        actionLabel(actionName) {
            const labelKey = (actionName == PATHOLOGY_AND_RESTORATION_ACTIONS.TREAT) ? "threat" : actionName;
            return this.t(labelKey, 'dentalDetailsVC.dental');
        },

        performAction(name) {
            this.$store.dispatch(this.storeScope + '/performAction', { name }).then(() => {
                this.goToRoute(this.toothRoute(this.tooth));
            });
        },

        waiterName(actionName) {
            return this.$store.getters[this.storeScope + '/waiterName'](actionName);
        },

        close() {
            this.goToRoute(this.backRoute);
        }
    }
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/patient/tooth/pathology-and-restoration";
</style>
