<template>
    <div data-view="sign-in">
        <back-button />

        <form class="authentication" novalidate="novalidate" v-on:submit.prevent="authenticate">
            <input-field
                name="email"
                type="email"
                v-model="email"
                :label="t('email', 'loginVC')"
                ref="emailInput"
            />
            <input-field
                name="password"
                type="password"
                v-model="password"
                :label="t('password', 'loginVC')"
                ref="passwordInput"
            />

            <div class="errors" v-if="errorMessages.length > 0">
                <p class="error" v-for="message in errorMessages" :key="message">
                    {{ message }}
                </p>
            </div>

            <p class="actions">
                <waiting-button :for="waiterName" type="submit" class="button">
                    {{ t('signIn', 'loginVC') }}
                </waiting-button>
            </p>
        </form>

        <p class="links">
            <router-link :to="{ name: 'reset-password' }">{{ t('forgotPassword', 'loginVC') }}</router-link>
        </p>
    </div>
</template>
<script>
import { WAITERS } from '@/config/waiters.js';

export default {

    data: () => {
        return {
            email: undefined,
            password: undefined,
            errorMessages: []
        };
    },
    created() {
        this.waiterName = WAITERS.SIGNING_IN;
    },
    mounted() {
        this.$refs.emailInput.focus();
    },
    methods: {
        authenticate() {
            this.errorMessages = [];
            if (this.email && this.password) {
                const signInParameters = { email: this.email, password: this.password };
                this.$store.dispatch('authentication/signIn', signInParameters).catch(error => {
                    if (error && error.messages) {
                        this.errorMessages = error.messages;
                    } else {
                        throw error;
                    }
                });
            } else if (!this.email) {
                this.$refs.emailInput.focus();
            } else if (!this.password) {
                this.$refs.passwordInput.focus();
            }
        }
    }
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/account/sign-in";
</style>
