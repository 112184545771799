import Vue from 'vue';
import { JAWS, FACES } from '@/config/teeth.js';
import { WAITERS } from '@/config/waiters.js';

export default {

    namespaced: true,

    state: {
        zoneValues: {
            "disto-palatal": 1,
            "disto-lingual": 1,
            "palatal": 2,
            "lingual": 2,
            "mesio-palatal": 3,
            "mesio-lingual": 3,
            "distal": 4,
            "occlusal": 5,
            "mesial": 6,
            "disto-buccal": 7,
            "buccal": 8,
            "mesio-buccal": 9
        },
        zoneNameRowsByJaw: {
            [JAWS.UPPER]: {
                [FACES.PALATAL_OR_LINGUAL]: [ "disto-palatal", "palatal", "mesio-palatal" ],
                [FACES.BUCCAL]: [ "disto-buccal", "buccal", "mesio-buccal" ]
            },
            [JAWS.LOWER]: {
                [FACES.PALATAL_OR_LINGUAL]: [ "disto-lingual", "lingual", "mesio-lingual" ],
                [FACES.BUCCAL]: [ "disto-buccal", "buccal", "mesio-buccal" ]
            }
        },
        zoneNamesByProjection: {
            buccal:  ["disto-buccal", "buccal", "mesio-buccal"],
            palatal: ["disto-palatal", "palatal", "mesio-palatal"],
            lingual: ["disto-lingual", "lingual", "mesio-lingual"]
        },
        issueValues: {
            bleeding: 0,
            plaque: 1,
            pus: 2,
            tartar: 3
        },

        editableTooth: {
            number: undefined,
            state: undefined
        },
        audioEnabled: localStorage.getItem('periodontal-probing-audio-enabled') === "true"
    },

    getters: {
        toothNumberBeingEdited: (state) => {
            return state.editableTooth.number;
        },

        isToothBeingEdited: (state) => {
            return state.editableTooth.number != undefined;
        },

        zoneNameRows: (state, getters, rootState, rootGetters) => (toothNumber) => {
            const jaw = rootGetters['patient/teeth/tooth'](toothNumber).jaw;
            // for teeth in quadrants 2-3 the zone names get reversed in each row to match the visualizations.
            // (e.g. mesio-buccal (towards the center of the mouth) is on the left side, so it comes first in the list)
            const reversed = rootGetters['patient/teeth/tooth'](toothNumber).reversed;

            const rowsByFace = state.zoneNameRowsByJaw[jaw];
            return Object.keys(rowsByFace).reduce((rows, face) => {
                const row = rowsByFace[face];

                rows[face] = (reversed) ? row.slice().reverse() : row;
                return rows;
            }, {});

        },

        zoneNameRowsInOrder: (state, getters) => (toothNumber) => {
            const rowsByFace = getters.zoneNameRows(toothNumber);
            return [ FACES.PALATAL_OR_LINGUAL, FACES.BUCCAL ].map(face => rowsByFace[face]);
        },

        zoneNames: (state, getters) => (toothNumber) => {
            return getters.zoneNameRowsInOrder(toothNumber).reduce((names, row) => { return names.concat(row); }, []);
        },

        issueNames: (state) => {
            return Object.keys(state.issueValues);
        },

        issueName: (state, getters) => (value) => {
            return getters.issueNames.find(name => state.issueValues[name] === value);
        },

        maxDepthForMargin: () => (marginValue) => {
            return (marginValue < 0) ? 13 + marginValue : 13;
        },

        loadedToothState: (state, getters) => (toothNumber) => {
            if (toothNumber === state.editableTooth.number) {
                return state.editableTooth.state;
            } else {
                return getters.toothStateFromMainStore(toothNumber);
            }
        },

        toothStateFromMainStore: (state, getters, rootState, rootGetters) => (toothNumber) => {
            const fullToothState = rootGetters['patient/teeth/state/tooth'](toothNumber) || {};
            return {
                periodontal: fullToothState["periodontal"] || {},
                furcation: fullToothState["furcation"] || 0,
                mobility: fullToothState["mobility"] || 0
            };
        },

        correctZoneName: (state, getters) => (toothNumber, attemptedZoneName, { chartMode }) => {
            const zoneNames = getters.zoneNames(toothNumber);
            if (zoneNames.includes(attemptedZoneName)) {
                // attempted zone exists for given tooth. return it
                return attemptedZoneName;
            }
            if (state.zoneValues[attemptedZoneName]) {
                // attempted zone does not exist for given tooth, but is a valid zone name
                // find its matching zone for this teeth (by numeric value)
                return zoneNames.find(zoneName => state.zoneValues[zoneName] == state.zoneValues[attemptedZoneName]);
            }

            // zone name is not valid or is blank, return default zone

            // the default zone differs in single tooth view and chart view.

            // in single tooth view, the default zone is always the first zone in the list
            if (!chartMode) {
                return zoneNames[0];
            }

            // in chart view, the default zone is the first buccal zone for this tooth
            // (disto-buccal for quadrants 1 and 4, mesio-buccal for quadrants 2 and 3)
            return zoneNames.find(zoneName => zoneName.includes('buccal'));
        },

        fixedIssueValues: (state) => (values) => {
            // de-duplicate and filter out garbage
            return [...new Set((values || []).filter(value => Object.values(state.issueValues).includes(value)))];
        },

        zone: (state, getters) => (toothNumber, zoneName) => {
            const periodontalState = getters.loadedToothState(toothNumber).periodontal;
            const zoneNames = getters.zoneNames(toothNumber);

            if (zoneNames.includes(zoneName)) {
                const zoneIndex = state.zoneValues[zoneName].toString();
                let zone = Object.assign({}, periodontalState[zoneIndex] || {});

                if (zone.depth === undefined) {
                    zone.depth = 0;
                }
                if (zone.margin === undefined) {
                    zone.margin = 0;
                }

                if (zone.issues === undefined) {
                    // periodontal issues are called 'details' on the API side.
                    // rename to issues and fix any problems
                    zone.issues = getters.fixedIssueValues(zone.details);
                    delete zone.details;
                }

                return zone;
            } else {
                return undefined;
            }
        },

        furcation: (state, getters) => (toothNumber) => {
            return getters.loadedToothState(toothNumber).furcation;
        },

        mobility: (state, getters) => (toothNumber) => {
            return getters.loadedToothState(toothNumber).mobility;
        },

        projectionValues: (state, getters) => (toothNumber, projectionName) => {
            const zoneNames = state.zoneNamesByProjection[projectionName];
            if (!zoneNames) {
                return undefined;
            }

            let values = {};

            zoneNames.forEach(zoneName => {
                values[zoneName] = getters.zone(toothNumber, zoneName);
            });

            return values;
        },

        depthLabel: () => (depthValue) => {
            return (depthValue > 12) ? '>12' : depthValue;
        },

        marginLabel: () => (marginValue) => {
            return (marginValue < -12) ? '<-12' : marginValue;
        },

        cloneToothState: (state, getters) => (cloneableState) => {
            // expects a single tooth state
            let clonedState = {
                periodontal: {},
                furcation: cloneableState.furcation,
                mobility: cloneableState.mobility
            };
            Object.keys(cloneableState.periodontal).forEach(zoneKey => {
                const cloneableZone = cloneableState.periodontal[zoneKey];
                clonedState.periodontal[zoneKey] = {
                    depth: cloneableZone.depth,
                    margin: cloneableZone.margin
                };
                const issueValues = (cloneableZone.issues === undefined) ? cloneableZone.details : cloneableZone.issues;
                clonedState.periodontal[zoneKey].issues = getters.fixedIssueValues(issueValues);
            });
            return clonedState;
        },

        isFurcationPossible: (state, getters, rootState, rootGetters) => (toothNumber) => {
            return rootGetters['patient/teeth/tooth'](toothNumber).position >= 4;
        }

    },

    mutations: {
        startEditingTooth( state, { toothNumber, toothState }) {
            state.editableTooth.number = toothNumber;
            state.editableTooth.state = toothState;
        },
        endEditingTooth( state ) {
            state.editableTooth.number = undefined;
            state.editableTooth.state = undefined;
        },
        setEditableMeasurement( state, { zoneName, type, value } ) {
            const zoneKey = state.zoneValues[zoneName];
            if (!state.editableTooth.state.periodontal[zoneKey]) {
                Vue.set(state.editableTooth.state.periodontal, zoneKey, {});
            }
            Vue.set(state.editableTooth.state.periodontal[zoneKey], type, value);
        },
        setEditableIssues( state, { zoneName, issueValues } ) {
            const zoneKey = state.zoneValues[zoneName];
            if (!state.editableTooth.state.periodontal[zoneKey]) {
                Vue.set(state.editableTooth.state.periodontal, zoneKey, {});
            }
            Vue.set(state.editableTooth.state.periodontal[zoneKey], 'issues', issueValues);
        },
        setEditableFurcation( state, { value } ) {
            Vue.set(state.editableTooth.state, 'furcation', value);
        },
        setEditableMobility( state, { value } ) {
            Vue.set(state.editableTooth.state, 'mobility', value);
        },
        toggleAudio( state ) {
            state.audioEnabled = !state.audioEnabled;
            localStorage.setItem('periodontal-probing-audio-enabled', state.audioEnabled ? "true" : "false");
        },
        disableAudio( state ) {
            state.audioEnabled = false;
            localStorage.setItem('periodontal-probing-audio-enabled', "false");
        }
    },

    actions: {
        startEditingTooth(context, { toothNumber }) {
            const storedState = context.getters.toothStateFromMainStore(toothNumber);
            // clone the tooth state so that the editable copy does not point back to the main store
            const clonedState = context.getters.cloneToothState(storedState);
            context.commit('startEditingTooth', { toothNumber, toothState: clonedState } );
        },

        endEditingTooth(context) {
            context.commit('endEditingTooth');
        },

        setEditableMeasurement(context, { toothNumber, zoneName, type, value }) {
            if (context.state.editableTooth.number === toothNumber) {
                context.commit('setEditableMeasurement', { zoneName, type, value } );

                if (type == 'margin' && value < 0) {
                    // enforce depth limit
                    const currentDepth = context.getters.zone(toothNumber, zoneName).depth;
                    const maxDepth = context.getters.maxDepthForMargin(value);
                    if (currentDepth > maxDepth) {
                        context.dispatch('setEditableMeasurement', {
                            toothNumber,
                            zoneName,
                            type: 'depth',
                            value: maxDepth
                        });
                    }
                }
            }
        },

        setEditableIssues(context, { toothNumber, zoneName, issueValues }) {
            if (context.state.editableTooth.number === toothNumber) {
                context.commit('setEditableIssues', { zoneName, issueValues } );
            }
        },

        setEditableFurcation(context, { toothNumber, value }) {
            if (context.state.editableTooth.number === toothNumber) {
                context.commit('setEditableFurcation', { value } );
            }
        },

        setEditableMobility(context, { toothNumber, value }) {
            if (context.state.editableTooth.number === toothNumber) {
                context.commit('setEditableMobility', { value } );
            }
        },

        saveEditableToothState(context) {
            const toothNumber = context.state.editableTooth.number;
            const waiterName = WAITERS.SAVING_PERIODONTAL_STATE;
            const stateValues = context.getters.cloneToothState(context.state.editableTooth.state);
            // convert 'issues' back to 'details' for main storage
            Object.keys(stateValues.periodontal).forEach(key => {
                stateValues.periodontal[key].details = stateValues.periodontal[key].issues || [];
                stateValues.periodontal[key].details.sort();
                delete stateValues.periodontal[key].issues;
            });
            const updatePayload = { toothNumber, stateValues, waiterName };
            return context.dispatch('patient/teeth/state/updateToothState', updatePayload, { root: true });
        },

        toggleAudio(context) {
            context.commit('toggleAudio');
        },

        disableAudio(context) {
            context.commit('disableAudio');
        }

    }

};
