import "/home/build/node_modules/core-js/modules/es.array.iterator.js";
import "/home/build/node_modules/core-js/modules/es.promise.js";
import "/home/build/node_modules/core-js/modules/es.object.assign.js";
import "/home/build/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.error.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.date.to-json.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import 'focus-visible';
import Sentry from './sentry.js';
if (typeof process.env.VUE_APP_SENTRY_DSN !== "undefined") {
  Sentry.initialize({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV
  });
}
var API_ORIGIN = process.env.VUE_APP_API_ORIGIN;
import Vue from 'vue';
import App from './app.vue';
import router from './router';
import './registerServiceWorker';
import store from './store/store';
import apiClient from './plugins/apiClient.js';
Vue.use(apiClient, {
  apiOrigin: API_ORIGIN,
  store: store
});
import navigation from './mixins/navigation.js';
Vue.mixin(navigation);
import moment from './mixins/moment.js';
Vue.mixin(moment);
import filters from './filters';
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
import i18n from './plugins/i18n.js';
Vue.use(i18n);
import VueWait from 'vue-wait';
Vue.use(VueWait);
var wait = new VueWait({
  useVuex: true
});
import DocumentListenerBinder from './plugins/documentListenerBinder.js';
Vue.use(DocumentListenerBinder);
import BackButton from './components/back-button.vue';
Vue.component('BackButton', BackButton);
import WaitingButton from './components/waiting-button';
Vue.component('WaitingButton', WaitingButton);
import SvgImage from './components/svg-image.vue';
Vue.component('SvgImage', SvgImage);
import NavigationLink from './components/navigation-link.vue';
Vue.component('NavigationLink', NavigationLink);

// register all field components
var fieldComponents = require.context('./components/fields', false, /\w+(-\w+)*\.(vue|js)$/);
fieldComponents.keys().forEach(function (fileName) {
  var componentConfig = fieldComponents(fileName);
  var componentName = filters.capitalize(filters.camelize(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')) + 'Field');
  Vue.component(componentName, componentConfig["default"] || componentConfig);
});

// use browser-update to detect unsupported browsers, but do not use its message layer
// instead, store the result in vue
import VueBrowserUpdate from 'vue-browserupdate';
Vue.use(VueBrowserUpdate, {
  options: {
    required: {
      c: -2,
      s: -1,
      e: 1000,
      i: 1000,
      f: -10,
      o: 1000,
      y: 1000,
      v: 1000
    },
    insecure: true,
    unsupported: true,
    reminder: 0
  }
});
Vue.browserUpdate.onshow(function () {
  store.dispatch('general/showBrowserWarning');
});
if (window.navigator.userAgent.match(/Opera|Edge|OPR/)) {
  store.dispatch('general/showBrowserWarning');
}
Vue.config.productionTip = false;
Vue.config.devtools = false;
document.documentElement.setAttribute('data-env', process.env.NODE_ENV);
if (process.env.NODE_ENV == 'test') {
  // override native console.error() in test environment so that vue.js errors which use console.error() can fail tests
  window.console.error = function (message) {
    // when JS errors get thrown from inside of vue's rendering code,
    // they all get caught in vue's try/catch, which redirects them to console.error,
    // and they cannot be successfully rethrown,
    // so add them to a global array in localStorage,
    // which is then accessed from rspec's global after hook and fails the test if anything has been registered
    var errors = JSON.parse(localStorage.getItem('js-errors') || '[]');
    errors.push(message);
    localStorage.setItem('js-errors', JSON.stringify(errors));

    // rethrow the error anyway, in case it still gets through somehow and gets caught by cuprite's js_errors
    throw new Error(message);
  };

  // store promise errors in localstorage as well
  window.addEventListener("unhandledrejection", function (event) {
    var errors = JSON.parse(localStorage.getItem('js-errors') || '[]');
    errors.push(event.reason.stack);
    localStorage.setItem('js-errors', JSON.stringify(errors));
  });
  window.console.log = function (message) {
    var logLines = JSON.parse(localStorage.getItem('js-log') || '[]');
    logLines.push(message);
    localStorage.setItem('js-log', JSON.stringify(logLines));
  };
}
new Vue({
  router: router,
  store: store,
  wait: wait,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');