import uuid from 'uuid/v4';
import getFromQueryOrLocalStorage from "@/utils/getFromQueryOrLocalStorage.js";
import { WAITERS } from '@/config/waiters.js';

const userKeys = ["id", "full_name", "email", "phone_number", "locale", "timezone", "terms_accepted"];

const blankUser = userKeys.reduce((object, key) => {
    object[key] = undefined;
    return object;
}, {});

export default {
    namespaced: true,

    state: {
        sessionToken: getFromQueryOrLocalStorage({ key: 'session-token', defaultValue: null }),

        // false only during app initialization,
        // while it is not known whether a user will be loaded from session
        userStateIsKnown: false,
        user: Object.assign({}, blankUser),

        subscription: {
            type: undefined,
            status: undefined,
            patientLimit: undefined
        },

        integrator: {
            active: false,
            returnUrl: null,
            patientId: null
        },

        team: {
            title: undefined
        },
    },

    getters: {
        isUserAuthenticated: state => !!state.user.id,
        isUserStateKnown: state => state.userStateIsKnown,
        userAuthenticationState: (state, getters) => {
            return getters['isUserStateKnown'] ? getters['isUserAuthenticated'] : undefined;
        },

        isIntegratorModeActive: state => state.integrator.active,
        integratorPatientId: state => state.integrator.patientId,
        integratorReturnUrl: state => state.integrator.returnUrl,

        userFullName: state => state.user ? state.user.full_name : null,
        userTimeZone: state => state.user ? state.user.timezone : undefined,
        teamTitle: state => state.team.title,
        hasUserAcceptedTerms: state => state.user ? state.user.terms_accepted : undefined,

        isFullSubscriptionActive: state => state.subscription.type == 'full' && state.subscription.status == 'active',

        actualPatientLimit: (state, getters) => {
            if (getters.isFullSubscriptionActive) {
                return null;
            }
            return state.subscription.patientLimit;
        },

        patientLimitApproached: (state, getters, rootState) => {
            return getters.actualPatientLimit != null && rootState.patientList.totalNumberOfPatients + 1 == getters.actualPatientLimit;
        },
        patientLimitReached: (state, getters, rootState) => {
            return getters.actualPatientLimit != null && rootState.patientList.totalNumberOfPatients >= getters.actualPatientLimit;
        },
        deviceId: () => {
            // note that since this getter does not use the vuex state,
            // its value is not refreshed after changes in localStorage
            // and the app needs to be refreshed
            let deviceId = localStorage.getItem('device-id');
            if (!deviceId) {
                deviceId = uuid();
                localStorage.setItem('device-id', deviceId);
            }
            return deviceId;
        }

    },

    mutations: {
        setSessionToken(state, token) {
            state.sessionToken = token;
            localStorage.setItem('session-token', token);
        },
        clearSessionToken(state) {
            state.sessionToken = null;
            localStorage.removeItem('session-token');
        },
        setIntegrator(state, integrator) {
            if (integrator) {
                state.integrator.active = true;
                state.integrator.returnUrl = integrator.return_url;
                state.integrator.patientId = integrator.patient_id;
            } else {
                state.integrator.active = false;
                state.integrator.returnUrl = null;
                state.integrator.patientId = null;
            }
        },
        clearIntegrator(state) {
            state.integrator.active = false;
            state.integrator.returnUrl = null;
            state.integrator.patientId = null;
        },
        setSubscription(state, subscription) {
            state.subscription.type = subscription.current_type;
            state.subscription.status = subscription.status;
            state.subscription.patientLimit = subscription.limits.patient_limit;
        },
        setUser(state, user) {
            userKeys.forEach(key => {
                state.user[key] = user[key];
            });
            state.userStateIsKnown = true;
        },
        setTeam(state, subscription) {
            state.team.title = subscription.team_title;
        },

        clearUser(state) {
            state.user = Object.assign({}, blankUser);
            state.userStateIsKnown = true;
        },

        updateProfile(state, userValues) {
            Object.keys(userValues).forEach(key => {
                if (userKeys.includes(key)) {
                    state.user[key] = userValues[key];
                }
            });
        },

        markTermsAsAccepted(state) {
            if (state.user && state.user.terms_accepted === false) {
                state.user.terms_accepted = true;
            }
        },
    },

    actions: {
        setSessionToken(context, token) {
            context.commit('setSessionToken', token);
            context.dispatch('loadCurrentSession');
        },
        clearSession(context) {
            context.commit('clearSessionToken');
            this.$api.clearSessionToken();
            context.commit('clearIntegrator');
            context.commit('clearUser');
        },
        loadSession(context, sessionData) {
            context.commit('setSessionToken', sessionData.session_token);
            context.commit('setIntegrator', sessionData.integrator);
            context.commit('setSubscription', sessionData.subscription);

            const user = sessionData.current_user.settings;
            user.terms_accepted = sessionData.current_user.terms_accepted;
            context.commit('setUser', Object.assign( { id: sessionData.current_user.user_id }, user ));
            context.commit('setTeam', sessionData.subscription);

            context.dispatch('i18n/setLocale', sessionData.current_user.settings.locale, { root: true });
            context.dispatch('time/startClock', null, { root: true });

            if (sessionData.current_user.settings.notation_system) {
                context.dispatch('settings/setCurrentNotationSystem', sessionData.current_user.settings.notation_system, { root: true });
            }

            this.$api.setSessionToken(sessionData.session_token);
            context.dispatch('patientList/loadWithMeta', { list: sessionData.patient_list, loadedWithSession: true }, { root: true });

            context.dispatch('images/load', null, { root: true });
        },
        loadCurrentSession(context) {
            if (context.state.sessionToken) {
                this.$api.getSession(context.state.sessionToken).then((response) => {
                    context.dispatch('loadSession', response.data);
                }).catch(this.apiErrorHandler);
            } else {
                context.dispatch('clearSession');
            }
        },
        signIn(context, parameters) {
            const waiterName = WAITERS.SIGNING_IN;
            context.dispatch('wait/start', waiterName, { root: true });
            let deviceId = context.getters.deviceId;
            return this.$api.createSession( parameters.email, parameters.password, deviceId).then((response) => {
                context.dispatch('loadSession', response.data);
            }).catch((error) => {
                if (error.response && error.response.status == 422 && error.response.data && error.response.data.message) {
                    var messages = [
                        error.response.data.message // (API #9)
                    ];

                    if (error.response.data.errors && error.response.data.errors.email) {
                        error.response.data.errors.email.forEach(message => {
                            if (!messages.includes(message)) {
                                messages.push(message); // (API #9)
                            }
                        });
                    }
                    return Promise.reject({ messages });
                } else {
                    throw error;
                }
            }).catch(this.apiErrorHandler).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        },
        singInViaToken(context, parameters) {
            const waiterName = WAITERS.SIGNING_IN;
            context.dispatch('wait/start', waiterName, { root: true });
            let deviceId = context.getters.deviceId;
            return this.$api.createSessionFromToken( parameters.token, deviceId).then((response) => {
                context.dispatch('loadSession', response.data);
            }).catch((error) => {
                if (error.response && error.response.status == 422 && error.response.data && error.response.data.message) {
                    const messages = [
                        error.response.data.message
                    ];
                    return Promise.reject({ messages });
                } else {
                    throw error;
                }
            }).catch(this.apiErrorHandler).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        },
        signOut(context) {
            const waiterName = WAITERS.SIGNING_OUT;
            context.dispatch('wait/start', waiterName, { root: true });
            return this.$api.deleteCurrentSession().then(() => {
                context.dispatch('clearSession');
            }).catch(this.apiErrorHandler).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        },

        updateProfile(context, userValues) {
            return this.$api.updateProfile(userValues).then(() => {
                return context.commit('updateProfile', userValues);
            }).catch((error) => {
                if (error.response && error.response.status == 422 && error.response.data && error.response.data.errors) {
                    return Promise.reject({ errors: error.response.data.errors });
                } else {
                    throw error;
                }
            }).catch(this.apiErrorHandler);
        },

        markTermsAsAccepted(context) {
            context.commit('markTermsAsAccepted');
        },

    }
};
