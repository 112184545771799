<template>
    <transition :name="transitionName" appear>
        <div v-if="showingSubscriptionBanner" class="banner">
            <router-link :to="{ name: 'billing' }">
                <p>
                    <img :src="logoPath" alt="" />
                </p>

                <p class="message">
                    <span>{{ t('upgrade to', 'subscription') }}</span>
                </p>

                <p class="button subscribe">{{ t('upgrade', 'subscription') }}</p>
            </router-link>
            <button type="button" class="close" v-on:click="close()">
                <svg-image
                    name="close"
                    :title="t('close', 'common')"
                    width="21"
                    height="21"
                />
            </button>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        transitionName: {
            type: String,
            default: 'fade'
        },
    },
    data() {
        return {
            showingSubscriptionBanner: false,
        };
    },
    computed: {
        logoPath() {
            const image = this.$store.getters['images/image']('derec-logo');
            return (image) ? image.src : '';
        },
    },
    mounted() {
        this.showingSubscriptionBanner = !this.$store.getters['authentication/isFullSubscriptionActive'];
    },
    methods: {
        close() {
            this.showingSubscriptionBanner = false;
        }
    },
};
</script>
<style lang="scss">
@import "~@/assets/stylesheets/components/subscription-banner";
</style>
