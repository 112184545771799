<template>
    <ul class="legend-list">
        <li v-for="(groupEntries, groupName) in entries" :key="groupName" class="group" :data-name="groupName">
            <h3 class="heading">{{ t('title', 'dentalChartVC.legend.' + groupName) }}</h3>
            <ul>
                <li v-for="entry in groupEntries" :key="entry" :data-name="entry">
                    {{ t(entry, 'dentalChartVC.legend.' + groupName) }}
                </li>
            </ul>
        </li>
    </ul>
</template>

<script>
export default {

    data: () => {
        return {
            entries: {
                perio: [ "depth", "margin", "furcation1", "furcation2", "furcation3", "mobility1", "mobility2", "mobility3" ],
                endo: [ "cold", "heat", "palpation", "percussion", "electricity" ],
                colour: [ "monitor", "treatment" ],
                other: [ "extract" ]
            }
        };
    },

    computed: {

    },

    methods: {

    }
};
</script>
