<template>

    <li
        class="zone"
        :class="{ warning: hasWarning }"
        :data-name="zoneName"
    >
        <router-link :to="zoneRoute" :replace="linkMethod == 'replace'">

            <p class="label">{{ $store.getters['patient/teeth/zoneLabel'](zoneName) }}</p>

            <div class="depth" :title="t('depth', 'dentalChartVC.legend.perio')">{{ depthLabel }}</div>
            <div class="margin" :title="t('margin', 'dentalChartVC.legend.perio')">{{ marginLabel }}</div>

            <ul class="issues" v-if="activeIssuesExist">
                <li
                    v-for="issueName in activeIssueNames"
                    :key="issueName"
                    class="indicator active"
                    :data-type="issueName"
                    :data-zone="zoneName"
                    :title="t(issueName, 'dental')"
                >
                </li>
            </ul>

        </router-link>
    </li>

</template>
<script>
export default {
    props: {
        zoneName: { required: true },
        toothNumber: { required: true },
        zoneRoute: { required: true },
        linkMethod: { required: true }
    },
    computed: {
        depthLabel() {
            return this.$store.getters['patient/teeth/state/periodontal/depthLabel'](this.zone.depth);
        },
        marginLabel() {
            return this.$store.getters['patient/teeth/state/periodontal/marginLabel'](this.zone.margin);
        },
        zone() {
            return this.$store.getters['patient/teeth/state/periodontal/zone'](this.toothNumber, this.zoneName);
        },
        activeIssueNames() {
            return this.zone.issues.map(value => this.$store.getters['patient/teeth/state/periodontal/issueName'](value));
        },
        activeIssuesExist() {
            return this.activeIssueNames.length > 0;
        },
        hasWarning() {
            return this.zone.depth > 4;
        }
    }


};
</script>
