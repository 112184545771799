export default {
    namespaced: true,

    state: {
        // holds the last mouth view (e.g., upper-jaw, lower-jaw...)
        // that the user viewed before opening a single tooth
        // so that it can be used in tooth view's back button
        // and chart links in general
        chartRouteMouthView: undefined,
        chartRoutePatientId: undefined,

        // some routes use the previous route for back navigation
        previousRoute: undefined
    },

    getters: {
        patientsRoute: (state, getters, rootState) => {
            const route = { name: 'patients' };

            if (rootState.patientList.attemptedQuery) {
                route.query = { search: rootState.patientList.attemptedQuery };
            }
            return route;
        },

        storedChartRouteName: (state, getters, rootState) => {
            // chart route links preserve selected mouth view (upper/lower/full)
            // but resets for each patient
            let name = (state.chartRouteMouthView == 'full-mouth') ? 'chart' : state.chartRouteMouthView;
            let patientId = state.chartRoutePatientId;
            if (!name || (patientId != rootState.patient.id)) {
                name = 'chart';
            }
            return name;
        },

        storedChartRoute: (state, getters) => {
            return { name: getters.storedChartRouteName };
        }
    },

    mutations:
    {
        storeMouthView(state, values) {
            state.chartRouteMouthView = values.name;
            state.chartRoutePatientId = values.patientId;
        },
        storePreviousRoute(state, route) {
            state.previousRoute = route;
        }
    },

    actions: {
        storeMouthView( context, values ) {
            context.commit('storeMouthView', values);
        },
        storePreviousRoute( context, route ) {
            context.commit('storePreviousRoute', route);
        }
    }

};
