import { THEMES } from '@/config/app.js';
import { NOTATION_SYSTEMS } from '@/config/teeth.js';
import getFromQueryOrLocalStorage from "@/utils/getFromQueryOrLocalStorage.js";

const LOCALSTORAGE_THEME_ITEM = 'theme';
const LOCALSTORAGE_NOTATION_SYSTEM_ITEM = 'notation-system';

export default {
    namespaced: true,

    state: {
        currentTheme: getFromQueryOrLocalStorage({ key: LOCALSTORAGE_THEME_ITEM, defaultValue: THEMES.LIGHT}),

        themes: [ THEMES.LIGHT, THEMES.DARK, THEMES.AUTO ],

        currentNotationSystem: getFromQueryOrLocalStorage({ key: LOCALSTORAGE_NOTATION_SYSTEM_ITEM, defaultValue: NOTATION_SYSTEMS.ISO }),
        notationSystems: [ NOTATION_SYSTEMS.ISO, NOTATION_SYSTEMS.USA ]
    },

    getters: {
        isoNotationActive: state => state.currentNotationSystem == NOTATION_SYSTEMS.ISO,
        usaNotationActive: state => state.currentNotationSystem == NOTATION_SYSTEMS.USA
    },

    mutations: {
        setCurrentTheme(state, theme) {
            if (state.themes.includes(theme)) {
                state.currentTheme = theme;
                localStorage.setItem(LOCALSTORAGE_THEME_ITEM, theme);
            }
        },
        setCurrentNotationSystem(state, notationSystem) {
            if (state.notationSystems.includes(notationSystem)) {
                state.currentNotationSystem = notationSystem;
                localStorage.setItem(LOCALSTORAGE_NOTATION_SYSTEM_ITEM, notationSystem);
            }
        }
    },

    actions: {
        setCurrentTheme(context, theme) {
            context.commit('setCurrentTheme', theme);
        },
        setCurrentNotationSystem(context, notationSystem) {
            context.commit('setCurrentNotationSystem', notationSystem);
        }
    }
};
