export default {

    namespaced: true,

    getters: {
        params: () => () => {
            return {};
        }
    },

    actions: {

        perform(context, { numbers }) {
            const tasks = numbers.map(number => context.dispatch('patient/teeth/state/markToothAsToBeExtracted', number, { root: true }));
            return Promise.all(tasks);
        }

    }

};
