import Vue from 'vue';

import merge from 'lodash/merge';
import RecordStoreModule from './recordStoreModule.js';
import { WAITERS } from '@/config/waiters.js';

import clone from '@/utils/clone.js';
import { SOFT_TISSUE_ZONES_IN_ORDER } from '@/config/soft-tissue.js';

const blankState = SOFT_TISSUE_ZONES_IN_ORDER.reduce((state, sextant) => {
    state[sextant] = null;
    return state;
}, {});

export default merge({}, RecordStoreModule, {
    state: {
        changedDescriptions: {}
    },

    getters: {
        orderedItems: (state, getters) => {
            return state.items.concat().sort((a, b) => {
                // sort in ascending order by creation time first
                // and then in ascending order by order in the original item array
                // (the array initially arrives sorted in ascending order already, but may have new entries added)
                let result = a.created_at.localeCompare(b.created_at);

                if (result === 0) {
                    result = getters.itemIndex(a) - getters.itemIndex(b);
                }
                return result;
            });
        },

        currentItems: (state, getters, rootState, rootGetters) => {
            if (!rootGetters['patient/teeth/state/isHistorical']) {
                return getters.orderedItems;
            }
            const date = rootGetters['patient/teeth/state/teethStateDate'];
            return getters.orderedItems.filter(item => item.date <= date);
        },
        currentItemsByZone: (state, getters) => {
            const itemsByZone = clone(blankState);
            // take the latest item for each sextant
            getters.currentItems.forEach(item => {
                itemsByZone[item.zone] = clone(item);
            });
            return itemsByZone;
        },

        storedDescriptions: (state, getters) => {
            const descriptionsByZone = clone(blankState);
            const itemsByZone = getters.currentItemsByZone;
            Object.keys(itemsByZone).forEach((zone) => {
                const item = itemsByZone[zone];
                descriptionsByZone[zone] = (item) ? item.description : null;
            });
            return descriptionsByZone;
        },

        changedDescriptions: (state) => {
            return state.changedDescriptions;
        },

        changedZoneNames: (state, getters) => {
            return Object.keys(getters.changedDescriptions);
        },

        changesExist: (state, getters) => {
            return getters.changedZoneNames.length > 0;
        },

        editingIsAvailable: (state, getters, rootState, rootGetters) => {
            return rootGetters['patient/teeth/state/isModifiable'];
        },

        savingIsAvailable: (state, getters) => {
            if (!getters.editingIsAvailable) {
                return false;
            }
            if (!getters.changesExist) {
                return false;
            }
            return true;
        },

        description: (state, getters) => (zone) => {
            if (typeof state.changedDescriptions[zone] !== 'undefined') {
                return state.changedDescriptions[zone];
            }
            return getters.storedDescriptions[zone];
        },

    },

    mutations: {
        changeDescriptionForZone(state, { zone, description }) {
            Vue.set(state.changedDescriptions, zone, description);
        },
        clearChangesForZone(state, zone) {
            Vue.delete(state.changedDescriptions, zone);
        },
        clearAllChanges(state) {
            state.changedDescriptions = {};
        }
    },

    actions: {
        setItemsFromApiResponse(context, { apiResources, replace }) {
            const items = apiResources.map((resource) => {
                return {
                    id: resource.data.id,
                    zone: resource.data.attributes.zone,
                    description: resource.data.attributes.description,
                    created_at: resource.data.attributes.created_at,
                    date: resource.data.attributes.date,
                };
            });
            const action = (replace) ? "replace" : "load";
            context.dispatch(action, items);
            if (action === "replace") {
                context.dispatch('clearAllChanges');
            }
        },

        setDescriptionForZone(context, { zone, description }) {
            if (context.getters.storedDescriptions[zone] === description) {
                return context.commit('clearChangesForZone', zone);
            }

            return context.commit('changeDescriptionForZone', { zone, description });
        },

        clearChangesForZone(context, { zone }) {
            context.commit('clearChangesForZone', zone);
        },

        clearAllChanges(context) {
            context.commit('clearAllChanges');
        },

        saveChangedDescriptions(context) {
            if (!context.getters.savingIsAvailable) {
                return;
            }

            const patientId = context.rootState.patient.object.id;
            const saveableEntries = context.getters.changedZoneNames.map((zone) => {
                return {
                    zone,
                    description: context.getters.changedDescriptions[zone]
                };
            });

            const waiterName = WAITERS.SAVING_SOFT_TISSUE_RECORDS;
            context.dispatch('wait/start', waiterName, { root: true });

            return this.$api.createSoftTissueRecords(patientId, saveableEntries).then((result) => {
                return context.dispatch('setItemsFromApiResponse', { apiResources: result, replace: false }).then(() => {
                    return context.dispatch('clearAllChanges');
                });
            }).catch(this.apiErrorHandler).finally(() => {
                context.dispatch('wait/end', waiterName, { root: true });
            });
        },


    }
});
