export default {

    namespaced: true,

    getters: {
        relevantToothNumbers: (state, getters, rootState, rootGetters) => (numbers) => {
            // ignore missing teeth
            return numbers.filter(number => !rootGetters['patient/teeth/state/isToothMissing'](number));
        },

        params: (state, getters, rootState, rootGetters) => (selectedParams) => {
            const testNames = rootGetters['patient/teeth/state/endodontic/testNames'];

            const details = {
                test: {
                    options: testNames.map(testName => {
                        return {
                            value: testName,
                            label: rootGetters['patient/teeth/state/endodontic/testLabel'](testName)
                        };
                    })
                }
            };

            if (selectedParams.test) {
                // test has been selected
                const testName = selectedParams.test;

                // add first level options
                const firstStepOptions = rootGetters['patient/teeth/state/endodontic/firstStepOptions'](testName);

                details.result = {
                    options: firstStepOptions.map(option => {
                        return {
                            value: rootGetters['patient/teeth/state/endodontic/optionValue']({ testName, option }),
                            label: rootGetters['patient/teeth/state/endodontic/optionLabel']({ testName, option })
                        };
                    })
                };

                if (selectedParams.result) {
                    // first step value has been selected
                    const firstStepValue = selectedParams.result;
                    const firstStepOption = rootGetters['patient/teeth/state/endodontic/valueOption']({ testName, value: firstStepValue });

                    // add second level options, if present
                    if (rootGetters['patient/teeth/state/endodontic/testHasTwoSteps'](testName, firstStepOption)) {
                        const secondStepOptions = rootGetters['patient/teeth/state/endodontic/secondStepOptions'](testName, firstStepValue);
                        details.subresult = {
                            options: secondStepOptions.map(option => {
                                return {
                                    value: rootGetters['patient/teeth/state/endodontic/optionValue']({ testName, option }),
                                    label: rootGetters['patient/teeth/state/endodontic/optionLabel']({ testName, option })
                                };
                            })
                        };
                    }

                }
            }

            return details;
        },

        buildTestValues: (state, getters, rootState, rootGetters) => (params) => {
            return Object.assign(
                {},
                // use blank result as a base to always have some values for result and subresult
                // even if one or both keys are missing from params
                rootGetters['patient/teeth/state/endodontic/blankResult'],
                // pick only thhe two needed keys from params
                (({ result, subresult }) => ({ result, subresult }))(params)
            );
        }
    },

    actions: {

        perform(context, { numbers, params }) {
            if (!params.test && !params.result) {
                // this is a safeguard to not save anything if no test or no first step value has been selected.
                // the save button should not be visible in this case anyway
                return;
            }

            const toothNumbers = context.getters.relevantToothNumbers(numbers);
            if (toothNumbers.length < 1) {
                return;
            }

            const testValues = context.getters.buildTestValues(params);

            const saveableValues = { [params.test]: testValues };

            // after saving endo tests via quickselect,
            // it is not needed to update root canal values with updateAfterEndodonticTest (which causes root canal analysis).
            // it should be called only after endo tests from a single tooth view
            return context.dispatch('patient/teeth/state/endodontic/saveTeethValues', { toothNumbers, saveableValues }, { root: true });
        }
    }
};
