import uuid from 'uuid/v4';
import merge from 'lodash/merge';
import RecordStoreModule from './recordStoreModule.js';
import { ISSUE_TYPES, ISSUE_STATUSES } from '@/config/teeth.js';
import { WAITERS } from '@/config/waiters.js';

export default merge({}, RecordStoreModule, {
    state: {
        idAttribute: "issue_record_id"
    },

    getters: {
        orderedActiveItemsByToothNumber: (state, getters) => (toothNumber) => {
            return getters.orderedActiveItems.filter(entry => entry.source_tooth_number === toothNumber);
        },
        levelByToothNumber: (state, getters) => (toothNumber) => {
            const records = getters.orderedActiveItemsByToothNumber(toothNumber);
            if (records.some(record => record.type == ISSUE_TYPES.URGENT)) {
                return ISSUE_TYPES.URGENT;
            } else if (records.some(record => record.type == ISSUE_TYPES.MONITOR)) {
                return ISSUE_TYPES.MONITOR;
            }
            return null;
        },

        orderedActiveItems: (state, getters, rootState, rootGetters) => {
            let statusFilterFunction;
            if (rootGetters['patient/teeth/state/isHistorical']) {
                const historicalDate = rootState.patient.teeth.state.teethStateDate;
                // if historical date is loaded, filter issues by their being active on that date

                // API returns manipulation dates in UTC (instead of team's or user's time zone) (API #36)
                // so they can currently be used directly on the utc timestamps.
                // this will have to change, when the dates get fixed on API side
                statusFilterFunction = entry => {
                    return (
                        (entry.created_at.substring(0, 10) <= historicalDate)
                        &&
                        (
                            (!entry.resolved_at || (entry.resolved_at.substring(0, 10) > historicalDate))
                            &&
                            // currently this does nothing, because soft-deleted records are not returned by the API (API #37)
                            (!entry.deleted_at || (entry.deleted_at.substring(0, 10) > historicalDate))
                        )
                    );
                };
            } else {
                // if current teeth state is loaded, filter by currently active status
                statusFilterFunction = entry => entry.status == ISSUE_STATUSES.ACTIVE && !entry.deleted_at;
            }

            return getters.orderedItems.filter(statusFilterFunction).filter(item => {
                return rootGetters['patient/teeth/state/isToothAvailable'](item.source_tooth_number);
            });
        },

        activeItemById: (state, getters) => (id) => {
            return getters.orderedActiveItems.find(item => item.issue_record_id == id);
        },

        resolvingWaiterName: () => (recordId) => {
            return WAITERS.RESOLVING_TREATMENT_PLAN_RECORD + ' ' + recordId;
        }
    },

    mutations: {

    },

    actions: {
        addRecord(context, { type, status, message, toothNumber, id }) {
            const patientId = context.rootState.patient.object.id;
            const waiterName = WAITERS.ADDING_TREATMENT_PLAN_RECORD;

            context.dispatch('wait/start', waiterName, { root: true });

            const values = {
                issue_record_id: id || uuid(),
                content: message,
                source_tooth_number: (toothNumber) ? toothNumber : "",
                type,
                status
            };

            return this.$api.createIssueRecord(patientId, values).then(() => {
                // to avoid having to reload the whole issue list,
                // simply append the new values as an object to the list of others

                // since creation time is not yet known, simulate it from the browser's time
                values.created_at = context.rootGetters['time/currentUtcTimestamp'];

                context.commit('prepend', values);

                return Promise.resolve();
            }).catch(this.apiErrorHandler).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        },

        resolveRecord(context, recordId) {
            const record = context.getters.item(recordId);
            if (!record) {
                return;
            }

            const patientId = context.rootState.patient.object.id;
            const toothNumber = record.source_tooth_number;
            const message = record.content;

            const waiterName = context.getters.resolvingWaiterName(recordId);
            context.dispatch('wait/start', waiterName, { root: true });
            return this.$api.resolveIssueRecord(patientId, recordId).then(() => {

                // do not remove item from list, because it might be used in rollback.
                // mark it locally as resolved instead

                // since resolution time is not yet known, simulate it from the browser's time
                context.commit('modify', { id: recordId, attribute: "resolved_at", value: context.rootGetters['time/currentUtcTimestamp'] });
                context.commit('modify', { id: recordId, attribute: "status", value: ISSUE_STATUSES.RESOLVED });

                const rootCanalParams = { toothNumber, resolvedIssue: record };
                return context.dispatch('patient/teeth/state/rootCanal/updateAfterTreatment', rootCanalParams, { root: true })
                    .then(() => {
                        // create an automatic history record with the same text
                        return context.dispatch('patient/history/addRecord', { message, toothNumber }, { root: true });
                    });

            }).catch(this.apiErrorHandler).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        },

        deleteRecord(context, recordId) {
            const record = context.getters.item(recordId);
            if (!record) {
                return;
            }

            const patientId = context.rootState.patient.object.id;
            const waiterName = WAITERS.DELETING_TREATMENT_PLAN_RECORD;
            context.dispatch('wait/start', waiterName, { root: true });

            return this.$api.deleteIssueRecord(patientId, recordId).then(() => {
                // do not remove item from list, because it might be used in rollback.
                // mark it locally as deleted instead

                // since deletion time is not yet known, simulate it from the browser's time
                context.commit('modify', { id: recordId, attribute: "deleted_at", value: context.rootGetters['time/currentUtcTimestamp'] });
            }).catch(this.apiErrorHandler).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        },
    }
});
