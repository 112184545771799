import freeze from '@/utils/freeze.js';
import { TOOTH_TYPES, TOOTH_NUMBERS } from '@/config/teeth.js';


const APICAL_DETAIL_TYPES = freeze({
    PRESENCE: "presence"
});

const APICAL_PRESENCES = freeze({
    YES: "yes",
    NO: "no"
});

const APICAL_DETAILS = freeze({
    [APICAL_DETAIL_TYPES.PRESENCE]: {
        options: [
            { value: APICAL_PRESENCES.YES, labelKey: "common.yes" },
            { value: APICAL_PRESENCES.NO, labelKey: "common.no" },
        ]
    }
});


const definition = {
    toothTypes: [TOOTH_TYPES.INCISOR, TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};

TOOTH_NUMBERS.forEach(number => {
    definition.teeth[number] = {
        surfaces: {},
        details: APICAL_DETAILS
    };
});


const APICAL_DEFINITION = freeze(definition);

export {
    APICAL_DEFINITION,

    APICAL_DETAIL_TYPES,
    APICAL_PRESENCES,
    APICAL_DETAILS
};
