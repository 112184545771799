import merge from 'lodash/merge';
import ItemStoreModule from '../../lib/itemStoreModule.js';
import clone from '@/utils/clone.js';
import { SEXTANTS_IN_ORDER } from '@/config/basic-periodontal-examination.js';
import { WAITERS } from '@/config/waiters.js';

const blankState = SEXTANTS_IN_ORDER.reduce((state, sextant) => {
    state[sextant] = null;
    return state;
}, {});

export default merge({}, ItemStoreModule, {

    state: {
        editing: false,
        editableScoresBySextant: null
    },

    getters: {
        orderedItems: (state, getters) => {
            return state.items.concat().sort((a, b) => {
                // sort in ascending order by creation time first
                // and then in ascending order by order in the original item array
                // (the array initially arrives sorted in ascending order already, but may have new entries added)
                let result = a.created_at.localeCompare(b.created_at);

                if (result === 0) {
                    result = getters.itemIndex(a) - getters.itemIndex(b);
                }
                return result;
            });
        },

        currentItems: (state, getters, rootState, rootGetters) => {
            if (!rootGetters['patient/teeth/state/isHistorical']) {
                return getters.orderedItems;
            }
            const date = rootGetters['patient/teeth/state/teethStateDate'];
            return getters.orderedItems.filter(item => item.date <= date);
        },
        currentItemsBySextant: (state, getters) => {
            const itemsBySextant = clone(blankState);
            // take the latest item for each sextant
            getters.currentItems.forEach(item => {
                itemsBySextant[item.sextant] = clone(item);
            });
            return itemsBySextant;
        },

        currentScoresBySextant: (state, getters) => {
            const scoresBySextant = clone(blankState);
            const itemsBySextant = getters.currentItemsBySextant;
            Object.keys(itemsBySextant).forEach((sextant) => {
                const item = itemsBySextant[sextant];
                scoresBySextant[sextant] = {
                    level: (item) ? item.level : null,
                    furcation: (item) ? item.furcation : null,
                };
            });
            return scoresBySextant;
        },

        editingIsAvailable: (state, getters, rootState, rootGetters) => {
            return rootGetters['patient/teeth/state/isModifiable'];
        },

        editing: (state) => state.editing,

        currentOrEditableScoresBySextant: (state, getters) => {
            if (getters.editing) {
                return state.editableScoresBySextant;
            }

            return getters.currentScoresBySextant;
        },

        saveableScores: (state, getters) => {
            // collect editable scores for all sextants where the values differ from the saved ones
            const currentScores = getters.currentScoresBySextant;
            const editableScores = state.editableScoresBySextant;

            const saveableScores = [];

            SEXTANTS_IN_ORDER.forEach((sextant) => {
                const currentScore = currentScores[sextant];
                const editableScore = editableScores[sextant];

                if (editableScore.level !== currentScore.level || editableScore.furcation !== currentScore.furcation) {
                    saveableScores.push({
                        sextant,
                        level: editableScore.level,
                        furcation: editableScore.furcation
                    });
                }
            });

            return saveableScores;
        }
    },

    mutations: {
        setEditableScores(state, scoresBySextant) {
            state.editableScoresBySextant = scoresBySextant;
        },
        setEditing(state, value) {
            state.editing = value;
        },
        setEditableScore(state, { sextant, level, furcation }) {
            let settableLevel = level;
            if (settableLevel !== null) {
                settableLevel = parseInt(settableLevel);
            }
            state.editableScoresBySextant[sextant].level = settableLevel;

            // non-true furcation should be stored as null if level is null
            // and as false if level is set.
            // true furcation should be stored as true, regardless of level.
            let settableFurcation = furcation;
            if (!settableFurcation) {
                if (level === null) {
                    settableFurcation = null;
                } else {
                    settableFurcation = false;
                }
            }
            state.editableScoresBySextant[sextant].furcation = settableFurcation;
        },
    },

    actions: {
        setItemsFromApiResponse(context, { apiResources, replace }) {
            const items = apiResources.map((resource) => {
                return {
                    id: resource.data.id,
                    sextant: resource.data.attributes.sextant,
                    level: resource.data.attributes.level,
                    furcation: resource.data.attributes.furcation,
                    created_at: resource.data.attributes.created_at,
                    date: resource.data.attributes.date,
                };
            });
            const action = (replace) ? "replace" : "load";
            context.dispatch(action, items);
        },

        startEditing(context) {
            if (!context.getters.editingIsAvailable) {
                return;
            }
            context.commit('setEditableScores', clone(context.getters.currentScoresBySextant));
            context.commit('setEditing', true);
        },
        endEditing(context) {
            if (!context.state.editing) {
                return;
            }
            context.commit('setEditableScores', null);
            context.commit('setEditing', false);
        },
        setEditableScore(context, { sextant, level, furcation }) {
            if (!context.state.editing) {
                return;
            }
            context.commit('setEditableScore', { sextant, level, furcation });
        },

        saveEditableScores(context) {
            if (!context.state.editing) {
                return;
            }
            const patientId = context.rootState.patient.object.id;
            const saveableScores = context.getters.saveableScores;

            if (saveableScores.length < 1) {
                return;
            }

            const waiterName = WAITERS.SAVING_BASIC_PERIODONTAL_EXAMINATION_RECORDS;
            context.dispatch('wait/start', waiterName, { root: true });

            return this.$api.createBasicPeriodontalExaminationRecords(patientId, saveableScores).then((result) => {
                return context.dispatch('setItemsFromApiResponse', { apiResources: result, replace: false }).then(() => {
                    return context.dispatch('patient/teeth/state/addTodaysDateToHistoryIfNeeded', null, { root: true });
                });
            }).catch(this.apiErrorHandler).finally(() => {
                context.dispatch('wait/end', waiterName, { root: true });
            });

        }
    }
});
