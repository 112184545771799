import merge from 'lodash/merge';
import { RESTORATIONS, TOOTH_STATES } from '@/config/teeth.js';
import RestorationType from '../restorationType.js';

export default merge({}, RestorationType, {

    state: {
        name: RESTORATIONS.VENEER
    },

    getters: {
        calculateStateValue: ()  => () => {
            return TOOTH_STATES.VENEER; // (#32)
        }
    },

    mutations: {

    },

    actions: {

    }

});
