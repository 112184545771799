<template>
    <transition name="fade" appear>
        <div class="overlay">
            <div class="error dialog" role="dialog">
                <p class="message">
                    {{ errorMessage }}
                </p>
                <p>
                    <button type="button" v-on:click="showDetails = !showDetails" class="details">
                        <template v-if="showDetails">
                            {{ t('Hide technical details', 'common.error') }}
                        </template>
                        <template v-else>
                            {{ t('View technical details', 'common.error') }}
                        </template>
                    </button>
                </p>

                <p class="details field" v-if="showDetails">
                    <span class="control textarea">
                        <textarea :value="errorDetails" readonly></textarea>
                    </span>
                </p>

                <p class="actions">
                    <button type="button" class="button" v-on:click="close">{{ t('close', 'common') }}</button>
                </p>
            </div>
        </div>
    </transition>

</template>
<script>

export default {
    components: {  },
    data() {
        return {
            showDetails: false
        };
    },
    computed: {
        error() {
            return this.$store.state.api.error;
        },

        errorMessage() {
            return this.t('Something has gone wrong. Please check your internet connection', 'common.error');
        },

        errorDetails() {
            return JSON.stringify(this.error);
        }

    },
    methods: {
        close() {
            this.$store.dispatch('api/clearError');
        }
    }
};
</script>
