<template>
    <p class="field toggle-switch" :data-name="id">
        <input
            type="checkbox"
            :id="id"
            :checked="value"
            v-bind="$attrs"
            v-on="listeners"
        />
        <label :for="id">{{ label }}</label>
    </p>
</template>

<script>
export default {
    inheritAttrs: false,
    model: {
        event: 'change'
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        label: String,
        value: Boolean,
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                change: this.onChange,
            };
        },
    },
    methods: {
        onChange(event) {
            this.$emit('change', event.target.checked);
        },
    },
};
</script>
