<template>
    <div class="medical-issues" v-if="activeIssuesExist">

        <h2>{{ t('title', 'patientVC.medicalIssue') }}</h2>

        <medical-issue-list :issues="activeIssues" :columns="2" />

    </div>
</template>
<script>
import MedicalIssueList from '@/components/patient/dashboard/medical-issues/list.vue';

export default {
    components: { MedicalIssueList },

    computed: {
        activeIssues() {
            return this.$store.getters['patient/medicalIssues/active'];
        },
        activeIssuesExist() {
            return this.activeIssues.length > 0;
        }
    }
};

</script>
