<template>
    <div class="app" :data-theme="theme">
        <template>
            <terms-and-conditions v-if="shouldShowTerms" />
            <route-transition v-else :level="0">
                <router-view />
            </route-transition>
        </template>

        <api-error v-if="$store.state.api.error" />

        <browser-warning v-if="$store.state.general.shouldShowBrowserWarning" />

        <rollback-warning v-if="$store.state.patient.teeth.state.shouldShowRollbackWarning" />
    </div>
</template>
<script>
import RouteTransition from '@/components/route-transition.vue';
import ApiError from '@/components/api-error.vue';
import BrowserWarning from '@/components/browser-warning.vue';
import RollbackWarning from '@/components/rollback-warning.vue';
import TermsAndConditions from '@/views/terms-and-conditions.vue';

import { TEETH_GEOMETRY } from '@/config/visualization/geometry.js';

import { TOOTH_EROSION_SURFACES } from '@/config/visualization/erosion.js';

import { PATHOLOGIES_AND_RESTORATIONS } from '@/config/pathologies-and-restorations.js';


export default {
    components: { RouteTransition, ApiError, BrowserWarning, RollbackWarning, TermsAndConditions },

    data: () => {
        return {
            viewportOrientationIsCorrect: undefined,
            viewportIsBigEnough: undefined
        };
    },
    computed:
    {
        userAuthenticationState() { return this.$store.getters['authentication/userAuthenticationState']; },

        theme() {
            return this.$route.meta.theme || this.$store.state.settings.currentTheme;
        },

        viewportStatus() {
            if (this.$route.meta.restrictViewport !== false) {
                if (this.viewportOrientationIsCorrect === false) {
                    return 'bad-orientation';
                } else if (this.viewportIsBigEnough === false) {
                    return 'bad-size';
                }
            }
            return 'ok';
        },

        shouldShowTerms() {
            if (
                (this.$route.meta.allowAnonymousUser !== true)
                &&
                (this.$store.getters['authentication/isUserAuthenticated'])
                &&
                (!this.$store.getters['authentication/hasUserAcceptedTerms'])
            ) {
                return true;
            }
            return false;
        },

        restrictViewport() {
            return (this.$route.meta.restrictViewport === false) ? false : true ;
        }

    },
    watch:
    {
        userAuthenticationState(value) {
            if (this.$route.params.nextUrl) {
                this.goToRoute(this.$route.params.nextUrl);
            } else {
                this.goToRoute(value ? '/welcome' : '/');
            }
        },
        pageTitle() {
            this.setPageTitle();
        },
        viewportStatus() {
            this.setViewportStatus();
        },
        restrictViewport() {
            this.setViewportStatus();
        }

    },
    created() {
        this.$store.dispatch('i18n/loadDefaultLocale');
        if (this.$route.meta.useExistingSession !== false) {
            this.$store.dispatch('authentication/loadCurrentSession');
        }
        this.initViewportDimensionTracking();
        this.setPageTitle();
        this.setViewportStatus();

        // :DEBUG: uncomment this to enable development override loading from window.developmentOverrides
        // this.initializeDevelopmentOverrides();
    },
    methods:
    {
        initializeDevelopmentOverrides() {
            // clone original values into initial development overrides
            window.developmentOverrides = {
                teethGeometry: JSON.parse(JSON.stringify(TEETH_GEOMETRY)),
                toothErosionSurfaces: JSON.parse(JSON.stringify(TOOTH_EROSION_SURFACES)),
                pathologiesAndRestorations: JSON.parse(JSON.stringify(PATHOLOGIES_AND_RESTORATIONS))
            };
            setInterval(this.loadDevelopmentOverrides, 200);
        },
        loadDevelopmentOverrides() {
            this.$store.commit('patient/teeth/state/loadDevelopmentOverrides');
        },

        initViewportDimensionTracking() {
            const orientationMediaQueryListener = window.matchMedia('(orientation: landscape)');
            orientationMediaQueryListener.addListener(() => { this.updateViewportOrientation(orientationMediaQueryListener); });
            this.updateViewportOrientation(orientationMediaQueryListener);

            const sizeMediaQueryListener = window.matchMedia('(min-width: 800px) and (min-height: 200px)');
            sizeMediaQueryListener.addListener(() => { this.updateViewportSize(sizeMediaQueryListener); });
            this.updateViewportSize(sizeMediaQueryListener);
        },
        updateViewportOrientation(orientationMediaQueryListener) {
            this.viewportOrientationIsCorrect = orientationMediaQueryListener && orientationMediaQueryListener.matches;
            this.$store.dispatch('general/viewportOrientationIsCorrect', this.viewportOrientationIsCorrect);
        },
        updateViewportSize(sizeMediaQueryListener) {
            this.viewportIsBigEnough = sizeMediaQueryListener && sizeMediaQueryListener.matches;
            this.$store.dispatch('general/viewportIsBigEnough', this.viewportIsBigEnough);

        },
        setPageTitle() {
            document.title = this.pageTitle;
        },
        setViewportStatus() {
            const body = document.querySelector('body');
            body.setAttribute('data-restrict-viewport', this.restrictViewport);
            body.setAttribute('data-viewport-status', this.viewportStatus);
        }
    }
};
</script>

<style lang="scss">
@import "./assets/stylesheets/app";
</style>
