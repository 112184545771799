<template>
    <div
        class="visualization"
        :data-jaw="tooth.jaw"
        :data-number="number"
        :data-previous-number="previousNumber"
        :data-next-number="nextNumber"
        :data-projection="projection"
        :data-image-type="imageType"
        :data-context="context"
        :data-layers="layers.join(' ')"
        v-bind="periodontalDataAttributes"
        :data-pathology="JSON.stringify(pathologyValues)"
        :data-restoration="JSON.stringify(restorationValues)"
        :data-root-canal="JSON.stringify(rootCanalValues)"
        :data-eruption="eruption"
        :data-state="toothStateName"
        :data-label="labelText"
    >
        <canvas
            ref="canvas"
            :width="canvasWidth"
            :height="canvasHeight"
            :style="canvasStyle"
        >
            <tooth-drawing
                :tooth="tooth"
                :previous-tooth="previousTooth"
                :next-tooth="nextTooth"
                :image-type="imageType"
                :context="context"
                :projection="projection"
                :periodontal-values="periodontalValues"
                :sibling-periodontal-values="siblingPeriodontalValues"
                :furcation-value="furcationValue"
                :mobility-value="mobilityValue"
                :canvas-context="canvasContext"
                :layers="layers"
                :pathology="pathologyValues"
                :restoration="restorationValues"
                :root-canal="rootCanalValues"
                :eruption="eruption"
                :draw-blank-images="drawBlankImages"
                :surrounded-by-erupted-teeth="surroundedByEruptedTeeth"
            />

        </canvas>

    </div>
</template>
<script>
// import clone from '@/utils/clone.js';
import ToothDrawing from '@/components/patient/tooth/drawing.vue';
import { JAWS, VISUALIZATION_CONTEXTS, PROJECTIONS, TOOTH_STATES, TOOTH_STATE_NAMES } from '@/config/teeth.js';
import { IMAGE_TYPES, IMAGE_TYPES_BY_TOOTH_STATE } from '@/config/visualization/general.js';

import { TOOTH_CANVAS_DIMENSIONS } from '@/config/visualization/canvas.js';

// import { PULP_TYPES } from '@/config/root-canal.js';

const allowedContexts = Object.values(VISUALIZATION_CONTEXTS);

export default {
    components: { ToothDrawing },
    props: {
        number: { required: true },
        previousNumber: { required: false },
        nextNumber: { required: false },
        projection: { required: true },
        context: { required: true, validator: value => allowedContexts.includes(value) },
        layers: { required: true },
        drawBlankImages: { required: true },
        surroundedByEruptedTeeth: { required: true }
    },
    data() {
        return {
            canvasContext: undefined
        };
    },

    computed:
    {
        tooth() {
            return this.$store.getters['patient/teeth/tooth'](this.number);
        },

        previousTooth() {
            return (this.previousNumber) ? this.$store.getters['patient/teeth/tooth'](this.previousNumber) : undefined;
        },

        nextTooth() {
            return (this.nextNumber) ? this.$store.getters['patient/teeth/tooth'](this.nextNumber) : undefined;
        },

        isToothErupted() {
            return this.$store.getters['patient/teeth/state/isToothErupted'](this.number);
        },

        imageType() {
            if (!this.isToothErupted) {
                return IMAGE_TYPES.BLANK;
            }
            const toothState = this.$store.getters['patient/teeth/state/toothState'](this.number);

            // :DEBUG: uncomment this to override tooth state (which image to use) for all teeth
            // const toothState = 4; // 1: missing, 2: crown, 4: implant

            return IMAGE_TYPES_BY_TOOTH_STATE[toothState];
        },

        periodontalValueGetter() {
            return this.$store.getters['patient/teeth/state/periodontal/projectionValues'];
        },

        periodontalValues() {
            return this.periodontalValueGetter(this.number, this.projection);
        },

        siblingPeriodontalValues() {
            const siblings = { };

            if (this.previousNumber) {
                siblings.previous = this.periodontalValueGetter(this.previousNumber, this.projection);
            }

            if (this.nextNumber) {
                siblings.next = this.periodontalValueGetter(this.nextNumber, this.projection);
            }

            return siblings;
        },

        furcationValue() {
            return this.$store.getters['patient/teeth/state/periodontal/furcation'](this.number);
        },
        mobilityValue() {
            return this.$store.getters['patient/teeth/state/periodontal/mobility'](this.number);
        },

        periodontalDataAttributes() {
            let attributes = {};

            const valuesByOwner = {
                self: this.periodontalValues,
                previous: this.siblingPeriodontalValues.previous,
                next: this.siblingPeriodontalValues.next
            };
            Object.keys(valuesByOwner).forEach(owner => {
                const values = valuesByOwner[owner];
                if (values) {
                    const attributePrefix = (owner === 'self') ? '' : owner + '-';

                    Object.keys(values).forEach(zone => {
                        attributes['data-' + attributePrefix + 'periodontal-depth-' + zone] = values[zone].depth;
                        attributes['data-' + attributePrefix + 'periodontal-margin-' + zone] = values[zone].margin;
                    });
                }
            });

            attributes['data-furcation'] = this.furcationValue;
            attributes['data-mobility'] = this.mobilityValue;
            return attributes;
        },

        pathologyValues() {
            const values = this.$store.getters['patient/teeth/state/pathology/visualizationValues'](this.number);
            return values;

            // :DEBUG: disable return line above and uncomment this to enable fracture, apical or tooth wear for all teeth

            // var debugValues = clone(values);
            // debugValues.fracture = { surfaces: [], values: { type: "crown", position: "vertical" } };
            // debugValues.apical = { surfaces: [], values: { presence: "yes" } };
            // debugValues["tooth-wearx"] = { surfaces: [], values: { type: "erosion", zone: [
            //     "buccal",
            //     "palatal"
            // ] } };
            // debugValues["tooth-wear"] = { surfaces: [], values: { type: "erosion", zone: [
            //     "buccal",
            //     "palatal"
            // ] } };
            // debugValues["tooth-wear"] = { surfaces: [], values: { type: "abrasion", zone: [
            //     "buccal",
            //     "palatal",
            //     "lingual"
            // ] } };

            // console.log(JSON.stringify(debugValues["tooth-wear"]));
            // return debugValues;
        },
        restorationValues() {
            const values = this.$store.getters['patient/teeth/state/restoration/visualizationValues'](this.number);
            return values;

            // :DEBUG: disable return line above and uncomment this to override restoration values, e.g. crown for all teeth
            // console.log(JSON.stringify(values));
            // let debugValues = clone(values);
            // debugValues = [
            //     {"type":"crown", "surfaces":[], "material":0, "crown_type":2, "created_at":"2022-12-21T06:06:32Z"}
            // ];
            // return debugValues;
        },
        rootCanalValues() {
            const values = this.$store.getters['patient/teeth/state/rootCanal/visualizationValues'](this.number);
            return values;

            // :DEBUG: disable return line above and uncomment this to override root canal values for all teeth
            // var debugValues = clone(values);
            // debugValues.pulpType = PULP_TYPES.URGENT;
            // debugValues.pulpType = PULP_TYPES.ROOT_CANAL_TREATMENT;
            // console.log(JSON.stringify(debugValues));
            // return debugValues;
        },

        toothState() {
            return this.$store.getters['patient/teeth/state/toothState'](this.number);
        },

        toothStateName() {
            return TOOTH_STATE_NAMES[this.toothState];
        },

        labelText() {
            return (this.toothStateName == TOOTH_STATE_NAMES[TOOTH_STATES.TO_BE_EXTRACTED])
                ?
                this.t('extract', 'dentalChartVC.legend.other')
                :
                null;
        },

        eruption() {
            return this.$store.getters['patient/teeth/state/eruption'](this.number);
        },

        canvasDimensions() {
            return TOOTH_CANVAS_DIMENSIONS[this.context][this.projection][this.tooth.set][this.tooth.position];
        },

        canvasWidth() {
            return this.canvasDimensions.width;
        },

        canvasHeight() {
            return this.canvasDimensions.height;
        },

        canvasStyle() {
            if (this.shouldInvertCanvas) {
                return 'transform: scaleY(-1);';
            } else {
                return null;
            }
        },

        shouldInvertCanvas() {
            if (this.tooth.jaw == JAWS.UPPER && this.projection == PROJECTIONS.PALATAL) {
                return true;
            }
            if (this.tooth.jaw == JAWS.LOWER && this.projection == PROJECTIONS.BUCCAL) {
                return true;
            }

            return false;
        }

    },
    mounted() {
        this.canvasContext = this.$refs.canvas.getContext('2d');
    }
};
</script>
