<template>
    <fieldset class="field" data-type="radio" :class="{ 'has-errors': hasErrors }">
        <legend v-if="label" class="label">{{ label }}</legend>

        <span class="control radio">
            <span class="option" v-for="option in options" :key="option">
                <input
                    type="radio"
                    :name="name"
                    :value="option"
                    :id="buildId(option)"
                    :checked="isCheked(option)"
                    v-bind="$attrs"
                    v-on="listeners"
                />
                <label :for="buildId(option)">{{ t(option, translationScope) }}</label>
            </span>
        </span>

        <field-errors v-if="hasErrors" :errors="errors" />
    </fieldset>
</template>

<script>
import FieldErrors from '@/components/fields/errors.vue';

export default {
    components: { FieldErrors },
    inheritAttrs: false,
    model: {
        event: 'change'
    },
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            required: true
        },
        errors: Array,
        value: String,
        translationScope: String
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                change: this.onChange
            };
        },
        hasErrors() {
            return !!this.errors;
        }
    },
    methods: {
        onChange(event) {
            this.$emit('change', event.target.value);
        },
        buildId(option) {
            return `${this.name}-${option}`;
        },
        isCheked(option) {
            return option === this.value;
        }
    }
};
</script>
