import freeze from '@/utils/freeze.js';

const RESTORATION_DETAIL_TYPES = freeze({
    MATERIAL: "material",
    QUALITY: "quality",
    DETAIL: "detail"
});

const RESTORATION_MATERIALS = freeze({
    COMPOSIT: 0,
    CERAMIC: 1,
    AMALGAM: 2,
    GOLD: 3,
    NON_PRECIOUS_METAL: 4,
    TEMPORARY: 5
});

const RESTORATION_QUALITIES = freeze({
    SUFFICIENT: 0,
    UNCERTAIN: 1,
    INSUFFICIENT: 2
});

const RESTORATION_DETAILS = freeze({
    OVERHANG: 0,
    FLUSH: 1,
    SHORTFALL: 2
});

// most restorations have the same details block, consisting of "material", "quality" and "detail" with the same options
const RESTORATION_DEFINITION_DEFAULT_DETAILS = freeze({
    [RESTORATION_DETAIL_TYPES.MATERIAL]: {
        labelKey: "restoration.material",
        options: [
            { value: RESTORATION_MATERIALS.COMPOSIT,            labelKey: "restoration.material.composit" },
            { value: RESTORATION_MATERIALS.CERAMIC,             labelKey: "restoration.material.ceramic" },
            { value: RESTORATION_MATERIALS.GOLD,                labelKey: "restoration.material.gold" },
            { value: RESTORATION_MATERIALS.NON_PRECIOUS_METAL,  labelKey: "restoration.material.nonPreciousMaterial" },
        ]
    },
    [RESTORATION_DETAIL_TYPES.QUALITY]: {
        labelKey: "restoration.quality",
        options: [
            { value: RESTORATION_QUALITIES.SUFFICIENT,          labelKey: "restoration.quality.sufficient" },
            { value: RESTORATION_QUALITIES.UNCERTAIN,           labelKey: "restoration.quality.uncertain" },
            { value: RESTORATION_QUALITIES.INSUFFICIENT,        labelKey: "restoration.quality.insufficient" },
        ]
    },
    [RESTORATION_DETAIL_TYPES.DETAIL]: {
        labelKey: "restoration.detail",
        options: [
            { value: RESTORATION_DETAILS.OVERHANG,              labelKey: "restoration.detail.overhang" },
            { value: RESTORATION_DETAILS.FLUSH,                 labelKey: "restoration.detail.flush" },
            { value: RESTORATION_DETAILS.SHORTFALL,             labelKey: "restoration.detail.shortfall" },
        ]
    },
});

export {
    RESTORATION_DETAIL_TYPES,
    RESTORATION_MATERIALS,
    RESTORATION_QUALITIES,
    RESTORATION_DETAILS,

    RESTORATION_DEFINITION_DEFAULT_DETAILS
};
