<template>
    <section data-view="terms-and-conditions" class="overlay">

        <div class="dialog">
            <header>
                <h1>{{ t('title', 'terms.user') }}</h1>
            </header>

            <div class="content">
                <waiting-overlay v-if="$wait.is(loadingWaiterName)" />
                <div v-else class="terms" v-html="termsHtml" />
            </div>

            <form class="actions" v-on:submit.prevent="accept" v-if="termsLoaded">

                <toggle-switch-field
                    id="terms-accepted"
                    :label="t('accept', 'terms.user')"
                    v-model="termsAccepted"
                />

                <template v-if="!$wait.is(acceptanceWaiterName)">
                    <!-- hide rejection button during acceptance so that it does not jump under the mouse cursor -->
                    <waiting-button :for="rejectionWaiterName" type="button" v-on:click="reject" class="secondary button">
                        {{ t('reject', 'terms.user') }}
                    </waiting-button>
                </template>

                <waiting-button :for="acceptanceWaiterName" type="submit" class="button" :disabled="!termsAccepted">
                    {{ t('agree', 'terms.user') }}
                </waiting-button>

            </form>

        </div>
    </section>
</template>
<script>
import WaitingOverlay from '@/components/waiting-overlay';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { WaitingOverlay },
    data: () => {
        return {
            termsHtml: undefined,
            termsLoaded: false,
            termsAccepted: false
        };
    },
    computed: {

    },
    created() {
        this.loadingWaiterName = WAITERS.LOADING_TERMS;
        this.acceptanceWaiterName = WAITERS.ACCEPTING_TERMS;
        this.rejectionWaiterName = WAITERS.SIGNING_OUT;
        this.loadTerms();
    },
    methods: {
        loadTerms() {
            this.$wait.start(this.loadingWaiterName);
            this.$api.getTerms().then(response => {
                this.termsHtml = response.data.text;
                this.termsLoaded = true;
            }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.loadingWaiterName));
        },
        accept() {
            this.$wait.start(this.acceptanceWaiterName);
            const sessionToken = this.$store.state.authentication.sessionToken;
            this.$api.acceptTerms().then(() => {
                if (this.$store.state.authentication.sessionToken == sessionToken) {
                    // make sure the session is still the same
                    this.$store.dispatch('authentication/markTermsAsAccepted');
                }
            }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.acceptanceWaiterName));
        },
        reject() {
            this.$store.dispatch('authentication/signOut');
        }
    }
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/terms-and-conditions";
</style>
