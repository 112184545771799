<template>

    <div class="report">

        <general-info />
        <medical-issues />
        <treatment-plan />
        <mouth-charts />

    </div>

</template>

<script>
import GeneralInfo from './report/general-info.vue';
import MedicalIssues from './report/medical-issues.vue';
import TreatmentPlan from './report/treatment-plan.vue';
import MouthCharts from './report/mouth-charts.vue';

export default {
    components: { GeneralInfo, MedicalIssues, TreatmentPlan, MouthCharts },

    mounted() {
        this.$nextTick(() => {
            this.$emit('ready');
        });
    }
};
</script>
<style lang="scss">
@import "~@/assets/stylesheets/components/patient/report";
</style>
