<template>
    <aside class="oral-health">
        <header>
            <h2 class="heading">{{ t('oralHealth', 'patientVC') }}</h2>

            <button class="button link edit" type="button" v-on:click="openDialog">
                {{ t('edit', 'common') }}
            </button>
        </header>

        <dl class="issues">

            <div class="issue">
                <dt>{{ t('plaqueIndex', 'patientVC.oralHealth') }}</dt>
                <dd class="textual">{{ plaqueIndexTextual }}</dd>
                <dd class="visual"><meter low="19.9" optimum="10" high="59.9" :value="plaqueIndex" max="100"></meter></dd>
            </div>

            <div class="issue">
                <dt>{{ t('bleedingIndex', 'patientVC.oralHealth') }}</dt>
                <dd class="textual">{{ bleedingIndexTextual }}</dd>
                <dd class="visual"><meter low="19.9" optimum="10" high="59.9" :value="bleedingIndex" max="100"></meter></dd>
            </div>

            <div v-for="(issueValue, issueName) in issues" :key="issueName" class="issue" :data-value="issueValue">
                <dt>{{ issueLabel(issueName) }}</dt>
                <dd :title="issueValue">{{ issueValue }}</dd>
            </div>
        </dl>


        <modal-dialog
            v-if="editingIssues || addingIssue"
            v-on:open="focusDialogCancel"
            v-on:cancel="cancelDialog($event.cause)"
            :close-on-outside-click="false"
        >
            <h1 class="title">
                <template v-if="editingIssues">
                    {{ t('title', 'patientVC.oralHealth.edit') }}
                </template>
                <template v-else>
                    {{ t('title', 'patientVC.oralHealth.add') }}
                </template>
            </h1>

            <form method="post" v-on:submit.prevent="submitForm">

                <template v-if="editingIssues">
                    <fieldset class="issue" v-for="issueName in Object.keys(editableIssues)" :key="issueName">
                        <legend>{{ issueLabel(issueName) }}</legend>

                        <level-selector v-model="editableIssues[issueName]" />

                        <button
                            class="delete"
                            type="button"
                            :title="t('delete', 'common')"
                            v-on:click="deleteEditableIssue(issueName)"
                        >
                            {{ t('delete', 'common') }}
                        </button>
                    </fieldset>

                    <p class="tools">
                        <button type="button" class="button link" data-icon="add" v-on:click="switchToAddingIssue">
                            {{ t('addIssue', 'patientVC.oralHealth.edit') }}
                        </button>
                    </p>
                </template>

                <template v-else-if="addingIssue">

                    <input-field
                        type="text"
                        name="issueName"
                        v-model="newIssueName"
                        :label="t('itemName', 'patientVC.oralHealth')"
                        ref="issueName"
                    />

                    <fieldset class="issue">
                        <level-selector v-model="newIssueValue" />
                    </fieldset>

                </template>


                <p class="actions">
                    <waiting-button
                        :for="waiterName"
                        type="submit"
                        class="button"
                        :disabled="addingIssue && nameIsBlank"
                        :class="{ disabled: addingIssue && nameIsBlank }"
                    >
                        <template v-if="editingIssues">
                            {{ t('submit', 'patientVC.oralHealth.edit') }}
                        </template>
                        <template v-else>
                            {{ t('submit', 'patientVC.oralHealth.add') }}
                        </template>
                    </waiting-button>
                    <button
                        class="button link cancel"
                        type="button"
                        ref="cancel"
                        v-on:click="cancelDialog('cancel')"
                    >
                        {{ t('cancel', 'common') }}
                    </button>
                </p>
            </form>

        </modal-dialog>


    </aside>
</template>

<script>
import Vue from 'vue';
import ModalDialog from '@/components/modal-dialog.vue';
import LevelSelector from '@/components/patient/dashboard/oral-health/level-selector.vue';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { ModalDialog, LevelSelector },
    props: {
        patientId: { required: true }
    },
    data: function() {
        return {
            editingIssues: false,
            editableIssues: undefined,

            addingIssue: false,
            newIssueName: undefined,
            newIssueValue: undefined
        };
    },
    computed: {
        issues() {
            // filter out any records with 0 or invalid values.
            // iOS app submits deleted issues back to API with value 0,
            // instead of actually deleting them, so they have to be filtered out here.
            const validValues = [1, 2, 3, 4, 5];
            const storedIssues = this.$store.state.patient.oralHealth.values || {};
            const validKeys = Object.keys(storedIssues).filter(key => validValues.includes(storedIssues[key]));
            return validKeys.reduce((object, key) => {
                object[key] = storedIssues[key];
                return object;
            }, {});
        },
        nameIsBlank() {
            return !this.newIssueName;
        },
        plaqueIndex() {
            return this.calculateOralHealthIndexValue(this.$store.state.patient.teeth.state.periodontal.issueValues.plaque);
        },
        bleedingIndex() {
            return this.calculateOralHealthIndexValue(this.$store.state.patient.teeth.state.periodontal.issueValues.bleeding);
        },
        plaqueIndexTextual() {
            let value = this.plaqueIndex;
            return value < 0 ? this.t('noData', 'patientVC.oralHealth') : value + "%";
        },
        bleedingIndexTextual() {
            let value = this.bleedingIndex;
            return value < 0 ? this.t('noData', 'patientVC.oralHealth') : value + "%";
        }
    },
    watch: {
        addingIssue(adding) {
            if (adding) {
                this.$nextTick(() => {
                    if (this.$refs.issueName) {
                        this.$refs.issueName.focus();
                    }
                });
            }
        }
    },
    created() {
        this.waiterName = WAITERS.SAVING_ORAL_HEALTH_ISSUES;
    },
    methods: {
        issueLabel(issueName) {
            return this.$options.filters.humanize(issueName); // (API #8)
        },
        calculateOralHealthIndexValue(indexValue) {
            let presentedTeeth = 0;
            let affectedSites = 0;
            const maxSites = 6;

            this.$store.getters['patient/teeth/relevantNumbers'].forEach(toothNumber => {
                if (!this.$store.getters['patient/teeth/state/isToothPresent'](toothNumber)) {
                    return;
                }

                ++presentedTeeth;
                let periodontal = this.$store.getters['patient/teeth/state/periodontal/loadedToothState'](toothNumber).periodontal;

                affectedSites += Object.keys(periodontal).filter(key => {
                    return (periodontal[key].details || []).includes(indexValue);
                }).length;
            });

            if (affectedSites == 0) {
                return -1;
            }

            return Math.round((affectedSites / (presentedTeeth * maxSites)) * 100);
        },
        openDialog() {
            this.ensureTeethStateModifiability().then(() => {
                this.editingIssues = true;
                this.addingIssue = false;
                this.editableIssues = Object.assign({}, this.issues);
            }, () => {});
        },
        switchToAddingIssue() {
            this.editingIssues = false;
            this.addingIssue = true;
            this.newIssueName = '';
            this.newIssueValue = 1;
        },
        resetNewIssue() {
            this.newIssueName = undefined;
            this.newIssueValue = undefined;
        },
        resetEditableIssues() {
            this.editableIssues = {};
        },
        switchToEditingIssue() {
            this.editingIssues = true;
            this.addingIssue = false;
            this.resetNewIssue();
        },
        cancelDialog(cause) {
            if (this.addingIssue && (cause == 'cancel' || cause == 'escape')) {
                this.switchToEditingIssue();
            } else {
                this.closeDialog();
            }
        },
        closeDialog() {
            this.resetNewIssue();
            this.resetEditableIssues();
            this.addingIssue = false;
            this.editingIssues = false;
        },
        focusDialogCancel() {
            this.$refs.cancel.focus();
        },
        deleteEditableIssue(issueName) {
            if (this.editableIssues[issueName] !== undefined) {
                Vue.delete(this.editableIssues, issueName);
            }
        },
        submitForm() {
            if (this.addingIssue) {
                Vue.set(this.editableIssues, this.newIssueName, this.newIssueValue);
                this.switchToEditingIssue();
                return;
            } else {
                if (this.$wait.is(this.waiterName)) {
                    return;
                }
                this.$wait.start(this.waiterName);

                const values = this.editableIssues;

                this.$api.updateOralHealthIssues(this.patientId, values).then(() => {
                    this.$store.dispatch('patient/oralHealth/setValues', values);
                    this.closeDialog();
                }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.waiterName));

            }

        }
    }
};
</script>
