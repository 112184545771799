<template>
    <div
        v-if="zone"
        data-view="periodontal-probing"
        :data-tooth-number="tooth.number"
        :data-quadrant="tooth.quadrant"
        :data-mode="mode"
    >

        <h2 v-if="singleToothMode" class="heading">
            {{ t('title', 'periodontalProbing') }}
        </h2>
        <h3 v-else class="heading">
            {{ t('tooth', 'dental') }} {{ tooth.label }}
        </h3>

        <nav class="teeth" v-if="chartMode">
            <router-link :to="previousToothRoute" class="previous" :title="t('previousTooth', 'periodontalProbing')">
                {{ t('previousTooth', 'periodontalProbing') }}
            </router-link>
            <router-link :to="nextToothRoute" class="next" :title="t('nextTooth', 'periodontalProbing')">
                {{ t('nextTooth', 'periodontalProbing') }}
            </router-link>
        </nav>

        <audio-button v-if="isAudioAvailable" />

        <back-button type="close" />

        <ul class="zones">
            <zone-pad
                v-for="zoneName in zoneNames"
                :key="zoneName"
                :zone-name="zoneName"
                :tooth-number="toothNumber"
                :zone-route="zoneRoute(zoneName)"
                link-method="replace"
            />
        </ul>

        <form method="post" v-on:submit.prevent="submit">

            <div class="measurement-selector" v-if="chartMode">

                <span
                    v-for="measurement in measurements"
                    :key="measurement"
                    class="measurement"
                    :class="{ 'selected': measurement == selectedMeasurement }"
                >
                    <input
                        type="radio"
                        name="measurement"
                        :value="measurement"
                        v-model="selectedMeasurement"
                        :id="'measurement-' + measurement"
                    />
                    <label :for="'measurement-' + measurement">{{ t(measurement, 'periodontalProbing') }}</label>
                </span>

            </div>

            <div class="measurements">
                <depth-selector
                    v-if="singleToothMode || selectedMeasurement == 'depth'"
                    :tooth-number="toothNumber"
                    :zone-name="selectedZoneName"
                    v-model="editableDepth"
                    :margin-value="editableMargin"
                    :show-legend="singleToothMode"
                />
                <margin-selector
                    v-if="singleToothMode || selectedMeasurement == 'margin'"
                    :tooth-number="toothNumber"
                    :zone-name="selectedZoneName"
                    v-model="editableMargin"
                    :show-legend="singleToothMode"
                />
            </div>

            <issue-selector :tooth-number="toothNumber" :zone-name="selectedZoneName" v-model="editableIssues" />

            <furcation-selector :tooth-number="toothNumber" v-model="editableFurcation" v-if="singleToothMode && isFurcationPossible" />

            <mobility-selector :tooth-number="toothNumber" v-model="editableMobility" v-if="singleToothMode" />

            <sequential-mode-switch v-if="chartMode" v-model="sequentialAutoMode" />

            <p class="actions">
                <template v-if="singleToothMode">
                    <waiting-button :for="waiterName" type="submit" class="button">
                        {{ t('save', 'periodontalProbing') }}
                    </waiting-button>
                </template>
                <template v-else-if="chartMode">
                    <button type="submit" class="button">
                        {{ t('nextTooth', 'periodontalProbing') }}
                    </button>
                </template>
            </p>

        </form>

        <subscription-banner v-if="singleToothMode" />

    </div>
</template>

<script>
import AudioButton from '@/components/patient/tooth/overview/periodontal/audio-button.vue';
import ZonePad from '@/components/patient/tooth/overview/periodontal/zone-pad.vue';
import DepthSelector from '@/components/patient/tooth/overview/periodontal/depth-selector.vue';
import MarginSelector from '@/components/patient/tooth/overview/periodontal/margin-selector.vue';
import IssueSelector from '@/components/patient/tooth/overview/periodontal/issue-selector.vue';
import FurcationSelector from '@/components/patient/tooth/overview/periodontal/furcation-selector.vue';
import MobilitySelector from '@/components/patient/tooth/overview/periodontal/mobility-selector.vue';
import SequentialModeSwitch from '@/components/patient/tooth/overview/periodontal/sequential-mode-switch.vue';

import CloseOnEscape from '@/mixins/closeOnEscape.js';

import { TOOTH_SETS, FACES, JAWS } from '@/config/teeth.js';
import { WAITERS } from '@/config/waiters.js';
import SubscriptionBanner from "@/components/subscription-banner.vue";

const SEQUENTIAL_AUTO_MODE_LOCAL_STORAGE_KEY = 'sequential-periodontal-probing-auto-mode';

export default {
    components: {
        AudioButton, ZonePad,
        DepthSelector, MarginSelector, IssueSelector, FurcationSelector, MobilitySelector,
        SequentialModeSwitch,
        SubscriptionBanner
    },
    mixins: [ CloseOnEscape ],
    props: {
        teeth: { required: false },
        tooth: { required: true },
        view: { required: true }
    },
    data: () => {
        return {
            measurements: [ "depth", "margin" ],
            selectedZoneName: undefined,
            selectedMeasurement: 'depth',

            zoneAudioAllowed: false,

            internalSequentialAutoMode: localStorage.getItem(SEQUENTIAL_AUTO_MODE_LOCAL_STORAGE_KEY) == '1',
        };
    },
    computed: {
        mode() {
            return (this.view == 'tooth') ? 'single-tooth' : 'chart';
        },
        singleToothMode() {
            return this.mode == 'single-tooth';
        },
        chartMode() {
            return this.mode == 'chart';
        },
        singleJawView() {
            return this.chartMode && this.view != 'full-mouth';
        },
        fullMouthView() {
            return this.chartMode && this.view == 'full-mouth';
        },
        sequentialAutoMode: {
            get() {
                return this.internalSequentialAutoMode;
            },
            set(value) {
                localStorage.setItem(SEQUENTIAL_AUTO_MODE_LOCAL_STORAGE_KEY, (value) ? '1' : '0');
                this.internalSequentialAutoMode = value;
            }
        },
        toothNumber() {
            return this.tooth.number;
        },
        zoneNames() {
            return this.$store.getters['patient/teeth/state/periodontal/zoneNames'](this.toothNumber);
        },
        routeZoneName() {
            return this.$route.params.zone_name;
        },
        zone() {
            return this.$store.getters['patient/teeth/state/periodontal/zone'](this.toothNumber, this.selectedZoneName);
        },
        isFurcationPossible() {
            return this.$store.getters['patient/teeth/state/periodontal/isFurcationPossible'](this.tooth.number);
        },
        isAudioEnabled() {
            return this.$store.state.patient.teeth.state.periodontal.audioEnabled;
        },
        isAudioAvailable() {
            return (this.chartMode) ? !this.sequentialAutoMode : true;
        },

        editableDepth:
        {
            get() { return this.zone.depth; },
            set(value) { return this.setMeasurement('depth', value); }
        },

        editableMargin:
        {
            get() { return this.zone.margin; },
            set(value) { return this.setMeasurement('margin', value); }
        },

        editableIssues:
        {
            get() { return this.zone.issues; },
            set(issueValues) { return this.setIssues(issueValues); }
        },

        editableFurcation:
        {
            get() { return this.$store.getters['patient/teeth/state/periodontal/furcation'](this.toothNumber); },
            set(value) { return this.setFurcation(value); }
        },

        editableMobility:
        {
            get() { return this.$store.getters['patient/teeth/state/periodontal/mobility'](this.toothNumber); },
            set(value) { return this.$store.dispatch('patient/teeth/state/periodontal/setEditableMobility', { toothNumber: this.toothNumber, value }); }
        },

        toothIndex() {
            return this.teeth.findIndex(tooth => tooth.number == this.tooth.number);
        },

        validTeethIndexes() {
            // all teeth are valid except missing and unerupted ones
            // NOTE: if this logic gets changed, also change it in tooth trigger component,
            // so that invalid teeth cannot get selected
            return this.teeth.reduce((array, tooth, index) => {
                if (this.$store.getters['patient/teeth/state/isToothPresent'](tooth.number)) {
                    array.push(index);
                }
                return array;
            }, []);
        },

        validTeethIndexesInCurrentJaw() {
            return this.validTeethIndexes.filter(index => this.teeth[index].jaw == this.currentJaw);
        },

        validTeethIndexesInOtherJaw() {
            return this.validTeethIndexes.filter(index => this.teeth[index].jaw == this.otherJaw);
        },

        // these are used for manual navigation of next / previous teeth
        previousTooth() {
            const indexes = this.validTeethIndexes.slice().reverse();
            let previousIndex = indexes.find(n => n < this.toothIndex);
            if (previousIndex == undefined) {
                // if there are no indexes smaller than this one, loop around to the other end
                previousIndex = indexes[0];
                // if there are no indexes at all, just stay on the same tooth
                if (previousIndex == undefined) {
                    previousIndex = this.toothIndex;
                }
            }
            return this.teeth[previousIndex];
        },

        nextTooth() {
            const indexes = this.validTeethIndexes;
            let nextIndex = indexes.find(n => n > this.toothIndex);
            if (nextIndex == undefined) {
                // if there are no indexes larger than this one, loop around to the start
                nextIndex = indexes[0];
                // if there are no indexes at all, just stay on the same tooth
                if (nextIndex == undefined) {
                    nextIndex = this.toothIndex;
                }
            }
            return this.teeth[nextIndex];
        },

        previousToothRoute() {
            return this.toothRoute(this.previousTooth, this.$route.name, { zone_name: undefined } );
        },

        nextToothRoute() {
            return this.toothRoute(this.nextTooth, this.$route.name, { zone_name: undefined } );
        },

        currentZoneFace() {
            return this.selectedZoneName.includes("buccal") ? FACES.BUCCAL : FACES.PALATAL_OR_LINGUAL;
        },

        otherZoneFace() {
            return (this.currentZoneFace == FACES.BUCCAL) ? FACES.PALATAL_OR_LINGUAL : FACES.BUCCAL;
        },

        currentJaw() {
            return this.tooth.jaw;
        },

        otherJaw() {
            return (this.currentJaw == JAWS.UPPER) ? JAWS.LOWER : JAWS.UPPER;
        },

        nextSequentialZoneNameInCurrentRow() {
            const zoneNameRows = this.$store.getters['patient/teeth/state/periodontal/zoneNameRows'](this.toothNumber);
            const currentRow = zoneNameRows[this.currentZoneFace];
            if (!currentRow) {
                return undefined;
            }
            const currentZoneIndexInRow = currentRow.findIndex(zoneName => zoneName == this.selectedZoneName);
            if (currentZoneIndexInRow < 0) {
                return undefined;
            }
            const nextOffset = (this.currentZoneFace == FACES.BUCCAL) ? 1 : -1;

            return currentRow[currentZoneIndexInRow + nextOffset];
        },
        nextSequentialZoneNameInOtherRow() {
            const zoneNameRows = this.$store.getters['patient/teeth/state/periodontal/zoneNameRows'](this.toothNumber);
            const otherRow = zoneNameRows[this.otherZoneFace];

            return (this.otherZoneFace == FACES.PALATAL_OR_LINGUAL) ? otherRow[otherRow.length - 1] : otherRow[0];
        },
        nextSequentialToothAndZoneNameInCurrentJaw() {
            const currentlyInBuccalZoneRow = this.currentZoneFace == FACES.BUCCAL;
            const teethIndexSequenceInCurrentJaw =
                (currentlyInBuccalZoneRow)
                    ? this.validTeethIndexesInCurrentJaw
                    : this.validTeethIndexesInCurrentJaw.slice().reverse()
            ;
            const currentToothIndex = this.toothIndex;
            const currentPositionInSequence = teethIndexSequenceInCurrentJaw.findIndex(index => index == currentToothIndex);

            if (currentPositionInSequence < 0) {
                return undefined;
            }
            const nextToothIndex = teethIndexSequenceInCurrentJaw[currentPositionInSequence + 1];
            if (nextToothIndex === undefined) {
                // end of jaw reached
                return undefined;
            }
            const nextTooth = this.teeth[nextToothIndex];
            const zoneNameRowsByFace = this.$store.getters['patient/teeth/state/periodontal/zoneNameRows'](nextTooth.number);
            const initialZoneRow = zoneNameRowsByFace[this.currentZoneFace];
            const initialZoneName = (currentlyInBuccalZoneRow) ? initialZoneRow[0] : initialZoneRow[initialZoneRow.length - 1];

            return {
                tooth: nextTooth,
                zoneName: initialZoneName
            };
        },
        nextSequentialToothInNextJaw() {
            let validTeethIndexesInTargetJaw;

            if (this.fullMouthView) {
                // in full mouth view, go to the start of the other jaw,
                // unless an edge case occurs where there are no valid teeth in the other jaw - then stay in the same jaw
                validTeethIndexesInTargetJaw = (this.validTeethIndexesInOtherJaw.length > 0)
                    ? this.validTeethIndexesInOtherJaw
                    : this.validTeethIndexesInCurrentJaw;
            } else {
                // in single jaw view, stay in the same jaw
                validTeethIndexesInTargetJaw = this.validTeethIndexesInCurrentJaw;
            }

            return this.teeth[validTeethIndexesInTargetJaw[0]];
        }
    },
    watch: {
        toothNumber() {
            this.initialize();
        },
        routeZoneName() {
            this.selectRouteZone();
        },
        selectedZoneName(zoneName) {
            if (
                (this.zoneAudioAllowed) // this prevents triggering zone audio on first load or when changing tooth + zone
                &&
                (this.isAudioEnabled)
            ) {
                this.$store.dispatch('audio/play', [zoneName]);
            }
        },
        sequentialAutoMode(on) {
            if (on) {
                this.disableAudio();
            }
        }
    },
    created() {
        this.waiterName = WAITERS.SAVING_PERIODONTAL_STATE;
        if (this.sequentialAutoMode) {
            this.disableAudio();
        }
        this.initialize();
    },
    beforeDestroy() {
        this.endEditingTooth();
    },
    methods: {
        initialize() {
            this.zoneAudioAllowed = false;
            const task = (this.isToothBeingEdited()) ? this.endEditingTooth() : Promise.resolve();
            task.then(() => this.startEditingTooth());
            this.selectRouteZone();
            if (this.chartMode && this.isAudioEnabled) {
                this.playToothAudio();
            }
            this.$nextTick(() => {
                this.zoneAudioAllowed = true;
            });
        },
        isToothBeingEdited() {
            return this.$store.getters['patient/teeth/state/periodontal/isToothBeingEdited'];
        },
        startEditingTooth() {
            this.$store.dispatch('patient/teeth/state/periodontal/startEditingTooth', { toothNumber: this.tooth.number });
        },
        endEditingTooth() {
            const task = (this.chartMode) ? this.save() : Promise.resolve();
            return task.then(() => this.$store.dispatch('patient/teeth/state/periodontal/endEditingTooth'));
        },

        selectRouteZone() {
            // this corrects unrecognized zone names in URL (first zone gets selected)
            // and also toggles palatal / lingual pairs for upper / lower jaw
            const chartMode = this.chartMode;
            const validZoneName = this.$store.getters['patient/teeth/state/periodontal/correctZoneName'](this.toothNumber, this.routeZoneName, { chartMode });
            if (validZoneName != this.routeZoneName) {
                this.goToRoute(this.zoneRoute(validZoneName), { replace: true });
            } else {
                this.selectedZoneName = validZoneName;
            }
        },

        zoneRoute(zoneName, tooth = this.tooth) {
            const routeName = (this.singleToothMode) ?
                'periodontal-probing' :
                this.chartRouteName('periodontal-probing', this.view)
            ;
            return this.toothRoute(tooth, routeName, { zone_name: zoneName } );
        },


        setMeasurement(type, value) {
            return this.$store.dispatch('patient/teeth/state/periodontal/setEditableMeasurement', {
                toothNumber: this.toothNumber,
                zoneName: this.selectedZoneName,
                type,
                value
            }).then(() => {

                const absoluteValue = Math.abs(value);
                if (absoluteValue > 0 && this.isAudioEnabled) {
                    const audioName = absoluteValue > 12 ? 'greater-12' : absoluteValue;
                    this.$store.dispatch('audio/play', [audioName]);
                }

                if (this.chartMode && this.sequentialAutoMode) {
                    return this.goToNextSequentialStep();
                }

            });
        },

        setIssues(issueValues) {
            const addedIssueNames = issueValues
                .filter(value => !(this.editableIssues || []).includes(value))
                .map(value => this.$store.getters['patient/teeth/state/periodontal/issueName'](value))
            ;

            return this.$store.dispatch('patient/teeth/state/periodontal/setEditableIssues', {
                toothNumber: this.toothNumber,
                zoneName: this.selectedZoneName,
                issueValues
            }).then(() => {
                if (addedIssueNames.length > 0 && this.isAudioEnabled) {
                    this.$store.dispatch('audio/play', addedIssueNames);
                }
            });
        },

        setFurcation(value) {
            return this.$store.dispatch('patient/teeth/state/periodontal/setEditableFurcation', {
                toothNumber: this.toothNumber,
                value
            }).then(() => {
                if (value && this.isAudioEnabled) {
                    this.$store.dispatch('audio/play', ['furcation', 'grade-' + value]);
                }
            });
        },

        save() {
            return this.$store.dispatch('patient/teeth/state/periodontal/saveEditableToothState');
        },

        submit() {
            // only save in single tooth mode,
            // because in chart mode saving will be triggered automatically on route change
            if (this.singleToothMode) {
                return this.save().then(() => this.close());
            } else {
                this.goToRoute(this.nextToothRoute, { replace: true });
            }
        },

        close() {
            this.goToRoute(this.backRoute);
        },

        playToothAudio() {
            // :TODO: add audio files for USA labels (#37)
            if (this.$store.getters['settings/isoNotationActive'] && this.tooth.set == TOOTH_SETS.PERMANENT) {
                this.$store.dispatch('audio/play', [this.tooth.type + '-tooth', this.tooth.quadrant + '-' + this.tooth.position]);
            }
        },

        disableAudio() {
            this.$store.dispatch('patient/teeth/state/periodontal/disableAudio');
        },

        goToNextSequentialStep() {
            // navigates to either next zone or next tooth + zone

            // the order is as follows:
            // 1) starting from quadrant 1, all buccal zones for each tooth,
            // up to the end of the upper jaw at the end of quadrant 2
            // 2) then back through quadrants 2 and 1 using all palatal zones in reverse order

            // 3) then the same for lower jaw (quadrants 4 and 3 buccal zones, and back quadrants 3 / 4 lingual zones)
            // 4) at the very end, go back to 1)

            // in single jaw mode, only the current jaw is traversed

            // see whether there is a next zone in the current zone row for the current tooth
            const nextZoneNameInRow = this.nextSequentialZoneNameInCurrentRow;
            if (nextZoneNameInRow) {
                // go to next zone in the row
                return this.goToRoute(this.zoneRoute(nextZoneNameInRow));
            }

            // no next zone found in current row, must switch to a new tooth and zone
            // try next tooth in the same jaw in the same direction
            const nextToothAndZoneName = this.nextSequentialToothAndZoneNameInCurrentJaw;
            if (nextToothAndZoneName) {
                return this.goToRoute(this.zoneRoute(nextToothAndZoneName.zoneName, nextToothAndZoneName.tooth));
            }

            // no more teeth to go in the current jaw in the current direction

            // if in buccal face, go to palatal/lingual face of same toooth
            // (reversing at the end of the jaw's first pass)
            if (this.currentZoneFace == FACES.BUCCAL) {
                const nextZoneNameInOtherRow = this.nextSequentialZoneNameInOtherRow;
                return this.goToRoute(this.zoneRoute(nextZoneNameInOtherRow));
            }

            // if in palatal/lingual face,
            // go to start of other jaw if in full mouth view
            // or go to start of same jaw in single jaw view (restart whole sequence)
            if (this.currentZoneFace == FACES.PALATAL_OR_LINGUAL) {
                const nextTooth = this.nextSequentialToothInNextJaw;
                const route = this.toothRoute(nextTooth, this.$route.name, { zone_name: undefined } );

                return this.goToRoute(route);
            }
        }


    }
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/views/patient/tooth/periodontal-probing";
</style>
