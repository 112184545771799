import filters from '@/filters';

const filtersModule = {
    namespaced: true,

    getters: {}
};

Object.keys(filters).forEach(filterName => {
    filtersModule.getters[filterName] = () => value => filters[filterName](value);
});

export default filtersModule;
