export default {
    namespaced: true,

    state: {
        error: undefined
    },

    getters: {

    },

    mutations: {
        setError(state, error) {
            state.error = error;
        },
        clearError(state) {
            state.error = undefined;
        }
    },

    actions: {

        handleError(context, error) {
            let rethrow = true;

            // automatically log user out if any API request returns that the session does not exist
            if (error && error.response && error.response.status == 401) {
                rethrow = false;
                context.dispatch('authentication/clearSession', null, { root: true });


            } else if (error && error.isAxiosError) {
            // for any other api client error, store the error (it shows a message to the user)
                context.commit('setError', error);
            }

            if (rethrow) {
                throw error;
            }
        },

        clearError(context) {
            return context.commit('clearError');
        }

    }
};
