<template>
    <div class="issues">
        <ul class="column" v-for="(list, index) in lists" :key="index" :class="{ blank: list.length < 1}">
            <li v-for="issue in list" :key="issue.value" class="issue" :data-name="issue.name">
                {{ issue.label }}
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    props: {
        issues: { required: true },
        columns: { required: false }
    },
    computed: {
        lists() {
            const itemsPerColumn = Math.ceil(this.issues.length / this.numberOfColumns);
            const lists = [];
            for (let i = 0; i < this.numberOfColumns; i++) {
                lists.push( this.issues.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn) );
            }

            return lists;
        },

        numberOfColumns() {
            return (this.columns) ? this.columns : 1;
        }
    }
};
</script>
