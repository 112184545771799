<template>
    <div data-view="patient">
        <patient-sidebar v-if="shouldShowSidebar" />

        <template v-if="patientExists">
            <transition :name="transitionName" mode="out-in">
                <router-view v-if="patientExists" :patient="patient" />
            </transition>
        </template>

        <template v-else-if="patientShouldExist">
            <p class="error">
                {{ t('Patient not found', 'patientVC') }}
            </p>
        </template>

        <template v-else>
            <waiting-overlay />
        </template>

    </div>
</template>
<script>
import PatientSidebar from '@/components/patient/sidebar.vue';
import WaitingOverlay from '@/components/waiting-overlay';
export default {
    components: { PatientSidebar, WaitingOverlay },
    data() {
        return {

        };
    },
    computed:
    {
        patientId() {
            return this.$route.params.patient_id;
        },

        patient() {
            return this.$store.state.patient.object;
        },

        patientExists() {
            return this.$store.getters['patient/exists'];
        },

        patientShouldExist() {
            return this.$store.getters['patient/shouldExist'];
        },

        shouldShowSidebar() {
            return (this.$route.meta.showPatientSidebar !== false);
        },

        transitionName() {
            return (this.$route.meta.transition === false) ? "none" : "fade";
        }
    },
    watch: {
        patientId(id) {
            this.loadPatient(id);
        }
    },
    created() {
        this.loadPatient(this.patientId);
    },
    mounted() {

    },
    destroyed() {
        this.clearPatient();
    },
    methods: {
        loadPatient(patientId) {
            this.$store.dispatch('patient/setId', patientId);
        },
        clearPatient() {
            this.$store.dispatch('patient/clearId');
        }
    }



};
</script>
<style lang="scss">
@import "~@/assets/stylesheets/views/patient";
</style>
