import freeze from '@/utils/freeze.js';
import clone from '@/utils/clone.js';

import { TOOTH_TYPES, ZONES, TOOTH_SETS, TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE } from '@/config/teeth.js';

import { RESTORATION_DEFINITION_DEFAULT_DETAILS } from '@/config/restorations/general.js';

// "restoration": 1

const definition = {
    toothTypes: [TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};

const permanentPremolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.PREMOLAR];
const deciduousPremolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.DECIDUOUS][TOOTH_TYPES.PREMOLAR];
const permanentMolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.PERMANENT][TOOTH_TYPES.MOLAR];
const deciduousMolarNumbers = TOOTH_NUMBERS_BY_TOOTH_SET_AND_TYPE[TOOTH_SETS.DECIDUOUS][TOOTH_TYPES.MOLAR];

const inlayToothNumbers = permanentPremolarNumbers.concat(deciduousPremolarNumbers, permanentMolarNumbers, deciduousMolarNumbers);

const inlaySurfaces = { };

// for permanent teeth, inlay surfaces are the same for all teeth of the same tooth type

permanentPremolarNumbers.forEach(number => {
    inlaySurfaces[number] = {
        [ZONES.MESIAL]: [2, 5, 8, 15, 18],
        [ZONES.OCCLUSAL]: [9],
        [ZONES.DISTAL]: [4, 7, 10, 17, 20]
    };
});

permanentMolarNumbers.forEach(number => {
    inlaySurfaces[number] = {
        [ZONES.MESIAL]: [2, 7, 11, 18, 22],
        [ZONES.OCCLUSAL]: [12],
        [ZONES.DISTAL]: [6, 10, 13, 21, 26]
    };
});

// for deciduous teeth, inlay surfaces are different for each individual tooth

inlaySurfaces["54"] = {
    [ZONES.MESIAL]: [2, 6].concat([9, 13, 16], [20, 23]),
    [ZONES.OCCLUSAL]: [14],
    [ZONES.DISTAL]: [5, 8].concat([11, 15, 18], [22, 26])
};
inlaySurfaces["64"] = clone(inlaySurfaces["54"]);

inlaySurfaces["55"] = {
    [ZONES.MESIAL]: [2, 7].concat([13], [18, 22]),
    [ZONES.OCCLUSAL]: [14],
    [ZONES.DISTAL]: [6, 10].concat([15], [21, 27])
};
inlaySurfaces["65"] = clone(inlaySurfaces["55"]);

inlaySurfaces["84"] = {
    [ZONES.MESIAL]: [1, 5].concat([14], [22, 23]),
    [ZONES.OCCLUSAL]: [15],
    [ZONES.DISTAL]: [4, 10].concat([16], [19, 30])
};
inlaySurfaces["74"] = clone(inlaySurfaces["84"]);

inlaySurfaces["85"] = {
    [ZONES.MESIAL]: [7, 11].concat([14], [19, 23]),
    [ZONES.OCCLUSAL]: [15],
    [ZONES.DISTAL]: [2, 8].concat([16], [22, 28])
};
inlaySurfaces["75"] = clone(inlaySurfaces["85"]);


inlayToothNumbers.forEach(number => {
    definition.teeth[number] = {
        surfaces: inlaySurfaces[number],
        default: [ ZONES.MESIAL, ZONES.OCCLUSAL, ZONES.DISTAL ],
        details: RESTORATION_DEFINITION_DEFAULT_DETAILS
    };
});


const INLAY_DEFINITION = freeze(definition);

export {
    INLAY_DEFINITION
};
