import uuid from 'uuid/v4';
import merge from 'lodash/merge';
import RecordStoreModule from './recordStoreModule.js';
import { WAITERS } from '@/config/waiters.js';
import { NOTATION_SYSTEMS } from '@/config/teeth.js';

export default merge({}, RecordStoreModule, {
    state: {
        idAttribute: "history_record_id"
    },

    getters: {
        orderedActiveItems: (state, getters, rootState, rootGetters) => {
            const orderedItems = getters.orderedItems;

            let filterFunction;
            if (rootGetters['patient/teeth/state/isHistorical']) {
                const historicalDate = rootState.patient.teeth.state.teethStateDate;

                // API returns manipulation dates in UTC (instead of team's or user's time zone) (API #36)
                // so they can currently be used directly on the utc timestamps.
                // this will have to change, when the dates get fixed on API side
                filterFunction = entry => {
                    return (
                        (entry.created_at.substring(0, 10) <= historicalDate)
                        &&
                        // currently this does nothing, because soft-deleted records are not returned by the API (API #37)
                        (!entry.deleted_at || (entry.deleted_at.substring(0, 10) > historicalDate))
                    );
                };

            } else {
                filterFunction = entry => !entry.deleted_at;
            }
            return orderedItems.filter(filterFunction);
        }
    },

    mutations: {

    },

    actions: {
        addRecord(context, { message, toothNumber }) {
            const patientId = context.rootState.patient.object.id;
            const waiterName = WAITERS.ADDING_HISTORY_RECORD;

            context.dispatch('wait/start', waiterName, { root: true });

            const values = {
                history_record_id: uuid(),
                content: message,
                source_tooth_number: (toothNumber) ? toothNumber : ""
            };

            return this.$api.createHistoryRecord(patientId, values).then(() => {
                // to avoid having to reload the whole history list,
                // simply append the new values as an object to the list of others

                // since creation time is not yet known, simulate it from the browser's time
                values.created_at = context.rootGetters['time/currentUtcTimestamp'];


                context.commit('prepend', values);
            }).catch(this.apiErrorHandler).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        },

        addToothRecord(context, { toothNumber, message }) {
            // tooth labels always use ISO notation internally, and only get converted to USA notation on output, if needed
            const toothLabel = context.rootGetters["patient/teeth/toothLabelInGivenNotation"](toothNumber, NOTATION_SYSTEMS.ISO);

            const fullMessage = [
                toothLabel,
                message
            ].join(", ");

            return context.dispatch('patient/history/addRecord', { message: fullMessage, toothNumber }, { root: true });
        },

        deleteRecord(context, recordId) {
            const patientId = context.rootState.patient.object.id;
            const waiterName = WAITERS.DELETING_HISTORY_RECORD;
            context.dispatch('wait/start', waiterName, { root: true });

            return this.$api.deleteHistoryRecord(patientId, recordId).then(() => {
                // do not remove item from list, because it might be used in rollback.
                // mark it locally as deleted instead

                // since deletion time is not yet known, simulate it from the browser's time
                context.commit('modify', {
                    id: recordId,
                    attribute: "deleted_at",
                    value: context.rootGetters['time/currentUtcTimestamp']
                });

            }).catch(this.apiErrorHandler).finally(() => context.dispatch('wait/end', waiterName, { root: true }));
        }
    }
});
