import { CHART_LAYERS, CHART_MODES } from '@/config/teeth.js';

export default {

    namespaced: true,

    state: {
        layers: {
            [CHART_LAYERS.ENDODONTIC]: { active: true },
            [CHART_LAYERS.PERODONTIAL]: { active: true },
            [CHART_LAYERS.DENTAL]: { active: true }
        },
        modes: Object.values(CHART_MODES),
        legendIsOpen: false,
        rollbackIsOpen: false
    },

    getters: {
        layers: state => Object.keys(state.layers),
        layerIsActive: state => name => state.layers[name].active,
        activeLayers: (state, getters) => getters.layers.filter(name => getters.layerIsActive(name)),
        selectableModes: state => state.modes.filter(mode => mode != CHART_MODES.REPORT),
        rollbackIsOpen: (state, getters, rootState, rootGetters) => {
            return state.rollbackIsOpen || !rootGetters['patient/teeth/state/isModifiable'];
        }
    },

    mutations: {
        toggleLayer(state, { name, active }) {
            state.layers[name].active = active;
        },
        toggleLegend(state) {
            state.legendIsOpen = !state.legendIsOpen;
        },
        setRollbackState(state, { isOpen } ) {
            state.rollbackIsOpen = isOpen;
        }
    },

    actions: {
        setActiveLayers(context, activeNames) {
            context.getters.layers.forEach(name => {
                context.commit('toggleLayer', { name, active: activeNames.includes(name) });
            });
        },
        activateAllLayers(context) {
            return context.dispatch('setActiveLayers', context.getters.layers);
        },

        toggleLegend(context) {
            context.commit('toggleLegend');
        },


        toggleRollback(context, value) {
            if (value == undefined) {
                value = !context.state.rollbackIsOpen;
            }
            context.commit('setRollbackState', { isOpen: value } );
        },


    }

};
