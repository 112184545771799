<template>
    <span class="number" v-if="isToothErupted" :data-treatment-plan-level="treatmentPlanLevel">{{ tooth.label }}</span>
    <span class="number" v-else />
</template>

<script>
export default {
    props: {
        tooth: { required: true }
    },

    computed:
    {
        treatmentPlanLevel() {
            return this.$store.getters['patient/treatmentPlan/levelByToothNumber'](this.tooth.number);
        },
        isToothErupted() {
            return this.$store.getters['patient/teeth/state/isToothErupted'](this.tooth.number);
        }
    },


};
</script>
