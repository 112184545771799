import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store/store.js';

import AccountRoutes from '@/routes/account.js';
import UserRoutes from '@/routes/user.js';
import PatientRoutes from '@/routes/patient.js';
import UtilityRoutes from '@/routes/utility.js';


// navigation failure handler based on
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
// and
// https://v3.router.vuejs.org/guide/advanced/navigation-failures.html#detecting-navigation-failures
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => {
        if (Router.isNavigationFailure(err)) {
            // silence navigation failure errors, because they are not a problem.
            // and are unavoidable when navigation guards perform redirects.
            // possible types:
            // export enum NavigationFailureType {
            //     redirected = 2,
            //     aborted = 4,
            //     cancelled = 8,
            //     duplicated = 16
            // }
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};




Vue.use(Router);
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        AccountRoutes,
        UserRoutes,
        PatientRoutes,
        UtilityRoutes
    ].flat()
});

router.beforeEach((to, from, next) => {
    if (to.query.locale) {
        store.dispatch('i18n/loadDefaultLocale', to.query.locale);
        const query = Object.assign({}, to.query);
        delete query.locale;
        next({ path: to.path, query });
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    let isUserStateKnown = store.getters['authentication/isUserStateKnown'];
    let userIsAuthenticated = store.getters['authentication/isUserAuthenticated'];
    let integratorModeIsActive = store.getters['authentication/isIntegratorModeActive'];
    let routeUsesExistingSession = to.meta.useExistingSession !== false;

    if (routeUsesExistingSession) {
        if (!isUserStateKnown) {
            // this happens on initial app load.
            // it is not yet known whether the user is logged in
            // (there may be a session token in localstorage, but it is not yet known whether it is valid)
            // redirect to a temporary loading page until the state gets cleared up
            if (to.name == 'loading') {
                next();
            } else {
                next({
                    name: 'loading',
                    params: { nextUrl: to.fullPath },
                    replace: true
                });
            }
        } else if (isUserStateKnown && to.name == 'loading') {
            // do not allow opening loading page directly when the user state is known.
            // redirect to root
            next({
                name: 'root',
                replace: true
            });
        } else if (!userIsAuthenticated && to.matched.some(record => record.meta.allowAnonymousUser !== true)) {
            // page requires auth, user is not signed in, redirect to sign-in
            next({
                name: 'sign-in',
                params: { nextUrl: to.fullPath },
                replace: true
            });
        } else if (userIsAuthenticated && to.matched.some(record => record.meta.allowAuthenticatedUser === false)) {
            // page not allowed for authenticated users, redirect to authenticated start page (patients)
            next({
                name: 'patients',
                replace: true
            });
        } else if (userIsAuthenticated && integratorModeIsActive && to.meta.allowInIntegratorMode === false) {
            // page not allowed in integrator mode sessions,
            // redirect to the single patient view based on integrator session
            const patientId = store.getters['authentication/integratorPatientId'];
            next({
                name: 'patient',
                params: { patient_id: patientId },
                replace: true
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

// prevent opening of editing routes when a historical teeth state has been loaded via rollback
router.beforeEach((to, from, next) => {
    if (
        // only check this if the app has already loaded a patient
        // (otherwise it prevents opening deep links, because state is not yet loaded (and thus not yet modifiable)
        (store.getters['patient/exists'])
        &&
        (!store.getters['patient/teeth/state/isModifiable'])
        &&
        (to.matched.some(record => record.meta.allowInRollback === false))
    ) {
        store.dispatch('patient/teeth/state/openRollbackWarning');
        next(false);
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    store.dispatch('navigation/storePreviousRoute', from);
});

export default router;
