<template>
    <fieldset class="field" data-type="birth-date" :class="{ dirty: isDirty, 'has-errors': hasErrors }">
        <legend v-if="label" class="label">{{ label }}</legend>

        <span class="controls">
            <span class="control text">
                <input
                    type="text"
                    pattern="\d*"
                    maxlength="2"
                    :placeholder="t('DD', 'date.format.placeholders')"
                    v-model="day"
                    v-on="partListeners"
                    name="Date"
                />
            </span>

            <span class="control select">
                <select
                    v-model="month"
                    v-on="partListeners"
                    name="Month"
                    required
                >
                    <option value="" selected disabled>{{ t('Month', 'date') }}</option>
                    <option v-for="(monthName, index) in monthNames" :key="monthName" :value="index">{{ t(monthName, 'date.months') }}</option>
                </select>
            </span>

            <span class="control text">
                <input
                    type="text"
                    v-model="year"
                    v-on="partListeners"
                    name="Year"
                    pattern="\d*"
                    maxlength="4"
                    :placeholder="t('YYYY', 'date.format.placeholders')"
                />
            </span>
        </span>

        <field-errors v-if="hasErrors" :errors="errors" />
    </fieldset>
</template>

<script>
import FieldErrors from '@/components/fields/errors.vue';
import Moment from 'moment-timezone';

export default {
    components: { FieldErrors },
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        value: String,
        errors: Array
    },
    data() {
        return {
            hasFocus: false,

            day: undefined,
            month: undefined,
            year: undefined,
            internalValue: undefined,

            monthNames: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],

        };
    },
    computed: {
        partListeners() {
            return {
                focus: this.onFocus,
                blur: this.onBlur
            };
        },
        isDirty() {
            return this.hasFocus || this.hasContent;
        },
        hasErrors() {
            return !!this.errors;
        },
        hasContent() {
            return this.anyPartHasValue();
        }
    },
    watch: {
        day() {
            this.recalculateValue();
        },
        month() {
            this.recalculateValue();
        },
        year() {
            this.recalculateValue();
        },
        internalValue(newInternalValue) {
            if (newInternalValue != this.value) {
                this.$emit('input', newInternalValue);
            }
        }
    },
    created() {
        this.parseValue();
    },
    methods: {
        parseValue() {
            let moment;
            const value = this.value;
            if (value && (moment = Moment(value, "YYYY-MM-DD")) && moment.isValid()) {
                this.day = moment.date();
                this.month = moment.month();
                this.year = moment.year();
            } else {
                this.day = undefined;
                this.month = undefined;
                this.year = undefined;
            }
        },
        anyPartHasValue() {
            return [this.day, this.month, this.year].some(value => value != undefined && value.toString() != '');
        },
        allPartsHaveValues() {
            return [this.day, this.month, this.year].every(value => value != undefined && value.toString() != '');
        },
        recalculateValue() {
            let value = undefined;
            if (this.allPartsHaveValues()) {
                const moment = Moment({ year: this.year, month: this.month, day: this.day });
                if (moment.isValid()) {
                    value = moment.format('YYYY-MM-DD');
                }
            }
            this.internalValue = value;
        },
        onFocus() {
            this.hasFocus = true;
            this.$emit('focus');
        },
        onBlur() {
            this.hasFocus = false;
            this.$emit('blur');
        },
    }
};
</script>
