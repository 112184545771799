import {
    ENDODONTIC_TESTS,

    COLD_TEST_RESULTS,
    COLD_TEST_SUBRESULTS,

    HEAT_TEST_RESULTS,
    HEAT_TEST_SUBRESULTS,

    PERCUSSION_TEST_RESULTS,
    PALPATION_TEST_RESULTS,

    ELECTRICITY_TEST_RESULTS,

    ENDODONTIC_TEST_VALUES
} from '@/config/endodontic-tests.js';

import issues from './endodontic/issues.js';

export default {

    namespaced: true,

    modules: {
        issues
    },

    state: {
        testAnswerStructure: {
            [ENDODONTIC_TESTS.COLD]: [
                {
                    [COLD_TEST_RESULTS.POSITIVE]: [
                        COLD_TEST_SUBRESULTS.WITHIN_LIMITS,
                        COLD_TEST_SUBRESULTS.UNPLEASANT,
                        COLD_TEST_SUBRESULTS.PAIN_STIMULUS,
                        COLD_TEST_SUBRESULTS.PAIN_LINGERING
                    ]
                },
                COLD_TEST_RESULTS.UNCERTAIN,
                COLD_TEST_RESULTS.NEGATIVE,
                {
                    [COLD_TEST_RESULTS.NOT_APPLICABLE]: [
                        COLD_TEST_SUBRESULTS.EXISTING_ROOT_CANAL_TREATMENT,
                        COLD_TEST_SUBRESULTS.PREVIOUSLY_INITIATED_THERAPY
                    ]
                }
            ],
            [ENDODONTIC_TESTS.PERCUSSION]: [
                PERCUSSION_TEST_RESULTS.NOT_PAINFUL,
                PERCUSSION_TEST_RESULTS.UNPLEASANT,
                PERCUSSION_TEST_RESULTS.PAINFUL
            ],
            [ENDODONTIC_TESTS.PALPATION]: [
                PALPATION_TEST_RESULTS.NOT_PAINFUL,
                PALPATION_TEST_RESULTS.UNPLEASANT,
                PALPATION_TEST_RESULTS.PAINFUL
            ],
            [ENDODONTIC_TESTS.HEAT]: [
                {
                    [HEAT_TEST_RESULTS.POSITIVE]: [
                        HEAT_TEST_SUBRESULTS.WITHIN_LIMITS,
                        HEAT_TEST_SUBRESULTS.UNPLEASANT,
                        HEAT_TEST_SUBRESULTS.PAIN_STIMULUS,
                        HEAT_TEST_SUBRESULTS.PAIN_LINGERING
                    ]
                },
                HEAT_TEST_RESULTS.UNCERTAIN,
                HEAT_TEST_RESULTS.NEGATIVE,
                HEAT_TEST_RESULTS.NOT_APPLICABLE
            ],
            [ENDODONTIC_TESTS.ELECTRICITY]: [
                ELECTRICITY_TEST_RESULTS.E_1,
                ELECTRICITY_TEST_RESULTS.E_2,
                ELECTRICITY_TEST_RESULTS.E_3,
                ELECTRICITY_TEST_RESULTS.E_4,
                ELECTRICITY_TEST_RESULTS.E_5,
                ELECTRICITY_TEST_RESULTS.E_6,
                ELECTRICITY_TEST_RESULTS.E_7,
                ELECTRICITY_TEST_RESULTS.E_8,
                ELECTRICITY_TEST_RESULTS.E_9,
                ELECTRICITY_TEST_RESULTS.E_10
            ]
        },

        editableTest: {
            toothNumber: undefined,
            testName: undefined,
            state: undefined
        }
    },

    getters: {
        testNames: (state) => {
            return Object.keys(state.testAnswerStructure);
        },
        blankResult: () => {
            return { result: 0, subresult: 0 };
        },

        loadedTestState: (state, getters) => ({ toothNumber, testName }) => {
            if (getters.isTestInProgress(toothNumber) && testName === state.editableTest.testName) {
                return state.editableTest.state;
            } else {
                return getters.testStateFromMainStore({ toothNumber, testName });
            }
        },

        isTestInProgress: (state) => (toothNumber, testName) => {
            // can be used to check whether a specific test is in progress for given teeth
            // OR
            // whether ANY test is in progress for given tooth (by not passing the testName argument)
            if (!toothNumber || (toothNumber !== state.editableTest.toothNumber)) {
                return false;
            }
            if (testName === undefined) {
                return true;
            }
            return testName === state.editableTest.testName;
        },

        testNameInProgress: state => state.editableTest.testName,

        toothStateFromMainStore: (state, getters, rootState, rootGetters) => (toothNumber) => {
            const fullToothState = rootGetters['patient/teeth/state/tooth'](toothNumber) || {};
            return { endodontic: fullToothState["endodontic"] || {} };
        },

        testStateFromMainStore: (state, getters) => ({ toothNumber, testName }) => {
            const stateInMainStore = getters.toothStateFromMainStore(toothNumber).endodontic[testName] || {};

            const state = {
                result: stateInMainStore.result || 0,
                subresult: stateInMainStore.subresult || 0,
            };

            // ensure valid values
            const firstStepOption = getters.valueOption({ testName, value: state.result });
            if (
                (!firstStepOption)
                ||
                (!getters.firstStepOptions(testName).includes(firstStepOption))
            ) {
                state.result = 0;
            }

            const secondStepOption = getters.valueOption({ testName, value: state.subresult });
            if (
                (state.result === 0)
                ||
                (!getters.testHasTwoSteps(testName, firstStepOption))
                ||
                (!getters.secondStepOptions(testName, state.result).includes(secondStepOption))
            ) {
                state.subresult = 0;
            }

            return state;
        },

        test: (state, getters) => ({ toothNumber, testName, forceStateFromMainStore }) => {
            if (getters.testNames.includes(testName)) {

                const getterName = (forceStateFromMainStore) ? 'testStateFromMainStore' : 'loadedTestState';

                let test = Object.assign({}, getters[getterName]({ toothNumber, testName }) || {});

                if (test.result === undefined) {
                    test.result = 0;
                }

                if (test.subresult === undefined) {
                    test.subresult = 0;
                }
                return test;
            } else {
                return undefined;
            }
        },

        testLabel: (state, getters, rootState, rootGetters) => (testName) => {
            return rootGetters['i18n/t'](testName, 'dental.endodontic');
        },

        testHasTwoSteps: (state) => (testName, firstStepOption) => {
            const answers = state.testAnswerStructure[testName];
            const result = answers.some(value => ((typeof value !== "string") && (!!value[firstStepOption])));
            return result;
        },

        firstStepOptions: (state) => (testName) => {
            return state.testAnswerStructure[testName].map(option => {
                return (["string", "number"].includes(typeof option)) ? option : Object.keys(option)[0];
            });
        },

        secondStepOptions: (state, getters) => (testName, firstStepValue) => {
            const firstStepOption = getters.valueOption({ testName, value: firstStepValue });
            return state.testAnswerStructure[testName].find(option => Object.keys(option)[0] === firstStepOption)[firstStepOption];
        },

        optionLabel: (state, getters) => ({ testName, option }) => {
            const value = getters.optionValue({ testName, option });
            return getters.valueLabel({ testName, value });
        },

        optionValues: () => ( testName ) => {
            return ENDODONTIC_TEST_VALUES[testName];
        },

        optionValue: (state, getters) => ({ testName, option }) => {
            if (testName == ENDODONTIC_TESTS.ELECTRICITY) {
                return option;
            }
            return getters.optionValues(testName)[option];
        },

        valueOption: (state, getters) => ({testName, value}) => {
            if (testName == ENDODONTIC_TESTS.ELECTRICITY) {
                return (state.testAnswerStructure[testName].includes(value)) ? value : undefined;
            } else {
                const values = getters.optionValues(testName);
                return Object.keys(values).find(key => values[key] === value);
            }
        },

        valueLabel: (state, getters, rootState, rootGetters) => ({ testName, value }) => {
            if (testName == ENDODONTIC_TESTS.ELECTRICITY) {
                return value.toString();
            }

            const option = getters.valueOption({testName, value});
            if (!option) {
                return;
            }
            const labelKey = rootGetters['filters/camelize'](option);
            const coldHeatTest = [ENDODONTIC_TESTS.COLD, ENDODONTIC_TESTS.HEAT].includes(testName);
            const labelScopeKey = coldHeatTest ? "coldHeatResult" : "percussionPalpationResult";
            const labelScope = 'dental.endodontic.' + labelScopeKey;
            return rootGetters['i18n/t'](labelKey, labelScope);
        },

        saveableStateValues: (state, getters) => ({ toothNumber, saveableValues }) => {
            const stateValues = JSON.parse(JSON.stringify(getters.toothStateFromMainStore(toothNumber)));
            Object.keys(saveableValues).forEach(testName => {
                stateValues.endodontic[testName] = saveableValues[testName];
            });
            return stateValues;
        },

        saveableValuesAfterRootCanalTreatment: (state, getters) => ({ toothNumber }) => {
            const saveableValues = {
                // set cold test to non applicable + existing root canal treatment
                [ENDODONTIC_TESTS.COLD]: {
                    result: getters.optionValue({ testName: ENDODONTIC_TESTS.COLD, option: COLD_TEST_RESULTS.NOT_APPLICABLE }),
                    subresult: getters.optionValue({ testName: ENDODONTIC_TESTS.COLD, option: COLD_TEST_SUBRESULTS.EXISTING_ROOT_CANAL_TREATMENT })
                },
                // clear percussion test
                [ENDODONTIC_TESTS.PERCUSSION]: getters.blankResult,
                // clear palpation test
                [ENDODONTIC_TESTS.PALPATION]: getters.blankResult
            };

            // if electricity is > 0, set it to 10
            const electricityResult = getters.testStateFromMainStore({ toothNumber, testName: ENDODONTIC_TESTS.ELECTRICITY });
            if (electricityResult && electricityResult.result != 0) {
                saveableValues[ENDODONTIC_TESTS.ELECTRICITY] = { result: ELECTRICITY_TEST_RESULTS.E_10, subresult: 0 };
            }

            // if heat has any value, change it to not applicable
            const heatResult = getters.testStateFromMainStore({ toothNumber, testName: ENDODONTIC_TESTS.HEAT });
            if (heatResult && heatResult.result != 0) {
                const notApplicableHeatValue = getters.optionValue({ testName: ENDODONTIC_TESTS.HEAT, option: HEAT_TEST_RESULTS.NOT_APPLICABLE });
                saveableValues[ENDODONTIC_TESTS.HEAT] = { result: notApplicableHeatValue, subresult: 0 };
            }

            return saveableValues;
        },


    },

    mutations: {
        startEditingTest( state, { toothNumber, testName, testState }) {
            state.editableTest.toothNumber = toothNumber;
            state.editableTest.testName = testName;
            state.editableTest.state = testState;
        },
        endEditingTest( state ) {
            state.editableTest.toothNumber = undefined;
            state.editableTest.testName = undefined;
            state.editableTest.state = undefined;
        },
        setEditableTestValue( state, { type, value } ) {
            state.editableTest.state[type] = value;
            if (type === "result") {
                state.editableTest.state.subresult = 0;
            }
        }
    },

    actions: {
        startEditingTest(context, { toothNumber, testName }) {
            const testState = context.getters.testStateFromMainStore({ toothNumber, testName });
            context.commit('startEditingTest', { toothNumber, testName, testState } );
        },

        endEditingTest(context) {
            context.commit('endEditingTest');
        },

        setEditableTestValue(context, { toothNumber, testName, type, value }) {
            if (context.getters.isTestInProgress( toothNumber, testName )) {
                context.commit('setEditableTestValue', { type, value });
            }
        },

        // saves a single test for a single tooth (does not have to be currently loaded)
        saveTestValues(context, { toothNumber, testName, testValues, waiterName }) {
            // testValues expects an object of a single test
            // e.g. { "result":1, "subresult":4 }

            const toothNumbers = [ toothNumber ];
            const saveableValues = { [testName]: testValues };

            const shouldUpdateRootCanal = [
                ENDODONTIC_TESTS.COLD,
                ENDODONTIC_TESTS.PERCUSSION,
                ENDODONTIC_TESTS.ELECTRICITY
            ].includes(testName);

            // after single tooth endo test the root canal values must be updated with updateAfterEndodonticTest
            // (which causes root canal issue analysis)
            return context.dispatch('saveTeethValues', { toothNumbers, saveableValues, waiterName }).then(() => {
                if (shouldUpdateRootCanal) {
                    return context.dispatch('patient/teeth/state/rootCanal/updateAfterEndodonticTest', {
                        toothNumber,
                        testName,
                        shouldAnalyze: true
                    }, { root: true });
                }
            });

        },


        // saves multiple test values for multiple teeth at once
        // (all teeth will have the same values saved)
        // :NOTE: this does not perform rootCanal analysis afterwards, but it does update pulp, if needed
        saveTeethValues(context, { toothNumbers, saveableValues, waiterName }) {
            // saveableValues expects an object with tests as keys
            // and result / subresult as values
            // e.g. { "cold": { "result":1, "subresult":4 } }

            const shouldUpdateRootCanal = saveableValues[ENDODONTIC_TESTS.COLD] != undefined;

            const teethStateValues = toothNumbers.reduce((teethStateValues, toothNumber) => {
                teethStateValues[toothNumber] = context.getters.saveableStateValues({ toothNumber, saveableValues });
                return teethStateValues;
            }, {});

            const updatePayload = { teethStateValues, waiterName };
            return context.dispatch('patient/teeth/state/updateTeethStates', updatePayload, { root: true }).then(() => {
                if (shouldUpdateRootCanal) {
                    const promises = toothNumbers.map(toothNumber => {
                        return context.dispatch('patient/teeth/state/rootCanal/updateAfterEndodonticTest', {
                            toothNumber,
                            testName: ENDODONTIC_TESTS.COLD,
                            // pulp type needs to be updated, but issues need not get analyzed when saving multiple teeth
                            shouldAnalyze: false
                        }, { root: true });
                    });
                    return Promise.all(promises);
                }
            });
        },


        updateStateAfterRootCanalTreatment(context, { toothNumber }) {
            // :NOTE: it is not needed to call rootCanal/updateAfterEndodonticTest after this
            const saveableValues = context.getters.saveableValuesAfterRootCanalTreatment({ toothNumber });
            const toothNumbers = [ toothNumber ];
            return context.dispatch('saveTeethValues', { toothNumbers, saveableValues });
        }


    }

};
