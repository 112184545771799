import freeze from '@/utils/freeze.js';
import { TOOTH_TYPES, TOOTH_NUMBERS } from '@/config/teeth.js';


const DISCOLORATION_DETAIL_TYPES = freeze({
    TYPE: "type"
});

const DISCOLORATION_TYPES = freeze({
    GRAY: "gray",
    RED: "red",
    YELLOW: "yellow"
});


// discoloration does not use manually selectable zone pads.
// all surfaces of the tooth are automatically shown if any discoloration type is set.
// surface numbers are only used for visualization and are not sent to the API

const DISCOLORATION_DETAILS = freeze({
    [DISCOLORATION_DETAIL_TYPES.TYPE]: {
        options: [
            { value: DISCOLORATION_TYPES.GRAY, surfaces: []  },
            { value: DISCOLORATION_TYPES.RED, surfaces: [] },
            { value: DISCOLORATION_TYPES.YELLOW, surfaces: [] }
        ]
    }
});

const definition = {
    toothTypes: [TOOTH_TYPES.INCISOR, TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};

TOOTH_NUMBERS.forEach(number => {
    definition.teeth[number] = {
        surfaces: { },
        details: DISCOLORATION_DETAILS
    };
});


const DISCOLORATION_DEFINITION = freeze(definition);

export {
    DISCOLORATION_DEFINITION,

    DISCOLORATION_DETAIL_TYPES,
    DISCOLORATION_TYPES,
    DISCOLORATION_DETAILS,
};
