import merge from 'lodash/merge';
import PathologyType from '../pathologyType.js';
import { ZONES_BY_JAW } from '@/config/teeth.js';
import { PATHOLOGIES } from '@/config/teeth.js';

export default merge({}, PathologyType, {

    state: {
        name: PATHOLOGIES.TOOTH_WEAR
    },

    getters: {

        superDefinition: PathologyType.getters.definition,

        definition: (state, getters) => (toothNumber) => {
            const definition = JSON.parse(JSON.stringify(getters.superDefinition(toothNumber)));

            // filter palatal / lingual zone options from details depending on jaw
            const jaw = getters.parent('tooth', toothNumber).jaw;
            const relevantZoneNames = ZONES_BY_JAW[jaw];

            definition.details.zone.options = definition.details.zone.options.filter(option => {
                return relevantZoneNames.includes(option.value);
            });

            return definition;
        },

        calculateSurfaces: () => () => {
            // tooth wear surfaces do not depend on zone pads
            // and are hardcoded in visualization config depending on selected detail values
            // so they do not need to be calculated here or sent to the server on save
            return [];
        },

    },

    mutations: {

    },

    actions: {

    }

});
