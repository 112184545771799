import merge from 'lodash/merge';
import { RESTORATIONS, RESTORATION_VALUES } from '@/config/teeth.js';
import PathologyAndRestoration from './pathologyAndRestoration.js';

import filling from './restoration/filling.js';
import inlay from './restoration/inlay.js';
import onlay from './restoration/onlay.js';
import veneer from './restoration/veneer.js';
import partialCrown from './restoration/partialCrown.js';
import crown from './restoration/crown.js';

export default merge({}, PathologyAndRestoration, {

    modules: {
        filling,
        inlay,
        onlay,
        veneer,
        partialCrown,
        crown
    },
    state: {
        name: "restoration",
        definedTypeNames: Object.values(RESTORATIONS),

        // this holds the full pathology/restoration state for the tooth being edited
        editableTooth: {
            number: undefined,
            state: undefined, // this holds the respective state object
            stateValue: undefined // this holds the number of tooth's state (missing = 1, implant = 4 etc)
        },

        // construct an object like { 0: "filling", 1: "inlay" ... }
        typeNamesByValue: Object.values(RESTORATIONS).reduce((result, type) => {
            result[RESTORATION_VALUES[type]] = type;
            return result;
        }, {}),
    },

    getters: {

        // returns the full array of restorations from main store
        // if tooth is being edited, adds the in-progress restoration to the end of the array
        toothState: (state, getters) => (toothNumber) => {
            const appendableState = (state.editableTooth.number == toothNumber) ? state.editableTooth.state : undefined;
            return getters.saveableToothState({ toothNumber, appendableState });
        },

        saveableToothState: (state, getters) => ({ toothNumber, appendableState }) => {
            const storedState = getters.storedToothState(toothNumber);
            if (appendableState != undefined) {
                return storedState.concat([appendableState]);
            } else {
                return storedState;
            }
        },

        superStoredToothState: PathologyAndRestoration.getters.storedToothState,
        storedToothState: (state, getters) => (toothNumber) => {
            const storedState = getters.superStoredToothState(toothNumber);
            // sometimes some details such as crown_type, crown_base, material etc have an invalid value of -1
            // this is, apparently, how iOS sometimtes stores missing details (e.g., when saving pontics via quickselect,
            // when there is no material provided).
            // the web-app, on the other hand, does not store missing values at all in these cases.
            // so in case anything has a -1 value in the loaded restoration data, simply remove that value
            // to avoid causing problems further down the line with component code that expects valid values
            storedState.forEach(restoration => {
                Object.keys(restoration).forEach(key => {
                    if (restoration[key] == -1) {
                        delete restoration[key];
                    }
                });
            });
            return storedState;
        },

        blankState: () => () => {
            return [];
        },

        calculateEditableToothStateValue: (state, getters, rootState, rootGetters) => {
            const toothNumber = state.editableTooth.number;
            const toothState = state.editableTooth.state;
            const typeName = (toothState && toothState.restoration != undefined) ? state.typeNamesByValue[toothState.restoration] : undefined;
            const details = (typeName) ? rootGetters[getters.typeStoreScope(typeName) + '/details'](toothNumber) : undefined;

            return getters.calculateToothStateValue({ toothNumber, toothState, details });
        },

        calculateToothStateValue: (state, getters, rootState, rootGetters) => ({ toothNumber, toothState, details }) => {
            let stateValue;

            if (toothState && toothState.restoration != undefined) {
                // some restoration has been set. detect whether the tooth state should be changed because of it
                const typeName = state.typeNamesByValue[toothState.restoration];
                if (typeName) {
                    stateValue = rootGetters[getters.typeStoreScope(typeName) + '/calculateStateValue']({ toothNumber, details });
                }
            }

            if (stateValue == undefined) {
                // no overriden state value is needed due to current restoration
                // use the stored value from the main store
                stateValue = rootGetters['patient/teeth/state/storedToothState'](toothNumber);
            }

            return stateValue;
        },


        orderedRestoration: () => (restoration) => {
            // fixes key order in a restoration instance.
            // so that they are in a predictable order for visualizations and tests that match the json string fragments

            // restoration and surfaces always come first
            // created_at is always last
            // everything else is in between
            const entry = {
                restoration: restoration.restoration,
                surfaces: restoration.surfaces || []
            };
            const ignorableKeys = ["restoration", "surfaces", "created_at"];
            Object.keys(restoration)
                .filter(key => !ignorableKeys.includes(key))
                .forEach(key => {
                    entry[key] = restoration[key];
                })
            ;
            if (restoration.created_at) {
                entry.created_at = restoration.created_at;
            }

            return entry;
        },

        visualizationValues: (state, getters) => (toothNumber) => {
            const toothState = JSON.parse(JSON.stringify(getters.toothState(toothNumber)));

            const visualizationValues = [];
            toothState.forEach(restoration => {
                const restorationType = state.typeNamesByValue[restoration.restoration];
                if (!restorationType) {
                    return;
                }
                const orderedRestoration = getters.orderedRestoration(restoration);
                const entry = {
                    type: restorationType
                };
                Object.keys(orderedRestoration).forEach(key => {
                    if (key != 'restoration') {
                        entry[key] = orderedRestoration[key];
                    }
                });
                visualizationValues.push(entry);
            });
            return visualizationValues;
        },

        areActionsEnabled: (state, getters) => () => {
            return !getters.isEditableStateBlank();
        },

        isEditableStateBlank: (state) => () => {
            const editableState = state.editableTooth.state;
            if (
                (editableState)
                &&
                (
                    // being non-blank means having either at least some surfaces
                    (editableState.surfaces && editableState.surfaces.length > 0)
                    ||
                    // or having any other values except restoration / surfaces / created_at
                    (Object.keys(editableState).filter(key => !["restoration", "surfaces", "created_at"].includes(key)).length > 0)
                    ||
                    // or having crown selected in the editable state
                    // (crown has no surfaces and should be submittable with no details)
                    (editableState.restoration == RESTORATION_VALUES[RESTORATIONS.CROWN])
                )
            ) {
                return false;
            }
            return true;
        },

        saveableStateValues: (state, getters) => (toothNumber) => {
            return {
                state: state.editableTooth.stateValue,
                restoration: getters.toothState(toothNumber)
            };
        },

        creatableRecords: (state, getters) => (action) => {
            // a restoration should be added to history/treatment plan only if the saveable state is not blank
            if (getters.isEditableStateBlank()) {
                return [];
            }

            const typeName = state.typeNamesByValue[state.editableTooth.state.restoration];
            const creatableRecord = getters.creatableRecordFromEditableState({ action, typeName });

            return (creatableRecord) ? [creatableRecord] : [];
        },

    },

    mutations: {
        startEditingTooth( state, { toothNumber, toothState, toothStateValue }) {
            state.editableTooth.number = toothNumber;
            state.editableTooth.state = toothState;
            state.editableTooth.stateValue = toothStateValue;
        },
        endEditingTooth( state ) {
            state.editableTooth.number = undefined;
            state.editableTooth.state = undefined;
            state.editableTooth.stateValue = undefined;
        },
        setTypeState( state, { typeState } ) {
            state.editableTooth.state = typeState;
        },
        setStateValue( state, { stateValue }) {
            state.editableTooth.stateValue = stateValue;
        }
    },

    actions: {
        startEditingTooth(context, { toothNumber }) {
            if (context.state.editableTooth.number == toothNumber) {
                return; // already editing this tooth.
            }
            context.dispatch('setType', { toothNumber, typeName: undefined });
        },

        setType(context, { toothNumber, typeName }) {
            let editableState;
            if (typeName) {
                editableState = context.rootGetters[context.getters.typeStoreScope(typeName) + '/defaultState'](toothNumber);
            } else {
                editableState = {};
            }

            const stateValue = context.getters.calculateEditableToothStateValue;

            context.commit('startEditingTooth', { toothNumber, toothState: editableState, stateValue } );
            context.dispatch('updateEditableStateValue');
        },

        superSetTypeState: PathologyAndRestoration.actions.setTypeState,
        setTypeState( context, { typeName, typeState }) {
            const orderedTypeState = context.getters.orderedRestoration(typeState);
            return context.dispatch('superSetTypeState', { typeName, typeState: orderedTypeState }).then(() => {
                return context.dispatch('updateEditableStateValue');
            });
        },

        updateEditableStateValue( context ) {
            const stateValue = context.getters.calculateEditableToothStateValue;
            return context.commit('setStateValue', { stateValue });
        },

    }

});
