<template>
    <main data-view="settings">
        <div class="content">
            <h1>{{ t('title', 'settingsVC') }}</h1>

            <radio-field
                name="theme"
                :label="theme.label"
                v-model="currentTheme"
                :options="theme.options"
                translation-scope="settingsVC.themes"
            />

            <radio-field
                name="notationSystem"
                :label="notationSystem.label"
                v-model="currentNotationSystem"
                :options="notationSystem.options"
                translation-scope="settingsVC.notationSystems"
            />

            <back-button type="close" />
        </div>
    </main>
</template>
<script>
import CloseOnEscape from '@/mixins/closeOnEscape.js';
export default {
    mixins: [ CloseOnEscape ],

    computed: {
        currentTheme: {
            get() {
                return this.$store.state.settings.currentTheme;
            },

            set(theme) {
                this.$store.dispatch('settings/setCurrentTheme', theme);
            }
        },

        theme() {
            return {
                label: this.t('theme', 'settingsVC.themes'),
                options: this.$store.state.settings.themes,
            };
        },


        currentNotationSystem: {
            get() {
                return this.$store.state.settings.currentNotationSystem;
            },

            set(theme) {
                this.$store.dispatch('settings/setCurrentNotationSystem', theme);
            }
        },

        notationSystem() {
            return {
                label: this.t('notationSystem', 'settingsVC.notationSystems'),
                options: this.$store.state.settings.notationSystems,
            };
        }
    },
    watch:
    {
    },
    created() {

    },
    mounted() {

    },
    methods:
    {
        close() {
            this.goToRoute(this.backRoute);
        },
    }
};
</script>
<style lang="scss">
@import "~@/assets/stylesheets/views/settings";
</style>
