<template>
    <div data-view="print">
        <patient-report v-if="teethStateLoaded" v-on:ready="reportReady = true" />
        <p class="error" v-if="dateInvalid">
            {{ invalidDateMessage }}
        </p>
        <subscription-banner transition-name="" />
    </div>
</template>
<script>
import PatientReport from '@/components/patient/report.vue';
import SubscriptionBanner from "@/components/subscription-banner.vue";

export default {
    components: {SubscriptionBanner, PatientReport },

    props: {
        patient: { required: true }
    },

    data: () => {
        return {
            teethStateLoaded: false,
            reportReady: false,

            readyForPrinting: false,
            dateInvalid: false,
        };
    },
    computed: {
        date() {
            return this.$route.params.date;
        },
        invalidDateMessage() {
            return 'Invalid report date parameter "' + this.date + '" for patient + ' + this.patient.id + '.';
        },
        imagesLoaded() {
            return this.$store.state.images.loaded;
        }
    },
    watch: {
        imagesLoaded() {
            this.checkReadiness();
        },
        reportReady() {
            this.checkReadiness();
        }
    },
    created() {
        this.loadTeethState();
    },
    mounted() {
        this.checkReadiness();
    },
    beforeMount() {
        // API printing service will wait until this function returns true before generating pdf
        window.isReadyForPrinting = () => {
            return this.readyForPrinting;
        };
    },
    beforeDestroy() {
        delete window.isReadyForPrinting;
    },

    methods: {
        loadTeethState() {
            const availableDates = this.$store.state.patient.teeth.state.history.dates;
            let latestAvailableDate = availableDates[availableDates.length - 1];

            if (latestAvailableDate === undefined) {
                latestAvailableDate = this.$store.state.patient.teeth.state.history.todaysDate;
            }

            if (this.date == latestAvailableDate || this.date > latestAvailableDate) {
                this.$store.dispatch('patient/teeth/state/restoreCurrentTeethState').then(() => {
                    this.teethStateLoaded = true;
                });
            } else if (availableDates.includes(this.date)) {
                this.$store.dispatch('patient/teeth/state/setHistoricalTeethState', this.date).then(() => {
                    this.teethStateLoaded = true;
                });
            } else {
                this.dateInvalid = true;

                var params = { message: this.invalidDateMessage };
                document.dispatchEvent(
                    new CustomEvent(
                        'sentry-error',
                        { detail: { error: 'Patient report print error', params } }
                    )
                );

                this.readyForPrinting = true;
            }
        },
        checkReadiness() {
            if (this.imagesLoaded && this.reportReady) {
                this.$nextTick(() => {
                    this.readyForPrinting = true;
                });
            }
        }
    }
};
</script>
<style lang="scss">
@import "~@/assets/stylesheets/views/patient/report/print";
</style>
