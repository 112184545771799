<template>
    <confirmation-dialog
        :for="waiterName"
        v-on:confirm="goToSubscription"
        v-on:close="close"

        :confirmation-label="t('Buy', 'addPatientVC.warning')"
        :cancellation-label="t('close', 'common')"
    >
        <h1 class="title"> {{ t('Free patient limit reached', 'addPatientVC.warning') }} </h1>
        <p>
            {{ t('Buy the full version of derec® to add more patients', 'addPatientVC.warning') }}
        </p>
    </confirmation-dialog>
</template>
<script>
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { ConfirmationDialog },
    data() {
        return {

        };
    },
    computed: {
    },
    created() {
        // this waiter is not actually used because router actions do not take long.
        // it is here because the confirmation dialog requires a waiter.
        this.waiterName = WAITERS.REDIRECTING_TO_SUBSCRIPTION;
    },
    methods: {
        goToSubscription() {
            this.$router.push({ path: 'billing' });
        },

        close() {
            this.$emit('close');
        }
    }
};
</script>
