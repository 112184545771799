<template>
    <div data-view="treatment-plan">

        <ul v-if="recordsExist" class="records">
            <li
                v-for="record in records"
                :key="record.issue_record_id"
                class="record"
                :data-type="record.type"
                :data-id="record.issue_record_id"
            >
                <router-link :to="toothRoute($store.getters['patient/teeth/tooth'](record.source_tooth_number), 'restoration')">
                    {{ recordMessage(record) }}
                </router-link>
            </li>
        </ul>

        <p v-else>
            {{ t('noTreatment', 'patientVC') }}
        </p>

    </div>
</template>

<script>

export default {
    components: {  },
    data() {
        return {

        };
    },
    computed:
    {
        records() {
            return this.$store.getters['patient/treatmentPlan/orderedActiveItems'];
        },
        recordsExist() {
            return this.records.length > 0;
        }
    },

    methods:
    {
        recordMessage(record) {
            return this.$store.getters['patient/treatmentPlan/recordMessage'](record);
        }
    }

};
</script>
