import { CHART_MODES } from '@/config/teeth.js';

export default {
    computed: {
        backRoute() {
            const back = this.$route.meta.back;

            if (typeof back == 'function') {
                return back.apply(this);
            }

            return back;
        },
        pageTitle() {
            const parts = this.$route.matched.reduce((array, route) => {
                let title = route.meta.title;
                if (title == undefined) {
                    throw "Page title not defined for route: " + route.name;
                }
                if (typeof title == 'function') {
                    title = title.apply(this, [ this.$route ]);
                } else if (title) {
                    title = this.t(title, 'pageTitles');
                }

                if (title) {
                    array.push(title);
                }

                return array;
            }, ['derec®']).reverse();
            return parts.join(" | ");
        }
    },
    methods: {
        chartRouteName(chartMode, mouthView) {
            if (chartMode == CHART_MODES.PERIODONTAL_PROBING) {
                return 'periodontal-probing-' + mouthView;
            } else if (chartMode == CHART_MODES.QUICKSELECT) {
                return 'quickselect-' + mouthView;
            } else {
                return mouthView;
            }
        },

        chartRoute(chartMode, mouthView, extraParams) {
            const name = this.chartRouteName(chartMode, mouthView);
            return {
                name,
                params: Object.assign({}, this.$route.params, extraParams || {})
            };
        },

        toothRoute(tooth, name, extraParams) {
            const routeName = (name) ? name : 'tooth';
            // can only be called from within a view that has patient_id in its params
            return {
                name: routeName,
                params: Object.assign({}, this.$route.params, { tooth_slug: tooth.slug }, extraParams || {})
            };
        },

        // duplicated in composables/useNavigation.js
        goToRoute(route, options) {
            const method = (options && options.replace) ? 'replace' : 'push';
            return this.$router[method](route).catch((error) => {
                if (error && error.name != 'NavigationDuplicated') {
                    throw error;
                }
            });
        },

        // duplicated in composables/useNavigation.js
        ensureTeethStateModifiability() {
            if (this.$store.getters['patient/teeth/state/isModifiable']) {
                return Promise.resolve();
            } else {
                this.$store.dispatch('patient/teeth/state/openRollbackWarning');
                return Promise.reject();
            }
        }
    }
};
