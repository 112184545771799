<template>
    <div class="mouth-charts">

        <div class="full-mouth chart">
            <derec-logo />
            <h2>{{ t('fullJaw', 'pdf') }}</h2>
            <full-mouth :teeth="fullMouthTeeth" mode="report" :layers="chartLayers" />
        </div>

        <div class="legend">
            <legend-list />
        </div>

        <div class="upper-jaw chart">
            <derec-logo />
            <h2>{{ t('upperJaw', 'pdf') }}</h2>
            <single-jaw code="upper" :teeth="upperJawTeeth"  mode="report" :layers="chartLayers" />
        </div>

        <div class="lower-jaw chart">
            <h2>{{ t('lowerJaw', 'pdf') }}</h2>
            <single-jaw code="lower" :teeth="lowerJawTeeth"  mode="report" :layers="chartLayers" />
        </div>


    </div>
</template>
<script>
import FullMouth from '@/components/patient/chart/full-mouth.vue';
import SingleJaw from '@/components/patient/chart/single-jaw.vue';
import DerecLogo from './derec-logo.vue';
import LegendList from '@/components/patient/chart/legend/list.vue';

export default {
    components: { FullMouth, SingleJaw, DerecLogo, LegendList },

    computed: {
        upperJawTeeth() {
            return this.$store.getters['patient/teeth/upperJaw'];
        },
        lowerJawTeeth() {
            return this.$store.getters['patient/teeth/lowerJaw'];
        },
        fullMouthTeeth() {
            return this.$store.getters['patient/teeth/relevant'];
        },
        chartLayers() {
            return this.$store.getters['chart/layers'];
        },

    }
};
</script>
