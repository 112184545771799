<template>
    <main data-view="report">
        <div class="actions">

            <waiting-button
                :for="pdfWaiterName"
                class="button"
                type="button"
                v-on:click="createPdf"
            >
                {{ t('Create PDF', 'report') }}
            </waiting-button>

            <p class="message" :class="{ error: pdfErrorOccured }" v-if="creatingPdf || pdfCreated || pdfErrorOccured">
                <template v-if="creatingPdf">
                    {{ t('Creating PDF file', 'report') }}
                </template>

                <template v-else-if="pdfCreated">
                    {{ t('Your PDF file is ready', 'report') }}
                    <a :href="pdfUrl" download>{{ t('Click here to download', 'report') }}</a>
                </template>

                <template v-else-if="pdfErrorOccured">
                    {{ t('An error occured while creating the PDF file', 'report') }}
                </template>
            </p>

        </div>
        <div class="preview">
            <patient-report />
        </div>
        <subscription-banner />

    </main>
</template>
<script>
import PatientReport from '@/components/patient/report.vue';
import { WAITERS } from '@/config/waiters.js';
import SubscriptionBanner from "@/components/subscription-banner.vue";

export default {
    components: { PatientReport, SubscriptionBanner },

    props: {
        patient: { required: true }
    },

    data: () => {
        return {
            pdfUrl: undefined,
            pdfErrorOccured: false
        };
    },

    computed: {

        creatingPdf() {
            return this.$wait.is(this.pdfWaiterName);
        },

        pdfCreated() {
            return !!this.pdfUrl;
        },

        stateIsHistorical() {
            return this.$store.getters['patient/teeth/state/isHistorical'];
        },

        reportDate() {
            return this.$store.state.patient.teeth.state.teethStateDate;
        },

        suggestedPdfFileName() {
            let isoDateString;
            if (this.stateIsHistorical) {
                // for historical reports, use stored manipulation date for filename
                isoDateString = this.reportDate;
            } else {
                // for current states, use current date for filename.
                // use browser's clock for time, but use signed in user's time zone
                const utcTimestamp = this.$store.getters['time/currentUtcTimestamp'];
                isoDateString = this.dateFromUtcTimestamp(utcTimestamp);
            }

            return this.patient.full_name.replace(/\\|\//g, '-') + ' ' + isoDateString + '.pdf';
        },

    },
    created() {
        this.pdfWaiterName = WAITERS.GENERATING_PDF;
    },
    methods: {

        createPdf() {
            this.clearPdf();
            this.$wait.start(this.pdfWaiterName);

            const parameters = {
                fileName: this.suggestedPdfFileName,
                patient_id: this.patient.id,
                date: this.reportDate,
                notation_system: this.$store.state.settings.currentNotationSystem
            };

            this.$api.createPrintJob(parameters).then(response => {
                const jobId = response.data.id;

                const checkStatus = () => {
                    this.$api.getPrintJobStatus(jobId).then(response => {
                        if (response.data.status == 'initiated') {
                            setTimeout(checkStatus, 1000);
                        } else if (response.data.status == 'ready') {
                            this.$wait.end(this.pdfWaiterName);
                            this.pdfUrl = response.data.downloadUrl;
                        } else {
                            this.pdfErrorOccured = true;
                            this.$wait.end(this.pdfWaiterName);
                        }
                    }).catch(this.apiErrorHandler).catch(error => {
                        this.pdfErrorOccured = true;
                        this.$wait.end(this.pdfWaiterName);
                        throw error;
                    });

                };

                checkStatus();
            }).catch(this.apiErrorHandler).catch(error => {
                this.pdfErrorOccured = true;
                this.$wait.end(this.pdfWaiterName);
                throw error;
            });
        },

        clearPdf() {
            this.pdfUrl = null;
            this.pdfErrorOccured = false;
        }

    }
};
</script>
<style lang="scss">
@import "~@/assets/stylesheets/views/patient/report";
</style>
