import freeze from '@/utils/freeze.js';
import clone from '@/utils/clone.js';

import {
    PROJECTIONS,
    TOOTH_SETS,
    TOOTH_TYPES,
    TOOTH_TYPES_BY_TOOTH_NUMBER,
    TOOTH_NUMBERS_BY_TOOTH_SET
} from '@/config/teeth.js';


// SURFACE OVERLAPS
const OVERLAPPING_SURFACES_FOR_PERMANENT_TEETH = {
    // pattern - surface: [..n surfaces that overlaps key surface]
    [TOOTH_TYPES.INCISOR]: {2: [3], 6: [5], 21: [22], 23: [24], 25: [26], 28: [4], 29: [10]},
    [TOOTH_TYPES.PREMOLAR]: {},
    [TOOTH_TYPES.MOLAR]: {4: [3, 5], 24: [23, 25]}
};


// empty keys for faster surface overlaps search
const emptySurfaceOverlaps = freeze({1: [], 2: [], 3: [], 4: [], 5: [], 6: [],
    7: [], 8: [],  9: [], 10: [], 11: [], 12: [], 13: [], 14: [],
    15: [], 16: [], 17: [], 18: [], 19: [], 20: [], 21: [],
    22: [], 23: [], 24: [], 25: [], 26: [], 27: [], 28: [], 29: [],
    30: [], 31: [], 32: [], 33: [], 34: []
});

const overlappingPermanentTeethSurfaces = (toothSet, toothType) => {
    return { ...emptySurfaceOverlaps, ...OVERLAPPING_SURFACES_FOR_PERMANENT_TEETH[toothType] };
};

const filterNonOverlappingSurfacesForPermanentTeeth = (toothType, projection) => {
    return PERMANENT_TOOTH_SURFACES_BY_TOOTH_TYPE[toothType][projection].filter(item => OVERLAPPING_SURFACES_FOR_PERMANENT_TEETH[toothType][item] == undefined);
};


// SURFACES BY TOOTH NUMBER AND PROJECTION
const toothSurfaces = {};

// for permanent teeth, surfaces are the same for all teeth of the same tooth type
const PERMANENT_TOOTH_SURFACES_BY_TOOTH_TYPE = {
    [TOOTH_TYPES.INCISOR]: {
        [PROJECTIONS.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 28],
        [PROJECTIONS.INCISAL]: [10, 11, 12, 13, 14, 15, 16, 17, 29],
        [PROJECTIONS.PALATAL]: [18, 19, 20, 21, 22, 23, 24, 25, 26, 27],
        overlaps: overlappingPermanentTeethSurfaces(TOOTH_SETS.PERMANENT, TOOTH_TYPES.INCISOR)
    },
    [TOOTH_TYPES.PREMOLAR]: {
        [PROJECTIONS.BUCCAL]: [1, 2, 3, 4, 5, 6, 7],
        [PROJECTIONS.INCISAL]: [8, 9, 10, 11, 12, 13, 14],
        [PROJECTIONS.PALATAL]: [15, 16, 17, 18, 19, 20, 21],
        overlaps: overlappingPermanentTeethSurfaces(TOOTH_SETS.PERMANENT, TOOTH_TYPES.PREMOLAR)
    },
    [TOOTH_TYPES.MOLAR]: {
        [PROJECTIONS.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        [PROJECTIONS.INCISAL]: [11, 12, 13, 14, 15, 16, 17],
        [PROJECTIONS.PALATAL]: [18, 19, 20, 21, 22, 23, 24, 25, 26, 27],
        overlaps: overlappingPermanentTeethSurfaces(TOOTH_SETS.PERMANENT, TOOTH_TYPES.MOLAR)
    },
};

TOOTH_NUMBERS_BY_TOOTH_SET[TOOTH_SETS.PERMANENT].forEach(number => {
    toothSurfaces[number] = PERMANENT_TOOTH_SURFACES_BY_TOOTH_TYPE[TOOTH_TYPES_BY_TOOTH_NUMBER[number]];
});


// for deciduous teeth, surfaces are the same for incisors,
// but different for each individual premolar and molar tooth (but still the same for left/right mirrored teeth)

const deciduousIncisorSurfaces = {
    [PROJECTIONS.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [PROJECTIONS.INCISAL]: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
    [PROJECTIONS.PALATAL]: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34],
    overlaps: emptySurfaceOverlaps
};

toothSurfaces[51] = clone(deciduousIncisorSurfaces);
toothSurfaces[61] = clone(toothSurfaces[51]);

toothSurfaces[52] = clone(deciduousIncisorSurfaces);
toothSurfaces[62] = clone(toothSurfaces[52]);

toothSurfaces[53] = clone(deciduousIncisorSurfaces);
toothSurfaces[63] = clone(toothSurfaces[53]);

toothSurfaces[54] = {
    [PROJECTIONS.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8],
    [PROJECTIONS.INCISAL]: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    [PROJECTIONS.PALATAL]: [20, 21, 22, 23, 24, 25, 26, 27],
    overlaps: emptySurfaceOverlaps
};
toothSurfaces[64] = clone(toothSurfaces[54]);

toothSurfaces[55] = {
    [PROJECTIONS.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    [PROJECTIONS.INCISAL]: [11, 12, 13, 14, 15, 16, 17],
    [PROJECTIONS.PALATAL]: [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28],
    overlaps: emptySurfaceOverlaps
};
toothSurfaces[65] = clone(toothSurfaces[55]);


toothSurfaces[81] = clone(deciduousIncisorSurfaces);
toothSurfaces[71] = clone(toothSurfaces[81]);

toothSurfaces[82] = clone(deciduousIncisorSurfaces);
toothSurfaces[72] = clone(toothSurfaces[82]);

toothSurfaces[83] = clone(deciduousIncisorSurfaces);
toothSurfaces[73] = clone(toothSurfaces[83]);

toothSurfaces[84] = {
    [PROJECTIONS.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    [PROJECTIONS.INCISAL]: [12, 13, 14, 15, 16, 17, 18],
    [PROJECTIONS.PALATAL]: [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    overlaps: emptySurfaceOverlaps
};
toothSurfaces[74] = clone(toothSurfaces[84]);

toothSurfaces[85] = {
    [PROJECTIONS.BUCCAL]: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    [PROJECTIONS.INCISAL]: [12, 13, 14, 15, 16, 17, 18],
    [PROJECTIONS.PALATAL]: [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29],
    overlaps: emptySurfaceOverlaps
};
toothSurfaces[75] = clone(toothSurfaces[85]);


// the structure is TOOTH_SURFACES[toothNumber][projection]
const TOOTH_SURFACES = freeze(toothSurfaces);

export {
    TOOTH_SURFACES,
    filterNonOverlappingSurfacesForPermanentTeeth,
    PERMANENT_TOOTH_SURFACES_BY_TOOTH_TYPE
};
