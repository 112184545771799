import Moment from 'moment-timezone';

export default {
    methods: {
        moment(iso8601utc) {
            return Moment(iso8601utc).tz(this.$store.getters['authentication/userTimeZone']);
        },

        dateFromUtcTimestamp(iso8601utc) {
            // takes into account the user's time zone,
            // so that the date is not simply extracted from the timestamp as a string
            return this.moment(iso8601utc).format('YYYY-MM-DD');
        },

        timeFromUtcTimestamp(iso8601utc) {
            return this.moment(iso8601utc).format('HH:mm');
        }
    }
};
