import merge from 'lodash/merge';
import { RESTORATION_VALUES } from '@/config/teeth.js';
import PathologyAndRestorationType from './pathologyAndRestorationType.js';

export default merge({}, PathologyAndRestorationType, {

    state: {
        parentName: 'restoration'
    },

    getters:
    {
        toothState: (state, getters) => {
            return getters.parentState.editableTooth.state;
        },

        blankState: (state) => {
            return {
                restoration: RESTORATION_VALUES[state.name],
                surfaces: []
            };
        },

        defaultState: (state, getters) => (toothNumber) => {
            const defaultState = JSON.parse(JSON.stringify(getters.baseState));

            const defaultZoneNames = (getters.definition(toothNumber).default || []);
            defaultState.surfaces = getters.calculateSurfaces({ toothNumber, selectedZoneNames: defaultZoneNames, previouslySelectedZoneNames: [], previousSurfaces: [] });

            return defaultState;
        },

        calculateState: (state, getters) => ({ selectedZoneNames, details }) => {
            let calculatedState = JSON.parse(JSON.stringify(getters.baseState)); // clone to not change the original instance

            const toothNumber = getters.toothNumber;
            if (typeof details == 'undefined') {
                details = getters.details(toothNumber);
            }
            Object.keys(details).forEach(detailName => {
                const value = details[detailName].values[0];
                if (value != undefined && value != null) {
                    calculatedState[detailName] = value;
                }
            });

            if (typeof selectedZoneNames == 'undefined') {
                selectedZoneNames = getters.selectedZoneNames();
            }

            const previouslySelectedZoneNames = getters.selectedZoneNames(state.name);
            const previousSurfaces = getters.toothState.surfaces || [];
            calculatedState.surfaces = getters.calculateSurfaces({ toothNumber, selectedZoneNames, previouslySelectedZoneNames, previousSurfaces, details });

            if (calculatedState.surfaces.length < 1 && getters.requireSurfacesForDetailSelection) {
                // for most restorations, removing all surfaces means removing all details as well,
                // so simply restore to the base state
                calculatedState = JSON.parse(JSON.stringify(getters.baseState));
            }

            return calculatedState;
        },

        requireSurfacesForDetailSelection: () => {
            return true;
        },

        // DETAIL CALCULATIONS
        details: (state, getters) => (toothNumber) => {
            if (getters.toothNumber != toothNumber) {
                return {};
            }

            const definition = getters.parent('type', state.name, toothNumber).details || {};
            const toothState = getters.toothState;

            return getters.detailsFromToothState({ toothNumber, definition, toothState });
        },

        detailsFromToothState: (state, getters) => ({ toothNumber, definition, toothState }) => {

            // collect definitions and their values one by one
            // only return details that are appliccable for current state and can be shown in the selector
            let previousDetailValue;
            const details = Object.keys(definition).reduce((details, detailName) => {
                const detailDefinition = definition[detailName];

                const detailValue = toothState[detailName];

                if (
                    // each detail is only available if it is either the first one
                    // or if the previous detail has its value set
                    ((Object.keys(details).length == 0) || (previousDetailValue != undefined))
                    &&
                    // in addition, each detail may have custom rules for when it is available
                    (getters.isDetailAvailable({ toothNumber, toothState, detailName, detailDefinition, details }))
                ) {
                    const detail = JSON.parse(JSON.stringify(detailDefinition));

                    const validValues = detail.options.map(option => option.value);

                    const value = (validValues.includes(detailValue)) ? detailValue : undefined;
                    previousDetailValue = value;
                    detail.values = [value];
                    details[detailName] = detail;
                }

                return details;
            }, {});

            return details;
        },

        isDetailAvailable: () => ({ toothState }) => {
            // no details are available for any restoration until a zone surface has been selected
            return toothState.surfaces && toothState.surfaces.length > 0;
        },


        // TOOTH STATE VALUE CALCULATIONS
        calculateStateValue: ()  => () => {
            return undefined;
        }

    },

    actions:
    {

    }

});
