export default {
    escapeHtml(text) {
        if (typeof text != "string") {
            return '';
        }

        return text.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&apos;')
        ;
    },
    dasherize(text) {
        // convert fooBar to foo-bar
        return text.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`);
    },
    camelize(text) {
        // convert foo_bar-baz to fooBarBaz
        return text.replace(/([-_][a-z])/ig, ($1) => {
            return $1.toUpperCase().replace('-', '').replace('_', '');
        });
    },
    humanize(text) {
        const result = text.replace(/_/g, ' ');
        return result.charAt(0).toUpperCase() + result.slice(1);
    },
    capitalize(text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    },
};
