import Moment from 'moment-timezone';

export default {
    namespaced: true,

    state: {
        clockStarted: false,
        currentTime: undefined
    },

    getters: {
        currentUtcTimestamp: state => { return state.currentTime.utc().format(); }
    },

    mutations: {
        markClockAsStarted(state) {
            state.clockStarted = true;
        },
        updateClock(state, currentTime) {
            state.currentTime = currentTime;
        }
    },

    actions: {
        startClock(context) {
            if (context.state.clockStarted) {
                return;
            }
            context.commit('markClockAsStarted');
            const updateClock = () => {
                // use browser's clock for time, but use signed in user's time zone
                const currentTime = Moment.tz(context.rootGetters['authentication/userTimeZone']);
                context.commit('updateClock', currentTime);
            };
            updateClock();
            setInterval(updateClock, 1000);
        }
    }
};
