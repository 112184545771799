<template>

    <fieldset class="issue selector">

        <div class="options">
            <span
                v-for="issueName in issueNames"
                :key="issueName"
                class="option"
                :data-name="issueName"
            >
                <input
                    type="checkbox"
                    :id="issueId(issueName)"
                    :value="issueValue(issueName)"
                    v-model="editableValue"
                />
                <label :for="issueId(issueName)">{{ t(issueName, 'dental') }}</label>
            </span>
        </div>

    </fieldset>

</template>
<script>
export default {
    props: {
        toothNumber: { required: true },
        zoneName: { required: true },
        value: { required: true }
    },
    data: () => {
        return {

        };
    },
    computed: {
        issueNames() {
            return this.$store.getters['patient/teeth/state/periodontal/issueNames'];
        },
        editableValue:
        {
            get() { return this.value; },
            set(value) { this.$emit('input', value); }
        }
    },
    methods:
    {
        issueValue(issueName) {
            return this.$store.state.patient.teeth.state.periodontal.issueValues[issueName];
        },
        issueId(issueName) {
            return `issue-${issueName}`;
        }
    }
};
</script>
