import freeze from '@/utils/freeze.js';


const SEXTANTS = freeze({
    UPPER_RIGHT: "upper_right",
    LOWER_RIGHT: "lower_right",
    UPPER_ANTERIOR: "upper_anterior",
    LOWER_ANTERIOR: "lower_anterior",
    UPPER_LEFT: "upper_left",
    LOWER_LEFT: "lower_left"
});

const SEXTANTS_IN_ORDER = freeze([
    SEXTANTS.UPPER_RIGHT,
    SEXTANTS.UPPER_ANTERIOR,
    SEXTANTS.UPPER_LEFT,
    SEXTANTS.LOWER_RIGHT,
    SEXTANTS.LOWER_ANTERIOR,
    SEXTANTS.LOWER_LEFT
]);

export {
    SEXTANTS,
    SEXTANTS_IN_ORDER
};
