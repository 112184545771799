<template>
    <modal-dialog v-if="patientLoaded" v-on:open="focusPatientName" v-on:cancel="close()" :close-on-outside-click="false">
        <h1 class="title">{{ t( (editingNewPatient ? 'add' : 'edit'), 'addPatientVC.title') }}</h1>

        <form method="post" v-on:submit.prevent="savePatient" novalidate>

            <p class="last-free-patient" v-if="shouldShowLastFreePatientMessage">
                {{ t('lastPatient', "addPatientVC.warning") }}
            </p>

            <div class="field" v-for="field in patientFields" :key="field.attribute">
                <radio-field
                    v-if="field.type == 'radio'"
                    :name="field.id"
                    :label="t(field.labelKey, 'addPatientVC')"
                    v-model="patientValues[field.attribute]"
                    :options="field.options"
                    :errors="patientErrors[field.attribute]"
                    translation-scope="addPatientVC"
                />
                <birth-date-field
                    v-else-if="field.type == 'birth-date'"
                    :name="field.id"
                    :label="t(field.labelKey, 'addPatientVC')"
                    v-model="patientValues[field.attribute]"
                    :errors="patientErrors[field.attribute]"
                />
                <input-field
                    v-else
                    :name="field.id"
                    type="text"
                    v-model="patientValues[field.attribute]"
                    :label="t(field.labelKey, 'addPatientVC')"
                    :errors="patientErrors[field.attribute]"
                    :ref="field.ref"
                />
            </div>

            <p class="actions">

                <waiting-button :for="waiterName" type="submit" class="button">
                    {{ t('save', 'addPatientVC') }}
                </waiting-button>

                <button class="button link cancel" type="button" v-on:click="close()">{{ t('cancel', 'common') }}</button>
            </p>

        </form>
    </modal-dialog>
</template>

<script>
import ModalDialog from '@/components/modal-dialog.vue';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { ModalDialog },
    props: {
        patient: { required: true }
    },
    data() {
        return {
            patientId: undefined,
            patientValues: undefined,
            patientErrors: {},
            patientFields: [
                {
                    attribute: "full_name",
                    labelKey: "name",
                    id: "patient-name",
                    ref: "patientNameInput",
                },
                {
                    attribute: "date_of_birth",
                    labelKey: "dateOfBirth",
                    id: "patient-date-of-birth",
                    type: 'birth-date'
                },
                {
                    attribute: "address",
                    labelKey: "address",
                    id: "patient-address"
                },
                {
                    attribute: "person_code",
                    labelKey: "idNumber",
                    id: "patient-id-number"
                },
                {
                    attribute: "gender",
                    labelKey: "gender",
                    id: "patient-gender",
                    type: "radio",
                    options: [ "male", "female" ]
                },
            ]
        };
    },
    computed: {
        patientLoaded() { return !!this.patientValues; },
        patientAttributes() { return this.patientFields.map(field => field.attribute); },

        editingNewPatient() { return !this.patientId; },
        editingExistingPatient() { return this.patientId; },

        shouldShowLastFreePatientMessage() {
            return this.$store.getters['authentication/patientLimitApproached'];
        }
    },
    watch:
    {
        patient() {
            this.loadPatient();
        }
    },
    created() {
        this.waiterName = WAITERS.SAVING_PATIENT;
        this.loadPatient();
    },
    mounted() {

    },
    methods:
    {
        loadPatient() {
            if (this.patient) {
                this.patientId = this.patient.patient_id;
                this.patientValues = this.buildPatientValues(this.patient);
                this.patientErrors = {};
            } else {
                this.patientId = undefined;
                this.patientValues = undefined;
                this.patientErrors = {};
            }
        },
        buildPatientValues(patient) {
            return this.patientAttributes.reduce((object, attribute) => {
                object[attribute] = (patient) ? patient[attribute] : null;
                return object;
            }, {});
        },

        focusPatientName() {
            this.$refs.patientNameInput[0].focus();
        },

        close(nextRoute) {
            this.$emit('close');
            if (nextRoute) {
                this.goToRoute(nextRoute);
            }
        },

        savePatient() {
            this.$wait.start(this.waiterName);

            let apiMethod;

            if (this.editingNewPatient) {
                apiMethod = 'createPatient';
            } else if (this.editingExistingPatient) {
                apiMethod = 'updatePatient';
            }

            this.$api[apiMethod](this.patientValues, this.patientId).then((response) => {
                this.$store.dispatch('patientList/refresh');
                let nextRoute;
                if (apiMethod == 'createPatient') {
                    this.$store.dispatch('patientList/increaseTotalNumberOfPatients');
                    nextRoute = { name: 'dashboard', params: { patient_id: response.data.patient_id } };
                }
                this.close(nextRoute);
            }).catch((error) => {
                if (error && error.response && error.response.status == 422 && error.response.data && error.response.data.errors) {
                    this.patientErrors = error.response.data.errors;  // (API #9)
                } else if (error && error.response && error.response.status === 403) {
                    // 403 means that the user is not allowed to create another patient
                    // (this is probably due to the patient limit reached by an unpaid account)

                    // when limit handling is fully implemented in web app,
                    // the new patient dialog should not normally even be available in this case,
                    // and clicking on "NEW PATIENT" should display a message instead of this dialog.

                    // therefore actually receiving a 403 is an edge case (e.g., dialog opened when limit not yet reached,
                    // last allowed patient added in another tab, switched back to the first tab, attempted to submit).
                    // so just add a validation-like error message to the first field in the form.
                    this.patientErrors = { full_name: [ this.t('Free patient limit reached', 'addPatientVC.warning') ] };
                } else {
                    throw error;
                }
            }).catch(this.apiErrorHandler).finally(() => this.$wait.end(this.waiterName));

        }

    }
};
</script>
