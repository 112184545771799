<template>
    <transition name="fade" appear>
        <ul class="tools">
            <li>
                <button type="button" v-on:click="editItem">{{ t('edit', 'common') }}</button>
            </li>
            <li>
                <button type="button" v-on:click="deleteItem">{{ t('delete', 'common') }}</button>
            </li>
        </ul>
    </transition>
</template>

<script>
import CloseOnEscape from '@/mixins/closeOnEscape.js';
import CloseOnClickOutside from '@/mixins/closeOnClickOutside.js';
export default {
    mixins: [ CloseOnEscape, CloseOnClickOutside ],
    methods: {
        close() {
            this.$emit('close');
        },

        editItem() {
            this.$emit('edit');
        },

        deleteItem() {
            this.$emit('delete');
        }
    },
};
</script>
