<template>
    <div class="waiting overlay">
        <page-spinner />
    </div>
</template>
<script>
import { HalfCircleSpinner } from 'epic-spinners';

export default {
    components: { 'PageSpinner': HalfCircleSpinner }
};
</script>
