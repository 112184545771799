<template>
    <aside>
        <nav>
            <ul>
                <li>
                    <back-button />
                </li>
                <navigation-link class="primary" tag="li" :to="$store.getters['navigation/storedChartRoute']">
                    <svg-image
                        name="jaws"
                        :title="t('Chart', 'patientVC')"
                        width="28"
                        height="28"
                    />
                </navigation-link>
                <navigation-link class="primary" tag="li" :to="{ name: 'dashboard' }">
                    <svg-image
                        name="dashboard"
                        :title="t('Dashboard', 'patientVC')"
                        width="28"
                        height="28"
                    />
                </navigation-link>
                <navigation-link class="primary" tag="li" :to="{ name: 'report' }">
                    <svg-image
                        name="report"
                        :title="t('Report', 'patientVC')"
                        width="28"
                        height="28"
                    />
                </navigation-link>
            </ul>
        </nav>

        <ul class="chart-layers" v-if="chartViewOpen">
            <li v-for="name in chartLayers" :key="name">
                <input type="checkbox" :id="chartLayerId(name)" :value="name" v-model="activeChartLayers" />
                <label :for="chartLayerId(name)">{{ t(name, 'dentalChartVC.show') }}</label>
            </li>
        </ul>

    </aside>
</template>

<script>
export default {
    data: () => {
        return {

        };
    },

    computed: {
        chartViewOpen() {
            return this.$route.matched.some(route => route.meta.chart);
        },

        chartLayers() {
            return this.$store.getters['chart/layers'];
        },

        activeChartLayers:
        {
            get() { return this.$store.getters['chart/activeLayers']; },
            set(activeNames) { return this.$store.dispatch('chart/setActiveLayers', activeNames); }
        }

    },
    created() {
        // this ensures all layers are enabled again when opening a new patient
        this.$store.dispatch('chart/activateAllLayers');

    },
    methods: {
        chartLayerId(layerName) {
            return 'chart-layer-' + layerName;
        },

        layerIsActive(layerName) {
            return this.$store.getters['chart/layerIsActive'](layerName);
        }
    }

};
</script>
