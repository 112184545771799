<template>
    <svg :width="width" :height="height" :viewBox="viewBox" :aria-labelledby="titleId" role="img">
        <title :id="titleId">{{ title }}</title>
        <use v-bind="{ 'xlink:href': href }" />
    </svg>
</template>

<script>
export default {
    props: {
        name: String,
        title: String,
        width: [Number, String],
        height: [Number, String],
    },
    computed: {
        titleId() {
            return `${this.name}-image-title`;
        },
        viewBox() {
            return `0 0 ${this.width} ${this.height}`;
        },
        href() {
            return require('@/assets/images/sprite.svg') + '#' + this.name;
        }
    }
};
</script>
