<template>
    <p
        class="field"
        :class="{ dirty: isDirty, 'has-errors': hasErrors }"
        :data-type="type"
        :data-label-type="labelType"
        :data-sizing="sizing"
    >
        <span v-if="label" class="label">
            <label :for="name">{{ label }}</label>
        </span>

        <span class="control" :class="type">
            <template v-if="type == 'select'">
                <select
                    ref="input"
                    :id="name"
                    :disabled="readonly"
                    v-bind="$attrs"
                    v-on="listeners"
                >
                    <option
                        v-for="(optionLabel, optionValue) in options"
                        :key="optionValue"
                        :value="optionValue"
                        :selected="value == optionValue"
                    >
                        {{ optionLabel }}
                    </option>
                </select>
            </template>
            <template v-else-if="type == 'textarea'">
                <textarea
                    ref="input"
                    :rows="rows"
                    :id="name"
                    :value="value"
                    :readonly="readonly"
                    v-bind="$attrs"
                    v-on="listeners"
                />
            </template>
            <template v-else>
                <input
                    ref="input"
                    :id="name"
                    :value="value"
                    :type="type"
                    :readonly="readonly"
                    v-bind="$attrs"
                    v-on="listeners"
                />
            </template>
        </span>

        <field-errors v-if="hasErrors" :errors="errors" />
    </p>
</template>

<script>
import FieldErrors from '@/components/fields/errors.vue';

export default {
    components: { FieldErrors },
    inheritAttrs: false,
    props: {
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        labelType: {
            type: String,
            default: 'compact',
            validator: function (value) {
                return ['compact', 'normal'].includes(value);
            }
        },
        type: {
            type: String,
            default: 'text'
        },
        sizing: {
            type: String,
            default: 'fixed',
            validator: function (value) {
                return ['fixed', 'auto'].includes(value);
            }
        },
        options: {
            required: false
        },
        readonly: {
            required: false
        },
        value: [String, Number],
        errors: Array
    },
    data() {
        return {
            hasFocus: false,
            hasContent: (this.value !== '')
        };
    },
    computed: {
        listeners() {
            const listeners = {
                ...this.$listeners,
                focus: this.onFocus,
                blur: this.onBlur,
                input: this.onInput
            };

            const inputEvent = (this.type == 'select') ? 'change' : 'input';
            if (inputEvent == 'change') {
                const handler = listeners.input;
                delete listeners.input;
                listeners.change = handler;
            }

            return listeners;
        },
        isDirty() {
            return (this.hasFocus && !this.readonly) || this.hasContent;
        },
        hasErrors() {
            return !!this.errors;
        },
        rows() {
            if (this.type === 'textarea' && this.sizing === 'auto') {
                return (this.isDirty) ? 3 : 1;
            }
            return undefined;
        }
    },
    mounted() {
        this.hasContent = (this.$refs.input.value !== '');
    },
    methods: {
        onInput(event) {
            this.hasContent = (event.target.value !== '');
            this.$emit('input', event.target.value);
        },
        onFocus() {
            this.hasFocus = true;
            this.$emit('focus');
        },
        onBlur() {
            this.hasFocus = false;
            this.$emit('blur');
        },
        focus() {
            this.$refs.input.focus();
        },
        blur() {
            this.$refs.input.blur();
        }
    }
};
</script>
