import store from '@/store/store.js';

import PatientTooth from '@/views/patient/tooth.vue';

import ToothOverview from '@/views/patient/tooth/overview.vue';
import ToothPathologyAndRestoration from '@/views/patient/tooth/pathology-and-restoration.vue';
import ToothPeriodontalProbing from '@/views/patient/tooth/periodontal-probing.vue';

export default [{
    path: '/patients/:patient_id/tooth/:tooth_slug',
    name: 'tooth',
    redirect: '/patients/:patient_id/tooth/:tooth_slug',
    component: PatientTooth,
    meta: {
        back: () => store.getters['navigation/storedChartRoute'],
        title: (route) => {
            const tooth = store.getters['patient/teeth/toothBySlug'](route.params.tooth_slug);
            return tooth ? tooth.label : false;
        }
    },
    children: [
        {
            path: 'pathology/:type_name?',
            name: 'pathology',
            component: ToothPathologyAndRestoration,
            meta: { back: { name: 'tooth' }, title: 'Pathology', allowInRollback: false }
        },
        {
            path: 'restoration/:type_name?',
            name: 'restoration',
            component: ToothPathologyAndRestoration,
            meta: { back: { name: 'tooth' }, title: 'Restoration', allowInRollback: false }
        },
        {
            path: 'periodontal-probing/:zone_name?',
            name: 'periodontal-probing',
            component: ToothPeriodontalProbing,
            meta: { back: { name: 'tooth' }, title: 'Periodontal probing', allowInRollback: false }
        },
        {
            path: '',
            name: 'overview',
            component: ToothOverview,
            meta: { back: () => store.getters['navigation/storedChartRoute'], title: false }
        },
    ]
}];
