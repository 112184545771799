import { SEXTANTS_IN_ORDER } from '@/config/basic-periodontal-examination.js';
import BasicPeriodontalExaminationDialog from '@/components/patient/dashboard/basic-periodontal-examination/dialog.vue';
export default {
  __name: 'basic-periodontal-examination-trigger',
  props: {
    patientId: {
      type: String,
      required: true
    }
  },
  setup: function setup(__props) {
    var isDialogOpen = ref(false);
    var defaultDialogSextant = ref(undefined);
    var openDialog = function openDialog() {
      defaultDialogSextant.value = SEXTANTS_IN_ORDER[0];
      isDialogOpen.value = true;
    };
    var closeDialog = function closeDialog() {
      isDialogOpen.value = false;
      defaultDialogSextant.value = undefined;
    };
    return {
      __sfc: true,
      isDialogOpen: isDialogOpen,
      defaultDialogSextant: defaultDialogSextant,
      openDialog: openDialog,
      closeDialog: closeDialog,
      BasicPeriodontalExaminationDialog: BasicPeriodontalExaminationDialog
    };
  }
};