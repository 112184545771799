<template>
    <div data-view="upper-jaw">

        <h2 class="jaw-heading">
            {{ t('upper', 'dentalChartVC.jaw') }}
        </h2>

        <single-jaw code="upper" :teeth="teeth" :mode="mode" :layers="activeChartLayers" />

    </div>
</template>

<script>
import MouthView from '@/mixins/mouthView.js';
import SingleJaw from '@/components/patient/chart/single-jaw.vue';
export default {
    components: { SingleJaw },
    mixins: [ MouthView ],
    props: {
        teeth: { required: true },
    }
};
</script>
