import freeze from '@/utils/freeze.js';
import { TOOTH_TYPES, TOOTH_NUMBERS } from '@/config/teeth.js';


// FRACTURE

const FRACTURE_DETAIL_TYPES = freeze({
    TYPE: "type",
    POSITION: "position"
});

const FRACTURE_TYPES = freeze({
    CROWN: "crown",
    ROOT: "root",
});

const FRACTURE_POSITIONS = freeze({
    VERTICAL: "vertical",
    HORIZONTAL: "horizontal",
});

const FRACTURE_DETAILS = freeze({
    [FRACTURE_DETAIL_TYPES.TYPE]: Object.values(FRACTURE_TYPES),
    [FRACTURE_DETAIL_TYPES.POSITION]: Object.values(FRACTURE_POSITIONS)
});

const definition = {
    toothTypes: [TOOTH_TYPES.INCISOR, TOOTH_TYPES.PREMOLAR, TOOTH_TYPES.MOLAR],
    teeth: { }
};

TOOTH_NUMBERS.forEach(number => {
    definition.teeth[number] = {
        surfaces: { },
        details: FRACTURE_DETAILS
    };
});

const FRACTURE_DEFINITION = freeze(definition);

export {
    FRACTURE_DEFINITION,

    FRACTURE_DETAIL_TYPES,
    FRACTURE_TYPES,
    FRACTURE_POSITIONS,
    FRACTURE_DETAILS,
};
