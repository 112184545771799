<template>
    <section class="endodontic-test panel" :data-name="testName">
        <h2 class="heading">{{ title }}</h2>

        <button class="close" type="button" v-on:click="close" :disabled="anyOptionSavingInProgress">
            <svg-image
                name="close"
                :title="t('close', 'common')"
                width="21"
                height="21"
            />
        </button>

        <ul class="options">
            <li v-for="option in options" :key="option">
                <waiting-button
                    :for="waiterName(option)"
                    type="button"
                    v-on:click="selectOption(option)"
                    :disabled="anyOptionSavingInProgress"
                >
                    {{ optionLabel(option) }}
                </waiting-button>
            </li>
        </ul>

        <p class="actions">
            <waiting-button
                :for="waiterName('clear')"
                class="button link"
                type="button"
                v-on:click="clear"
                :disabled="anyOptionSavingInProgress"
            >
                {{ t('clear', 'endodonticTest') }}
            </waiting-button>
        </p>
    </section>
</template>

<script>
import CloseOnEscape from '@/mixins/closeOnEscape.js';
import { WAITERS } from '@/config/waiters.js';

export default {
    components: { },
    mixins: [ CloseOnEscape ],
    props: {
        tooth: { required: true }
    },
    data: () => {
        return {
            step: "first"
        };
    },
    computed: {
        toothNumber() {
            return this.tooth.number;
        },

        testName() {
            return this.$store.getters['patient/teeth/state/endodontic/testNameInProgress'];
        },

        options() {
            const toothNumber = this.toothNumber;
            const testName = this.testName;

            if (this.step == "first") {
                return this.$store.getters['patient/teeth/state/endodontic/firstStepOptions'](testName);
            } else {
                const firstStepValue = this.$store.getters['patient/teeth/state/endodontic/test']({ toothNumber, testName }).result;
                return this.$store.getters['patient/teeth/state/endodontic/secondStepOptions'](testName, firstStepValue);
            }
        },

        title() {
            const translationKey = this.$options.filters.camelize('endodontic-' + this.testName + '-test');
            return this.t(translationKey, 'dentalChartVC.examination');
        },

        waiterBaseName() {
            return WAITERS.SAVING_ENDODONTIC_STATE;
        },

        anyOptionSavingInProgress() {
            return this.$wait.is(this.waiterBaseName + '*');
        }

    },
    watch: {
        toothNumber() {
            this.endEditingTest();
        },
        testName() {
            this.step = "first";
        }
    },
    beforeDestroy() {
        this.endEditingTest();
    },
    methods:
    {
        optionLabel(option) {
            const testName = this.testName;
            return this.$store.getters['patient/teeth/state/endodontic/optionLabel']({ testName, option });
        },

        close() {
            if (!this.anyOptionSavingInProgress) {
                this.endEditingTest();
            }
        },

        endEditingTest() {
            return this.$store.dispatch('patient/teeth/state/endodontic/endEditingTest');
        },

        waiterName(option) {
            return [
                this.waiterBaseName,
                this.testName,
                option
            ].join(" ");
        },

        selectOption(option) {
            const type = (this.step === "first") ? 'result' : 'subresult';

            if (!this.options.includes(option)) {
                // this prevents a bug with rapid double-clicking on the first step in two-step tests,
                // when the incoming value from the second click is still from the options of the first step,
                // but this.step has already been switched to "second" on the first click,
                // and so the value gets posted as both "result" and "subresult"
                // instead of just setting seting the first step's value
                return;
            }

            const testName = this.testName;
            const value = this.$store.getters['patient/teeth/state/endodontic/optionValue']({ testName, option });

            this.setTestValue(type, value);

            if (this.step === "first" && this.testHasTwoSteps(option)) {
                this.step = "second";
            } else {
                this.save({ waiterName: this.waiterName(option) });
            }
        },

        testHasTwoSteps(firstStepOption) {
            return this.$store.getters['patient/teeth/state/endodontic/testHasTwoSteps'](this.testName, firstStepOption);
        },

        clear() {
            // state needs to be forced here on save
            // instead of modified in editableState and saved from there
            // because if the clear button gets pressed while in second step
            // then changing the state automatically attempts to find second step options
            // for result 0, which is invalid.
            // therefore, pass the expected state directly to save method,
            // so that the page stays on existing step 2 options while saving
            const waiterName = this.waiterName('clear');
            const forcedState = this.$store.getters['patient/teeth/state/endodontic/blankResult'];
            this.save({ waiterName, forcedState });
        },

        setTestValue(type, value) {
            const toothNumber = this.toothNumber;
            const testName = this.testName;
            this.$store.dispatch('patient/teeth/state/endodontic/setEditableTestValue', {
                toothNumber,
                testName,
                type,
                value
            });
        },

        save({ waiterName, forcedState }) {
            const toothNumber = this.toothNumber;
            const testName = this.testName;

            const testValues = (forcedState) ?
                forcedState :
                this.$store.getters['patient/teeth/state/endodontic/loadedTestState']({ toothNumber, testName })
            ;

            return this.$store.dispatch('patient/teeth/state/endodontic/saveTestValues', {
                toothNumber,
                testName,
                testValues,
                waiterName
            }).then(() => {
                this.endEditingTest();
            });

        }


    }
};
</script>
